// Library imports

import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {CreditCardIcon, PencilAltIcon} from '@heroicons/react/outline';

import {ArrowCircleLeftIcon, CheckCircleIcon} from '@heroicons/react/solid';


// Local imports
import NotificationCenter from '../../components/NotificationCenter.jsx';

import AlertModal from '../modals/AlertModal.jsx';
import ConfirmModal from '../modals/ConfirmModal.jsx';
import ClaimAwardModal from '../modals/ClaimAwardModal.jsx';

import {
    createSettlement, customGraphRequest,
    fetchClaim,
    querySettlements,
    updateQuotePrice
} from '../../utils/coreApi.js';

import {alertError, calculateProperties, classNames} from '../../utils/helpers.js';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import UpdateQuotePriceModal from '../modals/quote_modals/UpdateQuotePriceModal';
import UpdateQuoteModal from '../modals/quote_modals/UpdateQuoteModal';
import Safe, {safe} from "../../components/Safe";
import {useDocumentTitle} from "../../components/PageTitle";
import {getCache, hasCache, setCache} from "../../components/useQuery";
import {SUPPLIER_DELIVERY_COST} from "../../utils/graphqlTemplates";
import Tooltip from "../../components/Tooltip";


export default function ClaimIssueAward(props) {

    const setTitle = useDocumentTitle("Award");

    // React router
    const navigate = useNavigate();
    const {claimId} = useParams();

    // UI states
    const [claimAwardOpen, setClaimAwardOpen] = useState(false);

    // Used for errors
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        'heading': '',
        'message': ''
    });

    // Used for success feedback
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationOptions, setNotificationOptions] = useState({
        'heading': '',
        'message': ''
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalOptions] = useState({
        'heading': '',
        'message': '',
        'type': '',
        'buttonText': ''
    });

    const [editReplacementModalOpen, setEditReplacementModalOpen] = useState(false);
    const [editReplacementModalOptions, setEditReplacementModalOptions] = useState(null);


    // Data states
    const [claim, setClaim] = useState(null);
    const [uiClaim, setUiClaim] = useState(null); // All data in this page in state
    const [settlements, setSettlements] = useState(null); // All data in this page in state

    const [awardInfo, setAwardInfo] = useState({name: '', surname: '', mobile_number: '', excess: ''});

    const [updateManualQuoteOpen, setUpdateManualQuoteOpen] = useState(false);
    const [updateManualQuoteOptions, setUpdateManualQuoteOptions] = useState(null);


    // On every page load
    useEffect(() => {

        if (claim === null) {
            fetchClaim(claimId, setClaim, navigate);
        }

        if (claim && !props.navbarTopPageContent.claim) props.setNavbarTopPageContent({'claim': claim});

        if (uiClaim && !settlements) {
            let claimID = claim.id;
            querySettlements(
                claimID,
                setSettlements,
                (error) => {
                    alertError(setAlertOpen, setAlertOptions, error);
                }
            );
        }
        if (settlements && !uiClaim.settlements) {
            setSettlementData();
        }

        if(claim) setTitle(`Award : ${claim.insurer_claim_number}`)

    }, [claim, uiClaim, settlements]);

    useEffect(() => {
        createUiClaim();
    }, [claim]);

    useEffect(() => distributeExcessAmongSuppliers(), [awardInfo, setAwardInfo]);


    // Helper functions

    const onUIClaimUpdate = () => {
        // a simple helper function to set the uiClaim state
        let copy = {...uiClaim};
        setUiClaim(copy);
    };

    const createUiClaim = () => {
        /** Create the uiClaim state */

        if (!claim) return;

        let newClaim = {};

        newClaim.claim = claim;
        newClaim.items = claim.items;

        // make an array of all categories present in this claim
        /* let categories = [ "Smartphones, Tablets & Wearables", "Photography" ]*/
        let categories = [/* {...}, {...} */];
        for (let item of claim.items) {
            let itemCat = item.category;
            if (!(categories.find(cat => cat.id === itemCat.id))) {
                categories.push(itemCat);
            }
        }
        newClaim.categories = categories;

        newClaim.suppliers = claim.suppliers;

        for (let supplier of newClaim.suppliers) {
            supplier.supplier_items = [];
        }

        // in each supplier of this item, make a copy of the item
        for (const item of claim.items) {

            // manual/filter items can have a null supplier panel
            if (!item.supplier_panel) continue;

            // add this item to each supplier that supplies this item
            for (const supplierForThisItem of item.supplier_panel) {
                let copy = {};
                copy = {...item};
                copy.selected_for_award = false;
                let _supplier = newClaim.suppliers.find(s => s.id === supplierForThisItem.id);
                _supplier?.supplier_items.push(copy);
            }
        }

        // setup suppliers
        setSuppliers(newClaim)

        // set delivery costs for this supplier
        for (let supplier of newClaim.suppliers) {
            supplier.delivery_cost_per_category = [];

            // for each category in this supplier
            for (const category of newClaim.categories) {
                /** Check if this supplier's delivery fee has been cached
                 * If not, query the delivery fee, and cache it
                 * Same usage as a normal useQuery, however directly, without the hook */

                let query = `
                    query SupplierDeliveryCost {
                      delivery_cost (|placeholder|){
                        cost
                        error {
                          type
                          message
                          }
                      }
                    }
                `
                let queryArgs = `supplier: "${supplier.id}" category: "${category.id}" claim_id: "${claim.id}"`;
                if (claim.postal_code) queryArgs += ` postal_code:"${claim.postal_code}"`;

                if (claim.locality) queryArgs += ` locality:"${claim.locality}"`;

                query = SUPPLIER_DELIVERY_COST.replace('|placeholder|', queryArgs);
                let deliveryFeeIsCached = hasCache(query);

                function deliveryFeeSuccess(data) {
                    let deliveryCostForThisCategory = {...category};
                    deliveryCostForThisCategory.delivery_cost_for_this_category = data.cost;

                    /** Cache the delivery fee */
                    setCache(query, data, 60);

                    /** DEVELOPMENT: to imitate placeholder delivery data, uncomment this line */
                    /** deliveryCostForThisCategory.delivery_cost_for_this_category = 100;*/

                    supplier.delivery_cost_per_category.push(deliveryCostForThisCategory);
                }

                if (deliveryFeeIsCached) {
                    /** Delivery fee is cached, get and set. Do not query the delivery fee */

                    // Get the cached delivery fee
                    let data = getCache(query);

                    // Add the delivery fee to the supplier
                    let deliveryCostForThisCategory = {...category};
                    deliveryCostForThisCategory.delivery_cost_for_this_category = data.cost;
                    supplier.delivery_cost_per_category.push(deliveryCostForThisCategory);
                }

                else {
                    /** There is no cached delivery fee, query, set, and cache */
                    customGraphRequest(
                        query,
                        (data) => {
                            deliveryFeeSuccess(data);
                        },
                        props.onError,
                    );
                }


            }
        }

        // order the suppliers based on the average rank
        newClaim.suppliers.sort((a, b) => a.average_rank(a) - b.average_rank(b));

        setUiClaim(newClaim);

    };

    const setSuppliers = (newClaim) => {

        for (let supplier of newClaim.suppliers) {
            supplier.do_not_deliver = false;
            supplier.excess = 0;


            supplier.has_selected_items = (_supplier, item = null) => {
                if (item) {
                    return item.selected_for_award;
                }
                return _supplier.supplier_items.find(item => item.selected_for_award) ? true : false;
            };
            supplier.get_selected_items = (_supplier) => {
                return _supplier.supplier_items.filter(item => item.selected_for_award);
            };
            supplier.has_items_in_category = (_supplier, category) => {
                let hasItems = false;
                let _items = _supplier.supplier_items.filter(item => item.category.id === category.id);
                // if (!_item) return false;

                _items.forEach(_item => {
                    let replacement = _supplier.get_recommended_replacement(_supplier, _item);
                    if (replacement.quote) {
                        hasItems = true;
                    }
                });

                return hasItems;
            };
            supplier.has_replaceable_items = (_supplier) => {
                let replaceable = false;
                _supplier.supplier_items.forEach(item => {
                    let replacement = _supplier.get_recommended_replacement(_supplier, item);
                    if (replacement.quote) {
                        replaceable = true;
                    }
                });
                return replaceable;
            };

            supplier.get_recommended_replacement = (_supplier, item) => {
                return item.supplier_panel.find(s => s.id === _supplier.id).recommended_replacement;
            };

            supplier.delivery_cost_for_category = (_supplier, _category) => {
                return _supplier.delivery_cost_per_category.find(category => category.id === _category.id)?.delivery_cost_for_this_category || 0;
            };
            supplier.delivery_cost = (_supplier) => {
                // get selected items
                let selected_items = _supplier.get_selected_items(_supplier);
                let selected_categories = [];
                selected_items.forEach(item => {
                    if (!selected_categories.find(c => c.id === item.category.id)) {
                        selected_categories.push(item.category);
                    }
                });

                let deliveryCost = 0;
                selected_categories.forEach(_category => {
                    let catDeliveryCost = _supplier.delivery_cost_per_category.find(category => category.id === _category.id)?.delivery_cost_for_this_category || 0;
                    if (catDeliveryCost > deliveryCost) {
                        deliveryCost = catDeliveryCost;
                    }
                });

                return deliveryCost;
            };
            supplier.delivery_cost_for_selected_items = (_supplier) => {
                return supplier?.delivery_cost(supplier) || 0;
            };

            supplier.item_total = (_supplier) => {
                let total = 0;
                _supplier.supplier_items.forEach(item => {
                    let replacement = _supplier.get_recommended_replacement(_supplier, item);
                    let itemPrice = item.selected_for_award ? replacement.quote.price : 0;
                    total += itemPrice * item.quantity;
                });
                return total;
            };
            supplier.total_cost = (_supplier, withDelivery = false, withExcess = false) => {
                let total = _supplier.item_total(_supplier);
                if (withDelivery) total += _supplier.delivery_cost(_supplier);
                if (withExcess) total -= _supplier.excess;
                return total;
            };

            supplier.average_rank = (_supplier) => {
                let total = 0;
                let count = 0;
                _supplier.supplier_items.forEach(item => {
                    let rank = item.supplier_panel.find(s => s.id === _supplier.id).ranking;
                    if (rank !== '-') {
                        total += rank;
                        count++;
                    }
                });
                return count > 0 ? total / count : '-';
            }
        }

    }

    const setSettlementData = () => {

        uiClaim.settlements = {...settlements};

        // for each supplier
        uiClaim.suppliers.forEach(supplier => {
            // for item of that supplier
            supplier.supplier_items.forEach(item => {

                item.has_been_settled = false;

                // for each settlement
                settlements.settlements.forEach(settlement => {
                    // for each item that has been settled
                    settlement.items.forEach(settledItem => {

                        if (settledItem.id === item.id) {
                            item.has_been_settled = true;
                            item.selected_for_award = false;
                        }

                    });
                });

            });
        });

        setTimeout(onUIClaimUpdate, 100);

    };

    // onClick functions

    const distributeExcessAmongSuppliers = () => {
        if (!uiClaim) {
            return;
        }

        let excess = awardInfo.excess;

        // --- make sure excess does not exceed total invoice
        let total = uiClaim.suppliers.reduce((_total, _supplierToAdd) => _total + _supplierToAdd.total_cost(_supplierToAdd), 0);
        if (excess > total) {
            awardInfo.excess = 0;
            alertError(setAlertOpen, setAlertOptions, {
                type: 'Excess exceeded',
                message: 'The excess can not exceed the total value of all items'
            });
            return;
        }


        // --- create a list of suppliers - sorted by total cost
        let listOfSuppliers = [];
        uiClaim.suppliers.forEach(supplier => {
            listOfSuppliers.push(supplier);
            supplier.totalCost = supplier.total_cost(supplier, !supplier.do_not_deliver, false);
        });
        // sort the suppliers so that the highest total is first
        listOfSuppliers.sort((a, b) => a.totalCost - b.totalCost);
        listOfSuppliers.reverse();

        // --- assign each supplier their excess amount
        let excessRemaining = excess;
        // tell each supplier their excess
        for (let supplier of listOfSuppliers) {
            if (excessRemaining > supplier.totalCost) {
                // this supplier can only pay excess of up to the suppliers total cost
                let rollOverAmount = excessRemaining - supplier.totalCost; // e.g R20 = R100 - R80
                supplier.excess = excessRemaining - rollOverAmount; // e.g R80 = R100 - R20
                excessRemaining = rollOverAmount; // e.g R20 = R100 - R80
            } else {
                // this supplier's total item cost is LESS than the excess
                // so this supplier will pay the remaining excess
                let rollOverAmount = excessRemaining - supplier.totalCost; // e.g R-5 = R20 - R25
                if (rollOverAmount <= 0) {
                    supplier.excess = excessRemaining; // e.g R20
                    excessRemaining = 0;
                }
            }
        }

        onUIClaimUpdate();
    };

    const issueAward = () => {

        if (!awardInfo.name) return props.showAlertModal('info', 'Invalid name', 'Please enter a first name')
        if (!awardInfo.surname) return props.showAlertModal('info', 'Invalid surname', 'Please enter a surname')
        if (!awardInfo.email) return props.showAlertModal('info', 'Invalid email', 'Please enter a valid email')

        if (!awardInfo.country_code) return props.showAlertModal('info', 'Invalid country code', 'Please select a country mobile code')
        if (!awardInfo.mobile_number) return props.showAlertModal('info', 'Invalid mobile number', 'Please enter a mobile number')

        distributeExcessAmongSuppliers();

        // create a settlement per supplier
        uiClaim.suppliers.forEach(supplier => {
            if (supplier.has_selected_items(supplier)) {
                // this supplier has selected items.

                let items = supplier.get_selected_items(supplier);
                let itemList = [];
                items.forEach(item => itemList.push(item.id));
                createSettlementForSupplier(supplier, itemList);

            }
        });

    };

    function currencyChange(cur = null) {
    if (cur === 0) {
        return '0.00';
    }
    if (cur) {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cur);
    } else {
        return ' ';
    }
}



    const createSettlementForSupplier = (supplier, itemList) => {
        if (awardInfo.mobile_number || awardInfo.name || awardInfo.email || awardInfo.surname) {
            let excess = supplier.excess;
            if (!(supplier.excess)) {
                excess = 0;
            }

            let numberInput = awardInfo.mobile_number;
            if (numberInput[0] === '0') numberInput = numberInput.substring(1); // remove a leading zero
            let wholeNumber = awardInfo.country_code + numberInput;

            createSettlement({
                    claimId: claimId,
                    method: 'AWARD',
                    supplierId: supplier.id,
                    value: supplier.total_cost(supplier, !supplier.do_not_deliver, true),
                    excess: excess,
                    itemIds: itemList,
                    note: null,
                    purchase_order: awardInfo.purchase_order,
                    delivery_fee: supplier.delivery_cost_for_selected_items(supplier),

                    lastName: awardInfo.surname,
                    phoneNumber: wholeNumber,
                    title: awardInfo.name,
                    email: awardInfo.email,
                },
                (data) => {
                    props.showNotificationModal('success', 'Awarded', 'Successfully created settlement');
                },
                props.onError,
            );
        } else {
            document.getElementById('infoCheck').innerText = 'Please fill in the details';
            document.getElementById('infoCheck').css = 'Please fill in the details';
        }

    };

    const onIssueAwardClicked = (event) => {

        props.showConfirmModal(
            'success',
            'Issue Award',
            'Are you sure you want to issue award?',
            'Issue',
            () => {
                issueAward();
            }
        );
    };

    const onItemAwardChecked = (event, item) => {

        // set all instances of this item as unselected
        for (let _supplier of uiClaim.suppliers) {
            for (let _item of _supplier.supplier_items) {
                if (_item.id === item.id) {
                    _item.selected_for_award = false;
                }
            }
        }

        // update only the clicked item
        item.selected_for_award = event.target.checked;

        // make a copy so the state can be updated
        onUIClaimUpdate();

    };

    const getTotalAwardValue = (withExcess = true) => {

        if (uiClaim === null) {
            return 0;
        }

        let total = 0;
        uiClaim.suppliers.forEach(supplier => {
            let supplierTotal = supplier.total_cost(supplier, !supplier.do_not_deliver, withExcess);
            total += supplierTotal;
        });

        return total;
    };

    const getTotalDeliveryCost = () => {

        if (uiClaim === null) {
            return 0;
        }

        let total = 0;
        uiClaim.suppliers.forEach(supplier => {
            let supplierTotal = supplier.delivery_cost_for_selected_items(supplier);
            total += supplierTotal;
        });
        return total;

    };

    const getItemIsSelectedByAnySupplier = (item) => {

        // if this row item is selected = return white
        if (item.selected_for_award) {
            return false;
        }

        // if this row item is not selected - but another row item is - return grey
        let itemRowIsSelectedByAnySupplier = false;
        for (let _supplier of uiClaim.suppliers) {
            for (let _item of _supplier.supplier_items) {
                if (_item.id === item.id) {
                    if (_item.selected_for_award)
                        itemRowIsSelectedByAnySupplier = true;
                }
            }
        }

        return itemRowIsSelectedByAnySupplier;

    };

    const promptUpdateQuotePrice = (item, quote) => {
        /** Open the "Update quote price" modal */

        /** supplierRecommendedReplacement is from the supplier_panel - it is the "recommended replacement(quote product)" from the recommendation engine */

        setUpdateManualQuoteOptions({
            'item': item,
            'quote': quote
        });

        setTimeout(() => {
            setUpdateManualQuoteOpen(true);
        }, 50);
    };
    const onUpdateQuotePrice = (options, buttonHandler) => {

        let item = options.item;
        let quote = options.quote;
        let price = parseFloat(options.price);

        let errorMessage = null;

        if (isNaN(price)) {
            errorMessage = 'Please enter a valid number.';
        } else if (price === quote.price) {
            errorMessage = 'The price has not been changed.';
        }

        if (errorMessage !== null) {
            let error = {
                'type': 'Error: Invalid Input',
                'message': errorMessage
            };
            alertError(setAlertOpen, setAlertOptions, error);
            buttonHandler.onError('Invalid input');
        }

        updateQuotePrice(
            item.id,
            quote.id,
            price,
            options.update_catalog_price,
            false,
            (data) => {
                setUpdateManualQuoteOpen(false);
                fetchClaim(claimId, setClaim, navigate);
                buttonHandler.onSuccess('Quote price updated');
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
                buttonHandler.onError('Failed to update quote price');
            }
        );
    };


    const promptUpdateQuote = (item, supplier, supplierRecommendedReplacement) => {
        /** Open the "Update quote" modal */

        setEditReplacementModalOptions({
            'claim': claim,
            'supplier': supplier,
            'item': item,
            'supplierRecommendedReplacement': supplierRecommendedReplacement,
            'originPage': 'award'
        });
        setEditReplacementModalOpen(true);
    };
    const onSuccessfulEditReplacement = (data) => {
        fetchClaim(claimId, setClaim, navigate);
    };


    // HTML render functions
    const renderSuppliersAndItems = () => {

        if (!uiClaim) {
            return (
                <div className="mt-5">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <LoadingSpinner/>
                        </div>
                    </div>
                </div>
            );
        }


        // TODO why is the below this block empty? confirm it's functionality and remove if not needed
        // let suppliersAndItems = {};
        // for (const item of claim.items) {
        //     if (!item.supplier_panel) continue; // manual/filter items can have a null supplier panel
        //     for (const supplier of item.supplier_panel) {
        //
        //         if (!(supplier.unique_reference_name in suppliersAndItems)) {
        //         }
        //     }
        // }


        // Create an array of all suppliers, and an array of all the categories that appear in all the items of this claim
        let suppliers = [ /* {'id': '123', 'name': 'Supplier1'}, {'id': '124', 'name': 'Supplier2'} */];
        claim.suppliers.map(sup => suppliers.push({
            'id': sup.id,
            'name': sup.display_name,
            'supplier': sup
        }));

        // Create an array of all the categories that appear in all the items of this claim
        let categories = [/* "Smartphones, Tablets & Wearables", "Photography" */];
        for (let item of claim.items) {
            let itemCat = item.category.category_a;
            if (!(categories.includes(itemCat)))
                categories.push(itemCat);
        }

        return (
            <div className="flex flex-col gap-10 mt-10">
                {uiClaim.suppliers.map(supplier => (

                    <>

                        <div className={classNames(
                            supplier.has_replaceable_items(supplier) ? '' : 'hidden',
                            'widget'
                        )}>

                            <div className="flex items-center py-4">
                                <div className="flex-auto">
                                    <h1 className="text-2xl font-semibold text-gray-900 ml-1 font-semibold text-gray-900 sm:pl-6 ">{supplier.display_name}</h1>
                                </div>
                            </div>


                            <div className="px-4 px-8">
                                {uiClaim.categories.map(category => (
                                    <div
                                        className={classNames(supplier.has_items_in_category(supplier, category) ? '' : 'hidden')}>

                                        <div className="flex items-center mt-8">
                                            <div className="sm:flex-auto">
                                                <h3 className="text-xl font-light text-gray-600 ml-1">{category.display_name}</h3>
                                            </div>
                                        </div>


                                        {/*Table */}
                                        <div
                                            className="">
                                            <table className="min-w-full divide-y divide-gray-300 table-fixed">
                                                <thead className="bg-gray-50">
                                                <tr>
                                                    <th>
                                                        #
                                                    </th>
                                                    <th>
                                                        Item
                                                    </th>
                                                    <th>

                                                    </th>
                                                    <th>
                                                        Quote Expiry
                                                    </th>
                                                    <th>
                                                        <Tooltip
                                                            content={(<p>Average rank: {supplier.average_rank(supplier)}</p>)}
                                                            position="top">
                                                            Rank
                                                        </Tooltip>
                                                    </th>
                                                    <th>
                                                        Unit Cost
                                                    </th>
                                                    <th>
                                                        Qty
                                                    </th>
                                                    <th>
                                                        Item Total
                                                    </th>

                                                    <th>
                                                        Award
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200">
                                                {supplier.supplier_items.map(item => (
                                                    safe(()=> renderSingleItem(item, supplier, category) )
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>


                                    </div>
                                ))}


                            </div>

                            <div className="m-6 py-4 bg-gray-50">
                                <div className="sm:flex-auto">

                                    <div className="flex justify-between">
                                        <h1 className="mx-6">Do not deliver:
                                            <input
                                                type="checkbox"
                                                defaultChecked={supplier.do_not_deliver}
                                                onChange={((event) => {
                                                    const currentCheckbox = event.target;
                                                    const isChecked = currentCheckbox.checked; // Capture the new intended state of the checkbox

                                                    props.showConfirmModal(
                                                        'info',
                                                        'Do Not Deliver',
                                                        'Are you sure you want to set this supplier to not deliver?',
                                                        'Do not deliver',
                                                        () => {
                                                            // User confirmed, so proceed with the intended change
                                                            supplier.do_not_deliver = isChecked; // Update your model/state as necessary
                                                            onUIClaimUpdate(); // Perform any additional updates or API calls
                                                        },
                                                        () => {
                                                            // User did not confirm, revert the checkbox state
                                                            currentCheckbox.checked = !isChecked; // Revert the checkbox to its original state
                                                        }
                                                    );
                                                })}
                                                className="mx-4"/>

                                        </h1>
                                        <div className={classNames(
                                            supplier.do_not_deliver ? ' text-gray-500' : '',
                                            'flex text-md'
                                        )}>
                                            <h1 className="mx-6">Delivery Cost:</h1>
                                            <h1 className="mr-12 w-24">{claim && claim.organisation.info.currency_symbol} {supplier.do_not_deliver ? 0 : supplier.delivery_cost_for_selected_items(supplier)}</h1>
                                        </div>
                                    </div>

                                    <div className="flex justify-end">
                                        <h1 className="text-md font-semibold text-gray-900 font-semibold text-gray-900 mx-6 ">Total:</h1>
                                        <h1 className="text-md font-semibold text-gray-900 font-semibold text-gray-900 mr-12 w-24">{claim && claim.organisation.info.currency_symbol} {currencyChange(
                                            supplier.total_cost(supplier, !supplier.do_not_deliver, false))}</h1>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                ))}
            </div>
        );


    };

    const renderSingleItem = (item, supplier, category) => {

        if (item.category.id !== category.id) {
            return (<></>);
        }


        let replacement = supplier.get_recommended_replacement(supplier, item);
        if (!replacement.quote) {
            return (<></>);
        }

        let qty = item.quantity || 0;
        let sup = supplier;
        let currencySymbol = item.replacement && item.replacement.quote.currency_symbol ? item.replacement.quote.currency_symbol : ' ';
        let price = replacement.quote.price;
        let date_expiry = item.replacement && item.replacement.quote.date_expiry ? item.replacement.quote.date_expiry.split(' ')[0] : 0;
        let item_rank = item.supplier_panel.find(_supplier => _supplier.id === supplier.id)?.ranking || '-';
        // let match_accuracy = item.replacement.quote.match_accuracy || "N/A";
        let item_index = sup.supplier_items.indexOf(item) + 1;


        return (
            <tr key={item.id} itemID={item.id}
                className={classNames(getItemIsSelectedByAnySupplier(item) ? 'bg-gray-100' : '')}>
                <td className="">{item_index}</td>
                <td className="">
                    {/*<td className="w-96 py-4 pl-4 ">*/}
                    <div className="flex items-center">
                        <div className="flex-shrink-0">

                        </div>
                        <div className="ml-4">
                            <div>
                                {replacement.product.common_name}
                            </div>
                            <div className="text-gray-600 font-normal mt-1">
                                {item.category.display_name}
                            </div>
                            <dl className="font-normal">
                                <dd className="mt-1 max-w-md text-gray-900">
                                    {replacement.product.type === 'MANUAL' ? replacement.product.description : calculateProperties(replacement.product)}
                                </dd>
                            </dl>
                        </div>
                    </div>
                </td>
                <td className="">
                    <div className='h-full flex justify-center'>
                        <img
                            src={replacement.product.images !== null && replacement.product.images.thumbnail_web ?
                                replacement.product.images.thumbnail_web :
                                'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                            className="h-10 w-15 rounded-full object-right"
                            alt=""
                        />
                        <button className="btn-outline p-2 border-none m-0 ml-2 shadow-none"
                                onClick={() => promptUpdateQuote(item, supplier, replacement)}
                        >
                            <PencilAltIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                    </div>
                </td>
                <td className="">{date_expiry}</td>
                <td className="">{item_rank}</td>
                <td className="" price={price}>
                    <div className="flex items-center w-fit">
                        <p>{currencySymbol} {currencyChange(price)}</p>
                        <button className="btn-outline p-2 border-none m-0 ml-1 shadow-none"
                                onClick={() => promptUpdateQuotePrice(item, replacement.quote)}><PencilAltIcon
                            className="h-5 w-5" aria-hidden="true"/></button>
                    </div>
                </td>
                <td className="" qty={qty}>{qty}</td>
                <td className="">{currencySymbol} {currencyChange(qty * price)} </td>

                <td className="">


                    {item.has_been_settled ?
                        <div
                            className="tooltip-container mr-2 inline-flex items-center text-sm font-medium rounded-md pointer-events-none text-cyan-500"
                        >
                            <CreditCardIcon className="h-7 w-7" aria-hidden="true"/>
                            <span className="tooltip">Issue Voucher</span>
                        </div>
                        :
                        <input
                            className={getItemIsSelectedByAnySupplier(item) ? 'hidden' : ''}
                            type="checkbox"
                            checked={item.selected_for_award}
                            onChange={(e) => onItemAwardChecked(e, item)}
                        />
                    }

                </td>
            </tr>
        );
    };

    return (
        <>
            <div className="">

                <Safe>
                    <ClaimAwardModal
                        open={claimAwardOpen}
                        setOpen={setClaimAwardOpen}
                        onIssueAwardClicked={onIssueAwardClicked}
                        onItemAwardChecked={onItemAwardChecked}
                        awardInfo={awardInfo}
                        setAwardInfo={setAwardInfo}
                        getTotalAwardValue={getTotalAwardValue}
                        claim={claim}
                        getTotalDeliveryCost={getTotalDeliveryCost}
                        uiClaim={uiClaim}
                    />
                </Safe>

                {/* TODO : change usage to base modal */}
                <NotificationCenter
                    open={notificationOpen}
                    message={notificationOptions}
                />

                {/* TODO : change usage to base modal */}
                <AlertModal
                    open={alertOpen}
                    setOpen={setAlertOpen}
                    options={alertOptions}
                />

                <Safe>
                    <UpdateQuoteModal
                        {...props}
                        open={editReplacementModalOpen}
                        setOpen={setEditReplacementModalOpen}
                        options={editReplacementModalOptions}
                        onConfirmClicked={onSuccessfulEditReplacement}
                        onError={(e) => props.onError(e)}
                    />
                </Safe>

                <Safe>
                    <UpdateQuotePriceModal
                        {...props}
                        open={updateManualQuoteOpen}
                        setOpen={setUpdateManualQuoteOpen}
                        options={updateManualQuoteOptions}
                        onConfirmClicked={onUpdateQuotePrice}
                        onError={(e) => props.onError(e)}
                    />
                </Safe>

                <div className="flex flex-col flex-1 body">

                    <Safe>

                        {/* TODO : change usage to base modal */}
                        <ConfirmModal
                            open={confirmModalOpen}
                            setOpen={setConfirmModalOpen}
                            options={confirmModalOptions}
                            onConfirmClicked={() => {
                            }}
                        />
                    </Safe>

                    <main className="flex-1 pb-8">

                        {/* Page header */}
                        <div className="page-header-bar">

                            <h3 className="page-header-title">
                                Award
                            </h3>

                            <div className="flex items-center justify-between">

                                {/* RETURN TO CLAIM PROFILE */}
                                <div
                                    onClick={() => navigate(`/claim/quantification-review/${claimId}`)}
                                    className="tooltip-container btn py-2"
                                >
                                    <span className="">
                                      <ArrowCircleLeftIcon className="mr-2 inline h-5 w-5 align-center"
                                                           aria-hidden="true"/>
                                      Claim Profile
                                    </span>
                                    <span style={{marginTop: '75px', marginLeft: '-15px', width: '200px'}}
                                          className="tooltip">Return to the claim quantification review</span>
                                </div>

                                {/* OPEN AWARD MODAL */}
                                <button
                                    type="button"
                                    className="btn py-2 mx-10"
                                    onClick={() => {
                                        /** Open the award modal
                                         * Confirm that all suppliers have retrieved their delivery fees, before
                                         * allowing the modal to be opened */

                                        let suppliersHaveDeliveryCosts = false;
                                        uiClaim?.suppliers.forEach(supplier => {
                                            if (supplier.delivery_cost_per_category.length > 0) {
                                                suppliersHaveDeliveryCosts = true;
                                            }
                                        });

                                        if(!suppliersHaveDeliveryCosts){
                                            props.showAlertModal(
                                                'info',
                                                'Delivery Costs Not Retrieved',
                                                'Please wait for the delivery costs of all suppliers to be retrieved before issuing an award'
                                            );
                                        } else {
                                            setClaimAwardOpen(true);
                                        }

                                    }}

                                >
                                    <span className="">
                                        <CheckCircleIcon className="mr-1 inline h-5 w-5 align-top"
                                                         aria-hidden="true"/>
                                        Award
                                    </span>
                                </button>

                            </div>

                        </div>


                        <div>
                            <div>
                                {safe(renderSuppliersAndItems)}
                            </div>
                            <br/>

                            <div className="px-4 sm:px-6 lg:px-8">

                                <div className="sm:flex justify-end mt-8">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            /** Open the award modal
                                             * Confirm that all suppliers have retrieved their delivery fees, before
                                             * allowing the modal to be opened */

                                            let suppliersHaveDeliveryCosts = false;
                                            uiClaim?.suppliers.forEach(supplier => {
                                                if (supplier.delivery_cost_per_category.length > 0) {
                                                    suppliersHaveDeliveryCosts = true;
                                                }
                                            });

                                            if(!suppliersHaveDeliveryCosts){
                                                props.showAlertModal(
                                                    'info',
                                                    'Delivery Costs Not Retrieved',
                                                    'Please wait for the delivery costs of all suppliers to be retrieved before issuing an award'
                                                );
                                            } else {
                                                setClaimAwardOpen(true);
                                            }

                                        }}
                                        className="btn mx-10"
                                    >
                                        <span className="ml-auto mr-auto">
                                            <CheckCircleIcon className="mr-1 inline h-5 w-5 align-top"
                                                             aria-hidden="true"/>
                                            Award
                                        </span>
                                    </button>
                                </div>

                            </div>
                        </div>

                    </main>
                </div>

            </div>
        </>
    );
}





import React, {useState} from "react";
import {classNames} from "../../utils/helpers";

export const ImageZoom = (props) => {
    /* This component creates a e-commence style pop-up preview when hovering over a image */

    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className="relative"
            onMouseLeave={() => setIsHovered(false)}
        >
            <img
                src={props.imageSrc}
                alt={props.altText}
                className={`${props.className}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
            {isHovered && (
                <div className="absolute inset-0 z-[110] pointer-events-none">
                    <img
                        src={props.imageSrc}
                        alt={props.altText}
                        className={classNames(
                            'object-cover transition-transform transform',
                            props.zoomClassName || 'scale-[400%]'
                        )}
                    />
                </div>
            )}
        </div>
    );
};
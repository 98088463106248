// Library imports

import {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'

export default function CustomContentModal(props) {

    useEffect(() => {
        if(!props.open)
            props.onClose?.();
    }, [props.open]);

    return (
        <Transition.Root show={props.open} as={Fragment} onClick={() => props.setOpen(false)}>
            <Dialog as="div" className="fixed z-[10] inset-0 overflow-y-auto" onClose={() => {
                props.setOpen(false)
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-50"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-50"
                    leaveTo="opacity-0"
                >
                    {/*<Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-40 transition-opacity" onClick={()=>props.setOpen(false)} />*/}
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="flex items-center justify-center min-h-screen">

                    {/* To prevent Headless UI focusing on the first button (focusable-object) */}
                    <button className='opacity-0 pointer-events-none w-[0px] h-[0px] absolute'></button>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="flex justify-center items-center w-full h-full">

                            <div className='bg-white rounded-xl'
                                 onClick={(e) => {
                                     e.stopPropagation(); // prevent closing the modal from clicking inside of the main content
                                 }}
                            >

                            {/*{typeof props.content === 'function' ? props.content() : props.content}*/}
                                {props.content}

                            </div>

                        </div>


                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

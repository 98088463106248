import {XCircleIcon} from '@heroicons/react/solid';
import React from 'react';
import {clearAllCache, useQuery} from "./useQuery";


/**
 * Standard React "Error Boundary"
 * Usage:
 * - Wrap potentially error-prone components using <Safe>
 * - Use `safe` function to wrap functions that might throw an error
 */


function errorJsx() {

    function clearCacheAndReload() {
        clearAllCache();
        window.location.reload();
    }

    return (
        <div className='w-full h-full flex flex-col justify-center items-center overflow-hidden p-10'>
            <div className='flex'>
                <XCircleIcon className={'h-7 w-7 mr-4 text-gray-400'}/>
                <p className='text-gray-500'>
                    A unexpected error has occurred. Please try again.
                </p>
            </div>
            <button
                className='text-gray-400'
                onClick={clearCacheAndReload}
            >
                Click here to clear cache and reload
            </button>
        </div>
    );
};

export default class Safe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null, errorInfo: null};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
        this.setState({error, errorInfo});
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return errorJsx();
        }

        return this.props.children;
    }
}


// This function is a wrapper for functions that may throw an error
// If the function/component has state - use the above <Safe> component wrapper instead
export function safe(func) {

    // If no error was returned, return the component/function
    try {
        return func();
    }

    // If an error is encountered, return the default error jsx
    catch (e) {
        console.log(e)
        return errorJsx();
    }
}

 /** EXAMPLE USAGE

  // COMPONENT
  <Safe> <SomeComponent /> <Safe/>

  // FUNCTION - NO PARAMS
  {safe(someFunction)}

  // FUNCTION - WITH PARAMS
  {safe(() => someFunctionWithParams(props.claimId))}

*/

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';

import './index.css';
import ErrorBoundary from "./components/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <ErrorBoundary>
            <App/>
        </ErrorBoundary>
    // </React.StrictMode>
);





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();


import React from 'react';

export const Filter = ({column}) => {
    return (
        <div>
            {column.canFilter && column.render('Filter')}
        </div>
    );
};

export const DefaultColumnFilter = ({
                                        column: { // this is the headerGroup object
                                            filterValue,
                                            setFilter,
                                            // Header,
                                            preFilteredRows: {length},
                                        },
                                    }) => {
    return (
        <input
            value={filterValue || ''}
            // id={Header + '_filter'}
            className="table-filter-search"
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder={`Search (${length})`}
        />
    );
};















export const SelectColumnFilter = ({
                                       column: {filterValue, setFilter, preFilteredRows, id},
                                   }) => {
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    console.log("options", options)
    return (
        <>
            <div
                id='custom-select'
                type='select'
                value={filterValue}
                onChange={(e) => {
                    setFilter(e.target.value || undefined);
                }}
            >
                <button id="dropdownRadioButton" data-dropdown-toggle="dropdownRadio"
                        className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button">
                    <svg className="w-4 h-4 mr-2 text-gray-400" aria-hidden="true" fill="currentColor"
                         viewBox="0 0 20 20"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                              clip-rule="evenodd"></path>
                    </svg>
                    Last 30 days
                    <svg className="w-3 h-3 ml-2" aria-hidden="true" fill="none" stroke="currentColor"
                         viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </button>

                <div id="dropdownRadio"
                     className="z-10 hidden w-48 bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                     data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="top"
                     style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(522.5px, 3847.5px, 0px);">
                    <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownRadioButton">
                        {options.map((option) => (<li>
                                <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                    <input id="filter-radio-example-1" type="radio" key={option} value={option}
                                           name="filter-radio "
                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="filter-radio-example-1"
                                           className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"> {option}
                                    </label>
                                </div>
                            </li>
                        ))}
                        {/*<li>*/}
                        {/*    <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">*/}
                        {/*        <input id="filter-radio-example-1" type="radio" value="" name="filter-radio"*/}
                        {/*               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>*/}
                        {/*        <label htmlFor="filter-radio-example-1"*/}
                        {/*               className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Last*/}
                        {/*            day</label>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                    </ul>
                </div>

                {/*<option value=''>All</option>*/}
                {/*{options.map((option) => (*/}
                {/*    <option key={option} value={option}>*/}
                {/*        {option}*/}
                {/*    </option>*/}
                {/*))}*/}
            </div>
        </>

    );
};
/* This file contains hidden components throughout PORTAL, that assist in development */





/* This component is present in the Tender page.
* A button next to the Supplier name, when clicked, will generate random quotes
* for the items in that supplier*/
import {customGraphRequest} from "../../utils/coreApi";

export function CreateRandomQuotesForSupplier(props) {


    function createRandomQuotesForSupplier(supplier_and_items) {

        let quotesCreated = 0;

        let supplier = supplier_and_items.supplier;
        let items = supplier_and_items.items;

        items.forEach(item => {

            // random price between 50 and 10000
            let price = Math.floor(Math.random() * 10000) + 50;

            // Chance to skip this item
            if (Math.random() < 0.5) {
                return;
            }

            quotesCreated++;

            const mutation = `
                mutation {
                  create_manual_quote(
                      item_id:"${item.id}"
                      product:"${item.product.id}" 
                      supplier:"${supplier.id}" 
                      price:${price} 
                      validity_period:1 
                      reason:"random quote" 
                  ) {
                    error {
                      type
                      message
                    }
                    quote {
                      id
                      status
                    }
                  }
                }
            `

            console.log('CREATE RANDOM QUOTE: ', price, item.product.common_name);

            customGraphRequest(
                mutation,
                (data) => {
                    // fetchItemsTendering();
                },
                (error) => {
                    console.log('ERROR: ', error);
                },
            );
        });

        setTimeout(() => props.showToastNotificationModal(
            'success',
            `${quotesCreated} Random Quotes Created`,
            'Randomly priced quotes have been created for a 50% random selection of items for this supplier',
            ),
            1000
        )

    }

    // Only available in localhost
    if(!(window.location.href.includes('localhost')) ) return (<></>);

    return (
        <button
            className="btn-outline text-xs"
            onClick={() => createRandomQuotesForSupplier(props.supplier_and_items)}
        >
            Create Random Quotes
        </button>

    );

}

import moment from "moment-timezone";

export const HUMAN_READABLE_DATETIME = 'DD MMM YY HH:mm'
export const HUMAN_READABLE_DATETIME_TIMEZONE = 'DD MMM YY HH:mm z'


/**
 * Converts a UTC ISO timestamp to the local time equivalent ISO timestamp based on user's timezone.
 *
 * This function uses Moment.js and Moment Timezone to convert a given ISO timestamp from UTC to a
 * local time zone determined by the browser's settings or the server's environment.
 *
 * @param {string} utcTimestamp - The ISO timestamp in UTC (e.g., "2023-04-18T12:00:00Z").
 * @returns {string} Returns the ISO timestamp converted to the local timezone.
 *
 * @example
 * // returns something like "2023-04-18T08:00:00-04:00" if the user is in the EDT timezone
 * convertUTCToLocalTime("2023-04-18T12:00:00Z");
 */
export function convertUTCToLocalTime(utcTimestamp) {
    // Parse the UTC timestamp with moment.utc() to ensure it's treated as UTC
    const momentUtcTime = moment.utc(utcTimestamp);

    // Guess the user's timezone (you could also pass this as a parameter if preferred)
    const userTimezone = moment.tz.guess();

    // Convert the UTC time to the user's local time zone
    const localTime = momentUtcTime.tz(userTimezone);

    // Return the time as an ISO string in the local timezone
    return localTime.format(); // "2024-04-26T23:47:19+09:00"
}

export function utcMomentInstanceFromLocal(localTimestamp) {
    // Create a moment instance from the local time, apply UTC modifying the time (reverse of utc to local time)

    // Create a moment instance
    // Assuming the timestamp is in ISO or Unix timestamp format - e.g. "2023-04-18T12:00:00"
    let instance = moment.utc(localTimestamp);

    // Apply the browser's timezone without modifying the time
    const browserTimezone = moment.tz.guess(); // Guesses the user's timezone
    instance.tz(browserTimezone, true); // Apply timezone without shifting the time

    // At this point, `instance` has the browser's timezone applied but the time hasn't changed

    // Change the instance to UTC timezone, modifying the time of the instance
    instance.tz('UTC'); // This changes the timezone to UTC and adjusts the time accordingly

    return instance;
}

export function utcMoment(timestamp){
    // Create a moment instance, apply the UTC timezone without modifying the time

    let instance = moment.utc(timestamp);
    instance.tz('UTC', true); // This changes the timezone to UTC and adjusts the time accordingly
    return instance;
}

export function localMomentFromLocal(localTimestamp){
    // Create a moment instance, apply the browser's timezone without modifying the time

    // Create a moment instance
    let instance = moment.utc(localTimestamp);

    // Apply the browser's timezone without modifying the time
    const browserTimezone = moment.tz.guess(); // Guesses the user's timezone
    instance.tz(browserTimezone, true); // Apply timezone without shifting the time

    // At this point, `instance` has the browser's timezone applied but the time hasn't changed

    return instance;
}

// Function to convert a UTC timestamp to a local moment instance based on the user's timezone
export function localMomentInstanceFromUTC(utcTimestamp) {
    // Parse the UTC timestamp with moment.utc() to ensure it is treated as UTC time
    const momentUtcTime = moment.utc(utcTimestamp);

    // Guess the user's current timezone using the moment-timezone library
    // This function determines the timezone based on the browser/environment settings
    const userTimezone = moment.tz.guess();

    // Convert the moment object from UTC to the guessed user's timezone
    // This creates a new moment instance that reflects the local time equivalent of the UTC timestamp
    return momentUtcTime.tz(userTimezone);
}

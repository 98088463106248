import React, {useEffect, useState} from "react";
import {useTable, useSortBy, useFilters, useExpanded, usePagination} from "react-table"
import {Filter, DefaultColumnFilter} from './filters';

export default function TableComp(props) {
    let columns = props.columns

    const [data, setData] = useState([props.data])

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow, // rows, -> we change 'rows' to 'page'
        page,
        // visibleColumns, // below new props related to 'usePagination' hook
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: {pageIndex}
        }
        = useTable(
            {
                columns, data, defaultColumn: {Filter: DefaultColumnFilter}, initialState: {pageIndex: 0, pageSize: 10}
            },
            useFilters, useSortBy, useExpanded, usePagination
        )



    const generateSortingIndicator = column => {
        let upA = <button
            className="ml-2">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-alt-circle-up"
                 className="w-4 h-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                      d="M256 504c137 0 248-111 248-248S393 8 256 8 8 119 8 256s111 248 248 248zm0-448c110.5 0 200 89.5 200 200s-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56zm20 328h-40c-6.6 0-12-5.4-12-12V256h-67c-10.7 0-16-12.9-8.5-20.5l99-99c4.7-4.7 12.3-4.7 17 0l99 99c7.6 7.6 2.2 20.5-8.5 20.5h-67v116c0 6.6-5.4 12-12 12z"></path>
            </svg>
        </button>

        let upD = <button
            className="ml-2">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-alt-circle-down"
                 className="w-4 h-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm-32-316v116h-67c-10.7 0-16 12.9-8.5 20.5l99 99c4.7 4.7 12.3 4.7 17 0l99-99c7.6-7.6 2.2-20.5-8.5-20.5h-67V140c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12z"></path>
            </svg>
        </button>


        let symbol = column.isSortedDesc ? upA : upD

        return column.isSorted ? symbol : ""
    }


    return (// <Table bordered hover {...getTableProps()}>
        <>
            <div className="overflow-x-auto">
                <div className="py-2 -inline-block">
                    <table {...getTableProps()} className="">

                        {/* THEAD */}
                        <thead className="">
                            {headerGroups.map((headerGroup) => ( // if you have multiple header rows, this will loop each header row
                                <tr {...headerGroup.getHeaderGroupProps()} className="">
                                    {
                                    headerGroup.headers.map((column) => ( // this is each header/colum
                                        <th {...column.getHeaderProps()} className="">
                                            <div {...column.getSortByToggleProps()} className="">
                                                {column.render("Header")}
                                                {generateSortingIndicator(column)}
                                            </div>

                                            <div className="w-a">
                                                {column.searchable !== false && (<div className="w-a"><Filter column={column}/></div>)}
                                            </div>
                                        </th>))
                                    }
                                </tr>
                            ))}
                        </thead>

                        {/* TBODY */}
                        <tbody {...getTableBodyProps()}>
                            {
                                page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} className="">
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()} className="">{cell.render('Cell')}</td>;
                                            })}
                                        </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
            </div>


            {/*PAGINATION*/}
            <div className="mt-5 mb-10">
                <div className="grid grid-cols-4 gap-4 "
                     style={{maxWidth: 1000, margin: "0 auto", textAlign: "center"}}>
                    <div md={3}>
                        <button
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-100 rounded shadow"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            {"<<"}
                        </button>
                        <button
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-100 rounded shadow"

                            onClick={previousPage}
                            disabled={!canPreviousPage}
                        >
                            {"<"}
                        </button>
                    </div>
                    <div md={2} style={{marginTop: 7}}>
                        Page{" "}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </div>
                    <div md={2}>
                        <input
                            type="number"
                            min={1}
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-100 rounded shadow"
                            style={{width: 70}}
                            max={pageOptions.length}
                            defaultValue={pageIndex + 1}
                            onChange={onChangeInInput}
                        />
                    </div>

                    <div md={3}>
                        <button
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-100 rounded shadow"
                            onClick={nextPage} disabled={!canNextPage}>
                            {">"}
                        </button>
                        <button
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-100 rounded shadow"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            {">>"}
                        </button>
                    </div>
                </div>
            </div>
        </>)
};


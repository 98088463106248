import {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';

import {CheckIcon, ExclamationIcon, RefreshIcon, XIcon} from '@heroicons/react/outline';

// import {
//   CheckCircleIcon
// } from '@heroicons/react/solid'
import {classNames} from '../../utils/helpers.js';
import {InformationCircleIcon} from '@heroicons/react/solid';


export default function ConfirmModal(props){

    const type = props.options.iconType || props.options.type;
    let color = '';

    switch (type) {
        case 'convert':
            color = 'cyan';
            break;

        case 'warning':
            color = 'red';
            break;

        case 'info':
            color = 'yellow';
            break;

        default:
            color = 'green';
    }

    const bgColor = `bg-${color}-100`;
    const highlightColor = `text-${color}-600`;
    const buttonColors = `bg-${color}-600 focus:ring-${color}-500 hover:bg-${color}-700`;

    const renderIcon = () => {

        let className = classNames(highlightColor, 'h-6 w-6');

        if (type === 'convert') {
            return <RefreshIcon className={className} aria-hidden="true"/>;
        } else if (type === 'warning') {
            return <ExclamationIcon className={className} aria-hidden="true"/>;
        } else if (type === 'info') {
            return <InformationCircleIcon className={className} aria-hidden="true"/>;
        } else {
            return <CheckIcon className={className} aria-hidden="true"/>;
        }

    };

    const onConfirmClicked = () => {

        props.setOpen(false);

        if (props.options.onConfirm) props.options.onConfirm(props.options);
        if (props.options.onConfirmClicked) props.options.onConfirmClicked(props.options);

        if (props.onConfirm) props.onConfirm(props.options);
        if (props.onConfirmClicked) props.onConfirmClicked(props.options);
    };

    const onCancelClicked = () => {

        props.setOpen(false);

        if (props.options.onCancel) props.options.onCancel(props.options);
        if (props.options.onCancelClicked) props.options.onCancelClicked(props.options);

    };


    return (
        <Transition.Root show={props.open} as={Fragment}  onClick={() => props.setOpen(false)}>
            <Dialog as="div" className="relative z-[100]" onClose={props.setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >

                            <div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
                                 onClick={(e) => {
                                     e.preventDefault(); // prevent closing the modal from clicking inside of the main content
                                 }}
                            >
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                                        onClick={onCancelClicked}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className={classNames(bgColor, 'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10')}>
                                        {renderIcon()}
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {props.options.heading}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-normal text-gray-800">
                                                {props.options.message}
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                {props.options.kwargs?.showInput && (
                                    <input className='px-4 my-6' placeholder={props.options.kwargs.inputPlaceholder || 'Reason'}
                                           onChange={(e)=> props.setOptions( {...props.options, 'inputValue': e.target.value} ) }/>
                                )}


                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className={classNames(buttonColors, 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm')}
                                        onClick={onConfirmClicked}
                                    >
                                        {(()=>{
                                            if(props.options.buttonText) return props.options.buttonText
                                            if(props.options.confirmButtonText) return props.options.confirmButtonText
                                            return 'OK'
                                        })()}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={onCancelClicked}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

// Library imports
import React, {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {XIcon} from '@heroicons/react/outline';

// Local imports
import OrganisationBox from '../../components/OrganisationBox.jsx';
import UserBox from '../../components/UserBox.jsx';

import {classNames, isEmpty} from '../../utils/helpers.js';
import {
    searchLocalities,
    searchRegions
} from '../../utils/coreApi';
import SelectClaimCompany from '../../components/SelectClaimCompany';
import LocalityBox from '../../components/LocalityBox';
import CountrySelection from '../../components/CountrySelection';
import Safe from "../../components/Safe";
import {useQuery} from "../../components/useQuery";
import ApiButton from "../../components/base/ApiButton";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import Tooltip from "../../components/Tooltip";


export default function CreateClaimModal(props) {


    const [insurerClaimNumber, setInsurerClaimNumber] = useState('');
    const [insurerPolicyNumber, setInsurerPolicyNumber] = useState('');

    const [nationalSuppliersOnly, setNationalSuppliersOnly] = useState(true);
    const [postalCode, setPostalCode] = useState(''); // the user supplied postal code
    const [regionData, setRegionData] = useState(''); // the region data for the postal code
    const [localityData, setLocalityData] = useState(''); // locality data for the postal code
    const [selectedLocality, setSelectedLocality] = useState(''); // the users selected locality


    // ======= USER ORGANISATION'S CONNECTED ORGS & ACCOUNTS =======

    /** All ADMIN and INSURER organisations that are connected to the user's organisation (mainOrganisation) */
    const organisationsHook = useQuery({
        onLoadQueryDelaySeconds: 2,
        queryStringFunction: () => {
            /** The organisation and account data (for logged in user's organisation) needs to be retrieved */
            /** Get all the ['ADMIN', 'INSURER'] organisations that are connected to this organisation */

            let queryArgs = "";

            let orgTypes = ['INSURER', 'ADMINISTRATOR']
            queryArgs += ` types:[${orgTypes.join(", ")}]`;

            let status = 'ACTIVE'
            queryArgs += ` status: ${status}`;

            let minimalOrganisationsQuery = `
            query CreateClaimModal_UserOrgConnectedOrganisations{
              organisations(|placeholder|) {
                error {
                  type
                  message
                }
                organisations {
                  id
                  date_created
                  date_updated
                  status
                  unique_reference_name
                  type
                  account_type
                  info {
                    name
                    country
                    logo_image_url
                  }
                  users {
                    id
                    username
                    info {
                      first_name
                      last_name
                      full_name
                      profile_image_url
                    }
                  }
                }
              }
            }
            `
            return minimalOrganisationsQuery.replace('|placeholder|', queryArgs);
        },
        onSuccess: (data) => {

            // Sort the organisations alphabetically
            let sortedOrganisations = data['organisations'].sort((a, b) => {
                return a.info.name.localeCompare(b.info.name);
            });

            // For each organisation.users, sort the users alphabetically
            sortedOrganisations.forEach(org => {
                org.users = org.users.sort((a, b) => {
                    return a.info.full_name.localeCompare(b.info.full_name);
                });
            });

            setOrganisations(sortedOrganisations);
        },
        onError: props.onError,
        cacheExpirationMin: 60 * 4, // 4 hours
        cacheResponse: true, useExistingCache: true, skipQueryIfCache: true,
    });
    const {state: organisations, setState: setOrganisations} = organisationsHook;

    /** All 'SELF', 'ADMINISTRATOR_INSURER', 'INSURER_INSURER' accounts that are connected to the user's organisation (mainOrganisation) */
    const accountsHook = useQuery({
        onLoadQueryDelaySeconds: 2,
        dependsOn: [props.mainOrganisation],
        /** When the organisation data has been retrieved, retrieve the account data to determine primary and sub organisations */
        queryStringFunction: () => {
            /** Get all the accounts that are connected to the currently logged in organisation */

            if (!props.mainOrganisation) return null

            let queryArgs = '';

            queryArgs += ` status: ACTIVE`;

            queryArgs += ` organisation: "${props.mainOrganisation.id}"`;

            queryArgs += ` types: [${['SELF', 'ADMINISTRATOR_INSURER', 'INSURER_INSURER'].join(', ')}]`;


            let minimalAccountsQuery = `
                query CreateClaimModal_UserOrgConnectedAccounts{ 
                    accounts(|placeholder|) {
                        error{
                            type
                            message
                        }
                
                        accounts
                        {
                            id
                            status
                            type
                            organisation_1{
                                id
                                type
                                unique_reference_name
                            }
                            organisation_2{
                                info{
                                    name
                                    logo_image_url
                                }
                                id
                                type
                                unique_reference_name
                            }
                
                            sub_accounts{
                                id
                                type
                                status
                                organisation_1{
                                    id
                                    type
                                    unique_reference_name
                                }
                                organisation_2{
                                    info{
                                        name
                                        logo_image_url
                                    }
                                    id
                                    type
                                    unique_reference_name
                                }
                
                            }
                
                        }
                
                    }
                }
                `

            return minimalAccountsQuery.replace('|placeholder|', queryArgs);

        },
        onSuccess: (data) => {
            setAccounts(data['accounts']);
        },
        onError: props.onError,
        cacheExpirationMin: 60 * 4, // 4 hours
        cacheResponse: true,
        useExistingCache: true,
        skipQueryIfCache: true,
    });
    const {state: accounts, setState: setAccounts} = accountsHook;

    useEffect(() => {
        /**
         * If the selected country is modified, the selectable primary orgs needs to be re-calculated
         * When the list of "organisations" and "accounts" has been retrieved, the primary organisations need to be calculated
         */

        updatePrimaryOrganisations();
    }, [props.selectedCountry, organisations, accounts]);


    const updatePrimaryOrganisations = () => {
        /** This function must result in a list of organisations that are primary organisations to the selected country
         * And, that are linked (have a account) to the user's organisation (mainOrganisation) */

        if (!organisations) return;
        if (!accounts) return;

        // we only want to display organisations from the selected country
        let organisationsForSelectedCountry = organisations.filter(org => org.info.country === props.selectedCountry.code);

        // only INSURER organisations must display in the CreateClaimModal
        let insurerTypeOrganisations = organisationsForSelectedCountry.filter(org => org.type === 'INSURER');

        // we only care about INSURER_INSURER accounts in order to determine what organisations are primary and what are sub
        let insurerInsurerAccounts = accounts.filter(account => account.type === 'INSURER_INSURER' && account.status === 'ACTIVE');

        // add all the accounts in the 'sub_accounts' fields
        accounts.forEach(account => {
            // Accounts & Primary/Sub organisations : https://silvercloud.atlassian.net/wiki/spaces/POR/pages/1104084993/Accounts+Primary+Sub+organisations
            let accountsForOrg2 = account.sub_accounts; // the sub_accounts field is the 'accounts' query for the organisation_2
            let _insurerInsurerAccounts = accountsForOrg2.filter(a => a.type === 'INSURER_INSURER' && account.status === 'ACTIVE');
            insurerInsurerAccounts = insurerInsurerAccounts.concat(_insurerInsurerAccounts)
        })

        let _primaryOrganisations = [];
        for (const org of insurerTypeOrganisations) {

            // if this organisation exists in an account, where it is organisation_2, then this organisation is a sub org, not a primary org
            let thisIsASubOrganisation = insurerInsurerAccounts.find(account => account.organisation_2.id === org.id && account.status === 'ACTIVE');

            // If this organisation is type INSURER, and is only organisation_1 in all accounts, then it is a Primary organisation
            if (!thisIsASubOrganisation) _primaryOrganisations.push(org);

        }

        if (isEmpty(_primaryOrganisations)) {
            // if there are no primary organisations - then set the mainOrganisation as the single primary organisation
            // this is the case for e.g insurer agents
            // but only set the mainOrganisation, if it is type INSURER. this is the case for admin organisations
            if (props.mainOrganisation.type === 'INSURER') {
                setPrimaryOrganisations([props.mainOrganisation]);
                setSelectedPrimaryOrganisation(props.mainOrganisation);
            } else {
                setPrimaryOrganisations(null);
                setSelectedPrimaryOrganisation(null);
            }


        } else {
            // else set the list of selectable organisations
            setPrimaryOrganisations(_primaryOrganisations);
        }
    };


    // ======= SELECTED PRIMARY ORGANISATION'S ACCOUNTS =======

    // Selectable states
    const [primaryOrganisations, setPrimaryOrganisations] = useState(null);
    const [subOrganisations, setSubOrganisations] = useState(null);

    // Selected states
    const [selectedPrimaryOrganisation, setSelectedPrimaryOrganisation] = useState(null);
    const [selectedSubOrganisation, setSelectedSubOrganisation] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    const [emailSets, setEmailSets] = useState([{firstName: '', lastName: '', email: ''}]);


    /** Get all the accounts that are connected to the selected primary organisation */
    const accountsForOrgHook = useQuery({
        dependsOn: [selectedPrimaryOrganisation],
        queryStringFunction: () => {
            /** Get all the accounts that are connected to the organisation :organisationId: */

            if (!selectedPrimaryOrganisation) return null;

            let queryArgs = '';

            queryArgs += ` status: ACTIVE`;

            queryArgs += ` organisation: "${selectedPrimaryOrganisation.id}"`;

            // if(accountType) queryArgs += ` type: ${accountType}`;
            let accountTypes = ['SELF', 'ADMINISTRATOR_INSURER', 'INSURER_INSURER']
            if (accountTypes) queryArgs += ` types: [${accountTypes.join(', ')}]`;

            let minimalAccountsQuery = `
                query CreateClaimModal_SelectedOrgConnectedAccounts{ 
                    accounts(|placeholder|) {
                        error{
                            type
                            message
                        }
                
                        accounts
                        {
                            id
                            status
                            type
                            organisation_1{
                                id
                                type
                                unique_reference_name
                            }
                            organisation_2{
                                info{
                                    name
                                    logo_image_url
                                }
                                id
                                type
                                unique_reference_name
                            }
                
                            sub_accounts{
                                id
                                type
                                status
                                organisation_1{
                                    id
                                    type
                                    unique_reference_name
                                }
                                organisation_2{
                                    info{
                                        name
                                        logo_image_url
                                    }
                                    id
                                    type
                                    unique_reference_name
                                }
                
                            }
                
                        }
                
                    }
                }
                `
            return minimalAccountsQuery.replace('|placeholder|', queryArgs);
        },
        onSuccess: (data) => {
            /** All the accounts for the selected primary organisation, in order to determine the sub accounts */
            accountsForOrgHook.setState(data['accounts']);
        },
        onError: props.onError,
        cacheExpirationMin: 60 * 4, // 4 hours
        cacheResponse: true, useExistingCache: true, skipQueryIfCache: true,
    });
    const {state: orgAccounts} = accountsForOrgHook;

    useEffect(() => {
        /** Sub organisations
         * when the selected primary organisation has changed, the sub organisation logic needs to be run */

        // Clear the selected sub organisation, to prevent an organisation from a previous primary org being selected,
        // however not displayed to the user, potentially creating a claim under the incorrect organisation
        setSelectedSubOrganisation(null);

    }, [selectedPrimaryOrganisation]);

    useEffect(() => {
        /** When the accounts for the selected primary organisation have been retrieved, determine the selectable sub accounts */
        if (orgAccounts)
            calculateSubOrganisations();
    }, [orgAccounts]);

    // TODO - replace these uses with the consoleColors in the utils/helpers.js
    const red = (_text) => `\x1b[31m${_text}\x1b[0m`;
    const blue = (_text) => `\x1b[36m${_text}\x1b[0m`;
    const green = (_text) => `\x1b[32m${_text}\x1b[0m`;
    const bold = (_text) => `\x1b[1m${_text}\x1b[0m`;

    function calculateSubOrganisations() {
        /** This function must result in a list of organisations that are the sub organisations to the selected Primary organisation */

        console.log(`\n\n${bold('CALCULATING SUB ORGANISATIONS')}`)
        console.log(`${bold('FOR PRIMARY ORGANISATION:')} ${selectedPrimaryOrganisation.unique_reference_name}`)
        let accountsForSelectedPrimaryOrganisation = orgAccounts;

        // only show organisations where the 'primaryOrganisation' is organisation_1
        let accountsForPrimaryOrganisation = accountsForSelectedPrimaryOrganisation.filter(account => account.organisation_1.id === selectedPrimaryOrganisation.id && account.status === 'ACTIVE');
        let subOrganisations = accountsForPrimaryOrganisation.filter(account => account.organisation_2 && account.status === 'ACTIVE');

        // TODO the accounts query now supports users, the account directly for org1 and org2, rather then reverse looking up in the organisations state
        subOrganisations = subOrganisations.map(subOrg => {
            // because accounts query does not include users, find this org in the organisations state and check there
            let thisOrg = organisations.find(org => org.id === subOrg.organisation_2.id);

            if (!thisOrg) {
                // this organisation does not exist in the organisations state, do not include it
                console.log(`${red('EXCLUDING:')} ${subOrg} \n  ${blue('REASON:')} org not present in 'all organisations' ${organisations}`);

                return false;
            }

            if (!thisOrg.users.length && !selectedPrimaryOrganisation.users.length) {
                // this organisation has no users, do not include it
                console.log(`${red('EXCLUDING:')} ${thisOrg.unique_reference_name} \n  ${blue('REASON:')} empty users list in both primary and sub orgs`);
                return false;
            }

            console.log(`${green('INCLUDING SUB ORG:')} ${bold(thisOrg.unique_reference_name)}`)
            return subOrg.organisation_2; // this organisation has users, include it
        }).filter(e => !!e); // "!!e" is a boolean cast operation. It is used in the filter to detect and remove nulls

        // if there are no sub organisations, then set the state to null
        if (isEmpty(subOrganisations)) subOrganisations = null;

        // automatically select the first sub organisation
        // and add the primary organisation as an option - because it is an insurer organisation as well
        if (subOrganisations) {
            setSelectedSubOrganisation(subOrganisations[0]); // automatically select the first sub organisation
            // subOrganisations.push(selectedPrimaryOrganisation); // add the primary as an option
        }

        setSubOrganisations(subOrganisations);
    }


    useEffect(() => {
        /** When the user has entered a Postal code, the Postal and Region data needs to be queried  */
        // TODO cache regions and localities
        if (postalCode)
            getPostalCodes();
    }, [postalCode]);

    const onCreateClaimClicked = (buttonHandler, voucherClaim = false) => {
        /** Create claim mutation */

        let org = selectedSubOrganisation ? selectedSubOrganisation : selectedPrimaryOrganisation;
        props.onCreateClaim(
            org.id,
            selectedUser.id,
            insurerClaimNumber,
            insurerPolicyNumber,
            postalCode,
            selectedLocality,
            emailSets,
            voucherClaim,
            buttonHandler,
        );

    };

    const getPostalCodes = (event) => {
        /** Get the postal code data and region data for the entered postal code */

        // remove any locality selection if a new postal code has been entered
        setSelectedLocality('');

        searchRegions(
            postalCode,
            selectedPrimaryOrganisation.info.country,
            (data) => {
                if (!data.regions.length > 0) {
                    setRegionData({
                        'regions': [
                            {'region_0': 'Invalid postal code'},
                            {'region_1': ''},
                            {'region_2': ''}
                        ]
                    });
                } else {
                    setRegionData(data);
                }
            },
            (error) => {
                console.error('Could not fetch data', error);
            }
        );

        searchLocalities(
            postalCode,
            selectedPrimaryOrganisation.info.country,
            (data) => {
                setLocalityData(data);

                // auto select the first locality
                if (data['localities'][0]) setSelectedLocality(data['localities'][0]);
            },
            (error) => {
                console.error('Could not fetch locality data');
            }
        );
    };

    function selectFromEmailFilter(userEmail) {
        let selectedOrg = null; // Variable to hold the matching organization

        userEmail = userEmail.trim()

        // Iterate through each organization
        organisations.forEach(org => {
            if (selectedOrg) return; // If we've already found the organization, exit early

            // Iterate through each user in the current organization
            org.users.forEach(user => {
                if (user.username === userEmail) {
                    selectedOrg = org; // Set the matching organization
                }
            });
        });

        if (!selectedOrg) return console.log('No user found for email:', userEmail);
        else {
            console.log('Found user for organisation:', selectedOrg);

            setSelectedPrimaryOrganisation(selectedOrg); // Set the selected organization

            // get the user with the email address
            let selectedUser = selectedOrg.users.find(user => user.username === userEmail);
            setTimeout(() => setSelectedUser(selectedUser), 1400); // Set the selected user

        }

    }

    const handleEmailSetInputChange = (index, field, value) => {
        const newEmailSets = [...emailSets];
        newEmailSets[index][field] = value;

        // Check if the current set is now empty (all fields are empty)
        const isCurrentSetEmpty = !newEmailSets[index].firstName && !newEmailSets[index].lastName && !newEmailSets[index].email;

        // If the current set is empty and it's not the only set, remove it
        if (isCurrentSetEmpty && newEmailSets.length > 1) {
            newEmailSets.splice(index, 1);
        } else if (field === 'email' && index === emailSets.length - 1 && value.trim() !== '') {
            // If updating the email of the last item, and it's not empty, add a new empty set
            newEmailSets.push({firstName: '', lastName: '', email: ''});
        }

        setEmailSets(newEmailSets);
    };

    const countryText = () => {

        if (!props.selectedCountry || !props.selectedCountry.code) return 'Please select a country';
        if (!postalCode) return props.selectedCountry.name;

        let regionText = ''

        if (regionData?.regions?.[0]) {
            const region = regionData.regions[0];

            if (region.region_0) regionText += region.region_0;
            if (region.region_1) regionText += (regionText ? ' - ' : '') + region.region_1;
            if (region.region_2) regionText += (regionText ? ' - ' : '') + region.region_2;
        }

        return regionText

    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="z-20 fixed inset-0 overflow-hidden" onClose={() => {
            }}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay
                        className={`absolute inset-0 transition-all duration-300 bg-gray-600 opacity-0 ${props.open ? ' opacity-20' : ' opacity-0'}`}
                        onClick={() => props.setOpen(false)}
                    />

                    <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-[60rem]">
                                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                    <div className="flex-1 h-0 overflow-y-auto scrollbar-hide">
                                        <div className="py-6 px-4 sm:px-6 modal-header">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-lg font-medium text-white">
                                                    Create a new claim
                                                </Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="btn-outline bg-transparent text-white p-1"
                                                        onClick={() => props.setOpen(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-cyan-100">
                                                    Get started by filling in the information below.
                                                </p>
                                            </div>
                                        </div>

                                        {/* COUNTRY SELECTION */}
                                        <div className="w-full my-2">
                                            <Safe><CountrySelection {...props} bgOpacity="bg-opacity-30"/></Safe>
                                        </div>

                                        <div
                                            className={!props.selectedCountry || !props.selectedCountry.code || props.selectedCountry.code === 'WORLD' ? 'flex justify-center mt-6 ' : 'hidden'}>
                                            Please select a country
                                        </div>

                                        <div
                                            className={props.selectedCountry && props.selectedCountry.code && props.selectedCountry.code !== 'WORLD' ? ' opacity-1 ' : ' opacity-0 '}>
                                            <div className="flex flex-wrap w-full px-4 py-6">


                                                <div className="w-1/2 p-4 flex flex-col space-y-4">

                                                    {/* PRIMARY ORGANISATION */}
                                                    <div className='h-[4rem]'>
                                                        <label htmlFor="organisation"
                                                               className="block text-sm font-medium text-gray-900">
                                                            Organisation
                                                        </label>
                                                        <div className="mt-1">
                                                            <Safe>
                                                                <OrganisationBox
                                                                    primaryOrganisations={primaryOrganisations}
                                                                    selectedOption={selectedPrimaryOrganisation}
                                                                    selectedCountry={props.selectedCountry}
                                                                    setSelectedOption={setSelectedPrimaryOrganisation}
                                                                    user={props.user}
                                                                    organisations={organisations}
                                                                />
                                                            </Safe>
                                                        </div>
                                                    </div>

                                                    {/* USER */}
                                                    <div className='h-[4rem]'>
                                                        <label htmlFor="ownedBy"
                                                               className="block text-sm font-medium text-gray-900">
                                                            User
                                                        </label>
                                                        <div className="mt-1">
                                                            <Safe>
                                                                <UserBox
                                                                    users={selectedPrimaryOrganisation ? selectedPrimaryOrganisation.users : null}
                                                                    selectedOption={selectedUser}
                                                                    setSelectedOption={setSelectedUser}
                                                                />
                                                            </Safe>
                                                        </div>
                                                    </div>

                                                    {/* SUB ORGANISATION */}
                                                    <div className='h-[4rem]'>
                                                        <label htmlFor="organisation"
                                                               className="block text-sm font-medium text-gray-900">
                                                            Claim Organisation
                                                        </label>
                                                        <div className="mt-1">
                                                            <Safe>
                                                                <SelectClaimCompany
                                                                    organisations={subOrganisations}
                                                                    selectedOption={selectedSubOrganisation}
                                                                    setSelectedOption={setSelectedSubOrganisation}
                                                                />
                                                            </Safe>
                                                        </div>
                                                    </div>

                                                    {/* EMAIL FILTER */}
                                                    <div className={classNames(
                                                        'h-[4rem]',
                                                        props.mainOrganisation && ['ADMINISTRATOR', 'ROOT'].includes(props.mainOrganisation.type) ? 'visible' : 'hidden'
                                                    )}>
                                                        <label htmlFor="organisation"
                                                               className="block text-sm font-medium italic text-gray-900">
                                                            Filter by email
                                                        </label>
                                                        <div className="mt-1">
                                                            <Safe>
                                                                <input className="input" type="text"
                                                                       placeholder="Enter email"
                                                                       onChange={(e) => selectFromEmailFilter(e.target.value)}/>
                                                            </Safe>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="w-1/2 p-4 flex flex-col space-y-4 m-0">

                                                    {/* NATIONAL SUPPLIERS */}
                                                    <div className="flex gap-3 items-center h-[4rem]">
                                                        <input
                                                            type="checkbox"
                                                            name="nationalSuppliersOnly"
                                                            id="nationalSuppliersOnly"
                                                            // disabled="disabled"
                                                            value={nationalSuppliersOnly}
                                                            checked={nationalSuppliersOnly}
                                                            onChange={(e) => {
                                                                setNationalSuppliersOnly(e.target.checked);

                                                                // if "national suppliers only" is checked on, clear postal region data
                                                                if (e.target.checked) {
                                                                    setPostalCode('');
                                                                    setSelectedLocality('');
                                                                    setRegionData('');
                                                                }
                                                            }
                                                            }
                                                            className="checkbox"
                                                        />

                                                        <label htmlFor="project-name"
                                                               className="block text-sm font-medium text-gray-900">
                                                            National Suppliers Only
                                                        </label>

                                                    </div>

                                                    {/* POSTAL CODE */}
                                                    <div className='h-[4rem]'>
                                                        <label htmlFor="project-name" className={classNames(
                                                            nationalSuppliersOnly ? 'text-gray-500'
                                                                : 'text-gray-900', 'block text-sm font-medium')}
                                                        >
                                                            Postal code
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                type="text"
                                                                onChange={(event) => {
                                                                    // Trim the edges of the postal code
                                                                    const value = event.target.value.trim();
                                                                    setPostalCode(value);
                                                                }}
                                                                value={postalCode}
                                                                name="postalCode"
                                                                id="postalCode"
                                                                onClick={() => {
                                                                    setNationalSuppliersOnly(false);
                                                                }
                                                                }
                                                                className="input"
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* COUNTRY */}
                                                    <div className='h-[4rem]'>
                                                        <label htmlFor="project-name" className={classNames(
                                                            nationalSuppliersOnly ? 'text-gray-500'
                                                                : 'text-gray-900', 'block text-sm font-medium')}>
                                                            Country
                                                        </label>
                                                        <div className="mt-1">
                                                            <div
                                                                className="text-sm mt-2 text-gray-800">
                                                                {countryText()}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* LOCALITY */}
                                                    <div className='h-[4rem]'>
                                                        <label htmlFor="locality" className={classNames(
                                                            nationalSuppliersOnly ? 'text-gray-500'
                                                                : 'text-gray-900', 'block text-sm font-medium')}
                                                        >
                                                            Locality
                                                        </label>
                                                        <div className="mt-1">
                                                            <Safe>
                                                                <LocalityBox
                                                                    locality={localityData}
                                                                    selectedOption={selectedLocality}
                                                                    setSelectedOption={setSelectedLocality}
                                                                />
                                                            </Safe>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="w-1/2 p-4 flex flex-col space-y-4">

                                                    {/* CLAIM NUMBER */}
                                                    <div className='h-[4rem]'>
                                                        <label htmlFor="project-name"
                                                               className="block text-sm font-medium text-gray-900">
                                                            Claim number
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                type="text"
                                                                onChange={(event) => setInsurerClaimNumber(event.target.value)}
                                                                value={insurerClaimNumber}
                                                                name="insurerClaimNumber"
                                                                id="insurerClaimNumber"
                                                                className="input"
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* POLICY NUMBER */}
                                                    <div className='h-[4rem]'>
                                                        <label htmlFor="project-name"
                                                               className="block text-sm font-medium text-gray-900">
                                                            Policy number
                                                        </label>
                                                        <div className="mt-1">
                                                            <input
                                                                type="text"
                                                                onChange={(event) => setInsurerPolicyNumber(event.target.value)}
                                                                value={insurerPolicyNumber}
                                                                name="insurerPolicyNumber"
                                                                id="insurerPolicyNumber"
                                                                className="input"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="text-sm text-gray-500 pt-4">
                                                        A claim and/or policy number is required.
                                                    </div>

                                                </div>

                                                <div className="w-1/2 p-4 flex flex-col space-y-4">

                                                    {/* CC EMAIL */}
                                                    <div className='h-[4rem]'>
                                                        <label htmlFor="organisation"
                                                               className="block text-sm font-medium text-gray-900">
                                                            CC Emails
                                                        </label>
                                                        <div className="mt-1 tailwind-tooltip-container">
                                                            <Safe>
                                                                {
                                                                    emailSets.map((set, index) => (
                                                                        <div key={index} className="mb-4">
                                                                            <div className="flex space-x-2 mb-2">
                                                                                <input
                                                                                    type="text"
                                                                                    placeholder="First Name"
                                                                                    value={set.firstName}
                                                                                    onChange={(e) => handleEmailSetInputChange(index, 'firstName', e.target.value)}
                                                                                    className="input flex-1"
                                                                                />
                                                                                <input
                                                                                    type="text"
                                                                                    placeholder="Last Name"
                                                                                    value={set.lastName}
                                                                                    onChange={(e) => handleEmailSetInputChange(index, 'lastName', e.target.value)}
                                                                                    className="input flex-1"
                                                                                />
                                                                            </div>
                                                                            <input
                                                                                type="email"
                                                                                placeholder="Email"
                                                                                value={set.email}
                                                                                onChange={(e) => handleEmailSetInputChange(index, 'email', e.target.value)}
                                                                                className="input w-full"
                                                                            />
                                                                        </div>
                                                                    ))
                                                                }

                                                            </Safe>
                                                        </div>
                                                    </div>

                                                </div>

                                                {/* DIVIDER LINE */}
                                                <div className="border-b border-gray-200 pt-3"></div>

                                                {/* SPACER TO PREVENT PASSWORD MANAGER BUG */}
                                                <div className='h-[12rem]'></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={props == null || props.selectedCountry == undefined || props.selectedCountry == 'Global' ? 'invisible' : 'flex-shrink-0 px-4 py-4 gap-4 flex justify-end'}>

                                        <button
                                            type="button"
                                            className="btn-light"
                                            onClick={() => props.setOpen(false)}
                                        >
                                            Cancel
                                        </button>

                                        <Tooltip content='Create an instantly quantified voucher claim'>
                                            <ApiButton
                                                onClick={(buttonHandler) => onCreateClaimClicked(buttonHandler, true)}
                                                className='btn-outline'
                                                content='Voucher'
                                                loadingContent={<LoadingSpinner color='darkcyan' size='6' body=''/>}
                                                successContent='Claim created'
                                                errorContent='Failed to create claim'
                                                defaultStatus={'idle'}
                                            />
                                        </Tooltip>

                                        <ApiButton
                                            onClick={onCreateClaimClicked}
                                            className='btn'
                                            content='Create'
                                            loadingContent={<LoadingSpinner color='darkcyan' size='6' body=''/>}
                                            successContent='Claim created'
                                            errorContent='Failed to create claim'
                                            defaultStatus={'idle'}
                                        />

                                    </div>
                                </div>

                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );

}


const minute_ratelimit = 400;
const hour_ratelimit = 10000;

export default function ratelimited() {

    initialise();

    // Increase count
    sessionSet('rl_min', sessionGet('rl_min') + 1);
    sessionSet('rl_hour', sessionGet('rl_hour') + 1);

    // Check for runaway api calls
    // let totalHits = sessionGet('rl_min') + sessionGet('rl_hour');
    // if (totalHits > 1000) {
    //     console.error("Runaway API calls detected - redirecting");
    //     // alert("Error: Runaway API calls detected")
    //     window.location.href = '/';
    //     return true;
    // }

    // check if minute ratelimit is exceeded
    let count = parseInt(sessionGet('rl_min'));
    if (count > minute_ratelimit) {
        console.error("Ratelimited: m");
        alertError("Too many request - Please wait a minute before continuing");
        return true;
    }

    // check if hour ratelimit is exceeded
    count = parseInt(sessionGet('rl_hour'));
    if (count > hour_ratelimit) {
        console.error("Ratelimited: h");
        alertError("Too many request - Please try again after an hour");
        return true;
    }

    return false;
}

function sessionGet(key) {
    return parseInt(sessionStorage.getItem(key));
}

function sessionSet(key, v) {
    sessionStorage.setItem(key, v.toString());
}

function initialise() {
    /** set up the intervals to clear the count every minute and hour */

    if (!window.minuteInterval || !window.hourInterval) {
        window.minuteInterval = setInterval(() => {
            sessionStorage.setItem('rl_min', '0');
        }, 1000 * 60);

        window.hourInterval = setInterval(() => {
            sessionStorage.setItem('rl_hour', '0');
        }, 1000 * 60 * 60);
    }

    if (!sessionGet('rl_min')) {
        sessionSet('rl_min', '0');
    }
    if (!sessionGet('rl_hour')) {
        sessionSet('rl_hour', '0');
    }

}

let alertTimeout = null;
function alertError(message) {
    // TODO change to new modal
    // window.base.alertModal.onError("Too many request", "Please wait a minute before continuing");

    // do not alert or redirect if the ratelimited page is open
    if(window.location.href.includes("ratelimited")){
        return
    }

    window.clearTimeout(alertTimeout)
    alertTimeout = setTimeout(()=>{
        window.location.href = '/ratelimited'
        // alert(message)
        window.clearTimeout(alertTimeout)
    }, 1000)

}


// Library imports
import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {ArrowCircleLeftIcon, XIcon as XIconSolid} from '@heroicons/react/solid';

// Local imports
import NotificationCenter from '../../components/NotificationCenter.jsx';

import {
    calculateReplacements,
    createClaimComment,
    fetchClaim,
    updateItemClaimantQuote,
    updateItemQuantity
} from '../../utils/coreApi.js';
import {calculateProperties, escapeDoubleQuotes, formatAsTitle} from '../../utils/helpers.js';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import {safe} from "../../components/Safe";
import {useDocumentTitle} from "../../components/PageTitle";
import {BasicImage} from "../../components/base/BasicImage";
import ApiButton from "../../components/base/ApiButton";

const claimQuantifyMessage = {
    'heading': 'Claim Quantifying...',
    'message': 'Redirecting to next page...'
};

export default function ClaimCartReview(props) {

    const setTitle = useDocumentTitle("Cart Review");

    // React router
    const navigate = useNavigate();

    const {claimId} = useParams();

    // UI states
    const [successMessageOpen, setSuccessMessageOpen] = useState(false);

    const [claim, setClaim] = useState(null);

    const customPriceChange = (itemID, price) => {
        if(price === '' || price === null) return;

        if (price === '0' || price == 0) return props.showAlertModal('info', 'Invalid price', 'The price must be a positive number');

        document.getElementById(`ms${itemID}`).textContent = ' ';
        document.getElementById(`me${itemID}`).textContent = ' ';
        updateItemClaimantQuote(
            itemID,
            price,
            (data) => {
                document.getElementById(`ms${itemID}`).textContent = 'Quote Updated';
            },
            (error) => {
                console.log('Failed to add custon price');
                document.getElementById(`me${itemID}`).textContent = 'Error Updating Quote';
            }
        );
    };

    // On every page load
    useEffect(() => {

        if (claim === null) {
            fetchClaim(claimId, setClaim, navigate);
        }

        if(claim) props.setNavbarTopPageContent({'claim': claim})

        if(claim) setTitle(`Cart : ${claim.insurer_claim_number}`)

    }, [claim]);


    // onClick functions
    const confirmDeleteItem = (itemId) => {

        let quantity = 0;

        updateItemQuantity(
            itemId,
            quantity,
            (data) => {
                fetchClaim(claimId, setClaim, navigate);
                props.showToastNotificationModal('success', 'Item removed', 'The item has been removed from the claim');
            },
            (error) => {
                props.onError(error);
            }
        );
    };

    const promptDeleteItem = (event) => {

        let itemId = event.currentTarget.id;
        let commonName = event.currentTarget.getAttribute('name');

        props.showConfirmModal(
            'warning',
            'Remove item',
            (<div>
                <p>Are you sure you want to remove:</p>
                <p><b>{commonName}?</b></p>
            </div>),
            'Remove item',
            () => {
                confirmDeleteItem(itemId);
            }
        )

    };

    const updateQuantity = (event) => {

        let itemId = event.currentTarget.getAttribute('name');
        let quantity = event.currentTarget.value;

        updateItemQuantity(
            itemId,
            quantity,
            (data) => {
                fetchClaim(claimId, setClaim, navigate);
            },
            (error) => {
                props.onError(error);
            }
        );

    };

    const quantifyClaim = (buttonHandler) => {

        createClaimComments();

        calculateReplacements(
            claimId,
            (data) => {
                buttonHandler.onSuccess();
                setSuccessMessageOpen(true);
                setTimeout(() => {
                    navigate(`/claim/quantification-review/${claimId}`);
                }, 1000);
            },
            (error) => {
                buttonHandler.onError();
                props.onError(error);
            }
        );

    };

    const createClaimComments = () => {

        const comment = document.getElementById('cartReviewText').value;

        if (comment) {

            let commentMessage = comment.replace(/\s/g, ' ');
            commentMessage = escapeDoubleQuotes(commentMessage);

            createClaimComment(
                claimId,
                commentMessage,
                (data) => {},
                (error) => props.onError(error)
            );

        }
    }



    // HTML render functions

    const cartItems = () => {

        if (claim === null) {
            return (
                <ul role="list" className="border-t border-b border-gray-200 divide-y divide-gray-200">
                    <div className="m-12"><LoadingSpinner/></div>
                </ul>
            );
        }

        return (
            <ul role="list" className="widget ml-8 divide-y divide-gray-200">
                {claim.items.map((item, itemIdx) => (
                    <li key={item.id} className="flex py-6 sm:py-10">
                        <div className="flex-shrink-0">
                            <BasicImage
                                src={item.product.images && item.product.images.thumbnail_web}
                                fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                alt="Product Image"
                                sizeWidthRem="20"
                                sizeHeightRem={20 * 0.75} // 4:3 aspect ratio
                                className="rounded-md"
                            />
                        </div>

                        <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                            <div className="relative pr-9">

                                {/* ITEM */}
                                <div>
                                    <div className="flex justify-between">
                                        <h3 className="font-medium text-lg hover:text-gray-700">
                                            {item.is_manual ? item.product.brand || item.product.model_numbers[0] : item.product.common_name}
                                        </h3>
                                    </div>
                                    <div className="mt-1 flex text-sm">
                                        <p className="text-md text-gray-800">
                                            {item.is_manual ? `${item.product.brand} | ${item.product.description}` : calculateProperties(item.product)}
                                        </p>
                                    </div>
                                    <div className="mt-1 text-sm font-medium text-gray-900">
                                        {item.product.model_number !== null ? (
                                            <p className="text-sm text-gray-500">{item.product.model_number}</p>
                                        ) : (
                                            <p className="text-sm text-gray-500">{item.product.model_numbers.slice(0, 3).join(' | ')}</p>
                                        )}
                                        {item.is_manual ? `${formatAsTitle(item.category.category_a)} >  ${formatAsTitle(item.product.category)}` : ''}

                                    </div>
                                    {item.is_manual ? (
                                        <div className="mt-2 flex text-sm">
                                      <span
                                          className="manual-item-tag">
                                        Manual item
                                      </span>
                                        </div>
                                    ) : <div></div>}
                                </div>

                                <div className="mt-4">

                                    {/* QUANTITY */}
                                    <label htmlFor={`quantity-${itemIdx}`} className="sr-only">
                                        Quantity, {item.product.common_name}
                                    </label>
                                    <div className="absolute top-0 right-0">
                                        <select
                                            id={`quantity-${itemIdx}`}
                                            name={item.id}
                                            defaultValue={item.quantity}
                                            onChange={updateQuantity}
                                            className="select"
                                        >
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                            <option value={7}>7</option>
                                            <option value={8}>8</option>
                                            <option value={9}>9</option>
                                            <option value={10}>10</option>
                                            {item.quantity > 10 && <option>{item.quantity}</option>}
                                        </select>

                                        {/* DELETE ITEM */}
                                        <button type="button" onClick={promptDeleteItem} id={item.id}
                                                name={item.product.common_name}
                                                className="btn-light shadow-none">
                                            <span className="sr-only">Remove</span>
                                            <XIconSolid className="h-5 w-5" aria-hidden="true"/>
                                        </button>


                                        {/* CUSTOM QUOTE */}
                                    </div>
                                    <div className="mt-5 absolute left-0">
                                        <div className="left-0 flex items-center  text-sm font-medium text-gray-700 ">
                                            <span> Client's Quote (optional)</span>
                                            <div className="relative mt-1 rounded-md Dshadow-sm ml-5">

                                                <div
                                                    className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ">
                                                    <span
                                                        className="text-gray-500 sm:text-sm">{(claim.organisation.info.currency_symbol || '-')}</span>
                                                </div>

                                                <input
                                                    type="text"
                                                    id={item.id}
                                                    className="input block w-1/2  pl-7 pr-12 sm:text-sm"
                                                    placeholder={item.claimant_quote}
                                                    onBlur={(event) => {
                                                        customPriceChange(item.id, event.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <span id={`ms${item.id}`} className="text-green-700 sm:text-sm"></span>
                                        <span id={`me${item.id}`} className="text-red-700 sm:text-sm"></span>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    const claimSummary = () => {

        if (claim === null) {
            return (
                <section
                    aria-labelledby="summary-heading"
                    className="widget mt-16 px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
                >
                    <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                        Claim summary
                    </h2>
                    <div className="mt-10 mb-8"><LoadingSpinner/></div>
                </section>
            );
        }

        return (
            <section
                aria-labelledby="summary-heading"
                className="widget mt-16px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
            >
                <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                    Claim summary
                </h2>

                <dl className="mt-6 space-y-4">
                    <div className="flex items-center justify-between">
                        <dt className="text-sm text-gray-600">Claim Number</dt>
                        <dd className="text-sm font-medium text-gray-900">{claim.insurer_claim_number}</dd>
                    </div>
                    <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                        <dt className="flex items-center text-sm text-gray-600">
                            <span>Location</span>
                        </dt>
                        <dd className="text-sm font-medium text-gray-900">{claim.location_text}</dd>
                    </div>
                    <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                        <dt className="flex items-center text-sm text-gray-600">
                            <span>Unique Item Count</span>
                        </dt>
                        <dd className="text-sm font-medium text-gray-900">{claim.item_count_unique}</dd>
                    </div>
                    <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                        <dt className="flex items-center text-sm text-gray-600">
                            <span>Total Item Count</span>
                        </dt>
                        <dd className="text-sm font-medium text-gray-900">{claim.item_count}</dd>
                    </div>
                    <div className="border-t border-gray-200 pt-4 ">
                        <dt className="flex items-center text-sm text-gray-600">
                            <span>Claim Notes (optional)</span>
                        </dt>
                        <br/>
                        <textarea id="cartReviewText"
                                  className="input mb-4 w-full bg-white rounded-lg border border-gray-200"></textarea>
                    </div>
                </dl>

                <div className="mt-6">

                    <ApiButton
                        onClick={quantifyClaim}
                        className='btn w-full block text-center text-md p-4'
                        content={'Quantify'}
                        successContent={'Quantified'}
                        errorContent='Failed to quantify'
                    />

                </div>
            </section>
        );
    };

    // Page content

    return (
        <>
            <div className="">

                <NotificationCenter
                    open={successMessageOpen}
                    message={claimQuantifyMessage}
                />

                <div className="flex flex-col flex-1">

                    <main className="flex-1 pb-8">

                        {/* Page header */}
                        <div className="page-header-bar">

                            <h1 className="page-header-title">
                                Cart Review
                            </h1>

                            <Link
                                type="button"
                                to={`/claim/item-search/${claimId}`}
                                className="btn m-4 tooltip-container"
                            >
                                <span className="ml-auto mr-auto">
                                  <ArrowCircleLeftIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                                  Back to search
                                </span>
                                <span
                                    style={{marginTop: '75px', marginLeft: '-17px', width: '152px'}}
                                    className="tooltip"
                                >
                                  Go to search page
                                </span>
                            </Link>
                        </div>

                        <div>
                            <div className="mt-2 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
                                <section aria-labelledby="cart-heading" className="lg:col-span-7">
                                    <h2 id="cart-heading" className="sr-only">
                                        Items in your shopping cart
                                    </h2>

                                    {safe(cartItems)}
                                </section>

                                {safe(claimSummary)}
                            </div>

                        </div>
                    </main>
                </div>

            </div>
        </>
    );

}

import {Listbox, Transition} from "@headlessui/react";
import {ArrowDownIcon, CheckIcon} from "@heroicons/react/solid";
import React, {Fragment} from "react";
import {classNames, isEmpty} from "../../utils/helpers";
import LoadingSpinner from "../ui/LoadingSpinner";

export function SimpleSelect(props) {

    const selected = props.selectedState;
    const selectableOptions = isEmpty(props.selectableOptions) ? [] : props.selectableOptions;

    if(props.selectableOptions === null){
        return (
            <LoadingSpinner size="6" text="hidden" color="rgb(8 145 178)"/>
        );
    }

    return (
        <Listbox
            value={selected}
            onChange={props.onChange}
        >
            <div className={classNames(
                "relative",
                props.width ? props.width : "w-fit"
            )}>

                {/* INPUT */}
                <Listbox.Button
                    className="input">
                    <span className="">{props.renderSelected(selected)}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ArrowDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                      />
                    </span>
                </Listbox.Button>

                {/* DROPDOWN MENU */}
                <Transition
                    as={Fragment}
                    leave="transition ease-in-out duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                >
                    <Listbox.Options
                        className={classNames(
                            "absolute z-[100] mt-1 w-full max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm",
                            props.selectOptionClassName
                        )}>

                        {/* SELECT OPTIONS */}
                        {selectableOptions.map((option, index) => (
                            <Listbox.Option
                                key={index}
                                value={option}
                                className={({active}) =>
                                    `relative cursor-default select-none py-2 pl-6 pr-4 
                                    ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'}`
                                }
                            >
                                {({selected}) => (
                                    <>
                                      <span
                                          className={`block truncate 
                                          ${selected ? 'font-medium' : 'font-normal'}`}
                                      >
                                        {props.renderOption(option)}
                                      </span>
                                        {selected ? (
                                            <span
                                                className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                              <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </Listbox.Option>
                        ))}

                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    );

}

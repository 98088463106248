// Library imports
import {LocationMarkerIcon, OfficeBuildingIcon} from '@heroicons/react/outline';
import LoadingSpinner from "./ui/LoadingSpinner";
import React from "react";
import {BasicImage} from "./base/BasicImage";


export default function ClaimBasicInfoPanel(props) {

  if (!props.claim) {
    return (
      <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
        <LoadingSpinner/>
      </ul>
    );
  }

  return (
    <div className="flex mb-1 items-center">
        <BasicImage
            src={props.claim.organisation.info.logo_image_url}
            fallbackSrc={'/org-logo-placeholder.jpg'}
            alt="logo"
            sizeWidthRem="4"
            className="rounded-full"
        />
      <div>
        <div className="flex items-center">
          <img
            className="h-16 w-16 rounded-full sm:hidden"
            src="/org-logo-placeholder.jpg"
            alt=""
          />
          <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
            {props.claim.insurer_claim_number}
          </h1>
          {/*<span className="text-base mt-1 font-medium ml-3 text-gray-700">{props.claim.organisation.info.name}</span>*/}
        </div>
        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
          <dt className="sr-only">Location</dt>
          <dd className="flex justify-start gap-6 text-sm text-gray-500 font-medium capitalize">

            <span className="flex">
              <LocationMarkerIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <p>{props.claim.location_text}</p>
            </span>
              <p>|</p>

            <span className="flex">
              <OfficeBuildingIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              <p>{props.claim.organisation.info.name}</p>
            </span>

          </dd>
          {/*<dt className="sr-only">Account status</dt>
          <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
            <ShoppingCartIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
              aria-hidden="true"
            />
            Verified account
          </dd>*/}
        </dl>
      </div>
    </div>
  );

}

// Library imports

import React, {Fragment, useEffect, useState} from 'react';
import {Combobox, Dialog, Transition} from '@headlessui/react';
import {DocumentReportIcon, XIcon} from '@heroicons/react/outline';
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid';

// Local imports
import {createManualQuote} from '../../../utils/coreApi.js';
import {classNames} from '../../../utils/helpers.js';
import {useNavigate} from 'react-router-dom';
import ApiButton from "../../../components/base/ApiButton";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";


export default function CreateManualQuoteModal(props){

    // Data state
    const [replacementQuery, setReplacementQuery] = useState('');
    const [selectedReplacementProduct, setSelectedReplacementProduct] = useState(null);

    const navigate = useNavigate();

    // onClick functions
    useEffect(() => {

        // first set the default replacement to the defaultSelectedReplacementData, second to the supplierRecommendedReplacement, or NULL
        let recommendedReplacement = props.options?.defaultSelectedReplacementData || props.options?.supplierRecommendedReplacement || null;
        setSelectedReplacementProduct(recommendedReplacement?.product || null);

    }, [props.options]);


    const onClose = () => {

        props.setOpen(false);
        setSelectedReplacementProduct(null);
    };

    const onCreateManualQuoteClicked = (buttonHandler) => {

        if (selectedReplacementProduct === null) {
            props.onError({
                'type': 'Invalid Input',
                'message': 'A replacement must be selected.'
            });

            buttonHandler.onError('Invalid replacement');
            return;
        }

        let options = props.options;

        let productId = selectedReplacementProduct.id;
        let supplierId = options.supplier.id;

        let reason = document.getElementById('reason').value;
        reason = reason.trim() === 'Best replacement' ? 'Best replacement' : reason; // replace the default "Best replacement" with "none"

        let price = document.getElementById('price').value;
        let validityPeriod = document.getElementById('validityPeriod').value;

        createManualQuote(
            productId, supplierId, reason, price, validityPeriod, options.item.id,
            (data) => {
                props.onConfirmClicked(data);
                props.setOpen(false);
                setSelectedReplacementProduct(null);
            },
            (error) => {
                props.onError(error);
            }
        );

        return false;
    };

    const onReplacementSelected = (newReplacement) => {

        setSelectedReplacementProduct(newReplacement);
    };

    const replacementField = () => {

        if (props.options === undefined || props.options === null) {
            return (<div></div>);
        }

        let replacements = [];

        for (let possibleReplacement of props.options.item.possible_replacements) {
            replacements.push(possibleReplacement.product);
        }

        const filteredReplacements =
            replacementQuery === '' ? replacements : replacements.filter(
                (product) => {
                    return product.common_name.toLowerCase().includes(replacementQuery.toLowerCase());
                }
            );

        if (selectedReplacementProduct === null && filteredReplacements.length > 0 && props.open) {
            setSelectedReplacementProduct(filteredReplacements[0]);
        }

        return (
            <Combobox as="div" value={selectedReplacementProduct} onChange={onReplacementSelected}>
                <Combobox.Label className="block text-sm font-medium">Replacement</Combobox.Label>
                <div className="relative mt-1">
                    <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                        onChange={(event) => setReplacementQuery(event.target.value)}
                        displayValue={(product) => product !== null ? product.common_name : ''}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </Combobox.Button>

                    {filteredReplacements.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredReplacements.map((product) => (
                                <Combobox.Option
                                    key={product.id}
                                    value={product}
                                    className={({active}) =>
                                        classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                        )
                                    }
                                >
                                    {({active, selected}) => (
                                        <>
                                            <div className="flex items-center">
                                                <img
                                                    src={product.images !== null && product.images !== undefined && product.images.thumbnail_web !== null ? product.images.thumbnail_web : 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                                    alt=""
                                                    className="h-6 w-6 flex-shrink-0 rounded-full"
                                                />
                                                <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>{product.common_name}</span>
                                            </div>

                                            {selected && (
                                                <span
                                                    className={classNames(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        active ? 'text-white' : 'text-indigo-600'
                                                    )}
                                                >
                          <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                        </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </div>
            </Combobox>
        );
    };


    const renderFields = () => {

        let options = props.options;

        let currencySymbol = '';
        let brand = '';

        currencySymbol = props.options?.claim?.organisation?.info?.currency_symbol ? ` (${props.options?.claim?.organisation?.info?.currency_symbol})` : '';

        if (selectedReplacementProduct) brand = selectedReplacementProduct.brand;
        else if (options !== null) {

            if (options.claim !== null && options.claim !== undefined) {

                let claim = options.claim;
                currencySymbol = ` (${claim.organisation.info.currency_symbol})`;
            }

            brand = options.item.product.brand;
        }

        return (
            <div className="space-y-6 px-1 pt-6 pb-5">
                {replacementField()}
                <div>
                    <button
                        onClick={() => {
                            navigate(`/claim/item-add-replacement/${options.claim.id}/${options.item.id}?category=${options.item.product.category.toLowerCase()}`,
                                {
                                    state: {
                                        supplierId: props.options.supplier.id,
                                        quoteModal: 'createQuote'
                                    }
                                }
                            );
                        }}
                        className="btn m-0"
                    >
                        Add replacement
                    </button>
                </div>
                <div>
                    <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                        Brand
                    </label>
                    <div className="mt-1">
                        <div className="text-sm mt-2 text-gray-800">{brand}</div>
                    </div>
                </div>

                <div>
                    <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                        Price {currencySymbol}
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="price"
                            id="price"
                            className="input"
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                        Validity Period
                    </label>
                    <div className="mt-1">
                        <select
                            id="validityPeriod"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md "
                            defaultValue="30"
                        >
                            <option value="1">1 day</option>
                            <option value="3">3 days</option>
                            <option value="6">6 days</option>
                            <option value="9">9 days</option>
                            <option value="12">12 days</option>
                            <option value="15">15 days</option>
                            <option value="20">20 days</option>
                            <option value="30">30 days</option>
                            <option value="60">60 days</option>
                        </select>
                    </div>
                </div>
                <div>
                    <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                        Reason
                        <span className="block mb-2 mt-1 text-sm font-normal text-gray-500">
              Please provide the reason for creating a manual quote.
            </span>
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="reason"
                            id="reason"
                            className="input"
                            defaultValue="Best replacement"
                        />
                    </div>
                </div>
            </div>
        );
    };

    // Page content

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="z-20 fixed inset-0 overflow-hidden" onClose={() => {
            }}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0"/>

                    <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-md">
                                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                    <div className="flex-1 h-0 overflow-y-auto">
                                        <div className="py-6 px-4 modal-header sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-lg font-medium text-white">New
                                                    Quote</Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="btn-outline bg-transparent text-white p-1"
                                                        onClick={onClose}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-cyan-100">
                                                    Get started by filling in the information below.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                {renderFields()}
                                                <div className="pt-4 pb-6">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                        <button
                                            type="button"
                                            className="btn-light"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </button>
                                        <ApiButton
                                            onClick={onCreateManualQuoteClicked}
                                            className='btn'
                                            content={'Create'}
                                            successContent={'Quote created'}
                                            errorContent='Failed to create quote'
                                            defaultStatus={'idle'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

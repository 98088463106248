// Library imports
import React, {useEffect, useState} from 'react';
import {classNames} from "../utils/helpers";
import {customGraphRequest} from "../utils/coreApi";
import {BasicImage} from "./base/BasicImage";


const countryNavigationDefaults = [
    {
        name: 'Global',
        icon: 'countries/world.svg',
        code: 'WORLD',
    },
    {
        name: 'South Africa',
        icon: 'countries/za.svg',
        code: 'ZA',
    },
    {
        name: 'Netherlands',
        icon: 'countries/nl.svg',
        code: 'NL',
    },
    {
        name: 'Australia',
        icon: 'countries/au.svg',
        code: 'AU',
    },
    {
        name: 'United States of America',
        icon: 'countries/us.svg',
        code: 'US',
    },
    {
        name: 'Belgium',
        icon: 'countries/be.svg',
        code: 'BE',
    },
    {
        name: 'Great Britain',
        icon: 'countries/gb.svg',
        code: 'GB',
    },
]

const countriesShortList = ['ZA', 'NL', 'AU', 'US', 'BE', 'GB']

export default function CountrySelection(props) {

    useEffect(() => {
        if(!props.countrySelectionAccounts)
            getAccounts();
    }, [props.mainOrganisation]);

    function getAccounts () {

        if(!props.mainOrganisation) return

        let query =
            `query countrySelect_accounts_min{
              accounts( organisation: "${props.mainOrganisation.id}") {
                error {
                  type
                  message
                }
                
                accounts {
                  type
                  organisation_1 {
                    unique_reference_name
                    id
                    info {
                      country
                      country_text
                    }
                  }
                  organisation_2 {
                    unique_reference_name
                    id
                    type
                    info {
                      country
                      country_text
                    }
                  }
                  
                }
              }
            }

        `;

        customGraphRequest(query,
            (data) => {
                props.setCountrySelectionAccounts(data['accounts']);
            },
            (error) => {
                console.error(error);
            }
        );
    }


    useEffect(() => {

        /** When the mainOrganisation state has been set/changed, update the current selected to the users default country */
        if (props.mainOrganisation) {

            if(props.selectedCountry.code && countriesShortList.includes(props.selectedCountry.code)){
                // when the country selector component is initialised (e.g opening create claim modal), do not reset the country to global
                return
            }
            
            // ADMIN accounts should have the Global country selected by default
            if("ROOT ADMINISTRATOR".includes(props.mainOrganisation.type)){
                let global = countryNavigationDefaults.find(country => country.code === "WORLD") // find the country "Global"
                props.setSelectedCountry(global)
            }
            
            // Set the selected country to the mainOrganisation's country
            else {
                let mainOrganisationsCountry = countryNavigationDefaults.find(country => country.code === props.mainOrganisation.info.country) // find the country that the mainOrganisation belongs to
                props.setSelectedCountry(mainOrganisationsCountry)
            }
        }
        
    }, [props.mainOrganisation, props.countrySelectionAccounts]);
    
    
    const onCountrySelected = (selectedCountry) => {
        props.setSelectedCountry(selectedCountry);
    }
    
    const mainOrgHasAnAccountToThisCountry = (countryToCheck) => {
        
        /** What countries are selectable are based off the Accounts for this organisation
         * Example: If this logged-in user's organisation's country is Netherlands, and is type ADMINISTRATOR
         * and has an account of type ADMIN_INSURER, to an type INSURER organisation, and that insurer organisation's country is Australia.
         * In this example, this user can choose the countries Netherlands, and Australia, and Global(because this is an ADMIN organisation)
         * */
    
        // is the countryToCheck the same country as the main organisation's country
        if(props.mainOrganisation.info.country == countryToCheck.code) return true
    
        for (const account of props.countrySelectionAccounts) {
            // for each account connecting the main org to another org - is the countryToCheck present in any of those connected organisations
            if(account.organisation_2.info.country == countryToCheck.code) return true

            // it is not guaranteed that, in this account, the mainOrganisation will be "organisation_1". so check both 1 and 2
            if(account.organisation_1.info.country == countryToCheck.code) return true

        }
        
        
        // Only ADMIN organisations can see the Global country option
        if (countryToCheck.code === "WORLD" && "ADMINISTRATOR ROOT".includes(props.mainOrganisation.type)) return true
        
        // there is no account, with an organisation, that belongs to the country in countryToCheck
        return false
        
        
    }
    
    if(!props.user || !props.countrySelectionAccounts || !props.mainOrganisation)
        return (<></>);

    return (
        <div className="flex flex-wrap justify-around w-full p-3">

            {countryNavigationDefaults.map((country) => {

                // do not include this country if the mainOrganisation is not connected to any organisation that is from this country
                if (!mainOrgHasAnAccountToThisCountry(country)) {
                    // return (<div key={country.code}></div>);
                    return;
                }

                // The mainOrganisation is connected to an organisation from this country
                return (
                    <div
                        key={country.code}
                        className={classNames(
                            'w-12 p-[12px]',
                            'transition-all group flex justify-center text-base font-medium rounded-md',
                            'bg-transparent',
                            `hover:bg-cyan-200  hover:bg-slate-500 hover:shadow-lg`,

                            // SELECTED
                            props.selectedCountry.code === country.code ? `!p-2 border border-0 border-b-2 border-white-100 hover:bg-transparent` : '',
                        )}
                        onClick={() => onCountrySelected(country)}
                    >
                        {/*<img className="object-cover w-full h-full rounded"*/}
                        {/*     src={'/' + country.icon}>*/}
                        {/*</img>*/}
                        <BasicImage
                             src={'/' + country.icon}
                            fallbackSrc={'/org-logo-placeholder.jpg'}
                            alt="logo"
                            sizeWidthRem="1.5"
                            sizeHeightRem="1.5"
                            className="w-full h-full rounded"
                             imageContain={'object-cover'}
                        />

                    </div>
                );

            })}

        </div>
    );

    
};


// Library imports
import {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";

import {CheckCircleIcon, InformationCircleIcon,} from '@heroicons/react/solid';


// Local imports
import NotificationCenter from '../../components/NotificationCenter.jsx';

import AlertModal from '../modals/AlertModal.jsx';
import ConfirmModal from '../modals/ConfirmModal.jsx';
import SettleClaimantInfoModal from '../modals/SettleClaimantInfoModal.jsx';

import {fetchUser, updateSettlementVoucherCode} from '../../utils/coreApi.js';

import {alertError} from '../../utils/helpers.js';


export default function ClaimVoucherReturn(props) {

    // React router
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Used for errors
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        "heading": "",
        "message": ""
    });

    // Used for success feedback
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationOptions, setNotificationOptions] = useState({
        "heading": "",
        "message": ""
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalOptions] = useState({
        "heading": "",
        "message": "",
        "type": "",
        "buttonText": "",
    });

    // Data states
    const [user, setUser] = useState(null);

    // On every page load
    useEffect(() => {

        if (user === null) {
            fetchUser(setUser, navigate);
        }

    });


    // Helper functions

    const buildQuery = () => {

        let query = {};

        for (const [key, value] of searchParams) {
            query[key] = value;
        }

        // If query is empty, return null
        if (Object.getOwnPropertyNames(query).length === 0)
            return null;


        return query;

    }

    // onClick functions

    const updateVoucher = () => {

        let vtid = buildQuery()["id"];
        let voucherCode = document.getElementById("voucherCode");

        updateSettlementVoucherCode(
            vtid, voucherCode.value,
            (data) => {
                setNotificationOptions({
                    'heading': 'Code submitted',
                    'message': 'The voucher code has been submitted. Redirecting...',
                })
                setNotificationOpen(true);
                setTimeout(() => {
                    setNotificationOpen(false);
                    window.location = "/";
                }, 1000);
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            }
        );
    }

    // HTML render functions

    const renderInstructions = () => {

        return (
            <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                        <div>
                            <p className="text-sm font-medium text-blue-700">
                                Please enter the voucher code below.
                            </p>
                        </div>
                        {/*<p className="mt-3 text-sm md:mt-0 md:ml-6">
              <a href="#" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                Learn more <span aria-hidden="true">&rarr;</span>
              </a>
            </p>*/}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="">

                {/*<NavbarSide user={user} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />*/}

                {/* TODO : change usage to base modal */}
                <NotificationCenter
                    open={notificationOpen}
                    message={notificationOptions}
                />

                {/* TODO : change usage to base modal */}
                <AlertModal
                    open={alertOpen}
                    setOpen={setAlertOpen}
                    options={alertOptions}
                />

                <div className="flex flex-col flex-1">

                    {/* TODO : change usage to base modal */}
                    <ConfirmModal
                        open={confirmModalOpen}
                        setOpen={setConfirmModalOpen}
                        options={confirmModalOptions}
                        onConfirmClicked={() => {
                        }}
                    />

                    <main className="flex-1 pb-8">
                        {/* Page header */}
                        <div className="bg-white shadow">
                            <div className="px-4 sm:px-6 lg:px-8">
                                <div
                                    className="pb-4 mt-4 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                                </div>
                                <div className="pb-5">
                                    {renderInstructions()}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="flex flex-col justify-center py-2 sm:px-6 lg:px-8 w-1/2">
                                <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                    <div className="space-y-6 pt-6 pb-5">
                                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                            <label htmlFor="vtid"
                                                   className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                VTID
                                            </label>
                                            <div className="mt-1 relative rounded-md">
                                                {buildQuery() ? buildQuery()["id"] : '-'}
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                            <label htmlFor="lessExcess"
                                                   className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                Claim
                                            </label>
                                            <div className="mt-1 relative rounded-md">
                                                {buildQuery() ? buildQuery()["claim_ref_number"] : '-'}
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                            <label htmlFor="lessExcess"
                                                   className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                Requested Voucher Value
                                            </label>
                                            <div className="mt-1 relative rounded-md">
                                                R {buildQuery() ? buildQuery()["requested_voucher_value"] : '-'}
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                            <label htmlFor="voucherCode"
                                                   className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                Voucher Code
                                            </label>
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="voucherCode"
                                                    id="voucherCode"
                                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    aria-describedby="lessExcess"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                            <div></div>
                                            <button
                                                type="button"
                                                onClick={updateVoucher}
                                                id="issueVoucher"
                                                className="mt-1 h-10 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 hover:cursor-pointer"
                                            >
                                                <span className="ml-auto mr-auto">
                                                    <CheckCircleIcon className="mr-1 inline h-5 w-5 align-top"
                                                                     aria-hidden="true"/>
                                                    Submit
                                                </span>
                                            </button>
                                        </div>
                                        <div className="flex justify-end">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>

            </div>
        </>
    )
}

// Library imports

import React, {Fragment, useEffect, useState} from 'react';
import {Combobox, Dialog, Transition} from '@headlessui/react';
import {XIcon} from '@heroicons/react/outline';
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid';

// Local imports
import {setPreferredReplacement} from '../../../utils/coreApi.js';
import {classNames} from '../../../utils/helpers.js';
import {useNavigate} from 'react-router-dom';
import ApiButton from "../../../components/base/ApiButton";


export default function UpdateQuoteModal(props){

    const navigate = useNavigate();

    // Data state
    const [replacementQuery, setReplacementQuery] = useState('');

    // Of the products in the "possible replacements" for this item, which one is selected
    const [selectedReplacementProduct, setSelectedReplacementProduct] = useState(null);

    // Does this supplier have a quote for this product? 1 quote per supplier per product
    const [selectedReplacementQuote, setSelectedReplacementQuote] = useState(null);


    const [price, setPrice] = useState(0);
    const [reason, setReason] = useState('Best replacement:');
    const [validity, setValidity] = useState('NULL');
    const [updateCatalogPrice, setUpdateCatalogPrice] = useState(false);

    useEffect(() => {

    }, []);

    // onClick functions
    useEffect(() => {

        // first set the default replacement to the defaultSelectedReplacementData, second to the supplierRecommendedReplacement, or NULL
        let recommendedReplacement = props.options?.defaultSelectedReplacementData || props.options?.supplierRecommendedReplacement || null;
        setSelectedReplacementProduct(recommendedReplacement?.product || null);

        setFieldsToQuote();
    }, [props.options]);

    useEffect(() => {

        setQuoteForSelectedProduct();

    }, [selectedReplacementProduct]);

    useEffect(() => {
        setFieldsToQuote();
    }, [selectedReplacementQuote]);

    const setQuoteForSelectedProduct = () => {
        // a new product has been selected from the replacements dropdown
        // does this supplier have a quote for the newly selected product?

        // first look for a quote in the possible replacements list
        let replacementDataForProduct = props.options?.item?.possible_replacements.find(replacement => replacement.product?.id === selectedReplacementProduct?.id) || null;
        let suppliersQuoteForProduct = replacementDataForProduct?.quotes?.find(quote => quote.supplier.id === props.options.supplier.id) || null;

        // if a quote for this product, for this supplier was not found in the possible_replacements, then look if it is available as the suppliers "recommended replacement"
        if (!suppliersQuoteForProduct) {
            // check if the product, of the recommended replacement quote, is for the same product selected in the "replacement" dropdown
            let recommendedIsSameProduct = selectedReplacementProduct?.id === props.options?.supplierRecommendedReplacement?.product?.id;
            if (recommendedIsSameProduct) suppliersQuoteForProduct = props.options?.supplierRecommendedReplacement.quote;
        }

        setSelectedReplacementQuote(suppliersQuoteForProduct || null);

    };

    const setFieldsToQuote = () => {
        if (!selectedReplacementQuote) {
            setPrice(0);
            setReason('');
            return;
        }

        setPrice(selectedReplacementQuote.price || 0);
        setValidity('NULL');
    };

    const onClose = () => {

        props.setOpen(false);
        setSelectedReplacementProduct(null);
    };

    const onSetPreferredReplacementAndQuoteClicked = (buttonHandler) => {

        if (selectedReplacementProduct === null) {
            props.onError({
                'type': 'Invalid Input',
                'message': 'A replacement must be selected.'
            });

            buttonHandler.onError('Invalid replacement');
            return;
        }

        let options = props.options;

        let productId = selectedReplacementProduct.id;
        let supplierId = options.supplier.id;

        let item = props.options.item;

        // Updating a manual quote requires a reason
        // reason and validity are hidden for auto/none-manual quotes
        if (selectedReplacementQuote?.is_manual && !reason) {
            props.showAlertModal('info', 'Reason required', 'Please specify a reason for updating this quote');
            buttonHandler.onError('Reason required');
            return;
        }

        setPreferredReplacement({
                itemId: item.id,
                supplierId: supplierId,
                productId: productId,
                price: price,
                validity: validity === 'NULL' ? null : validity,
                reason: reason,
                updateDeapi: updateCatalogPrice,
            },
            (data) => {
                props.showToastNotificationModal('success', 'Quote updated', 'The quote was successfully updated');
                props.onConfirmClicked(data);
                props.setOpen(false);
                setSelectedReplacementProduct(null);
                buttonHandler.onSuccess('Quote updated');
            },
            (error) => {
                props.onError(error);
                buttonHandler.onError('Failed to update quote');
            }
        );

        return false;
    };

    const onReplacementSelected = (newReplacement) => {
        setSelectedReplacementProduct(newReplacement);
    };

    const replacementField = () => {

        if (!props.options) {
            return (<div></div>);
        }

        let replacements = [];

        for (let possibleReplacement of props.options.item.possible_replacements) {
            replacements.push(possibleReplacement.product);
        }

        const filteredReplacements =
            replacementQuery === '' ? replacements : replacements.filter(
                (product) => {
                    return product.common_name.toLowerCase().includes(replacementQuery.toLowerCase());
                }
            );

        if (selectedReplacementProduct === null && filteredReplacements.length > 0 && props.open) {
            setSelectedReplacementProduct(filteredReplacements[0]);
        }

        return (
            <Combobox as="div" value={selectedReplacementProduct} onChange={onReplacementSelected}>
                <Combobox.Label className="block text-sm font-medium">Replacement</Combobox.Label>
                <div className="relative mt-1">
                    <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                        onChange={(event) => setReplacementQuery(event.target.value)}
                        displayValue={(product) => product !== null ? product.common_name : ''}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </Combobox.Button>

                    {filteredReplacements.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredReplacements.map((product) => (
                                <Combobox.Option
                                    key={product.id}
                                    value={product}
                                    className={({active}) =>
                                        classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                        )
                                    }
                                >
                                    {({active, selected}) => (
                                        <>
                                            <div className="flex items-center">
                                                <img
                                                    src={product.images !== null && product.images !== undefined && product.images.thumbnail_web !== null ? product.images.thumbnail_web : 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                                    alt=""
                                                    className="h-6 w-6 flex-shrink-0 rounded-full"
                                                />
                                                <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>{product.common_name}</span>
                                            </div>

                                            {selected && (
                                                <span
                                                    className={classNames(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        active ? 'text-white' : 'text-indigo-600'
                                                    )}
                                                >
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </div>
            </Combobox>
        );
    };


    const renderFields = () => {

        let options = props.options;

        let currencySymbol = '';
        let brand = '';

        let claim = props.options.claim || null;

        currencySymbol = ` (${claim.organisation.info.currency_symbol})`;

        if (selectedReplacementProduct) brand = selectedReplacementProduct.brand;
        else brand = options.item.product.brand;

        // disable the validity for auto/none manual quotes. if no quote is available, then we are creating a new claim so enable validity
        let isAutoQuote = false;
        let isManual = selectedReplacementQuote?.is_manual || true;
        isAutoQuote = !(isManual) || false;
        if (!selectedReplacementQuote) isAutoQuote = false;

        return (
            <div className="space-y-6 px-1 pt-6 pb-5">

                {/* REPLACEMENT DROPDOWN */}
                {replacementField()}

                {/* ADD REPLACEMENT */}
                <div>
                    <button
                        onClick={()=>{
                            navigate(`/claim/item-add-replacement/${options.claim.id}/${options.item.id}?category=${options.item.product.category.toLowerCase()}`,
                                {
                                    state: {
                                        supplierId: props.options.supplier.id,
                                        quoteModal: "updateQuote",
                                        originPage: props.options.originPage || "tender",
                                    }
                                }
                            );
                            }}
                        className="btn m-0"
                    >
                        Add replacement
                    </button>
                </div>

                {/* BRAND */}
                <div>
                    <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                        Brand
                    </label>
                    <div className="mt-1">
                        <div className="text-sm mt-2 text-gray-800">{brand}</div>
                    </div>
                </div>

                {/* PRICE */}
                <div>
                    <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                        Price{currencySymbol}
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="price"
                            id="price"
                            className="input"
                            // defaultValue={selectedReplacementProduct?.quote?.price || 0}
                            value={price}
                            onChange={e => setPrice(e.target.value)}
                        />
                    </div>
                </div>

                {/* VALIDITY */}
                <div className={`${isAutoQuote ? 'hidden' : ''}`}>
                    <label htmlFor="project-name" className={`block text-sm font-medium text-gray-900 `}>
                        Validity Period
                    </label>
                    <div className="mt-1">
                        <select
                            id="validityPeriod"
                            className={`block w-full shadow-sm sm:text-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md `}
                            disabled={isAutoQuote}
                            value={validity}
                            onChange={e => setValidity(e.target.value)}
                        >
                            <option value="NULL">-</option>
                            <option value="1">1 day</option>
                            <option value="3">3 days</option>
                            <option value="6">6 days</option>
                            <option value="9">9 days</option>
                            <option value="12">12 days</option>
                            <option value="15">15 days</option>
                            <option value="20">20 days</option>
                            <option value="30">30 days</option>
                            <option value="60">60 days</option>
                        </select>
                    </div>
                </div>

                {/* REASON */}
                <div className={`${isAutoQuote ? 'hidden' : ''}`}>
                    <label htmlFor="project-name" className={`block text-sm font-medium text-gray-900 `}>
                        Reason
                        {/*<span className="block mb-2 mt-1 text-sm font-normal text-gray-500">Please provide the reason for creating a manual quote.</span>*/}
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="reason"
                            id="reason"
                            className="input"
                            disabled={isAutoQuote}
                            value={reason}
                            onChange={e => setReason(e.target.value)}
                        />
                    </div>
                </div>

                {/* UPDATE CATALOG PRICE */}
                {/* Only show the update catalogue price for auto/none manual quotes */}
                <div className={`flex justify-start items-center gap-3 mt-6 px-2 tooltip-container ${isAutoQuote ? '' : 'hidden'}`}>
                    <input
                        type="checkbox"
                        value={updateCatalogPrice}
                        onChange={(e) => setUpdateCatalogPrice(e.target.checked)}
                        className="input w-min p-[10px] "
                    />

                    <label htmlFor="price" className={`block text-sm font-medium text-gray-900 ${selectedReplacementQuote && selectedReplacementQuote.is_manual ? 'text-gray-400' : ''}`}>
                        Update catalogue price
                    </label>

                    {selectedReplacementQuote && selectedReplacementQuote.is_manual ?
                        <span className="tooltip text-sm ml-14 p-3 w-[10rem]">Manually created quotes only apply to this claim</span>
                        :
                        <span className="tooltip text-sm ml-14 p-3 w-[10rem]">Also apply this update to the catalogue's price</span>
                    }
                </div>

            </div>
        );
    };

    // Page content

    if (!props.options || !props.options.claim) return <></>;

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="z-20 fixed inset-0 overflow-hidden" onClose={() => {
            }}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0"/>

                    <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-md">
                                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                    <div className="flex-1 h-0 overflow-y-auto">
                                        <div className="py-6 px-4 modal-header sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-lg font-medium text-white">
                                                    {selectedReplacementQuote ?
                                                        'Update Quote'
                                                        :
                                                        'Create Quote'
                                                    }
                                                </Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="btn-outline bg-transparent text-white p-1"
                                                        onClick={onClose}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-cyan-100">
                                                    Get started by filling in the information below.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                {renderFields()}
                                                <div className="pt-4 pb-6">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                        <button
                                            type="button"
                                            className="btn-light"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </button>
                                        <ApiButton
                                            onClick={onSetPreferredReplacementAndQuoteClicked}
                                            className='btn'
                                            content={
                                            selectedReplacementQuote ?
                                                <p>Update Quote</p>
                                                :
                                                <p>Create Quote</p>
                                            }
                                            successContent={'Quote updated'}
                                            errorContent='Failed to update quote'
                                            defaultStatus={'idle'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

// Library imports
import React, {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {XIcon} from '@heroicons/react/outline';
import {customGraphRequest, userChangePassword} from '../../utils/coreApi.js';
import NotificationCenter from '../../components/NotificationCenter';
import {useNavigate} from 'react-router-dom';
import {BasicImage} from "../../components/base/BasicImage";
import {formatAsTitle} from "../../utils/helpers";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import QRCode from "react-qr-code";
import Tooltip from "../../components/Tooltip";


export default function ProfileModal(props) {

    // UI states
    const [viewingComponent, setViewingComponent] = useState('USER_DETAILS'); // USER_DETAILS, CHANGE_PASSWORD, ENABLE_MFA
    const [mfaStatus, setMfaStatus] = useState(null);

    useEffect(() => {
        if(props.open && props.user) queryMfaStatus();
    }, [props.open, props.user]);

    function queryMfaStatus() {

        if(!props.user) return;

        // Has the mfa status already been queried?
        if(mfaStatus === true || mfaStatus === false) return;

        let query = `
            query GetMFAStatus{
              user_mfa_enabled(
                user_id: "${props.user.id}"
              ){
                error{type, message}
                mfa_enabled
              }
            }
        `

        customGraphRequest(
            query,
            (data) => {
                setMfaStatus(data.mfa_enabled);
            },
            (error) => {
                setMfaStatus(false);
                props.onError(error);
            }
        )

    }

    if (props.user === null) {
        return (<div></div>);
    }

    const allProps = {
        ...props,
        viewingComponent, setViewingComponent,
        mfaStatus, setMfaStatus,
    }

    return (
        <>
            <Transition.Root show={props.open} as={Fragment}>
                <Dialog as="div" className="z-20 fixed inset-0 overflow-hidden" onClose={() => {
                }}>
                    <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay
                        className={`absolute inset-0 transition-all duration-300 bg-gray-600 opacity-0 ${props.open ? ' opacity-20' : ' opacity-0'}`}
                        onClick={() => props.setOpen(false)}
                    />
                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-md">
                                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="py-6 px-4 modal-header sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title
                                                        className="text-lg font-medium text-white">{props.user.info.first_name} {props.user.info.last_name} </Dialog.Title>
                                                    <div className="ml-3 h-7 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="btn-outline bg-transparent text-white p-1"
                                                            onClick={() => props.setOpen(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    <p className="text-sm text-cyan-100">
                                                        Please find your information below.
                                                    </p>
                                                </div>
                                            </div>

                                            {viewingComponent === 'USER_DETAILS' && <UserDetails {...allProps} />}
                                            {viewingComponent === 'CHANGE_PASSWORD' && <ChangePassword {...allProps} />}
                                            {viewingComponent === 'ENABLE_MFA' && <EnableMFA {...allProps} />}

                                        </div>

                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            <button
                                                className="btn-light"
                                                onClick={() => props.setOpen(false)}
                                            >
                                                Close
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

function ChangePassword(props) {

    const handlePasswordValid = (event) => {
        let passValid = true;
        //collect form data in JavaScript variables
        let pw1 = document.getElementById('newPassword').value;
        let pw2 = document.getElementById('confirmPassword').value;

        const styleGreen = (id) => {
            let text = document.getElementById(id);
            text.classList.add('text-green-700');

            if (!text.innerText.includes('✔')) {
                text.innerText = '✔ ' + text.innerText;
            }
        };
        const styleDefault = (id) => {
            let text = document.getElementById(id);
            text.classList.remove('text-green-700');
            text.innerText = text.innerText.replace('✔ ', '');
        };

        //minimum password length validation
        if (pw1.length >= 12) {
            styleGreen('message1');
        } else {
            styleDefault('message1');
            passValid = false;
            console.log('pass failed: length');
        }

        // upper and lower case
        if (/[A-Z]/.test(pw1) && /[a-z]/.test(pw1)) {
            styleGreen('message2');
        } else {
            styleDefault('message2');
            passValid = false;
            console.log('pass failed: capital');
        }

        // include a number
        if (/\d/.test(pw1)) {
            styleGreen('message3');
        } else {
            styleDefault('message3');
            passValid = false;
            console.log('pass failed: number');
        }

        //check empty confirm password field
        if (pw1 === pw2) {
            document.getElementById('message4').classList.add('hidden');
        } else {
            document.getElementById('message4').classList.remove('hidden');
            passValid = false;
            console.log('pass failed: pw1 != pw2');
        }
        return passValid;
    };

    const submitChangePassword = (event) => {

        let valid = handlePasswordValid();
        if (!valid) {
            props.showAlertModal(
                'error',
                'Invalid Password',
                'Please refer to password requirements.'
            )
            return;
        }

        let newPassword = document.getElementById('newPassword').value;

        userChangePassword(
            newPassword,
            (data) => {
                props.showNotificationModal(
                    'success',
                    'Password Changed',
                    'Your password has been successfully changed.'
                )
            },
            props.onError
        );

    };


    return (
        <div className='px-8'>
            <div>
                <div className="mt-10 text-2xl text-gray-800">
                    Change Password
                </div>
                <div>
                    <label htmlFor="project-name"
                           className="mt-10 block text-sm font-medium text-gray-900">
                        New Password
                    </label>
                    <div className="mt-1">
                        <input
                            type="password"
                            placeholder="password"
                            onChange={handlePasswordValid}
                            defaultValue=""
                            name="newPassword"
                            id="newPassword"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md"
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="project-name"
                           className="mt-10 block text-sm font-medium text-gray-900">
                        Confirm Password
                    </label>
                    <div className="mt-1">
                        <input
                            type="password"
                            placeholder="confirm password"
                            defaultValue=""
                            onChange={handlePasswordValid}
                            name="confirmPassword"
                            id="confirmPassword"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md"
                        />
                    </div>
                </div>
                <div
                    className="py-8 px-4 text-gray-500 shadow sm:rounded-lg sm:px-10 mt-5">
                    <p className="text-gray-700 mb-1" id="">Password
                        requirements:</p>
                    <p id="message1">Consists of at least 12 characters.</p>
                    <p id="message2">Includes UPPER & lower case
                        characters.</p>
                    <p id="message3">Includes one or more numbers.</p>
                    <p className="text-red-700 hidden" id="message4">✖
                        Passwords do not match.</p>
                    <i>For example: <span className='underline'>silverCloud2019</span></i>
                </div>

                <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                        id="cancelChangePassword"
                        className="btn-light"
                        onClick={() => props.setViewingComponent('USER_DETAILS')}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={submitChangePassword}
                        className="btn"
                    >
                        Change Password
                    </button>

                </div>
            </div>
        </div>

    );

}

function UserDetails(props) {

    return (

        <div className='flex flex-col items-center gap-2'>

            {/* PROFILE PHOTO */}
            <div className="flex justify-center pb-7 pt-4">
                <BasicImage
                    src={props.user && props.user.info.profile_image_url}
                    fallbackSrc={'/profile-picture.jpg'}
                    alt="avatar"
                    sizeWidthRem="17"
                    sizeHeightRem="17"
                    className="content-between"
                />
            </div>

            <label className="mt-9 font-medium">
                Full Name
            </label>
            <div className="text-sm pb-4">
                {props.user.info.first_name} {props.user.info.last_name}
            </div>

            <label className="font-medium">
                Email
            </label>
            <div className="text-sm pb-4">
                {props.user.username}
            </div>

            <label className="font-medium">
                Organisation
            </label>
            <div className="text-sm pb-4">
                {props.user.organisation.info.name}
            </div>

            <label className="font-medium">
                Organisation Type
            </label>
            <div className="text-sm pb-4">
                {formatAsTitle(props.user.organisation.type.toLowerCase())}
            </div>

            <label className="font-medium">
                Organisation Role
            </label>
            <div className="text-sm pb-4">
                {formatAsTitle(props.user.roles[0].name)}
            </div>

            <label className="font-medium">
                MFA Status
            </label>
            <div className="text-sm pb-4">
                {props.mfaStatus === null ?
                    <LoadingSpinner />
                    :
                    props.mfaStatus ? 'Enabled' : 'Disabled'
                }
            </div>

            <button
                className='btn-light my-2'
                onClick={() => props.setViewingComponent('ENABLE_MFA')}
            >
                Enable MFA
            </button>

            <button
                className="btn-light my-2"
                onClick={() => props.setViewingComponent('CHANGE_PASSWORD')}
            >
                Change Password
            </button>

        </div>
    );
}

function EnableMFA(props) {

    const [mfaCode, setMfaCode] = useState('');
    const [mfaQRCode, setMfaQRCode] = useState('');


    useEffect(() => {
        getMfaQRCode();
    }, []);

    function getMfaQRCode(){

        let mutation = `
            mutation GetMfaQRCode{
              request_enable_mfa{
                error{type, message}
                data_uri
              }
            }
        `

        customGraphRequest(
            mutation,
            (data) => {
                setMfaQRCode(data.data_uri);
            },
            props.onError
        );

    }

    function submitMFACode(){

        let mutation = `
            mutation EnableMFA{
              enable_mfa(
                otp_code: "${mfaCode}"
              ){
                error{type, message}    
                success
              }
            }
        `

        customGraphRequest(
            mutation,
            (data) => {
                props.showNotificationModal(
                    'success',
                    'MFA Enabled',
                    'MFA has been successfully enabled'
                )
                props.setViewingComponent('USER_DETAILS');
            },
            props.onError
        )

    }

    return (
        <div className='p-8 flex flex-col gap-6'>

            <i>Using your MFA app of choice, scan the below QR code, and enter the Code shown</i>

            <div className='flex justify-center w-full'>
                {!mfaQRCode ?
                    <LoadingSpinner/>
                    :
                    <img src={mfaQRCode} alt="MFA QR Code" className='w-full h-full'/>
                }
            </div>

            <div className='flex justify-between gap-4'>
                <Tooltip
                    content={(<>
                            <p>Once you have scanned the QR code in your MFA app of choice, it should display a
                                code.</p>
                            <p> Enter that code below and click submit.</p>
                    </>)}
                    className='max-w-full w-full'
                >
                    <input
                        className='input'
                        value={mfaCode}
                        onChange={(e) => setMfaCode(e.target.value)}
                        placeholder={'Enter MFA code'}
                    />
                </Tooltip>

                <Tooltip content='Click to fresh the QR code'>
                    <button
                        className='btn-light my-0 py-2 mx-auto'
                        onClick={getMfaQRCode}
                    >
                        Refresh
                    </button>
                </Tooltip>

            </div>


            <div className='flex justify-between'>
                <button
                    className='btn-light'
                    onClick={() => props.setViewingComponent('USER_DETAILS')}
                >
                    Close
                </button>
                <button
                    className='btn'
                    onClick={submitMFACode}
                >
                    Submit
                </button>

            </div>


        </div>
    );

}

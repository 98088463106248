import { Combobox,} from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { classNames } from '../utils/helpers.js';

export default function SelectClaimCompany(props) {

  if(!props.organisations) return <>...</>
  
  return (
    <Combobox as="div" value={props.selectedOption !== null ? props.selectedOption : ""} onChange={props.setSelectedOption}>
      <div className="relative mt-1">
        <Combobox.Input
          className="input"
          onChange={(event) => props.setSelectedOption(event.target.value)}

          displayValue={(org) => org !== null && org.info !== undefined ? org.info.name : ""}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {props.organisations.map((organisation) => (
              <Combobox.Option
                key={organisation.id}
                value={organisation}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9 h-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <img
                        src={organisation.info.logo_image_url ? organisation.info.logo_image_url : '/org-logo-placeholder.jpg'}
                        alt=""
                        className="h-6 w-6 flex-shrink-0 rounded-full"
                        onError={({ currentTarget }) => {
                          currentTarget.onError = null;
                          currentTarget.src = "/org-logo-placeholder.jpg";
                        }}
                      />
                      <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>{organisation.info.name}</span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>

      </div>
    </Combobox>
  );

}

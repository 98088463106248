// Library imports

import { useState } from 'react'
import { Combobox } from '@headlessui/react'

import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

import { classNames } from '../utils/helpers.js';


export default function CategoryBox(props) {

  const [categoryQuery, setCategoryQuery] = useState('');

  if (props.categories === null) {
    return '...';
  }

  let filteredCategories = [];

  if (props.allowEmptySelection !== undefined && props.allowEmptySelection) {

    filteredCategories = [{
      'id': '',
      'category_d': '',
      'display_name': ''
    }];
  }

  if (categoryQuery === '') {
    filteredCategories = filteredCategories.concat(props.categories);
  }
  else {
    filteredCategories = props.categories.filter((category) => {
      return category.display_name.toLowerCase().includes(categoryQuery.toLowerCase())
    });
  }

  let disabled = undefined;

  if (props.disabled !== null && props.disabled !== undefined && props.disabled === true) {
    disabled = "disabled";
  }

  return (
    <Combobox disabled={disabled} as="div" value={props.selectedCategory !== null ? props.selectedCategory : ""} onChange={props.setSelectedCategory}>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setCategoryQuery(event.target.value)}
          displayValue={(category) => category !== null && category.display_name !== null ? category.display_name : ""}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredCategories.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredCategories.map((category) => (
              <Combobox.Option
                key={category.id}
                value={category}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9 h-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{category.display_name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );

}

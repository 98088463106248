// Library imports
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

// Local imports
import ApiButton from "../../components/base/ApiButton";
import LoadingSpinner from "../../components/ui/LoadingSpinner";


// Login container
export const Login = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        props.setAuthenticated(false); // if the login page is loaded, then the user is most likely no longer authenticated
    }, []);

    // Return either the Login form or the MFA form
    const [loginStep, setLoginStep] = useState("LOGIN"); // LOGIN or MFA

    function authenticationComplete() {
        if (!props.authenticated) {
            props.setAuthenticated(true);
        }
        navigate('/home');
    }

    const allProps = {
        ...props,
        loginStep, setLoginStep,
        authenticationComplete
    }

    if (loginStep === "LOGIN") return <LoginForm {...allProps} />
    else return <MFAForm {...allProps} />

};


function LoginForm(props) {

    // Data states
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLoginSuccess = (data) => {
        if (data.success) {
            if (data.mfa_required) {
                props.setLoginStep("MFA");
            } else {
                props.authenticationComplete();
            }
        }
    };

    const handleLoginError = (error) => {
        props.showAlertModal(
            "error",
            "Unauthorized",
            error.message || "An error occurred during login."
        );
    };

    const login = async (buttonHandler) => {

        // Encode email and password to Base64 for the Authorization header
        const credentials = btoa(`${email}:${password}`);
        const headers = {
            'Authorization': `Basic ${credentials}`,
            'X-Custom-Origin': window.location.origin // http://localhost:3000, https://portal.slvrcld.com
        };

        try {
            let url = process.env.REACT_APP_CORE_API_URL + 'login';

            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                credentials: 'include',
            });

            const data = await response.json();

            if (!response.ok || !data?.success) {
                props.showAlertModal(
                    "error",
                    "Login Error",
                    (<>
                        <p>Incorrect username and password</p>
                        <p>or other error has occurred. Please try again.</p>
                    </>)
                )
                buttonHandler.onError();
            }

            // Credentials accepted
            else {
                buttonHandler.onSuccess();
                handleLoginSuccess(data);
            }

        } catch (error) {
            buttonHandler.onError();
            handleLoginError(error);
        }
    };

    return (
        <div className="h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 pb-[8rem] bg-white">

            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-[7rem] w-auto" src="./logo512.png" alt="SLVRCLD logo"/>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Login
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="flex flex-col gap-5">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <input id="email" name="email" type="email" onChange={(e) => setEmail(e.target.value)}
                                   autoComplete="email" required
                                   className="input"
                                   value={email}
                            />
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <input id="password" name="password" type="password"
                                   onChange={(e) => setPassword(e.target.value)}
                                   autoComplete="current-password" required
                                   className="input"
                                   value={password}
                            />
                        </div>

                        <div>

                            <ApiButton
                                onClick={login}
                                className={'btn m-0 w-full flex justify-center'}
                                content='Login'
                                loadingContent={<LoadingSpinner color='darkcyan' size='6' body='authenticating'
                                                                text='text-md'/>}
                                successContent='Credentials accepted'
                                errorContent='Incorrect username or password'
                            />

                            <a href="/request-password-reset"
                               className="btn-outline border-0 w-full justify-center py-1 mx-0 mt-6">
                                Forgot your password?
                            </a>

                        </div>

                    </div>

                </div>
            </div>

        </div>
    );

}

function MFAForm(props) {

    const [mfaCode, setMfaCode] = useState("");

    const handleValidationSuccess = () => {
        props.authenticationComplete();
    };

    const handleValidationError = (error) => {
        props.showAlertModal(
            "error",
            "OTP Error",
            (<>
                <p>The provided OTP is invalid.</p>
                <p>Please try again.</p>
            </>)
        );
    };

    const validateMfaCode = async (buttonHandler) => {
        const headers = {
            'Content-Type': 'application/json',
            'X-Custom-Origin': window.location.origin
        };

        try {
            let url = process.env.REACT_APP_CORE_API_URL + 'verify-otp';

            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                credentials: 'include',
                body: JSON.stringify({otp_code: mfaCode}),
            });

            if (!response.ok) {
                const errorData = await response.json();
                props.showAlertModal(
                    "error",
                    "OTP Error",
                    `${errorData.Error_code}: ${errorData.Message}`
                )
                buttonHandler.onError();
                return
            }

            // If the response is OK, then the OTP code was accepted
            else {
                buttonHandler.onSuccess();
                handleValidationSuccess();
            }

        } catch (error) {
            buttonHandler.onError();
            handleValidationError(error);
        }
    };


    return (
        <div className="h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 pb-[8rem] bg-white">

            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-[7rem] w-auto" src="./logo512.png" alt="SLVRCLD logo"/>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Multi-Factor Authentication
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="flex flex-col gap-5">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Code
                            </label>
                            <input id="mfa_code"
                                   name="mfa_code"
                                   type="text"
                                   onChange={(e) => setMfaCode(e.target.value)}
                                   required
                                   className="input"
                                   value={mfaCode}
                            />
                        </div>

                        <div>

                            <ApiButton
                                onClick={validateMfaCode}
                                className={'btn m-0 w-full flex justify-center'}
                                content='Submit'
                                loadingContent={<LoadingSpinner color='darkcyan' size='6' body='Validating'
                                                                text='text-md'/>}
                                successContent='Accepted'
                                errorContent='Code not accepted'
                            />

                        </div>

                    </div>

                </div>
            </div>

        </div>
    );

}

// Library imports

import {Fragment, useEffect} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {XIcon} from '@heroicons/react/outline';
import {CheckCircleIcon} from '@heroicons/react/solid';
import {classNames} from '../../utils/helpers';
import Safe, {safe} from "../../components/Safe";
import {MobileNumberInput} from "../../components/MobileNumberInput";

// Local imports


export default function ClaimAwardModal(props) {

    const renderSelectedItem = (item, supplier) => {
        if (!item.selected_for_award) {
            return (<></>);
        }

        let replacement = supplier.get_recommended_replacement(supplier, item);
        if (!replacement.quote) {
            return (<></>);
        }

        // row data
        let qty = item.quantity || 0;
        let currencySymbol = replacement.quote.currency_symbol || ' ';
        let price = replacement.quote.price || 0;

        return (
            <tr key={item.id} itemID={item.id}
                className={classNames(item.selected_for_award ? '' : 'bg-gray-100')}>
                <td className="w-96 py-4 pl-4 text-sm font-medium text-gray-900">
                    <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                            <img
                                src={replacement.product.images !== null && replacement.product.images.thumbnail_web ?
                                    replacement.product.images.thumbnail_web :
                                    'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                className="h-10 w-10 rounded-full"
                                alt=""
                            />
                        </div>
                        <div className="ml-4">
                            <div>
                                {replacement.product.common_name}
                            </div>
                            <div className="text-gray-600 font-normal mt-1">
                                {item.category.display_name}
                            </div>
                            <dl className="font-normal">
                                <dd className="mt-1 max-w-md text-gray-900">
                                    {replacement.product.type === 'MANUAL' ? replacement.product.description : ' '}
                                </dd>
                            </dl>
                        </div>
                    </div>
                </td>
                <td className="px-3 py-4 text-right text-sm sm:table-cell" qty={qty}>{qty}</td>
                <td className="px-3 py-4 text-right text-sm sm:table-cell">{currencySymbol} {qty * price} </td>
                <td className="py-4 pl-3 pr-6 text-right text-sm font-medium">
                    <input type="checkbox"
                           defaultChecked={item.selected_for_award}
                           onChange={(e) => props.onItemAwardChecked(e, item)}
                    />
                </td>
            </tr>
        );
    };

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="z-20 fixed inset-0 overflow-hidden" onClose={() => {
            }}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay
                        className={classNames(
                            "transition absolute inset-0 ",
                            props.open ? "bg-gray-400/30" : "bg-gray-100/0"
                        )}
                        onClick={() => props.setOpen(false)}
                    />

                    <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="">
                                <form className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                    <div className="flex-1 h-0 overflow-y-auto">
                                        <div className="py-6 px-4 modal-header sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title
                                                    className="text-lg font-medium text-white">Award
                                                    Summary </Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="btn-outline bg-transparent text-white p-1"
                                                        onClick={() => props.setOpen(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-cyan-100">
                                                    Please find a summary of the items selected
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                <div className="">
                                                    <div className="text-sm text-gray-800"></div>
                                                    <div>


                                                        <main className="flex-1 ">
                                                            {/* Page header */}


                                                            <div>
                                                                <div>
                                                                    {/*{renderSuppliersAndItems()}*/}
                                                                </div>
                                                                <br/>
                                                                <div className="">
                                                                    <div className="sm:flex sm:items-center">
                                                                        <div className="sm:flex-auto">
                                                                        </div>
                                                                    </div>
                                                                    {props.uiClaim && props.uiClaim.suppliers.map(supplier => {

                                                                        if (!supplier.has_selected_items(supplier)) {
                                                                            return <></>;
                                                                        }

                                                                        return (
                                                                            <>
                                                                                <h1 className="text-lg font-medium mt-5">{supplier.display_name}</h1>
                                                                                <div
                                                                                    className="mb-5 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">

                                                                                    <table
                                                                                        className="">
                                                                                        <thead className="bg-gray-50">
                                                                                        <tr>
                                                                                            <th scope="col"
                                                                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                                Item
                                                                                            </th>
                                                                                            <th scope="col"
                                                                                                className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                                                                Qty
                                                                                            </th>
                                                                                            <th scope="col"
                                                                                                className="px-3 pr-5 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                                                                Total
                                                                                            </th>
                                                                                            <th scope="col"
                                                                                                className="px-3 pr-5 py-3.5 text-right text-sm font-semibold text-gray-900">
                                                                                                ...
                                                                                            </th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody
                                                                                            className="divide-y divide-gray-200 bg-white">

                                                                                        {supplier.get_selected_items(supplier).map(item => (
                                                                                            safe(()=> renderSelectedItem(item, supplier) )
                                                                                        ))}

                                                                                        <tr>
                                                                                            <td className="w-96 py-4 pl-4 text-sm font-medium text-gray-900">
                                                                                                + Delivery
                                                                                            </td>
                                                                                            <td className="px-3 py-4 text-right text-sm sm:table-cell"></td>
                                                                                            <td className="px-3 py-4 text-right text-sm sm:table-cell">
                                                                                                {props.claim && props.claim.organisation.info.currency_symbol} { supplier.do_not_deliver ? '0' : supplier.delivery_cost(supplier)}
                                                                                            </td>
                                                                                            <td className="px-3 py-4 text-right text-sm sm:table-cell"></td>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <td className="w-96 py-4 pl-4 text-sm font-medium text-gray-900">-
                                                                                                Excess
                                                                                            </td>
                                                                                            <td className="px-3 py-4 text-right text-sm sm:table-cell"></td>
                                                                                            <td className="px-3 py-4 text-right text-sm sm:table-cell">{props.claim && props.claim.organisation.info.currency_symbol} {supplier && supplier.excess ?
                                                                                                supplier.excess :
                                                                                                '0'}</td>
                                                                                            <td className="px-3 py-4 text-right text-sm sm:table-cell"></td>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <td className="w-96 py-4 pl-4 text-sm font-medium text-gray-900">Invoice
                                                                                                total
                                                                                            </td>
                                                                                            <td className="px-3 py-4 text-right text-sm sm:table-cell"></td>
                                                                                            <td className="px-3 py-4 text-right text-sm sm:table-cell">
                                                                                                {props.claim && props.claim.organisation.info.currency_symbol} {supplier.total_cost(supplier, !supplier.do_not_deliver, true)}</td>
                                                                                            <td className="px-3 py-4 text-right text-sm sm:table-cell"></td>
                                                                                        </tr>

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>);
                                                                    })}
                                                                </div>


                                                                <div
                                                                    className="flex flex-col justify-center py-2 sm:px-6 lg:px-8">
                                                                    <div className="divide-y divide-gray-200 ">
                                                                        <div className="space-y-6 pt-6 pb-5">
                                                                            <div
                                                                                className="pt-10">
                                                                                <h4 className="font-medium leading-tight text-2xl mt-0 mb-2 text-gray-500">
                                                                                    Cost Summary
                                                                                </h4>
                                                                            </div>

                                                                            {/* AWARD VALUE */}
                                                                            <div
                                                                                className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                                <label htmlFor="totalValue"
                                                                                       className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                                    Total Awarding value
                                                                                </label>
                                                                                <div className="mt-1 relative">
                                                                                    <div
                                                                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                                                        <span
                                                                                            className="text-gray-700 sm:text-sm">{props.claim && props.claim.organisation.info.currency_symbol}</span>
                                                                                    </div>
                                                                                    <div id="totalValue"
                                                                                         className="block w-full pl-7 pr-12 sm:text-sm border-none">
                                                                                        {props.getTotalAwardValue(false)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* EXCESS */}
                                                                            <div
                                                                                className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                                <label htmlFor="excess"
                                                                                       className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                                    Excess
                                                                                </label>
                                                                                <div
                                                                                    className="mt-1 relative rounded-md shadow-sm">
                                                                                    <div
                                                                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                                                        <span
                                                                                            className="text-gray-700 sm:text-sm">{props.claim && props.claim.organisation.info.currency_symbol}</span>
                                                                                    </div>
                                                                                    <input
                                                                                        type="text"
                                                                                        name="excess"
                                                                                        id="excess"
                                                                                        className="input pl-7"
                                                                                        placeholder="0.00"
                                                                                        aria-describedby="excess"
                                                                                        onChange={(event) => props.setAwardInfo({
                                                                                            ...props.awardInfo,
                                                                                            excess: event.target.value
                                                                                        })}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            {/* INVOICE TOTAL */}
                                                                            <div
                                                                                className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                                <label htmlFor="totalValue"
                                                                                       className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                                    Total Invoice value
                                                                                </label>
                                                                                <div className="mt-1 relative">
                                                                                    <div
                                                                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                                                        <span
                                                                                            className="text-gray-700 sm:text-sm">{props.claim && props.claim.organisation.info.currency_symbol}</span>
                                                                                    </div>
                                                                                    <div id="totalValue"
                                                                                         className="block w-full pl-7 pr-12 sm:text-sm border-none">
                                                                                        {props.getTotalAwardValue()}
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <div
                                                                                className=" pt-10">
                                                                                <h4 className="font-medium leading-tight text-2xl mt-0 mb-2 text-gray-500 ">
                                                                                    Additional Information
                                                                                </h4>
                                                                                <span id="infoCheck"
                                                                                   style={{color: '#ec7171'}}
                                                                                   className="">
                                                                                </span>
                                                                            </div>

                                                                            {/* PURCHASE ORDER */}
                                                                            <div
                                                                                className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                                <label htmlFor="firstName"
                                                                                       className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                                    Purchase order (optional)
                                                                                </label>
                                                                                <div
                                                                                    className="mt-1 relative rounded-md shadow-sm">
                                                                                    <input
                                                                                        type="text"
                                                                                        name="firstName"
                                                                                        id="firstName"
                                                                                        className="input"
                                                                                        aria-describedby="firstName"
                                                                                        onChange={(event) => props.setAwardInfo({
                                                                                            ...props.awardInfo,
                                                                                            purchase_order: event.target.value
                                                                                        })}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            {/* CLAIMANT DETAILS */}
                                                                            <div
                                                                                className=" pt-10">
                                                                                <h4 className="font-medium leading-tight text-2xl mt-0 mb-2 text-gray-500 ">
                                                                                    Claimant Details
                                                                                </h4>
                                                                                <span id="infoCheck"
                                                                                   style={{color: '#ec7171'}}
                                                                                   className="">
                                                                                </span>
                                                                            </div>

                                                                            {/* FIRST NAME */}
                                                                            <div
                                                                                className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                                <label htmlFor="firstName"
                                                                                       className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                                    Title
                                                                                </label>
                                                                                <div
                                                                                    className="mt-1 relative rounded-md shadow-sm">
                                                                                    <input
                                                                                        type="text"
                                                                                        name="firstName"
                                                                                        id="firstName"
                                                                                        className="input"
                                                                                        aria-describedby="firstName"
                                                                                        onChange={(event) => props.setAwardInfo({
                                                                                            ...props.awardInfo,
                                                                                            name: event.target.value
                                                                                        })}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            {/* LAST NAME */}
                                                                            <div
                                                                                className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                                <label htmlFor="lastName"
                                                                                       className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                                    Surname
                                                                                </label>
                                                                                <div
                                                                                    className="mt-1 relative rounded-md shadow-sm">
                                                                                    <input
                                                                                        type="text"
                                                                                        name="lastName"
                                                                                        id="lastName"
                                                                                        className="input"
                                                                                        aria-describedby="lastName"
                                                                                        onChange={(event) => props.setAwardInfo({
                                                                                            ...props.awardInfo,
                                                                                            surname: event.target.value
                                                                                        })}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            {/* MOBILE */}
                                                                            <div
                                                                                className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                                <label htmlFor="mobile"
                                                                                       className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                                    Mobile number
                                                                                </label>

                                                                                <div
                                                                                    className="mt-1 relative rounded-md shadow-sm flex justify-between">

                                                                                    <Safe>
                                                                                        <MobileNumberInput
                                                                                            className='input w-[10rem] p-1 pr-4 m-0 mr-4'
                                                                                            claim={props.claim}
                                                                                            onChange={(e) => props.setAwardInfo({
                                                                                                ...props.awardInfo,
                                                                                                country_code: e.target.value
                                                                                            })}
                                                                                            awardInfo={props.awardInfo}
                                                                                            setAwardInfo={props.setAwardInfo}
                                                                                            value={props.awardInfo.country_code}
                                                                                        />
                                                                                    </Safe>

                                                                                    <input
                                                                                        type="text"
                                                                                        name="mobile"
                                                                                        autoComplete='off'
                                                                                        data-lpignore="true"
                                                                                        id="mobile"
                                                                                        className="input w-[8rem] m-0"
                                                                                        aria-describedby="mobile"
                                                                                        onChange={(e) => props.setAwardInfo({
                                                                                            ...props.awardInfo,
                                                                                            mobile_number: e.target.value
                                                                                        })}
                                                                                    />


                                                                                </div>
                                                                            </div>


                                                                            {/* EMAIL */}
                                                                            <div
                                                                                className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                                                                                <label htmlFor="mobile"
                                                                                       className="block font-medium text-gray-900 sm:mt-px sm:pt-2">
                                                                                    Email
                                                                                </label>
                                                                                <div
                                                                                    className="mt-1 relative rounded-md shadow-sm">
                                                                                    <input
                                                                                        type="text"
                                                                                        name="email"
                                                                                        id="email"
                                                                                        className="input"
                                                                                        onChange={(event) => props.setAwardInfo({
                                                                                            ...props.awardInfo,
                                                                                            email: event.target.value
                                                                                        })}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start">

                                                                                <button
                                                                                    type="button"
                                                                                    id="issueVoucher"
                                                                                    onClick={props.onIssueAwardClicked}
                                                                                    // className="pointer-events-none      mt-1 h-10 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 hover:cursor-pointer">
                                                                                    className="btn w-full mt-6">
                                                                                    <span className="ml-auto mr-auto">
                                                                                      <CheckCircleIcon
                                                                                          className="mr-1 inline h-5 w-5 align-top"
                                                                                          aria-hidden="true"/>
                                                                                      Issue Award
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                            <div className="flex justify-end">

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </main>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 flex justify-start">
                                        <button
                                            type="button"
                                            className="btn-light"
                                            onClick={() => props.setOpen(false)}
                                        >
                                            <XIcon className="h-5 w-5 mr-2"/>
                                            Close
                                        </button>

                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}


// Library imports
import React, {Fragment, useLayoutEffect, useState} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/solid';
import {useLocation} from 'react-router-dom';


// Local imports
import {classNames} from '../../utils/helpers.js';
import ProfileModal from '../../containers/modals/ProfileModal';
import ClaimBasicInfoPanel from '../ClaimBasicInfoPanel';
import Safe from "../Safe";
import {BasicImage} from "../base/BasicImage";


// NavbarTop component
export default function NavbarTop(props) {

    // Data states
    const [profileOpen, setProfileOpen] = useState(false);

    const location = useLocation();
    useLayoutEffect(() => {
        /** useLayoutEffect is a version of useEffect that fires before the browser repaints the screen.
         * Clear the navbar content when navigating to a new page */
        props.setNavbarTopPageContent({});
    }, [location.pathname]);


    if (!props.authenticated) {
        return <></>;
    }


    return (
        <div className="mt-3">
            <ProfileModal
                {...props}
                open={profileOpen}
                setOpen={setProfileOpen}
                user={props.user}
            />

            <div className="relative z-10 flex-shrink-0 flex h-16 bg-red border-b border-gray-200 lg:border-none">

                {/* Search bar */}
                <div className="flex w-full px-4 flex justify-between">

                    {/* CLAIM HEADER AND DETAILS */}
                    <div className="flex">
                        <Safe> <ClaimBasicInfoPanel claim={props.navbarTopPageContent?.claim}/> </Safe>
                    </div>

                    {/* CUSTOM CONTENT PER PAGE */}
                    <div className="flex grow">
                        {props.navbarTopPageContent?.content}
                    </div>

                    <div className="flex w-fit">

                        {/* USER PROFILE */}
                        <Safe> <UserMenu {...props} setProfileOpen={setProfileOpen}/> </Safe>


                    </div>
                </div>
            </div>
        </div>
    );
}

function UserMenu(props) {

    return (
        <div className="items-center ml-6">

            {props.additionalIcons}

            {/* Profile dropdown */}
            <Menu as="div" className="widget h-14 p-2 m-1 relative">
                <div>
                    <Menu.Button
                        className="max-w-xs bg-white rounded-lg flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-1 lg:rounded-md lg:hover:bg-gray-50">
                        <BasicImage
                            src={props.user && props.user.info.profile_image_url}
                            fallbackSrc={'/profile-picture.jpg'}
                            alt="avatar"
                            sizeWidthRem="2"
                            sizeHeightRem="2"
                            className="rounded-full"
                        />
                        <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                    <span className="sr-only">Open user menu for </span>
                            {props.user === null ? 'Loading...' : props.user.info.first_name + ' ' + props.user.info.last_name}
                    </span>
                        <ChevronDownIcon
                            className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="#"
                                    onClick={() => props.setProfileOpen(true)}
                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                    Your Profile
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="/logout"
                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                    Logout
                                </a>
                            )}
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}
import React from 'react';
import {currencySymbol} from "../../utils/helpers";

const CurrencyInput = (props) => {

    return (
        <div className="relative">

            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                {props.currencySymbol || currencySymbol(props.claim)}
            </span>

            <input
                type="number"
                {...props}
                className={'pl-6 ' + props.className}
            />

        </div>
    );
};

export default CurrencyInput;

// Library imports
import { Combobox } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { classNames } from '../utils/helpers.js';

export default function LocalityBox(props) {

  if (props.localities === null) {
    return '...';
  }

  let localities = [];

  if (props.locality) {
    localities = localities.concat(props.locality.localities || []);
  }
  
  if (localities.length === 0) {
    return (
        <i className="text-gray-500">Please type in a Postal Code</i>
    )
  }

  return (
    <Combobox as="div" value={props.selectedOption !== null ? props.selectedOption : ""} onChange={props.setSelectedOption}>
    {/*<Combobox as="div" value=" Please Choose Locality">*/}
      <div className="relative mt-1">
        <Combobox.Input
          className="input"
          // onChange={(event) => setQuery(event.target.value)}
          // displayValue={(org) => org !== null ? org : ""}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {localities.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {localities.map((locality) => (
              <Combobox.Option
                key={locality}
                value={locality}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9 h-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <div>
                    <div className="flex items-center">
                      <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>{locality}</span>
                    </div>
                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );

}

/** Any page that is related to Claims, should fall under here, to get the benefit of the NavbarTop */
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ClaimItemSearch from './containers/claim/ClaimItemSearch';
import ClaimIssueAward from './containers/claim/ClaimIssueAward';
import ClaimIssueVoucher from './containers/claim/ClaimIssueVoucher';
import ClaimVoucherReturn from './containers/claim/ClaimVoucherReturn';
import ClaimCartReview from './containers/claim/ClaimCartReview';
import ClaimProfile from './containers/claim/ClaimProfile';
import ClaimTender from './containers/claim/ClaimTender';
import ClaimPostTenderInspection from './containers/claim/ClaimPostTenderInspection';
import ClaimVerification from './containers/claim/ClaimVerification';
import ClaimsHome from './containers/claim/ClaimsHome';
import Safe from "./components/Safe";


export default function ClaimPages(props){

    return (

        <div className="">

            <Safe> <ClaimPagesRoutes {...props} /> </Safe>

        </div>

    );

}

const ClaimPagesRoutes = (props) => {


    const access = props.access;

    return (
        <Routes>

            <Route path="/blank" element={access(<BlankPage {...props} />, 'ADMIN_UP')}/>

            <Route path="/" element={access(<ClaimsHome {...props} />, '')} exact/>
            <Route path="/home" element={access(<ClaimsHome {...props} />, '')} exact/>

            <Route path="/search" element={access(<ClaimItemSearch {...props}/>, 'ADMIN_UP')}/>
            <Route path="/claim/item-search/:claimId" element={access(<ClaimItemSearch {...props} mode="search"/>, 'INSURER_UP')}/>
            <Route path="/claim/item-convert/:claimId/:itemId" element={access(<ClaimItemSearch {...props} mode="convert"/>, 'ADMIN_UP')}/>
            <Route path="/claim/item-add-replacement/:claimId/:itemId" element={access(<ClaimItemSearch {...props} mode="addReplacement"/>, 'SUPPLIER_UP')}/>
            <Route path="/claim/item-link-to-task/:claimId" element={access(<ClaimItemSearch {...props} mode="linkToHaiTask"/>, 'ADMIN_UP')}/>

            <Route path="/claim/cart-review/:claimId" element={access(<ClaimCartReview {...props} />, 'INSURER_UP')}/>
            <Route path="/claim/quantification-review/:claimId" element={access(<ClaimProfile {...props} />, 'SUPPLIER_UP')}/>
            <Route path="/claim/tender/:claimId" element={access(<ClaimTender {...props} />, 'SUPPLIER_UP')}/>
            <Route path="/claim/post-tender/:claimId" element={access(<ClaimPostTenderInspection {...props} />, 'INSURER_UP')}/>
            <Route path="/claim/verification/:claimId" element={access(<ClaimVerification {...props} />, 'INSURER_UP')}/>

            <Route path="/claim/issue-award/:claimId" element={access(<ClaimIssueAward {...props} />, 'SUPPLIER_UP')}/>
            <Route path="/claim/issue-voucher/:claimId" element={access(<ClaimIssueVoucher {...props} />, 'INSURER_UP')}/>
            <Route path="/special/suppliervoucherkeyreturn" element={access(<ClaimVoucherReturn {...props} />, '')}/>

        </Routes>
    );

};

const BlankPage = () => {
    return (
        <></>
    );
};
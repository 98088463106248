import React, {useEffect, useState} from 'react';

export const BasicImage = ({
                               src,
                               alt = 'Image',
                               sizeWidthRem = '',
                               sizeHeightRem = '',
                               fallbackSrc = '/logo_default_organisation.png',
                               onClick = () => null,
                               imageContain = 'object-contain', // 'object-cover' or 'object-contain'
                               ...props
                           }) => {

    const [imgSrc, setImgSrc] = useState(src);
    const [loaded, setLoaded] = useState(false); // Track if the image has loaded

    const handleError = () => {

        // Prevent infinite loop (onError) if fallbackSrc is also unavailable
        if (imgSrc !== fallbackSrc)
            setImgSrc(fallbackSrc);
    };
    const handleLoad = () => {
        setLoaded(true); // Set loaded to true when the image has loaded
    };

    useEffect(() => {
        /** if a null or invalid src is passed to the Image, default to the fallback */

        if (!src) {
            setImgSrc(fallbackSrc);
        }
    }, [src]);

    // Tailwind classes for fade-in effect
    // Use Tailwind's built-in classes for opacity and transition
    const imageClasses = `transition-opacity duration-500 ease-out ${loaded ? 'opacity-100' : 'opacity-0'}`;

    return (
        <img
            {...props}
            src={imgSrc}
            alt={alt}
            onClick={onClick}
            onError={handleError}
            onLoad={handleLoad} // Handle when the image is loaded
            className={`${imageClasses} ${imageContain} ` + (props.className || '')}
            style={{
                width: sizeWidthRem ? sizeWidthRem + 'rem' : 'auto',
                height: sizeHeightRem ? sizeHeightRem + 'rem' : 'auto',
                transition: 'opacity 0.5s ease-in-out'
            }}
        />
    );
};

/**
// Full usage example:
function Example(){

    return (
        <BasicImage
            src={props.user && props.user.info.profile_image_url}
            fallbackSrc={'/profile-picture.jpg'}
            alt="avatar"
            sizeWidthRem="2"
            sizeHeightRem="2"
            onClick={() => console.log('Image clicked')}
            className="rounded-full"
            imageContain={'object-contain'}
            props = {id: "example"} // pass any other props
        />
    );

}
*/


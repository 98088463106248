// Library imports

import React from 'react';
import {classNames} from "../utils/helpers";


// Footer component
export default function Footer(props) {
    return (
        <footer className={classNames(
            "relative border-t border-gray-200 flex justify-center items-center min-h-[6rem] text-sm text-gray-500",
            window.location.pathname === '/login' && 'bg-white' // If we are on the login page, make the footer white
        )}>
            <p className="absolute left-1/2 transform -translate-x-1/2">
                &copy; {new Date().getFullYear()} <a href="https://www.slvrcld.com">slvrcld BV</a> | All rights reserved.
            </p>

            {/* SLVRCLD LOGO */}
            {/* Check if props.portalRepresentative is 'THESL' and conditionally render logo */}
            {props.portalRepresentative === 'THESL' &&
                <div className="flex items-center justify-end w-full gap-4 pr-12">
                    <a href="https://www.slvrcld.com/" target="_blank" rel="noopener noreferrer">Powered by <b>SLVR</b>CLD</a>
                    <a href="https://www.slvrcld.com/" target="_blank" rel="noopener noreferrer">
                        <img src="/logo192.png" alt="SLVRCLD logo" className="h-14"/>
                    </a>
                </div>
            }
        </footer>
    );
};

// Library imports

import React, {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {
    ArrowRightIcon,
    CloudUploadIcon,
    DocumentDuplicateIcon,
    DocumentSearchIcon,
    DocumentTextIcon,
    PencilAltIcon,
    PlusIcon,
    XIcon
} from '@heroicons/react/outline';

// Local imports
import {classNames, escapeDoubleQuotes, formatAsTitle, isEmpty} from '../../utils/helpers.js';
import ClaimItemSearch from '../claim/ClaimItemSearch';
import {ArrowLeftIcon} from '@heroicons/react/solid';
import {
    customGraphRequest,
    filterSearch,
    productCreationSpecs, productProperties,
    protoProductProperties,
    queryCategories,
    updateProtoProductManually
} from '../../utils/coreApi';
import {useParams} from "react-router-dom";
import {BasicImage} from "../../components/base/BasicImage";
import {useQuery} from "../../components/useQuery";
import Tooltip from "../../components/Tooltip";


export const standardTransition = {
    'enter': 'ease-out duration-300',
    'enterFrom': 'opacity-0 translate-y-4 md:translate-y-0 md:scale-95',
    'enterTo': 'opacity-100 translate-y-0 md:scale-100',
    'leave': 'ease-in duration-200',
    'leaveFrom': 'opacity-100 translate-y-0 md:scale-100',
    'leaveTo': 'opacity-0 translate-y-4 md:translate-y-0 md:scale-95'
};

export default function MultiProductModal(props){

    /** Queries and mutations
     *
     * Q product_properties
     *  get the full array of properties for a product (claim object only returns required properties)
     * Q categories
     *  get a list of all selectable the categories
     * Q product_creation_specs
     *  each product category has a list of specs (required, optional, general, additional) that can be added to a product
     *      general - required specs that are common to all products
     *      required - required specs that must be included in a product of this category
     *      optional - non-required specs that can be included in a product of this category
     *      additional - non-required specs that are general to multiple categories
     * Q filter_search
     *  retrieve a product's full data with only the product's ID
     *
     * M create_proto_product_manually
     *  create a new Proto product (not added to claim)
     * M update_proto_manually
     *  Update the specs of an existing Proto product
    * */

    /** Property and Spec/specification
     *   both are the same thing, used interchangeably
     *   a dictionary containing a name, a value, and (optionally) a display_name */

    const {claimId} = useParams();

    const [showModalTwo, setShowModalTwo] = useState(false);

    // A list of each product in all the ProductModals
    const [allProducts, setAllProducts] = useState([]);

    // A list of lists of all properties, found in all the products
    const [allProductsAllProperties, setAllProductsAllProperties] = useState([
        /**
         { product 1
            id: '...',
            properties: [
                {name: '...', value: '...'},
                {name: '...', value: '...'},
            ]
         },

         {
            { product 2
                id: '...',
                properties: [
                    {name: '...', value: '...'},
                    {name: '...', value: '...'},
                ]
            }
         }

        * */
    ]);

    // The name of the property that currently has focus, from the user clicking on it to edit
    const [focusedProductProperty, setFocusedProductProperty] = useState(null);

    // This state is used to copy property values from one product to another - e.g the "copy across" functionality
    const [copyProperty, setCopyProperty] = useState(null);

    // The current mode of the ProductModals ('view' products or 'edit' products)
    const [mode, setMode] = useState('view');

    // All supported categories
    const categoriesHook = useQuery({
        queryStringFunction: () => {
            return `
                query ProductModal_categories{
                  categories(sort_by:[{ field:"display_name" order:ASCENDING }]) {
                    error {
                      type
                      message
                    }
                    categories {
                      id
                      category_a
                      category_b
                      category_c
                      category_d
                      display_name
                    }
                  }
                }
            `
        },
        onSuccess: (data) => {
            categoriesHook.setState(data["categories"]);
        },
        cacheExpirationMin: 60 * 6,
        onError: props.onError,
        cacheResponse: true, useExistingCache: true, skipQueryIfCache: true,
    });
    const {state: categories, setState: setCategories} = categoriesHook;

    useEffect(() => {
        /** Set any options passed from Parent **/

        // Mode: view | edit
        setMode(props.options.mode);
    }, [props.options]);

    useEffect(() => {
        /** If a second product is passed, the second modal must be opened */

        if(props.options.product2) setShowModalTwo(true);
    }, [props.options]);

    const allProps = {
        ...props,
        claimId,
        showModalTwo, setShowModalTwo,
        allProducts, setAllProducts,
        allProductsAllProperties, setAllProductsAllProperties,
        focusedProductProperty, setFocusedProductProperty,
        mode, setMode,
        copyProperty, setCopyProperty,
        categories, setCategories,
    };

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={props.setOpen}>
                <div className="flex w-full items-stretch justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">

                    {/* DARK BACKGROUND */}
                    <Transition.Child as={Fragment} {...standardTransition} >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* MODAL 1 */}
                    {<ProductModal {...allProps} product={props.options.product} modalCount={0}/>}

                    {/* MODAL 2 */}
                    {showModalTwo && <ProductModal {...allProps} product={props.options.product2} modalCount={1}/>}

                </div>
            </Dialog>
        </Transition.Root>
    );
}

function ProductModal(props){


    // ==== PRODUCT ====

    // The product that is being displayed and modified in this modal
    const [product, setProduct] = useState(null);
    /** {
    //      id: '...',
    //      ai_generated: null,
    //      ...
    //      properties: [...]
    //  } **/

    // A deep copy of the original product, unmodified, used for reference (what properties have been modified, etc.)
    const [originalProduct, setOriginalProduct] = useState(null);

    // TODO make this a function call
    // Flag to indicate if any of the specs of this product have been modified
    const [productHasBeenModified, setProductHasBeenModified] = useState(false);


    // ==== PROPERTIES ====

    // Required specs on all products
    const [brand, setBrand] = useState('');
    const [modelNumbers, setModelNumbers] = useState('');
    const [eanCodes, setEanCodes] = useState('');
    const [releaseDate, setReleaseDate] = useState('');

    const [allProperties, setAllProperties] = useState([
    /** {
    //      name: 'internal_memory',
    //      value: '12GB RAM'
    //  } **/
    ]);

    useEffect(() => {
        if(!props.open) clearStates();
    }, [props.open]);

    function clearStates(){
        setSpecList_originalQuery([]);
        setSpecList_array([]);
        setAllProperties([]);
        setOriginalProduct(null);
        setProductHasBeenModified(false);

        props.setAllProductsAllProperties([]);
        props.setAllProducts([]);
    }


    useEffect(() => {
        /** if a product was passed down as a prop, use it (skip displaying ClaimItemSearch) **/

        if (!props.product) return;

        // State fix: do not reset the product (again) if it has already been set
        if (product) return;

        onSelectProduct(props.product);

    }, [props.product]);

    const getFullProduct = (_product) => {
        /** Get the full product
         * function accepts a product object, this is to circumvent a 'useEffect' setup,
         * and allow this function to be called directly **/

        productProperties(
            {
                productId: _product.id,
                productCategory: _product.category,
            },
            (data) => {
                let fullProduct = data.product || null;

                if(!fullProduct) return props.showAlertModal( 'error', 'Failed to retrieve all specs', 'Failed to retrieve the full product data');

                props.showToastNotificationModal('success', 'Full product retrieved', '');
                onSelectProduct(fullProduct, true)
            },
            (error) => props.onError(error),
        )

    }

    const onSelectProduct = (newProduct, newProductIncludesAllProperties = false) => {
        /** Set the product for this card/modal
         * Called locally in the ProductModal, and called inside the ClaimItemSearch **/

        clearStates();

        // A product's "properties" field can be null (in the case of manual items)
        // make sure the properties field is not null
        if (!newProduct.properties) newProduct.properties = [];

        // TODO temp fix for duplicated brand property
        // is the brand property present twice?
        let brandProperties = newProduct.properties.filter(property => property.name === 'brand');
        if(brandProperties.length > 1) {
            // Remove the first brand property
            newProduct.properties = newProduct.properties.filter(property => property.name !== 'brand');
        }

        // Flag to indicate if the full properties have been retrieved via the proto_product_properties query
        newProduct.hasRetrievedFullProperties = newProductIncludesAllProperties;
        if(!newProductIncludesAllProperties) getFullProduct(newProduct);

        setProduct(newProduct);
        // Create a deep copy of the product, that can be modified without affecting the original product
        setOriginalProduct(structuredClone(newProduct));

        createProperties(newProduct)

        /** When any ProductModal has selected a new product, clear the list of 'allProducts' , so each ProductModal will re-add it's product **/
        props.setAllProducts([]);

        // Get the list of specs for this product's category
        getSpecListForProductCategory(newProduct);

    };

    function createProperties(newProduct){
        /** Set the allProperties state **/

        setBrand(newProduct.brand)
        setReleaseDate(newProduct.date_released)

        if (newProduct.model_numbers) {
            setModelNumbers(newProduct.model_numbers.join(', '))
        } else {
            console.log('no model numbers')
            setModelNumbers('')
        }

        if (newProduct.ean_codes) {
            setEanCodes(newProduct.ean_codes.join(', '))
        } else {
            setEanCodes('')
        }

        // Remove any of the ignore specs (these are GENERAL specs and handled separately)
        let ignoreSpecs = ['brand', 'model_numbers', 'ean_codes', 'date_released'];
        let allSpecs = newProduct.properties.filter(spec => !ignoreSpecs.includes(spec.name));

        // create a deep copy of the product's properties
        setAllProperties(structuredClone(allSpecs));
    }


    useEffect(() => {
        /** Whenever the list of properties have been modified, update the allProductsAllProperties state */
        updatePropertiesToAllProductsAllProperties();
    }, [allProperties]);
    useEffect(() => {
        /** If allProductsAllProperties has been modified, update this products properties to the list **/
        addPropertiesToAllProductsAllProperties();
    }, [props.allProductsAllProperties]);

    function updatePropertiesToAllProductsAllProperties(){
        /** When a product has been modified, update the product in the MultiProductModal.allProducts list **/

        if(!product) return;

        // Check if this product has already been added
        const added = props.allProductsAllProperties.find(_product => _product.id === product.id);
        if(!added){
            // Add this product to the list
            addPropertiesToAllProductsAllProperties();
            return;
        }

        // Update the properties of this product
        props.allProductsAllProperties.find(_product => _product.id === product.id).properties = allProperties;
        props.setAllProductsAllProperties([...props.allProductsAllProperties])
    }
    function addPropertiesToAllProductsAllProperties(){
        /** When a product has been modified, update the product in the MultiProductModal.allProducts list **/

        if(!product) return;

        // Check if this product has already been added
        const added = props.allProductsAllProperties.find(_product => _product.id === product.id);
        if(added) return;

        let productAndProperties = {id: product.id, properties: allProperties};
        props.setAllProductsAllProperties([...props.allProductsAllProperties, productAndProperties])
    }

    useEffect(() => {
        /** When any ProductModal has selected a new product, the 'allProducts' is cleared
         * Each ProductModal must re-add it's product **/

        // Do not add this ProductModal's product to the list of allProducts, if a product has not been selected
        if (!product) return;

        addProductToAllProducts();
    }, [props.allProducts]);

    const addProductToAllProducts = () => {
        /** When any ProductModal has selected a new product, the 'allProducts' is cleared
         * Each ProductModal must re-add it's product **/

        // Does this product already exist in the allProducts list?
        const added = props.allProducts.find(_product => _product.id === product.id);

        // If not, add it
        if (!added) {
            /** Because multiple components are calling the setAllProducts in the same cycle
             * to add their product to the allProducts list,
             * we need to use a callback function to update the state with the previous setAllProducts state,
             * rather than just setting it directly **/
            props.setAllProducts((previousState) => {
                return [...previousState, product];
            });
        }
    };

    useEffect(() => {
        updateProduct_allProducts();
    }, [product]);

    function updateProduct_allProducts(){
        /** When a product has been modified, update the product in the MultiProductModal.allProducts list **/

        if(!product) return;
        let updatedProducts = props.allProducts.map(_product => {
            if (_product.id === product.id) return product;
            return _product;
        });

        props.setAllProducts(updatedProducts);
    }


    // ==== SPEC LIST ====

    // TODO cache this, or atleast use the state so to prevent multiple queries
    // The spec data for this product's category, in the original query format
    const [specList_originalQuery, setSpecList_originalQuery] = useState([
    /** {
    //      brand
    //      specs_by_importance{
    //          importance
    //          product_specs{
    //              id
    //              spec_field
    //              spec_values
    //          }
    //      }
    // } */
    ]);

    // A single array of all the possible spec fields that can be assigned to a product in the given category
    const [specList_array, setSpecList_array] = useState([
    /** {
    //     brand: 'Apple',
    //     importance: 'REQUIRED',
    //     id: '..',
    //     field: 'model_number',
    //     values: ['A1234', 'A1235', 'A1236']
    // }, */
    ]);

    useEffect(() => {
        getSpecListForProductCategory(product);
    }, [props.mode, product]);

    const getSpecListForProductCategory = (_product) => {
        /** Get all the specs for this category (the category of the product) */

        if (props.mode === 'view') return;
        if (!_product) return;
        if (!isEmpty(specList_originalQuery)) return;

        // Function to clean non-digit characters from strings and convert to numbers if applicable
        function cleanAndConvert(value) {
            const cleaned = value.replace(/[^\d.-]/g, '');  // Remove all non-digit characters except the decimal point and minus
            return isNaN(cleaned) ? value : parseFloat(cleaned);
        }

        productCreationSpecs(
            {
                category: _product.category,
                importanceLevels: ["REQUIRED", "ADDITIONAL", "OPTIONAL", "GENERAL"],
            },
            (data) => {

                // sort the spec values, alphabetically for strings, and numerically for digital values
                // product_specs_by_brand.specs_by_importance.product_specs.spec_values[] <-- array of values for this specification
                data.product_specs_by_brand.forEach(brandSpecs => {
                    brandSpecs.specs_by_importance.forEach(importanceSpecs => {
                        importanceSpecs.product_specs.forEach(spec => {
                            spec.spec_values.sort((a, b) => {
                                const numA = cleanAndConvert(a);
                                const numB = cleanAndConvert(b);
                                if (typeof numA === 'number' && typeof numB === 'number') {
                                    return numB - numA;
                                }
                                return a.localeCompare(b);
                            });
                        });
                    });
                });

                setSpecList_originalQuery(data.product_specs_by_brand)

                let allSpecs = [
                    /** {
                    //     brand: 'Apple',
                    //     importance: 'REQUIRED',
                    //     id: '..',
                    //     field: 'model_number',
                    //     values: ['A1234', 'A1235', 'A1236']
                    // }, */
                ]

                /** The product_creation_specs query's data structure
                // {
                //      brand
                //      specs_by_importance{
                //          importance
                //          product_specs{
                //              id
                //              spec_field
                //              spec_values
                //          }
                //      }
                // } */

                // Create a flat copy of the creation spec data
                for (const brandData of data.product_specs_by_brand) {

                    for (const specs_by_importance of brandData.specs_by_importance) {

                        for (const spec of specs_by_importance.product_specs) {

                            let specObj = {
                                brand: brandData.brand,
                                importance: specs_by_importance.importance,
                                // id: spec.id,
                                field: spec.spec_field,
                                values: spec.spec_values
                            }

                            allSpecs.push(specObj)
                        }

                    }

                }

                setSpecList_array(allSpecs);
            },
            (error) => {
                console.log('getSpecListForProductCategory error', error)
                if(error.message === 'Invalid category: unknown'){
                    props.showAlertModal('error', 'Invalid category', 'The category of this product is invalid');
                }

            }
        );
    }
    function orderSpecValues(spec){
        /** Order the spec values alphabetically, with the first value being an empty string */

        if(!spec) return [];

        let values = spec.values || [];
        values = values.sort();
        values.unshift('');
        return values;
    }

    useEffect(() => automaticallyAddRequiredPropertiesToProduct(), [specList_array]);
    const automaticallyAddRequiredPropertiesToProduct = () => {
        /** If the Proto modal goes into "Edit" mode, and the "product creation specs" list is retrieved,
         * Automatically include (in this products properties) any missing Required and Optional specs */

        if (!product || !specList_array) return;

        if(props.mode !== 'edit') return;

        // Has this function already been run on this product?
        if(product.specsAutoAdded) return;

        let requiredSpecs = specList_array.filter(spec => spec.importance === 'REQUIRED');
        let optionalSpecs = specList_array.filter(spec => spec.importance === 'OPTIONAL');

        let specsToAdd = []


        // Add any missing required specs
        requiredSpecs.forEach(spec => {
            const productHasSpec = allProperties.find(property => property.name === spec.field);
            const fieldHasNotBeenAdded = !specsToAdd.find(property => property.name === spec.field);
            const fieldHasSelectableValues = spec.values.length > 0;
            if (!productHasSpec && fieldHasNotBeenAdded && fieldHasSelectableValues) {
                specsToAdd.push({name: spec.field, value: ''});
            }
        });

        // Add any missing optional specs
        optionalSpecs.forEach(spec => {
            const productHasSpec = allProperties.find(property => property.name === spec.field);
            const fieldHasNotBeenAdded = !specsToAdd.find(property => property.name === spec.field);
            const fieldHasSelectableValues = spec.values.length > 0;
            if (!productHasSpec && fieldHasNotBeenAdded && fieldHasSelectableValues) {
                specsToAdd.push({name: spec.field, value: ''});
            }
        });

        // Remove any of the ignore specs (these are GENERAL specs and handled separately)
        let ignoreSpecs = ['brand', 'model_numbers', 'ean_codes', 'date_released'];
        let existingSpecs = allProperties.filter(spec => !ignoreSpecs.includes(spec.name));
        specsToAdd = specsToAdd.filter(spec => !ignoreSpecs.includes(spec.name));

        let combinedProperties = [...existingSpecs, ...specsToAdd];
        removeNonCreationSpecProperties(combinedProperties);

    }

    function removeNonCreationSpecProperties(combinedProperties){
        /** If a product has an existing spec (product.properties), that is not found in the product_creation_specs,
         * that spec must be excluded when editing the product.
         * When a product is submitted for an update "update_proto_product" it must only submit specs from
         * the list of creation specs */

        if(!product || isEmpty(specList_array)) return;

        let newProperties = combinedProperties.filter(property => {
            let spec = specList_array.find(spec => spec.field === property.name);
            return !!spec;
        });

        setAllProperties(newProperties);

    }


    // ==== BRAND VALIDATION ====

    useEffect(() => {
        checkProductBrandIsValid();
    }, [props.mode, product, specList_originalQuery]);
    function checkProductBrandIsValid(){
        /** Check if the product's brand is present in the creation specs **/

        if(props.mode === 'view') return;
        if(!product || isEmpty(specList_originalQuery)) return;

        let brandSpec = specList_originalQuery.find(spec => spec.brand === product.brand);

        if(!brandSpec)
            props.showAlertModal(
            'info',
            'Brand not found',
            "The brand of this product is not present in the creation specs for this product's category");

    }


    // ==== COPY SPEC ====

    useEffect(() => {
        /** When the "copyProperty" state has been modified, copy that value to all the ProductModal's properties **/

        if (!props.copyProperty) return;

        let propertyInModifiedProduct = null;
        propertyInModifiedProduct = allProperties.find(property => property.name === props.copyProperty.name);

        if (propertyInModifiedProduct) propertyInModifiedProduct.value = props.copyProperty.value;
        else {
            propertyInModifiedProduct = {name: props.copyProperty.name, value: props.copyProperty.value};
            allProperties.push(propertyInModifiedProduct);
        }

        setProduct({...product});

    }, [props.copyProperty]);


    // ==== PRODUCT MUTATIONS ====

    const updateProtoProduct = () => {
        /** Permanently update the product
         * - check that there are changes between the original and modified product states
         * - include the newProperties **/

        const category = props.categories.find(category => category.category_d === product.category);
        if (!category) return props.showAlertModal('Error', 'Missing Category', 'Could not find the category data for this product');

        // Make sure the brand, model numbers, and EAN codes are included in the properties, warn if not
        if (!brand) return props.showAlertModal('info', 'Missing Brand', 'The brand is missing from the product properties');
        if (!modelNumbers) return props.showAlertModal('info', 'Missing Model Numbers', 'The model numbers are missing from the product properties');
        // if (!eanCodes) return props.showAlertModal('info', 'Missing EAN Codes', 'The EAN codes are missing from the product properties');
        if (!releaseDate) return props.showAlertModal('info', 'Missing Date Released', 'The date released is missing from the product properties');

        let _modelNumbers = "[]"
        if(modelNumbers) {
            /* "123, 456, 789" -> ["123", "456", "789"] */
            _modelNumbers = modelNumbers.replaceAll(' ', '').split(',').map(n => escapeDoubleQuotes(n));
            _modelNumbers = `["${_modelNumbers?.join('", "')}"]`
        }

        let _eanCodes = "[]"
        if(eanCodes) {
            /* "123, 456, 789" -> ["123", "456", "789"] */
            _eanCodes = eanCodes.replaceAll(' ', '').split(',').map(n => escapeDoubleQuotes(n));
            _eanCodes = `["${_eanCodes?.join('", "')}"]`
        }


        let _allProperties = allProperties;
        if(!allProperties.find(property => property.name === 'brand')) {
            const _brandProperty = {name: 'brand', value: brand};
            _allProperties = [...allProperties, _brandProperty];
        }

        console.log('saveProduct', product, allProperties, props.options.item)

        updateProtoProductManually({
                protoId:product.id,
                category:category.category_d,
                providerImageUrl:null,
                dataSpec:_allProperties,        // the properties from product and new properties
                dateReleased:releaseDate,     // A datetime in the format 'yyyy-mm-dd hh:mm:ss' e.g. '2021-03-02 14:15:00'
                modelNumbers:_modelNumbers,    // the list of product model numbers
                eanCodes:_eanCodes,        // the list of product EAN codes
                itemId: props.options.item?.id || null,
            },
            (data) => {
                props.showNotificationModal('success', 'Product saved', 'The product has been saved successfully and a new proto has been created.');
                console.log('successfully saved proto: ', data);

                let newProductId = data.update_result[0].new_proto_product.id;

                //Return the updated product if the page embedding this CompareAndEditProductModal has the callback function
                fetchFullProduct(newProductId)
            },
            (error) => props.onError(error)
        );

    };

    const deleteProtoProduct = () => {

        function _deleteProduct(){

            let mutation = `
            mutation delete {
              delete_proto_product(
                proto_id: "${product.id}"
                category: ${product.category}
                # allow_catalogue_products: false
              ){
                error{type, message}
                product{
                  id
                }
                log_messages
              }
            }`

            customGraphRequest(
                mutation,
                (data) => {
                    console.log('Product deleted', data);
                    props.showNotificationModal('success', 'Product deleted', 'The product has been deleted successfully.');
                    props.setOpen(false);
                },
                (error) => props.onError(error)
            )

        }

        props.showConfirmModal(
            'warning',
            'Delete product',
            'Are you sure you want to delete this product?',
            'Delete product',
            _deleteProduct
        )

    }

    function createManualProduct(){

        const category = props.categories.find(category => category.category_d === product.category);
        if (!category) return props.showAlertModal('Error', 'Missing Category', 'Could not find the category data for this product');

        // Make sure the brand, model numbers, and EAN codes are included in the properties, warn if not
        if (!modelNumbers) return props.showAlertModal('info', 'Missing Model Numbers', 'The model numbers are missing from the product properties');
        if (!releaseDate) return props.showAlertModal('info', 'Missing Date Released', 'The date released is missing from the product properties');


        let _modelNumbers = "[]"
        if(modelNumbers) {
            /* "123, 456, 789" -> ["123", "456", "789"] */
            _modelNumbers = modelNumbers.replaceAll(' ', '').split(',').map(n => escapeDoubleQuotes(n));
            _modelNumbers = `["${_modelNumbers?.join('", "')}"]`
        }

        let _eanCodes = "[]"
        if(eanCodes) {
            /* "123, 456, 789" -> ["123", "456", "789"] */
            _eanCodes = eanCodes.replaceAll(' ', '').split(',').map(n => escapeDoubleQuotes(n));
            _eanCodes = `["${_eanCodes?.join('", "')}"]`
        }

        const format = (name) => name ? name.replaceAll(' ', '_').replaceAll('"', '\\"').toLowerCase() : '';

        let specs = '['
        specs += allProperties.map((spec) => {
            if(!spec.name || !spec.value) return null;
            return ` 
                {
                    field: "${format(spec.name)}" 
                    value: "${escapeDoubleQuotes(spec.value)}"
                }`;
        }).filter(spec => !!spec)
        specs += ']';

        let mutation = `
            mutation createProtoProductManually {
              create_proto_product_manually(
                category: ${category.category_d}
                brand: "${brand}"
                date_released: "${releaseDate}"
                model_numbers: ${_modelNumbers}
                ean_codes: ${_eanCodes}
                product_specs: ${specs}
                item_id: "${props.options.item.id}"
                
              ){
                error{type, message}
                proto_product{
                  id
                }
                duplicate_proto{
                  id
                }
                new_claim_item{
                  id
                }
              }
            }
        `


        console.log('createManualProduct', mutation)

        props.showConfirmModal(
            'success',
            'Create product',
            'Are you sure you want to create a proto product with the below specifications?',
            'Create product',
            () => {
                customGraphRequest(
                    mutation,
                    (data) => {
                        console.log('Product created', data);
                        props.showNotificationModal('success', 'Product created', 'The product has been created successfully.');
                        props.setOpen(false);
                    },
                    (error) => props.onError(error)
                );
            }
        )


    }


    // ==== FETCH FULL PRODUCT ====

    const fetchFullProduct = (newProductId) => {
        /** When a product has been modified, only the ID is returned.
         * Run a filter search on the model number to get the full product*/

        if(!newProductId) return props.showAlertModal(
            'error',
            'No product ID',
            'A new product ID was not returned from the server.'
        )

        filterSearch(
            props.claimId,
            {
                inputSearchText: newProductId,
            },
            (data) => {
                console.log('fetchNewProto', data);
                let productFromFilterSearch = data.products[0] || null;

                // if there is a call back function that wants to new product on save, pass the new product
                if(props.options.onProductSaved && productFromFilterSearch) props.options.onProductSaved(productFromFilterSearch);
            },
            (error) => props.onError(error)
        )
    }

    function logStates() {
        function logWithLabel(label, value) {
            console.log(`%c${label}: \n`, 'font-weight: bold', value);
        }

        logWithLabel('product', product);
        logWithLabel('originalProduct', originalProduct);
        logWithLabel('productHasBeenModified', productHasBeenModified);
        logWithLabel('allProperties', allProperties);
        logWithLabel('specList_originalQuery', specList_originalQuery);
        logWithLabel('specList_array', specList_array);
        logWithLabel('brand', brand);
        logWithLabel('modelNumbers', modelNumbers);
        logWithLabel('eanCodes', eanCodes);
        logWithLabel('releaseDate', releaseDate);
        logWithLabel('allProducts', props.allProducts);
        logWithLabel('allProductsAllProperties', props.allProductsAllProperties);
        logWithLabel('props', props);
    }


    const allProps = {
        ...props,
        product, setProduct,
        allProperties, setAllProperties,
        originalProduct, setOriginalProduct,
        productHasBeenModified, setProductHasBeenModified,
        // newProperties, setNewProperties,
        specList_originalQuery, setSpecList_originalQuery,
        specList_array, setSpecList_array,

        brand, setBrand,
        modelNumbers, setModelNumbers,
        eanCodes, setEanCodes,
        releaseDate, setReleaseDate,

        getCombinedPropertiesOfAllProducts,
    };


    // ==== COMPONENTS ====

    function getCombinedPropertiesOfAllProducts() {
        let allProductProperties = [];

        // Temporary map to track unique property names
        const uniqueProperties = new Map();

        props.allProductsAllProperties.forEach(_product => {
            _product.properties.forEach(property => {

                // Check if the property name is already added to the unique map
                if (!uniqueProperties.has(property.name)) {
                    // If not, add it to the map and the result array
                    uniqueProperties.set(property.name, true);
                    allProductProperties.push(property);
                }

            });
        });

        return allProductProperties;
    }


    // === Product header
    function ProductHeader(props){

        function createSaveDeleteButtons(){
            /**
             * If the product is a none-manual proto:
             *  Show Update button
             *      update_proto_product_manually
             *  Show Delete button
             *      delete_proto_product
             *
             * If the product is a manual product:
             *  Show the Create button
             *  */

            const manualProductButtons = (
                <div>
                    <Tooltip
                        position="top"
                        message={
                            <div>
                                <p>Create a new proto product</p>
                                <p>This will replace the item's requested product with the newly created product</p>
                            </div>}
                    >
                        <button
                            className='btn-success'
                            onClick={createManualProduct}
                        >
                            <PlusIcon className="mr-1 inline h-5 w-5 align-top" />
                            Create manual product
                        </button>
                    </Tooltip>
                </div>
            );

            const protoProductButtons = (
                <div>

                    {/* SAVE PROTO */}
                    <Tooltip message="Update this product to the below specifications and values" position="top">
                        <button
                            className={productHasBeenModified ? 'btn-success' : 'btn-outline-light'}
                            onClick={updateProtoProduct}
                        >
                            <CloudUploadIcon className="mr-1 inline h-5 w-5 align-top"/>
                            Save
                        </button>
                    </Tooltip>

                    {/* DELETE */}
                    {'ROOT ADMINISTRATOR'.includes(props.mainOrganisation.type) &&
                        <Tooltip message="Delete this product" position="top">
                            <button
                                className={'btn-danger'}
                                onClick={deleteProtoProduct}
                            >
                                <XIcon className="mr-1 inline h-5 w-5 align-top"/>
                                Delete
                            </button>
                        </Tooltip>
                    }
                </div>
            );

            return (
                <div>

                    {product.type === 'MANUAL' ? manualProductButtons : protoProductButtons}

                </div>
            );

        }


        return (
            <div className="flex gap-4 h-[26rem]">

                {/* PRODUCT IMAGE */}
                <div className="w-1/2">
                    <div className="rounded-lg overflow-hidden">
                        <BasicImage
                            src={product.images && product.images.thumbnail_web}
                            fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                            alt="Product Image"
                            className="bg-white"
                        />
                    </div>
                </div>


                {/* PRODUCT BUTTONS */}
                <div className="w-1/2">

                    {/* TITLE, CATEGORY, INFO */}
                    <div className="">
                        <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12" onClick={logStates}>
                            {product.common_name}
                        </h2>

                        <section>
                            <p className="mt-1 text-gray-700 capitalize">
                                {formatAsTitle(product.category || product.category?.category_a || '')}
                            </p>

                            <p className="mt-1 font-medium text-xl text-gray-900">
                                {product.date_released && product.date_released.split('-')[0]}
                            </p>
                        </section>
                    </div>

                    {/* BUTTONS */}
                    <div className="mt-12">

                        {/* COMPARE */}
                        <Tooltip message="Toggle a second modal to compare this product with another product" position="top">
                            <button
                                className="btn"
                                onClick={() => props.setShowModalTwo(!props.showModalTwo)}
                            >
                                <DocumentDuplicateIcon className="mr-1 inline h-5 w-5 align-top"/>
                                Compare
                            </button>
                        </Tooltip>


                        {/* SELECT PRODUCT */}
                        <Tooltip message="Click to open the item search, where this product can be swapped out for another" position="top" styling='max-w-[100%]'>
                            <button
                                className="btn"
                                onClick={() => setProduct(null)}
                            >
                                <DocumentSearchIcon className="mr-1 inline h-5 w-5 align-top"/>
                                Select different product
                            </button>
                        </Tooltip>

                        {createSaveDeleteButtons()}

                        {/* EDIT VIEW */}
                        {'ADMINISTRATOR ROOT'.includes(props.mainOrganisation.type) &&
                            <Tooltip message="Toggle between viewing or editing this product" position="top">
                                <button
                                    className="btn-outline-light"
                                    onClick={() => {
                                        props.setFocusedProductProperty(null); // clear the current focused property to prevent a permanent focus/highlight
                                        props.setMode(props.mode === 'view' ? 'edit' : 'view');
                                    }}
                                >
                                    {props.mode === 'view' ?
                                        <>
                                            <PencilAltIcon className="mr-1 inline h-5 w-5 align-top"/>
                                            Edit
                                        </>
                                        :
                                        <>
                                            <DocumentTextIcon className="mr-1 inline h-5 w-5 align-top"/>
                                            View
                                        </>
                                    }
                                </button>
                            </Tooltip>
                        }

                        {/* Add to claim, Add replacement, Link to HAI, etc. */}
                        {props.options.claimActionButton}

                    </div>

                </div>

            </div>
        );

    }


    // === Product modal or Item search
    const renderEditProtoCard = () => {
        /** Render a card with all the fields of a product, editable **/

        if (!product) {
            return <div></div>;
        }

        function productIsMissingHighlightedProperty(){

            // Do not highlight if no property has been set yet
            if (!props.focusedProductProperty) return false;

            if (props.focusedProductProperty === 'Model numbers') return true;
            if (props.focusedProductProperty === 'EAN codes') return true;

            // Does this product have the highlighted property?
            let productHasProperty = allProperties?.find(property => property.name === props.focusedProductProperty);
            productHasProperty = Boolean(productHasProperty);
            return !productHasProperty;
        }

        function getMissingPropertiesCount(){
            /** Return the number of properties that this Product does not have, that are found in props.combinedProperties **/
            let missingProperties = getCombinedPropertiesOfAllProducts().filter(combinedProperty => {
                let productHasProperty = allProperties?.find(productProperty => productProperty.name === combinedProperty.name);
                return !productHasProperty; // return true if the product does not have this property
            });
            return missingProperties.length; // the total number of properties that are found in props.combinedProperties, but not in this product.properties
        }


        return (
            <div className="flex w-fit px-2 px-4 ">
                <Transition.Child as={Fragment} {...standardTransition} >
                    <div className="flex w-full text-left transition px-4 my-8 max-w-4xl ">
                        <div className="w-full relative flex items-start bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8 ">

                            <button
                                type="button"
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                onClick={() => props.setOpen(false)}
                            >
                                <span className="sr-only">Close</span>
                                <XIcon className="h-6 w-6" />
                            </button>


                            <div className="w-full gap-y-8 gap-x-6 items-start">

                                {/* PRODUCT HEADER */}
                                {ProductHeader(allProps)}

                                <div className="">

                                    {/* TOTAL : MISSING  PROPERTIES*/}
                                    <div className="flex justify-between">
                                        <h2 className="text-2xl font-extrabold text-gray-900 pr-12">Properties</h2>
                                        <p className="text-gray-900 pr-6 tailwind-tooltip-container">
                                            <b>Total:</b> {allProperties?.length} &nbsp;&nbsp; <b>Missing:</b> {getMissingPropertiesCount()}

                                            <span className="tailwind-tooltip top-5 -left-[100%]">
                                                The total properties that this product has, and the number of properties that this product is <span className="text-purple-400">missing</span>.
                                            </span>
                                        </p>
                                    </div>


                                    <table className="mt-3 table-fixed w-full divide-y divide-gray-300 ">

                                        <thead>
                                            <tr>
                                                <th className="px-4 py-4 text-sm font-bold text-gray-900">Property</th>
                                                <th className="px-4 py-4 text-sm font-bold text-gray-900">Value</th>
                                            </tr>
                                        </thead>

                                        <tbody className={classNames('bg-white divide-y divide-gray-200',
                                            `transition-all duration-500 rounded-md outline outline-2 outline-offset-[10px]`,
                                            productIsMissingHighlightedProperty() ? 'outline-purple-200' : 'outline-transparent'
                                        )}
                                        >

                                        <ProductProperties {...allProps} />

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </div>
        );
    };

    const renderItemSelectionCard = () => {
        /** Render a card that contains the ClaimItemSearch component **/

        return (
            <Transition.Child as={Fragment} {...standardTransition} >
                <div className="relative max-h-max w-full max-w-4xl px-4 my-8 bg-white shadow-2xl">
                    <ClaimItemSearch {...props} mode="editProductModal" editModal_setProduct={onSelectProduct}/>
                </div>
            </Transition.Child>
        );

    };

    function render(){
        // If no product is available for this modal, show the ClaimItemSearch
        if (!product) return renderItemSelectionCard();
        else return renderEditProtoCard();
    }
    return render();

}


function ProductProperties(props){
    /** Display all the various properties */

    const onPropertyModified = (propertyName, newValue) => {
        /** When a property has been modified, find that property via its name or value, and update the state **/

        if(propertyName === 'brand'){
            props.setBrand(newValue);
            return;
        }
        if(propertyName === 'model_numbers'){
            props.setModelNumbers(newValue);
            return;
        }
        if(propertyName === 'ean_codes'){
            props.setEanCodes(newValue);
            return;
        }
        if(propertyName === 'date_released'){
            props.setReleaseDate(newValue);
            return;
        }

        let modifiedProperty = props.allProperties?.find(property => property.name === propertyName);
        if (!modifiedProperty) {
            modifiedProperty = {name: propertyName, value: newValue};
            props.allProperties.push(modifiedProperty);
        }

        modifiedProperty.value = newValue;
        props.setProduct({...props.product});

        props.setProductHasBeenModified(true);
    };

    // === Required fields
    function ProductIdField(props) {

        return (
            <tr className={`transition-all duration-500 rounded-md`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    Product ID
                </td>
                <td className="px-4 py-4 text-sm text-gray-900">

                    <p>
                        {props.product?.id || '-'}
                    </p>

                </td>
            </tr>
        );

    }

    function ModelNumberField(props) {

        return (
            <tr className={`transition-all duration-500 rounded-md outline outline-3 ${props.focusedProductProperty === 'model_numbers' ? 'outline-cyan-200' : 'outline-transparent'}`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    Model numbers
                </td>
                <td className="px-4 py-4 text-sm text-gray-900 tailwind-tooltip-container">

                    {/* VIEW OR EDIT */}
                    {props.mode === 'view' ?
                        <p>
                            {props.modelNumbers}
                        </p>
                        :
                        <input
                            className="input"
                            onFocus={() => props.setFocusedProductProperty('model_numbers')}
                            onChange={(e)=> {
                                onPropertyModified('model_numbers', e.target.value)

                            }}
                            value={props.modelNumbers}
                        />
                    }

                    {/* TOOLTIP */}
                    {props.mode === 'edit' &&
                        <span className='tailwind-tooltip -top-[6rem]'>
                            Multiple values should be seperated by a comma <br/>
                            e.g: <i>MLKT3LL/A, MLLA3Y/A, MLLD3Y/A?ES</i>
                        </span>
                    }

                </td>
            </tr>
        );

    }

    function EANCodeField(props){

        return (
            <tr className={`transition-all duration-500 rounded-md outline outline-3 ${props.focusedProductProperty === 'ean_codes' ? 'outline-cyan-200' : 'outline-transparent'}`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    EAN codes
                </td>
                <td className="px-4 py-4 text-sm text-gray-900 tailwind-tooltip-container">

                    {/* VIEW OR EDIT */}
                    {props.mode === 'view' ?
                        <p>
                            {props.eanCodes}
                        </p>
                        :
                        <input
                            className="input"
                            onFocus={() => props.setFocusedProductProperty('ean_codes')}
                            onChange={(e)=> {
                                onPropertyModified('ean_codes', e.target.value)
                            }}
                            value={props.eanCodes}
                        />
                    }

                    {/* TOOLTIP */}
                    {props.mode === 'edit' &&
                        <span className='tailwind-tooltip -top-[6rem]'>
                            Multiple values should be seperated by a comma <br/>
                            e.g: <i>08182790, 08182791, 08182797</i>
                        </span>
                    }

                </td>
            </tr>
        );
    }

    function DateReleasedField(props){

        return (
            <tr className={`transition-all duration-500 rounded-md outline outline-3 ${props.focusedProductProperty === 'date_released' ? 'outline-cyan-200' : 'outline-transparent'}`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    Date Released
                </td>
                <td className="px-4 py-4 text-sm text-gray-900 tailwind-tooltip-container">

                    {/* VIEW OR EDIT */}
                    {props.mode === 'view' ?
                        <p>
                            {props.releaseDate || ''}
                        </p>
                        :
                        <input
                            className="input"
                            onFocus={() => props.setFocusedProductProperty('date_released')}
                            onChange={(e)=> onPropertyModified('date_released', e.target.value)}
                            value={props.releaseDate}
                        />
                    }

                    {/* TOOLTIP */}
                    {props.mode === 'edit' &&
                        <span className='tailwind-tooltip -top-[6rem]'>
                            Date released must follow ISO standard <br/>
                             <i>time is optional</i><br/>
                            <b>YYYY-MM-DD HH:MM:SS</b> <br/>
                            e.g: <i>2023-06-19</i>
                        </span>
                    }

                </td>
            </tr>
        );
    }

    function BrandField(props){

        return (
            <tr className={`transition-all duration-500 rounded-md`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    Brand
                </td>
                <td className="px-4 py-4 text-sm text-gray-900">

                    <p>
                        {props.brand || '-'}
                    </p>

                </td>
            </tr>
        );
    }

    function CategoryField(props){

        return (
            <tr className={`transition-all duration-500 rounded-md`}>
                <td className="px-4 py-4 text-sm text-gray-900">
                    Category
                </td>
                <td className="px-4 py-4 text-sm text-gray-900">

                    <p>
                        {formatAsTitle(props.product.category) || '-'}
                    </p>

                </td>
            </tr>
        );
    }

    const allProps = {
        ...props,
        onPropertyModified,
    }

    function AddSpec(props) {

        if (props.mode === 'view') return <></>;

        function standardPropertyFields(){

            return (
                <>

                    {/* GAP */}
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>

                    {/* + NEW PROPERTY */}
                    <tr>
                        <td className="px-4 py-4 text-sm text-gray-900">
                            <i>Additional specs</i>
                        </td>
                        <td className="px-4 py-4 text-sm text-gray-900">
                            <div className="flex justify-end">
                                <NewPropertyButton
                                    {...props}
                                />
                            </div>
                        </td>
                    </tr>

                </>
            );
        }

        return standardPropertyFields(); // return standard input fields
    }

    function renderProperties() {

        return (

            <>

                {/* PRODUCT ID */}
                {ProductIdField(allProps)}


                {/* GENERAL REQUIRED FIELDS */}
                {BrandField(allProps)}
                {CategoryField(allProps)}
                {ModelNumberField(allProps)}
                {EANCodeField(allProps)}
                {DateReleasedField(allProps)}

                {/* List every property found in all products.properties */}
                {props.getCombinedPropertiesOfAllProducts().map((combinedProperty, index) => (
                    <SingleProperty key={combinedProperty.name} {...allProps} combinedProperty={combinedProperty} />
                ))}

                {/* GAP */}
                <tr>
                    <td></td>
                    <td></td>
                </tr>

                {/* NEW PROPERTIES */}
                {AddSpec(allProps)}

                {/* GAP */}
                <tr>
                    <td></td>
                    <td></td>
                </tr>
            </>

        );
    }

    return renderProperties()

}


function SingleProperty(props){
    /** Render a single property field, with the property name and value
     * - if the mode is 'view', the property is displayed as text
     * - if the mode is 'edit', the property is displayed as an Spec Select field
     * property - This property (e.g speakers) in the product
     * props.combinedProperty - This property (e.g speakers) from the list of all properties in all products
     * **/

    let property = props.allProperties.find(p => p.name === props.combinedProperty.name);
    if(!property) property = {name: props.combinedProperty.name, value: ''};

    const product = props.product;

    function propertyHasBeenModified(name){
        /** Find this property in the originalProduct.properties, compare with modifiedValue, and return True or False  **/

        /** The Model number and EAN code properties aren't included in the originalProduct.properties
         * if(name === 'Model numbers' && modelNumbers != modifiedValue) return true
         * if(modifiedValue === 'EAN codes' &&  eanCodes != modifiedValue) return true **/

        let modifiedProperty = props.allProperties?.find(property => property.name === name);
        if (!modifiedProperty) return false;
        let modifiedValue = modifiedProperty.value;

        let property = props.originalProduct.properties?.find(property => property.name === name);
        if (!property) return true;

        const valueIsTheSame = property.value === modifiedValue;
        return !valueIsTheSame;
    }

    const onRemoveExistingProperty = (propertyToRemove_name) => {
        /** remove the given property, from the list of properties in the newProperties state **/

        // keep all properties except the property to remove
        let removed = props.allProperties.filter(property => property.name !== propertyToRemove_name);

        props.setAllProperties(removed);

    };

    function onTogglePropertyInputType(property){
        property.inputType = property.inputType === 'input' ? 'select' : 'input';
        props.setProduct({...product});
    }

    function style_comparePropertyValueToOtherProducts(property){
        /** Certain properties can be compared (red if lower, green if higher)
         * Find the property on all Products, and return different styling depending on whether the product's property
         * is greater than or less than the other products equivalent property**/

        if (!property.value) return '';
        if (property.value.includes(',')) return ''; // if the value is a list of values, don't compare
        if (property.value.includes('x')) return ''; // if the value is a resolution, don't compare
        if (isNaN(property.value.charAt(0))) return ''; // if the value does not start with a number, don't compare

        let productProperty = props.allProperties?.find(p => p.name === property.name);
        if (!productProperty) return ''; // if this product does not have this property, don't compare

        let comparison = 0; // 0 = equal, 1 = higher, -1 = lower

        props.allProducts.forEach(_product => {
            if (_product.id === product.id) return; // don't compare with itself

            let otherProductProperty = _product.properties?.find(p => p.name === property.name);
            if (!otherProductProperty) return ''; // if this product does not have this property, don't compare

            let otherProperty = parseFloat(otherProductProperty.value.replace(/\D/g, ''));
            let thisProperty = parseFloat(productProperty.value.replace(/\D/g, ''));
            if (otherProperty > thisProperty) comparison = -1;
            if (otherProperty < thisProperty) comparison = 1;
        });

        if (comparison === 0) return '';
        if (comparison === 1) return 'bg-green-100';
        if (comparison === -1) return 'bg-red-100';
    }

    function propertyCanBeRemoved(field){
        /** Required and general properties can not be removed from a proto */

        if(field === 'brand' || field === 'model_numbers' || field === 'ean_codes' || field === 'date_released') return false;

        let importance = props.specList_array.find(spec => spec.field === field)?.importance
        if(importance === 'REQUIRED' || importance === 'GENERAL') return false;
        return true;
    }

    // TODO this should be a precalcualted
    let propertyExistsInProduct = props.allProperties.find(p => p.name === property.name);
    // let propertyIsNew = props.newProperties.find(p => p.name === property.name);

    const allProps = {
        ...props,
        property,
        propertyHasBeenModified,
        onRemoveExistingProperty,
        onTogglePropertyInputType,
        style_comparePropertyValueToOtherProducts,
        propertyCanBeRemoved,
        propertyExistsInProduct,
        // propertyIsNew

    }

    return <PropertyRow {...allProps} />

}


function PropertyRow(props){

    const property = props.property;

    return (
        <tr key={property.name}
            onClick={() => props.setFocusedProductProperty(property.name)}
            className={
                classNames(
                    `transition-all duration-500 rounded-md outline outline-3 h-[2rem]`,

                    // When a property has been highlighted on one product, highlight the property on the other product
                    `${props.focusedProductProperty === property.name ? 'outline-cyan-200' : 'outline-transparent'}`,

                    // Highlight this property, if this product's property is less than or higher than another product's property
                    props.style_comparePropertyValueToOtherProducts(property),

                    // TODO this should be a precalcualted variable "propertyAbsentButPresentInOtherProducts"
                    // Highlight this property, if this product is missing this property, but the other product has the property
                    props.allProperties?.find(p => p.name === property.name) ? '' : 'bg-slate-200/75',

                    // Highlight this property, if this product has been modified
                    props.propertyHasBeenModified(property.name) ? 'bg-sky-100' : '',

                )
            }>

            {/* PROPERTY DISPLAY NAME */}
            <td className="px-4 py-2 text-sm text-gray-900">
                {formatAsTitle(property.name)}
            </td>

            {/* PROPERTY VALUE */}
            <td className="px-4 py-2 text-sm text-gray-900">

                {props.mode === 'view' ?

                    // VIEW MODE
                    <p>
                        {props.allProperties?.find(p => p.name === property.name)?.value}
                    </p>
                    :

                    // EDIT MODE
                    <div className='flex justify-between'>


                        {/* DIRECT TEXT INPUT OR SPEC SELECT*/}
                        {property.inputType === 'input' ?
                            <input
                                className="input"
                                onChange={(e) => props.onPropertyModified(property.name, e.target.value)}
                                value={props.allProperties?.find(p => p.name === property.name)?.value}
                            />
                            :
                            <SpecValueSelector
                                {...props}
                                specField={property.name}
                                property={property}
                                onChange={(e) => props.onPropertyModified(property.name, e.target.value)}
                            />
                        }


                        <div className='h-[2rem] w-[4rem] flex justify-end gap-2'>

                            {/* VALUE EDIT: DIRECT TEXT INPUT */}
                            {/* Hidden for now - may be re-enabled later */}
                            <button className="hidden m-0 p-2 btn-outline-light h-full tailwind-tooltip-container"
                                    onClick={() => props.onTogglePropertyInputType(property)}>
                                <PencilAltIcon className="h-4 w-4"/>
                                <span className='tailwind-tooltip -left-[4rem]'>Direct text input</span>
                            </button>

                            {/* REMOVE PROPERTY */}
                            <button className={classNames(
                                "m-0 p-2 btn-outline-light h-full",
                                props.propertyCanBeRemoved(property.name) ? '' : 'hidden'
                            )}
                                    onClick={() => props.onRemoveExistingProperty(property.name)}>
                                X
                            </button>

                            {/* COPY SPEC ACROSS */}
                            {/* we only want to see this button if there is a second modal open */}
                            <Tooltip message="Copy this property to the other product" position="left"
                                     style='max-w-[15rem]'>
                                <button
                                    className={classNames(
                                        "m-0 p-2 btn-outline-light h-full",
                                        props.showModalTwo ? 'block' : 'hidden'
                                    )}
                                    onClick={() => props.setCopyProperty(property)}
                                >
                                    {props.modalCount === 1 ?
                                        <ArrowLeftIcon className="h-3 w-3"/>
                                        :
                                        <ArrowRightIcon className="h-3 w-3"/>
                                    }
                                </button>
                            </Tooltip>
                        </div>

                    </div>

                }
            </td>
        </tr>
    );
}


function SpecValueSelector(props) {

    function getAllValuesForSpecField(specField) {
        // Get the values for the selected spec field

        if (isEmpty(props.specList_array)) return [];

        specField = specField.replaceAll(' ', '_').toLowerCase();

        let forBrand = props.specList_array.filter(spec => spec.brand === props.product.brand);
        let fieldData = forBrand.find(spec => spec.field === specField);
        let values = fieldData?.values || [];

        return values;
    }

    return (
        <select
            onChange={props.onChange}
            className="select max-w-[13rem]"
        >
            <optgroup label='Current'>
                <option value={props.property.value}>{props.property.value}</option>
            </optgroup>

            <optgroup label='Options'>
                {getAllValuesForSpecField(props.specField).map(value => {
                    return <option key={value} value={value}>{value}</option>
                })}
            </optgroup>

        </select>
    );
}

function NewPropertyButton(props) {

    function onAddNewProperty() {

        // Get the spec field that has been selected to add
        let selectedSpecToAdd = document.getElementById('addNewPropertySelect').value;

        if(isSpecAlreadyInProperties(selectedSpecToAdd)) return;

        let firstValue = ''

        const newProperty = { name: selectedSpecToAdd, value: firstValue }

        // Add the new property to the list of new properties
        props.setAllProperties([...props.allProperties, newProperty]
        )
    }

    function getSpecsByImportance(importanceFilter) {

        let specsFilteredByImportance = props.specList_array.filter(s =>
            s.brand === props.product.brand &&
            s.importance === importanceFilter // 'REQUIRED', 'ADDITIONAL', 'OPTIONAL', 'GENERAL'
        );

        // Sort the specs alphabetically, by field name
        specsFilteredByImportance.sort((a, b) => a.field.localeCompare(b.field));

        return specsFilteredByImportance;
    }

    function isSpecAlreadyInProperties(specField) {
        // Check if the spec field is already in the product's properties
        let specExists = props.allProperties.find(property => property.name === specField);
        if (specExists) return true;

        return false;
    }
    
    function formatSpecName(specField){
        // Limit the spec.field string to 50 characters and append '...' if it's longer
        const displayField = specField.length > 50 ? `${specField.substring(0, 50)}...` : specField;

        return formatAsTitle(displayField);
    }

    return (
        <div className='flex'>

            <select
                id='addNewPropertySelect'
                className="select"
            >

                <optgroup label="Required">

                    {getSpecsByImportance('REQUIRED').map(spec => {
                        return (
                            <option disabled={isSpecAlreadyInProperties(spec.field)}
                                       key={spec.importance + spec.field}
                                       value={spec.field}>
                                        {formatSpecName(spec.field)} &nbsp; [{spec.values.length}]
                            </option>
                        );
                    })}

                </optgroup>


                <optgroup label="Optional">

                    {getSpecsByImportance('OPTIONAL').map(spec => {
                        return (
                            <option disabled={isSpecAlreadyInProperties(spec.field)}
                                       key={spec.importance + spec.field}
                                       value={spec.field}>
                                        {formatSpecName(spec.field)} &nbsp; [{spec.values.length}]
                            </option>
                        );
                    })}
                </optgroup>


                <optgroup label="Additional">

                    {getSpecsByImportance('ADDITIONAL').map(spec => {
                        return (
                            <option disabled={isSpecAlreadyInProperties(spec.field)}
                                       key={spec.importance + spec.field}
                                       value={spec.field}>
                                        {formatSpecName(spec.field)} &nbsp; [{spec.values.length}]
                            </option>
                        );
                    })}
                </optgroup>

            </select>


            <button className="btn-outline p-2 w-[8rem]"
                    onClick={onAddNewProperty}
            >
                + New property
            </button>

        </div>

    );

}




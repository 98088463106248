export const OrganisationTypes = [
    {value: 'ROOT', label: 'Super user'},
    {value: 'ADMINISTRATOR', label: 'Administrator'},
    {value: 'SUPPLIER', label: 'Supplier'},
    {value: 'INSURER', label: 'Insurer'}
];

// TODO technical debt: refactor to use the CountryMetaData below
export const CountryTypes = [
    {value: 'NL', label: 'Netherlands', currency: '€'},
    {value: 'ZA', label: 'South Africa', currency: 'R'},
    {value: 'AU', label: 'Australia', currency: '$'},
    {value: 'US', label: 'U.S.A', currency: '$'},
    {value: 'BE', label: 'Belgium', currency: '€'},
    {value: 'GB', label: 'Great Britain', currency: '£'}
];

export const CountryMetaData = [
    {code: 'NL', name: 'Netherlands', currencySymbol: '€', currencyCode: 'EUR'},
    {code: 'ZA', name: 'South Africa', currencySymbol: 'R', currencyCode: 'ZAR'},
    {code: 'AU', name: 'Australia', currencySymbol: '$', currencyCode: 'AUD'},
    {code: 'US', name: 'U.S.A', currencySymbol: '$', currencyCode: 'USD'},
    {code: 'BE', name: 'Belgium', currencySymbol: '€', currencyCode: 'EUR'},
    {code: 'GB', name: 'Great Britain', currencySymbol: '£', currencyCode: 'GBP'}
];

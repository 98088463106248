// Library imports
import React, {useEffect, useState} from 'react';
import {
    CogIcon,
    DocumentAddIcon,
    DocumentReportIcon,
    ExternalLinkIcon,
    HomeIcon,
    LibraryIcon
} from '@heroicons/react/solid';
import {DeveloperModal} from '../../containers/modals/DeveloperModal';


// Local imports
import {classNames} from '../../utils/helpers.js';
import {Link} from 'react-router-dom';
import CountrySelection from '../CountrySelection';
import Safe, {safe} from "../Safe";


// NavbarSide component
export default function NavbarMain(props) {

    const [navigationOptions, setNavigationOptions] = useState([
        {
            name: 'Dashboard',
            href: '/',
            icon: HomeIcon,
            classNames: ''
        },
        {
            name: 'Reports',
            href: '/admin/reports',
            icon: DocumentReportIcon,
            classNames: ''
        },
        {
            name: 'Profile',
            onClick: () => {
                props.setShowEditOrgProfileModal(true);
            },
            icon: LibraryIcon,
            classNames: ''
        }
    ]);

    useEffect(() => {
        // Only show HAI for SLVRCLD
        if (!props.mainOrganisation) return; // wait until main organisation has been set
        if (props.mainOrganisation?.type === 'ADMINISTRATOR' || props.mainOrganisation?.type === 'ROOT') {
            if (navigationOptions.find(nav => nav.name === 'HAI')) return; // Do not add the navigation twice

            let copy = [...navigationOptions];
            copy.push({
                name: 'HAI',
                href: '/admin',
                icon: DocumentAddIcon,
                classNames: ''
            });

            copy.push({
                name: 'Organisation Admin',
                href: '/organisation_admin',
                icon: CogIcon,
                classNames: ''
            });
            setNavigationOptions(copy);
        }
    }, [props.mainOrganisation]);


    const [infoModalExists, setInfoModalExists] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {

            // If PageInfoModal exists, set show i button active
            if (document.getElementById('pageInfoModal')) {
                setInfoModalExists(prevState => true);
            }

            // If PageInfoModal does not exist, hide i
            else {
                setInfoModalExists(prevState => false);
            }

        }, 2000);
        return () => clearInterval(interval);
    }, []);


    // if no user is authenticated, do not show the main menu
    if (!props.authenticated || !props.user) {
        return <></>;
    }

    function renderLinks() {
        return (
            <div className="flex flex-col pt-6 px-3">
                {navigationOptions.map((item) => (
                    <Link
                        key={item.name}
                        to={item.href ? item.href : '#'}
                        className={`
                            flex flex-col gap-2 items-center justify-center transition-all rounded-md 
                            text-lg text-center font-light text-sky-100 hover:text-white hover:bg-cyan-600 
                            w-full h-[7rem] px-4 py-2 m-0
                            shadow-sm hover:shadow-lg hover:bg-sky-500
                            ${item.active ? 'bg-slate-600 !text-white shadow-lg' : ''}
                            ${item.classNames}
                        `}
                        onClick={() => {

                            // de-select all the routes
                            let options = navigationOptions.map(nav => {
                                nav.active = false;
                                return nav;
                            });

                            // select the route that was clicked
                            options.find(nav => nav.href === item.href).active = true;
                            setNavigationOptions(options);

                            // if this route has a click handler, call it
                            item.onClick && item.onClick();
                        }}
                    >
                        <item.icon className={classNames(
                            item.classNames, 'flex-shrink-0 h-8 w-8 '
                        )} aria-hidden="true"/>
                        {item.name}

                    </Link>
                ))}
            </div>
        );
    }

    return (
        <div className="w-52 h-full">

            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow navbar-background pt-5 pb-4 h-full overflow-y-scroll scrollbar-hide">

                {/* SLVRCLD LOGO */}
                <div className="flex items-center flex-shrink-0 px-1 pb-4 mb-2  border-sky-600 ">
                    <Link
                        to="/"
                        className="px-4">

                        {props.portalRepresentative === 'THESL' ?
                            <img src="/thesl-logo.png" alt="THESL logo" className='drop-shadow-md bg-cyan-900 rounded-lg px-4 mb-2 ' />
                            :
                            <img src="/slvrcld_logo.png" alt="SLVRCLD logo" />
                        }

                    </Link>
                </div>

                <nav className="flex flex-col">

                    {/*COUNTRY NAV*/}
                    <div className="flex justify-start pb-4 mb-1 border-sky-600">
                        <Safe><CountrySelection {...props} /></Safe>
                    </div>

                    {/* LINKS */}
                    {safe(renderLinks)}

                </nav>

                <div className="grow"></div>

                <QueryCount {...props} />

                {/* Developer Modal */}
                {/*<OpenDeveloperModal {...props} />*/}

                <PageHelpInfoModal/>
                <Version infoModalExists={infoModalExists}/>

            </div>
        </div>
    );
}

function QueryCount(props) {

    const [allRequests, setAllRequests] = useState([]);

    useEffect(() => {
        window.onGraphRequest = (requestPayloadString) => {
            // console.log('requestPayloadString:', requestPayloadString, 'allRequests:', allRequests)
            const uniquePayload = requestPayloadString + Math.random();
            setAllRequests((previous) => [...previous, uniquePayload]);
        }
    }, []);

    function getRequestName(requestPayload){
        // let queryName = requestPayload.substring(23, 50);

        let queryName = requestPayload.replace('query {', '').replace('mutation {', '');

        queryName = queryName.substring(0, 50);

        if(queryName.includes('(')) queryName = queryName.substring(0, queryName.indexOf('('));
        if(queryName.includes('{')) queryName = queryName.substring(0, queryName.indexOf('{'));
        // let braceIndex = queryName.indexOf('(')
        // if (braceIndex === -1) braceIndex = queryName.indexOf('{')
        // queryName = queryName.substring(0, braceIndex);

        queryName = queryName.trim()

        return <p key={requestPayload}>- {queryName}</p>
    }

    if(!props.mainOrganisation) return (<></>);

    // Do not display outside of localhost
    if(!(window.location.href.includes('localhost')) ) return (<></>);

    return (
        <div className='flex flex-col items-center text-white max-h-[40rem] overflow-scroll scrollbar-hide p-2 text-sm'>
            <button className='btn-outline bg-transparent p-1 text-white' onClick={()=> setAllRequests([])}>Clear {allRequests.length}</button>
            {allRequests.map(getRequestName)}
        </div>
    );
}

function OpenDeveloperModal(props) {

    const [developerModalOpen, setDeveloperModalOpen] = useState(false);

    // Only ROOT, or localhost can open the developer modal
    let canViewDeveloperModal = false;
    // if(props.mainOrganisation && props.mainOrganisation?.type === 'ROOT') canViewDeveloperModal = true;
    // if(window.location.host.includes('localhost')) canViewDeveloperModal = true;
    if (window.canViewDeveloperModal >= 5) canViewDeveloperModal = true;
    if (!canViewDeveloperModal) return (<></>);

    return (
        <>

            {/* OPEN BUTTON */}
            <div className="absolute bottom-0 right-3 flex items-center gap-2 p-2 z-10">
                <button className="btn-light text-sm" onClick={() => setDeveloperModalOpen(true)}>Developer modal
                </button>
            </div>

            {/* DEVELOPER MODAL */}
            <DeveloperModal isOpen={developerModalOpen} onClose={setDeveloperModalOpen} {...props} />
        </>

    );
}

function PageHelpInfoModal(props) {
    return (
        <>
            <div
                className={`flex justify-center m-5 mt-0 tailwind-tooltip-container transition-all duration-500 ${props.infoModalExists ? ' opacity-100' : ' opacity-0'}`}
                onClick={() => props.setPageInfoModalOpen(true)}
            >
                <span className="tailwind-tooltip">
                  Click for info
                </span>
                <svg xmlns="http://www.w3.org/2000/svg"
                     className="transition-all h-12 w-12 hover:w-14 hover:h-14 stroke-gray-200 drop-shadow-md ring-0 hover:ring-offset-4 hover:ring-4 "
                     fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
            </div>
        </>
    );
}

function Version() {
    return (
        <>
            <a href="https://www.slvrcld.com/legal/privacy-policy" target="_blank"
               className="text-gray-300 opacity-50 text-center text-[12px] flex justify-center items-center">
                <ExternalLinkIcon className="h-4 w-4 mr-1"/>
                Privacy Policy
            </a>
            <a href="https://www.slvrcld.com/legal/terms-and-conditions" target="_blank"
               className="text-gray-300 opacity-50 text-center text-[12px] flex justify-center items-center">
                <ExternalLinkIcon className="h-4 w-4 mr-1"/>
                Terms & Conditions
            </a>
            <div className="text-gray-300 text-center text-sm" onClick={() => {
                window.canViewDeveloperModal = window.canViewDeveloperModal ? window.canViewDeveloperModal + 1 : 1;
                if (window.canViewDeveloperModal === 5) alert('Developer modal enabled, click "Profile" to render button.');
            }}
            >
                Version {process.env.REACT_APP_VERSION}
            </div>
        </>
    );
}

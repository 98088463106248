// Library imports
import React, {useEffect, useState} from 'react';
import {useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';


export default function DataTable(props){

    // const [data, setData] = useState([props.data]);
    // useEffect(() => setData(props.data), [props.data]);

    // The columns for the table
    const columns = props.columns;

    // The data/rows to be displayed in the table
    // const data = useMemo(() => props.data, [props.data]); // a memo will prevent extra re-renders - currently it is preventing the table from changing when the results-per-page is changed
    const data = props.data;


    /** All DataTable functionality is performed via this instance variable **/
    const tableInstance = useTable(
        {
            columns, data,
            defaultColumn: {Filter: DefaultColumnFilter},
            initialState: {pageIndex: 0, pageSize: 50, filters: props.stateFilters || [] }
            // initialState: {pageIndex: 0, pageSize: props.pageSize || 10}
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        if(props.setFilters) props.setFilters(tableInstance.state);
        if(props.setTableInstance) props.setTableInstance(tableInstance);
        // console.log('tableInstance', tableInstance)
        // console.log('tableInstance.state', tableInstance.state)
    }, [tableInstance.state]);

    // UI render methods
    const [globalFilterInput, setGlobalFilterInput] = useState(tableInstance.globalFilter);
    const onGlobalFilterChangeChange = useAsyncDebounce(value => tableInstance.setGlobalFilter(value || undefined), 1000);
    const renderGlobalTableSearch = () => {
        const count = tableInstance.preGlobalFilteredRows.length;

        // Search input
        return (
            <input
                value={globalFilterInput || ''}
                onChange={e => {
                    setGlobalFilterInput(e.target.value);
                    onGlobalFilterChangeChange(e.target.value);
                }}
                className="table-global-search"
                placeholder={`Search ${count} rows in all columns`}
            />
        );
    };

    // up or down sorting arrows
    const generateSortingIndicator = column => {
        let sortingIcon_upArrowAscending = (
            <button className="ml-2 ">
                <svg className="w-4 h-4 rotate-180" fill="#000000" width="800px" height="800px" viewBox="0 0 36 36" version="1.1"
                     preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"
                >
                    <title>arrow-line</title>
                    <path
                        d="M27.66,15.61,18,6,8.34,15.61A1,1,0,1,0,9.75,17L17,9.81V28.94a1,1,0,1,0,2,0V9.81L26.25,17a1,1,0,0,0,1.41-1.42Z"
                        className="clr-i-outline clr-i-outline-path-1"></path>
                    <rect x="0" y="0" width="36" height="36" fill-opacity="0"/>
                </svg>
            </button>
        );

        let sortingIcon_downArrowDescending = (
            <button className="ml-2 ">
                <svg className="w-4 h-4" fill="#000000" width="800px" height="800px" viewBox="0 0 36 36" version="1.1"
                     preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"
                >
                    <title>arrow-line</title>
                    <path
                        d="M27.66,15.61,18,6,8.34,15.61A1,1,0,1,0,9.75,17L17,9.81V28.94a1,1,0,1,0,2,0V9.81L26.25,17a1,1,0,0,0,1.41-1.42Z"
                        className="clr-i-outline clr-i-outline-path-1"></path>
                    <rect x="0" y="0" width="36" height="36" fill-opacity="0"/>
                </svg>
            </button>
        );

        let sortingIcon_default = (
            <button className="ml-2">
                <svg className="w-4 h-4" fill="#000000" fillOpacity="0.2" width="800px" height="800px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                    <path d="M743.3 512L598.5 656.8l-36.2-36.2L670.9 512 562.3 403.4l36.2-36.2L743.3 512zM425.5 656.8l36.2-36.2L353.1 512l108.6-108.6-36.2-36.2L280.7 512l144.8 144.8z"/>
                </svg>
            </button>
        );

        let icon = column.isSortedDesc ? sortingIcon_upArrowAscending : sortingIcon_downArrowDescending;
        return column.isSorted ? icon : sortingIcon_default;
    };

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        tableInstance.gotoPage(page);
        // if(props.setDatatablePaginationPage) props.setDatatablePaginationPage(page)
    };


    const allProps = {
        ...props,
        tableInstance,
        globalFilterInput, setGlobalFilterInput,
        onChangeInInput,
        // parentCustomPagination, setParentCustomPagination,
    }

    return (
        <div className='w-full overflow-x-scroll'>
            {/* SEARCH */}
            <div className="mt-3">
                {renderGlobalTableSearch()}
            </div>

            <table {...tableInstance.getTableProps()} className="">

                {/* HEADER ROW */}
                <thead>
                {tableInstance.headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className={column.headerStyle || ''}
                            >

                                {/* TITLE */}
                                <div {...column.getSortByToggleProps()} className="">
                                    {column.render('Header')}
                                    {generateSortingIndicator(column)}
                                </div>

                                {/* FILTER */}
                                {column.searchable !== false && (<div className="w-a"><Filter column={column}/></div>)}

                            </th>
                        ))}
                    </tr>
                ))}
                </thead>

                {/* TABLE ROWS */}
                <tbody {...tableInstance.getTableBodyProps()}>
                {tableInstance.page.map(row => {
                    tableInstance.prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} className="">
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()} className="">
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>


            {/*PAGINATION*/}
            <Pagination {...allProps} />

        </div>

    );
}

function Pagination(props){
    /** There are 2 types of pagination, Datatable controlled and Parent controlled **/

    if(props.parentPagination){
        return ParentPagination();
    }
    else{
        return DatatablePagination();
    }


    function pageUp(){
        /** Set the page number in the parent component's pagination state **/
        if( props.data.length >= 1) // if there is 1 row of data, we can page forward (because the other data might have been filtered out)
            props.setDatatablePaginationPage(props.datatablePaginationPage + 1)
    }
    function pageDown(){
        /** Set the page number in the parent component's pagination state **/
        if(props.datatablePaginationPage > 1) // we can only page forward if we have a full page of data, implying there is more data to be fetched
            props.setDatatablePaginationPage(props.datatablePaginationPage - 1)
    }


    /** With Parent pagination, the datatable displays all the rows it is supplied. Paging will re-query the data and return the new data.
     * Here, "Pagination" is paging via the mutation, and not the current data  **/
    function ParentPagination(){
        return (
            <div className="mt-5 mb-10">
                <div className="flex w-full justify-center items-center gap-16">

                    {/* Back */}
                    <div className="flex gap-4">
                        <button
                            className="btn-outline-light"
                            onClick={pageDown}
                            disabled={props.datatablePaginationPage <= 1} // we can only page forward if we have a full page of data, implying there is more data to be fetched
                        >
                            {'<'}
                        </button>
                    </div>

                    {/* Page count */}
                    <div className="flex gap-4 items-center">

                        {/* Page number input */}
                        <input
                            type="number"
                            min={1}
                            className="input py-3 px-4 text-sm w-20"
                            value={props.datatablePaginationPage}
                            onChange={(e)=>{
                                if(e.target.value > 0)
                                    props.setDatatablePaginationPage(parseInt(e.target.value));
                            }}
                        />
                    </div>

                    {/* Forward */}
                    <div className="flex gap-4">
                        <button
                            className="btn-outline-light"
                            onClick={pageUp}
                            // disabled={ props.data.length < props.pageSize} // we can only page forward if we have a full page of data, implying there is more data to be fetched

                             // because of status filtering on HAI tasks (10 are received, but 2 are DELETED, so only 8 are displayed)
                            // which means the above "full page" logic doesn't work. We stop paging forward when there is no more data
                            disabled={props.data.length <= 0}
                        >
                            {'>'}
                        </button>

                    </div>
                </div>
            </div>
        );
    }

    /** With Datatable pagination, all the data is given to the datatable (e.g 1000 rows),
     * And the Datatable will only display e.g 10 at a time. Paging through only changes the 10 rows being displayed
     * This is the opposite of the above ParentPagination **/
    function DatatablePagination(){
        return (
            <div className="mt-5 mb-10">
                <div className="flex w-full justify-center items-center gap-16">

                    {/* Back */}
                    <div className="flex gap-4">
                        <button
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-2 border-gray-200 rounded-md shadow"
                            onClick={() => props.tableInstance.gotoPage(0)}
                            disabled={!props.tableInstance.canPreviousPage}
                        >
                            {'<<'}
                        </button>
                        <button
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-2 border-gray-200 rounded-md shadow"

                            onClick={props.tableInstance.previousPage}
                            disabled={!props.tableInstance.canPreviousPage}
                        >
                            {'<'}
                        </button>
                    </div>

                    {/* Page count */}
                    <div className="flex gap-4 items-center">
                        <div>
                            Page <strong> {props.tableInstance.state.pageIndex + 1} of {props.tableInstance.pageOptions.length} </strong>
                        </div>

                        {/* Page number input */}
                        <input
                            type="number"
                            min={1}
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-2 border-gray-200 rounded-md shadow"
                            style={{width: 70}}
                            max={props.tableInstance.pageOptions.length}
                            defaultValue={props.tableInstance.state.pageIndex}
                            onChange={props.onChangeInInput}
                        />
                    </div>

                    {/* Forward */}
                    <div className="flex gap-4">
                        <button
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-2 border-gray-200 rounded-md shadow"
                            onClick={props.tableInstance.nextPage} disabled={!props.tableInstance.canNextPage}>
                            {'>'}
                        </button>
                        <button
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-2 border-gray-200 rounded-md shadow"
                            onClick={() => props.tableInstance.gotoPage(props.tableInstance.pageCount - 1)}
                            disabled={!props.tableInstance.canNextPage}
                        >
                            {'>>'}
                        </button>

                    </div>
                </div>
            </div>
        );
    }

}


const Filter = ({column}) => {
    return (
        <div>
            {column.canFilter && column.render('Filter')}
        </div>
    );
};

const DefaultColumnFilter = ({
                                 column: { // this is the headerGroup object
                                     filterValue,
                                     setFilter,
                                     // Header,
                                     preFilteredRows: {length}
                                 }
                             }) => {
    return (
        <input
            value={filterValue || ''}
            // id={Header + '_filter'}
            className="table-filter-search"
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder={`Search (${length})`}
        />
    );
};





import React, { useState, useEffect } from 'react';

// Custom hook that manages document title
export function useDocumentTitle(initialTitle) {
  const [title, setTitle] = useState(initialTitle);

  useEffect(() => {
    document.title = title;
  }, [title]);

  // Return setTitle so it can be used to update the title from a component
  return setTitle;
}

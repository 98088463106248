// Library imports

import {
    BanIcon,
    CheckIcon,
    MenuAlt2Icon,
    NewspaperIcon,
    PencilAltIcon,
    PlusIcon,
    SearchIcon,
    TrashIcon,
    MailIcon,
    RefreshIcon,
    DownloadIcon,
} from '@heroicons/react/outline';

import {
    ChevronDownIcon,
    ChevronRightIcon
} from '@heroicons/react/solid';
import moment from 'moment-timezone';

import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Safe from '../../components/Safe';
import LoadingSpinner from '../../components/ui/LoadingSpinner';

import {
    customGraphRequest,
    generateReportDownloadUrl,
    queryClaimComment,
    querySettlements,
    snoozeClaim
} from '../../utils/coreApi.js';
import {
    alertError,
    calculateProperties,
    classNames,
    escapeDoubleQuotes,
    formatAsTitle,
    isEmpty,
} from '../../utils/helpers.js';
import useInterval from '../../utils/useInterval';


// Local imports
import AlertModal from '../modals/AlertModal.jsx';
import MultiProductModal from '../modals/MultiProductModal';
import * as coreApi from "../../utils/coreApi";
import {useDocumentTitle} from "../../components/PageTitle";
import {BasicImage} from "../../components/base/BasicImage";
import {Countdown_ClaimSLA} from "./ClaimsHome";
import Tooltip from "../../components/Tooltip";
import {
    utcMomentInstanceFromLocal,
    localMomentInstanceFromUTC,
    HUMAN_READABLE_DATETIME_TIMEZONE,
    utcMoment
} from "../../utils/time";
import {Menu} from "@headlessui/react";
import {DropdownMenu} from "../../components/base/DropdownMenu";
import LimitedOrganisationAccess, {OrganisationType} from "../../components/LimitedOrganisationAccess";
import {SimpleSelect} from "../../components/base/SimpleSelect";


// Constants

const displayStatuses = {

    // Transition states

    'CALCULATION_IN_PROGRESS': 'Calculating',
    'VERIFICATION': 'Verification',
    'TENDERING': 'Tendering',
    'POST_TENDER_INSPECTION': 'Post tender inspection',

    // End states

    'CANNOT_BE_VERIFIED': 'Cannot be verified',
    'DELETED': 'Deleted',
    'EXPIRED': 'Expired',
    'QUANTIFIED': 'Quantified',
    'UNABLE_TO_TENDER': 'Unable to tender'

};

const statusSteps = {

    'Calculating': 0,

    'Tendering': 1, 'Verification': 1,

    'Post tender inspection': 2,

    'Cannot be verified': 3, 'Deleted': 3, 'Expired': 3, 'Quantified': 3, 'Unable to tender': 3

};


export default function ClaimProfile(props) {

    const setTitle = useDocumentTitle("Claim Profile");

    // React router
    const navigate = useNavigate();

    const {claimId} = useParams();

    // Used for errors
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        'heading': '', 'message': ''
    });

    // UI states
    const [refreshInterval, setRefreshInterval] = useState(null);

    // Data states
    const user = props.user;
    const [claim, setClaim] = useState(null);
    const [settlements, setSettlements] = useState(null);

    const [claimNote, setClaimNote] = useState(null);

    const [verifiedByUsers, setVerifiedByUsers] = useState([]);

    // Compare and Edit proto modal
    const [productInfoModalOpen, setProductInfoModalOpen] = useState(false);
    const [productInfoModalOptions, setProductInfoModalOptions] = useState({
        'product': null,
        'onProductAdded': null
    });

    // On every page load
    useEffect(() => {

        if (claim === null) {
            getFullClaim();
        }

        if (settlements === null) {
            querySettlements(claimId, (data) => {
                setSettlements(data.settlements);
            }, (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            });
        }

        return () => {
            if (refreshInterval !== null) {
                clearInterval(refreshInterval);
                setRefreshInterval(null);
            }
        };


    }, []);

    useEffect(() => {

        if (claim && !claimNote) {
            queryClaimComment(claim.id, (data) => {
                if (data.comments[0]) {
                    let commentMessage = data.comments[0].message;
                    setClaimNote(commentMessage);
                } else {
                    setClaimNote('No notes found');
                }
            }, (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            });
        }

        if (claim && !props.navbarTopPageContent.claim) props.setNavbarTopPageContent({'claim': claim});

        if (claim) setTitle(`Claim : ${claim.insurer_claim_number}`)

    }, [claim]);


    const [claimPollDelay, setClaimPollDelay] = useState(3000); // Initial delay set to 3000ms

    // null implies that the claim pooling has not started yet. False means it has stopped
    const shouldPoolForClaim = useRef(null);
    useInterval(() => {
        /** Pooling for claim data every 3 seconds */

        console.log('INTERVAL - update claim');

        // check if any items status is CALCULATION_IN_PROGRESS
        let calculationInProgress = claim?.items.some(item => item.status === 'CALCULATION_IN_PROGRESS');
        if (!calculationInProgress) setClaimPollDelay(10000);

        // If shouldPoolForClaims is null, it means the interval has not started
        if (shouldPoolForClaim.current === null) {

            shouldPoolForClaim.current = true;

            // stop polling after 20 minutes
            setTimeout(() => shouldPoolForClaim.current = false, (1000 * 60 * 20));
        }

        if (shouldPoolForClaim) getClaim();
    }, claimPollDelay);

    useEffect(() => {
        /** Additional data to retrieve once the claim has been retrieved */

        if (!claim) return;

        getVerifiedByUsers();

    }, [claim]);

    const getVerifiedByUsers = () => {
        /** Fetch the users who have verified this claim */

        // Do not query if the verifiedByUsers has already been set
        if (verifiedByUsers.length) return;

        let userIdsToRetrieve = [];

        // for each item.product.metadata.verified_by, add it to the list of users to retrieve
        claim?.items.forEach(item => {
            if (item.product?.metadata?.verified_by) {
                userIdsToRetrieve.push(item.product.metadata.verified_by);
            }
        });

        if (!userIdsToRetrieve.length) return;

        // query the users details for each verified_by user
        userIdsToRetrieve.forEach(userId => {
            let query = `
                query VerifiedByUsers {
                  users(
                    id: "${userId}"
                    ){
                    users {
                      id
                      organisation {
                        id
                      }
                      info {
                        first_name
                        last_name
                        full_name
                      }
                    }
                  }
                }
            `;

            customGraphRequest(
                query,
                (data) => {
                    let user = data['users'][0];
                    if (user && user.info)
                        setVerifiedByUsers(state => {
                            // add the user to the list of verified by users
                            return [...state, user];
                        });
                },
                props.onError
            );
        })
    }

    const getClaim = () => {
        if (!shouldPoolForClaim.current) return;

        if (claim?.status === 'QUANTIFIED') shouldPoolForClaim.current = false;

        let minimalClaimsQuery = `
            query minClaims_pollStatus{
                claims(|placeholder|){
                error {
                  type
                  message
                }
            
                claims {
                  status
                  items{
                    id
                    status
                  }
                }
              }
            }
        `;
        let queryArgs = `id:"${claimId}"`;
        minimalClaimsQuery = minimalClaimsQuery.replace('|placeholder|', queryArgs);

        // get claim data
        customGraphRequest(
            minimalClaimsQuery,
            (data) => {

                if (claimStatusHasChanged(claim, data['claims'][0])) {
                    console.log('claim status has changed, retrieving full claim')
                    getFullClaim();
                }

            },
            props.onError,
        );
    };

    function claimStatusHasChanged(oldClaim, newClaim) {

        if (!oldClaim || !newClaim) return false;

        let statusHasChanged = false

        // Compare the status of the claim
        if (oldClaim.status !== newClaim.status) statusHasChanged = true;

        // Compare the status of the items
        oldClaim.items.forEach(item => {
            let newItem = newClaim.items.find(i => i.id === item.id);
            if (newItem && newItem.status !== item.status) statusHasChanged = true;
        })

        return statusHasChanged;
    }

    const getFullClaim = () => {

        let query = `
            query {
              claims(
                id: "${claimId}"
              ) {
                error {
                  type
                  message
                }
                claims {
                  cc_recipients{
                    id,
                    email,
                    full_name,
                    first_name
                    last_name,
                  }
                  attachments{
                    id
                    object_type
                    content_type
                    date_created
                    date_updated
                    file_name
                    object_id
                    user{
                      id
                      username
                    }
                    url
                  }
                  total_cost
                  tat_minutes
                  cost_vat
                  claimant_report_s3_key
                  comments{
                    message
                  }
                  cost_subtotal
                  created_by {
                    id
                    info {
                      first_name
                      last_name
                      profile_image_url
                    }
                    status
                    status_reason
                    username
                  }
                  delivery_costs {
                    supplier_name
                    supplier_id
                    delivery_cost
                  }
                  date_created
                  date_quantified
                  date_quantification_requested
                  date_sla_expiry
                  date_tender_expiry
                  location_text
                  id
                  insurer_claim_number
                  insurer_policy_number
                  organisation {
                    id
                    info {
                      name
                      country
                      currency
                      currency_symbol
                      logo_image_url
                    }
                    unique_reference_name
                  }
                  owned_by {
                    id
                    status
                    username
                    info {
                      first_name
                      last_name
                      profile_image_url
                    }
                  }
                  quantification_requested_by {
                    id
                    info {
                      first_name
                      last_name
                    }
                  }
                  postal_code
                  status
                  status_reason
                  
                  
                  items {
                    id
                    is_tender_item
                    is_manual
                    status
                    quantity
                    status_reason
                    type
                    category {
                      id
                      category_a
                      display_name
                    }
                    supplier_panel {
                      id
                      name
                      unique_reference_name
                      status
                      supplier {
                        id
                        info {
                          name
                          country
                          currency
                          currency_symbol
                          logo_image_url
                        }
                      }
                      recommended_replacement {
                        product {
                          id
            
                          brand
                          category
                          common_name
                          description
            
                          status
                          type
            
                          metadata
                          model_number
                          model_numbers
            
                          images {
                            main
                            thumbnail_web
                            thumbnail_print
                          }
                          
                        }
                        quote {
                          id
                          status
                          country
                          currency
                          currency_symbol
                          is_manual
                          price
                          brand
                          description
                          supplier {
                            id
                            unique_reference_name
                            display_name
                          }
                        }
                      }
                    }
                    possible_replacements {
                      quotes {
                        id
                        supplier {
                          unique_reference_name
                        }
                      }
                    }
                    product {
                      id
            
                      brand
                      category
                      common_name
                      description
            
                      status
                      type
            
                      metadata
                      model_number
                      model_numbers
                      
                      ai_generated
            
                      images {
                        main
                        thumbnail_web
                        thumbnail_print
                      }
                      
                    }
                    replacement {
                      product {
                        id
            
                        brand
                        category
                        common_name
                        description
            
                        status
                        type
            
                        metadata
                        model_number
                        model_numbers
            
                        images {
                          main
                          thumbnail_web
                          thumbnail_print
                        }
                        
                      }
                      quote {
                        id
                        status
                        country
                        currency
                        currency_symbol
                        price
                        brand
                        date_expiry
                        is_manual
                        description
                        match_accuracy
                        supplier {
                          id
                          unique_reference_name
                          display_name
                        }
                      }
                      note
                      date_selected
                      selected_by
                      is_system_selected
                    }
                  }
                }
              }
            }
        `

        customGraphRequest(
            query,
            (data) => {
                let newClaim = data['claims'][0];

                if (isEmpty(data['claims'])) return props.showAlertModal('error', 'Failed to fetch claim', 'No claim data was returned');

                setClaim(newClaim);

                redirectIfDeleted(newClaim)
            },
            (error) => props.onError(error)
        )

    }

    function redirectIfDeleted(newClaim) {
        if (newClaim.status === 'DELETED') {
            props.showAlertModal(
                'error',
                'Claim deleted',
                'This claim has been deleted'
            );
        }
    }

    function currencyChange(cur = null) {
        if (cur === 0) {
            return 0;
        }
        if (cur) {
            return cur.toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,');
        } else {
            return ' ';
        }
    }

    const showProductInfoModal = (item) => {


        let product1 = item.product;
        let product2 = item.replacement?.product || null;
        // let product2 = item.product;

        if (props.mainOrganisation?.type === 'SUPPLIER') {
            // the replacement is only shown if the item is quantified/post tender
            // if the claim is quantified, the replacement is shown
            // The supplier does not see the item.replacement
            // instead the supplier sees their own quoted replacement in item.supplier_panel[at the supplier].recommended_replacement
            // IF THEY HAVE - SHOW THAT QUOTE AS THE REPLACEMENT
            // IF THEY HAVE NOT - SHOW "declined to tender"
            let supplier = item.supplier_panel?.find(supplier => supplier.id === props.mainOrganisation.id) || null;
            let replacement = supplier?.recommended_replacement || null;
            if (replacement && replacement.product) {
                product2 = replacement.product;
            }
        }

        setProductInfoModalOptions({
            'product': product1,
            'product2': product2,
            // 'productId': product1.id,
            // 'productId2': product2?.id || null,
            'onProductAdded': null,
            'mode': 'view'
        });

        setProductInfoModalOpen(true);
    };

    const allProps = {
        ...props,
        navigate,
        claimId,
        alertOpen, setAlertOpen,
        alertOptions, setAlertOptions,
        refreshInterval, setRefreshInterval,
        user,
        getFullClaim,
        claim, setClaim,
        settlements, setSettlements,
        claimNote, setClaimNote,
        verifiedByUsers, setVerifiedByUsers,
        shouldPoolForClaim,
        productInfoModalOpen, setProductInfoModalOpen,
        productInfoModalOptions, setProductInfoModalOptions,
        showProductInfoModal,
        currencyChange
    };

    // Page content
    return (<div className="">

        {/* TODO : change usage to base modal */}
        <AlertModal
            open={alertOpen}
            setOpen={setAlertOpen}
            options={alertOptions}
        />

        <div className="">

            <main className="max-w-full pt-8 pb-24 sm:pt-8 sm:px-6 lg:px-8">

                <Safe> <Header {...allProps} /> </Safe>

                <Safe> <ClaimInfo {...allProps} /> </Safe>

                <Safe> <ClaimNote {...allProps} /> </Safe>

                <Safe> <Settlements {...allProps} /> </Safe>

                <Safe> <ButtonSection {...allProps} /> </Safe>

                <Safe> <Items {...allProps} /> </Safe>

                <Safe>
                    <MultiProductModal
                        {...props}
                        open={productInfoModalOpen}
                        setOpen={setProductInfoModalOpen}
                        options={productInfoModalOptions}
                    />
                </Safe>

            </main>

        </div>

    </div>);
}

function Header(props) {

    const claim = props.claim;

    const generateAndDownloadReport = (event, type) => {
        // Prevent the default action of the event
        event.preventDefault();

        generateReportDownloadUrl(props.claimId, type, (data) => {
            // Create a new anchor element
            const anchor = document.createElement('a');

            // Set the href attribute to the download URL
            anchor.href = data.url;

            // Trigger a click event on the anchor element
            anchor.click();

            // Cleanup: remove the anchor element from the document
            anchor.remove();
        }, (error) => props.onError(error));
    };


    function resendQuantificationReport(recalculate = false) {

        let mutation = `
            mutation Regenerate_Resend_QuantificationReport{
              regenerate_quantification_report(
                claim_id: "${props.claim.id}"
                recalculate: ${recalculate}
                resend_report: true
              ){
                error {type, message}
                claim{
                  id
                }
              }
            }
        `

        customGraphRequest(
            mutation,
            (data) => {
                props.showNotificationModal(
                    'success',
                    'Successfully requested',
                    '');
            },
            (error) => props.onError(error)
        );


    }


    if (!claim) return <></>;

    return (
        <div className="page-header-bar flex items-baseline justify-between">

            {/* HEADER */}
            <h1 className="page-header-title">
                Claim Profile
            </h1>

            {/* DOWNLOAD REPORT */}
            <div className={'ROOT ADMINISTRATOR INSURER'.includes(props.mainOrganisation?.type) ? '' : 'hidden'}>

                <DropdownMenu
                    optionGroups={[
                        [
                            <button className='btn-outline border-none w-full m-0'
                                    onClick={(e) => generateAndDownloadReport(e, 'QUANTIFICATION')}>
                                <div className='flex gap-2'>
                                    <DownloadIcon className="mr-1 h-5 w-5"/>
                                    Quantification Report
                                </div>
                            </button>,
                            <button className='btn-outline border-none w-full m-0'
                                    disabled={!claim.claimant_report_s3_key}
                                    onClick={(e) => generateAndDownloadReport(e, 'CLAIMANT')}>
                                <div className='flex gap-2'>
                                    <DownloadIcon className="mr-1 h-5 w-5"/>
                                    Claimant Report
                                </div>
                            </button>
                        ],

                        [
                            <LimitedOrganisationAccess
                                types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                                mainOrganisation={props.mainOrganisation}
                            >
                                <button className='btn-outline border-none w-full m-0'
                                        onClick={() => resendQuantificationReport(false)}>
                                    <div className='flex gap-2'>
                                        <MailIcon className="mr-1 h-5 w-5"/>
                                        Resend all reports
                                    </div>
                                </button>
                            </LimitedOrganisationAccess>,

                            <LimitedOrganisationAccess
                                types={[OrganisationType.ROOT, OrganisationType.ADMINISTRATOR]}
                                mainOrganisation={props.mainOrganisation}
                            >
                                <Tooltip content={<>
                                    <p>Recalculate all reports, and resend</p>
                                    <i>Only use when needed</i>
                                </>}>
                                    <button className='btn-outline border-none w-full m-0'
                                            onClick={() => resendQuantificationReport(true)}>
                                        <div className='flex gap-2'>
                                            <RefreshIcon className="mr-1 h-5 w-5"/>
                                            Recalculate and resend
                                        </div>
                                    </button>
                                </Tooltip>
                            </LimitedOrganisationAccess>,
                        ]

                    ]}
                    mainButton={
                        <Menu.Button
                            className="btn">
                            <NewspaperIcon className="mr-1 h-5 w-5"/>
                            Download report
                            <ChevronDownIcon
                                className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    }

                    renderOption={(option) => option}
                />

            </div>

        </div>

    );
}

function ClaimInfo(props) {

    const [_, setClaimRemainingSLAtime] = useState('');
    const [claimSLATimeInUserLocalTime, setClaimSLATimeInUserLocalTime] = useState('');
    const [claimQuantifiedOnTimeInUserLocalTime, setClaimQuantifiedOnTimeInUserLocalTime] = useState('');
    const [showSnoozeButtons, setShowSnoozeButtons] = useState(false);

    const claim = props.claim;

    useEffect(() => {
        /** Update the SLA time when the claim state has been updated */
        if (claim)
            calculateRemainingSLATime();
    }, [claim]);

    const onSnoozeClaim = (snoozeAmount) => {

        let snoozeMinutes = '';
        if (snoozeAmount === '5m')
            snoozeMinutes = 'SNOOZE_5_MINUTES';
        else if (snoozeAmount === '30m')
            snoozeMinutes = 'SNOOZE_30_MINUTES';
        else if (snoozeAmount === '60m')
            snoozeMinutes = 'SNOOZE_60_MINUTES';
        else if (snoozeAmount === '4h')
            snoozeMinutes = 'SNOOZE_240_MINUTES';
        else
            snoozeMinutes = 'SNOOZE_5_MINUTES';

        snoozeClaim(
            claim.id,
            snoozeMinutes,
            (data) => {
                alert('Successfully snoozed claim');
                window.location.reload();
            }, (error) => {
                console.error('failed to snooze claim');
                console.error(error);
            }
        );
    };

    const calculateRemainingSLATime = () => {

        if (!claim) return;

        let now = moment().tz(moment.tz.guess());

        // let sla = moment.utc(claim.date_sla_expiry)
        const sla = localMomentInstanceFromUTC(claim.date_sla_expiry)

        // let quantifiedOn = moment.utc(claim.date_quantified).tz(moment.tz.guess());
        let quantifiedOn = localMomentInstanceFromUTC(claim.date_quantified);

        setClaimSLATimeInUserLocalTime(sla.format('YYYY-MM-DD HH:mm:ss z'));
        setClaimQuantifiedOnTimeInUserLocalTime(quantifiedOn.format('YYYY-MM-DD HH:mm:ss z'));
        // momentTimezone
        // "2023-09-05 14:55:39"

        if (now > sla) {
            // already expired
            setClaimRemainingSLAtime('expired');
        } else {
            // not expired yet

            let difference = moment.duration(sla.diff(now));

            let days = difference.asDays();
            days = Math.floor(days);
            let hours = difference.asHours();
            hours = Math.floor(hours % 24);
            let minutes = difference.asMinutes();
            minutes = Math.floor(minutes % 60);
            // let seconds = difference.asSeconds()
            // seconds = Math.floor(seconds % 60)

            let remainingTime = `${days ? days + 'D' : ''} ${hours < 10 ? '0' + hours : hours}H : ${minutes < 10 ? '0' + minutes : minutes}M`;

            setClaimRemainingSLAtime(remainingTime);

        }

    };

    function renderUnquantifiedSLA() {
        return (
            <div className="flex justify-start items-center gap-4 ml-5 mb-3">

                <div>
                    {/* STATUS REASON */}
                    <p className="m-1">
                        {claim.status === 'QUANTIFIED' ?
                            'Quantified on'
                            :
                            claim.status_reason
                        }
                    </p>

                    {/* EXPIRY DATE */}
                    <Tooltip
                        content={(<div>
                            <p>{utcMomentInstanceFromLocal(claim.date_sla_expiry).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</p>
                            <p>{localMomentInstanceFromUTC(claim.date_sla_expiry).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</p>
                        </div>)}
                        className=''
                    >
                        <span>Expires on:</span>

                        <b> {localMomentInstanceFromUTC(claim.date_sla_expiry).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</b>
                    </Tooltip>
                </div>


                {/* REMAINING TIME */}
                <div className="tailwind-tooltip-container flex justify-center">
                    <div className='input p-2.5'>
                        <Countdown_ClaimSLA date_sla_expiry={claim.date_sla_expiry}/>
                    </div>
                    <span
                        className="tailwind-tooltip -top-[3rem]">The remaining time of this claim</span>
                </div>

                {/* SNOOZE */}
                <button className="btn-light p-2 flex gap-1"
                        onClick={() => setShowSnoozeButtons(!showSnoozeButtons)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    Snooze
                </button>

                {/* SNOOZE AMOUNTS */}
                {showSnoozeButtons &&
                    <div>
                        <button className="btn-light p-2 flex gap-1"
                                onClick={() => onSnoozeClaim('30m')}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            30 minutes
                        </button>
                        <button className="btn-light p-2 flex gap-1"
                                onClick={() => onSnoozeClaim('60m')}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            1 hour
                        </button>
                        <button className="btn-light p-2 flex gap-1"
                                onClick={() => onSnoozeClaim('4h')}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            4 hours
                        </button>
                    </div>
                }

            </div>
        );
    }

    function renderQuantifiedSLA() {
        return (
            // {/* QUANTIFIED ON DATE AND TIME */}
            <Tooltip
                content={(<div>
                    <p>{utcMoment(claim.date_quantified).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</p>
                    <p>{localMomentInstanceFromUTC(claim.date_quantified).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</p>
                </div>)}
                className=''
            >

                {/* STATUS REASON */}
                <p className="m-1">
                    {claim.status === 'QUANTIFIED' ?
                        'Quantified on'
                        :
                        claim.status_reason
                    }
                </p>

                <b> {localMomentInstanceFromUTC(claim.date_quantified).format(HUMAN_READABLE_DATETIME_TIMEZONE)}</b>

            </Tooltip>
        );
    }


    const allItemsQuantified = (inClaim = null) => {

        let claimToCheck = props.claim;

        if (inClaim !== null) {
            claimToCheck = inClaim;
        }

        if (claimToCheck === null) {
            return false;
        }

        for (let item of claimToCheck.items) {
            if (item.status !== 'QUANTIFIED') {
                return false;
            }
        }

        return true;
    };


    const claimAddress = () => {

        let addressParts = props.claim.location_text.split(',');

        return (<dd>
            {addressParts.map((part) => (
                <span key={part} className="block">{part}</span>
            ))}
        </dd>);
    };


    const claimInfo = () => {

        if (!props.claim) {
            return <LoadingSpinner/>;
        }

        let requestedBy = null;

        if (props.claim.quantification_requested_by !== null) {
            let info = props.claim.quantification_requested_by.info;
            requestedBy = info.first_name + ' ' + info.last_name;
        }

        let tatDisplay = '-';

        if (props.claim.tat_minutes !== null) {
            const quotient = Math.floor(props.claim.tat_minutes / 60);
            const remainder = props.claim.tat_minutes % 60;
            tatDisplay = quotient.toString()
                .padStart(2, '0') + ':' + remainder.toString()
                .padStart(2, '0');
        }

        let supplierCosts = [];

        if (props.claim.delivery_costs !== null) {
            for (let cost of props.claim.delivery_costs) {
                supplierCosts.push({
                    'name': cost.supplier_name, 'cost': cost.delivery_cost
                });
            }
        }

        function ccEmails() {
            /** Render CC emails */

            // If there are no cc recipients or the user's org is not an  root/admin/insurer, return placeholder '-'
            if (!'ROOT ADMINISTRATOR INSURER'.includes(props.mainOrganisation?.type)) return <p>-</p>

            const recipients = props.claim.cc_recipients;

            return recipients.map((recipient) =>

                <div key={recipient.id}>
                    <div key={recipient.email}>
                        <p>{recipient.first_name} {recipient.last_name} </p>
                        <p>{recipient.email}</p>
                        {/* DIVIDER */}
                        <div className="border-b-2 border-gray-200 my-2 w-[4rem]"></div>
                    </div>
                </div>
            );
        }

        function showAddCCEmailModal() {

            function addCCEmail() {

                let ccFirstName = document.getElementById('ccFirstName').value;
                let ccLastName = document.getElementById('ccLastName').value;
                let ccEmail = document.getElementById('ccEmail').value;

                let mutation = `
                    mutation ClaimProfile_AddCCRecipient {
                      add_cc_recipients_to_claim(
                        claim_id: "${props.claim.id}",
                        cc_recipients: [
                          {
                            email: "${ccEmail}",
                            first_name: "${ccFirstName}",
                            last_name: "${ccLastName}",
                          }
                        ]
                      ){
                        error{type, message}
                        claim{
                          id
                        }
                      }
                    }
                `;

                customGraphRequest(
                    mutation,
                    (data) => {
                        props.showToastNotificationModal('success', 'CC email added', 'Reloading claim data...')
                        props.getFullClaim();
                        props.setCustomModalOpen(false);
                    },
                    props.onError
                )

            }

            let content = (
                <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                    <h1 className='p-4 mb-4 font-light text-3xl'>Add CC Email Address</h1>

                    <div className='w-full border-b-2 border-gray-200 my-4'></div>

                    <div className="mb-4">
                        <div className="flex space-x-2 mb-2">
                            <input
                                type="text"
                                id='ccFirstName'
                                placeholder="First Name"
                                className="input flex-1"
                            />
                            <input
                                type="text"
                                id='ccLastName'
                                placeholder="Last Name"
                                className="input flex-1"
                            />
                        </div>
                        <input
                            type="email"
                            id='ccEmail'
                            placeholder="Email"
                            className="input w-full"
                        />
                    </div>

                    <div className="mt-5 mt-4 flex w-full justify-between">

                        <button
                            type="button"
                            className="btn-outline"
                            onClick={() => props.setCustomModalOpen(false)}
                        >
                            Close
                        </button>


                        <button
                            type="button"
                            className="btn"
                            onClick={addCCEmail}
                        >
                            <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
                            Add
                        </button>

                    </div>

                </div>
            );

            props.showCustomModal(content);

        }

        function showAddClaimCommentModal() {

            function addClaimComment() {

                let claimComment = document.getElementById('claimComment').value;

                let mutation = `
                    mutation create_comment {
                      create_claim_comment (
                        claim:"${props.claim.id}" 
                        message:"${escapeDoubleQuotes(claimComment)}"
                      ) {
                        comment {
                          message
                        }
                      }
                    }
                `;

                customGraphRequest(
                    mutation,
                    (data) => {
                        props.showToastNotificationModal('success', 'Claim Comment Added', 'Successfully added claim comment')
                        props.getFullClaim();
                        props.setCustomModalOpen(false);
                    },
                    props.onError
                )

            }

            let content = (
                <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                    <h1 className='p-4 mb-4 font-light text-3xl'>Add Claim Comment</h1>

                    <div className='w-full border-b-2 border-gray-200 my-4'></div>

                    <textarea
                        id='claimComment'
                        placeholder="Claim comment"
                        className="input w-full my-6"
                    />

                    <div className="mt-5 mt-4 flex w-full justify-between">

                        <button
                            type="button"
                            className="btn-outline"
                            onClick={() => props.setCustomModalOpen(false)}
                        >
                            Close
                        </button>


                        <button
                            type="button"
                            className="btn"
                            onClick={addClaimComment}
                        >
                            <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
                            Add claim comment
                        </button>

                    </div>

                </div>
            );

            props.showCustomModal(content);

        }

        return (
            <section aria-labelledby="summary-heading" className="widget flex-col">
                <h2 id="summary-heading" className="font-medium ml-2 text-2xl">Claim Details</h2>

                <div
                    className="rounded-lg px-8 py-8 grid grid-cols-12 gap-x-8">
                    <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7 text-gray-900">

                        <div>
                            <dt className="font-bold">Claim number</dt>
                            <dd>{props.claim.insurer_claim_number}</dd>

                            <dt className="font-bold mt-2">Organisation</dt>
                            <dd>{props.claim.organisation.info.name}</dd>

                            <dt className="font-bold mt-2">Area</dt>
                            {claimAddress()}

                            <dt className="font-bold mt-2">Requested</dt>
                            <dd>{props.claim.date_quantification_requested !== null ? localMomentInstanceFromUTC(props.claim.date_quantification_requested).format(HUMAN_READABLE_DATETIME_TIMEZONE) : '-'}</dd>

                            <dt className="font-bold mt-2">Closed</dt>
                            <dd>{props.claim.date_quantified !== null ? localMomentInstanceFromUTC(props.claim.date_quantified).format(HUMAN_READABLE_DATETIME_TIMEZONE) : '-'}</dd>

                            <dt
                                onClick={showAddClaimCommentModal}
                            >
                                <Tooltip
                                    content={'Click to add additional claim comments'}
                                >
                                    <dt className="font-bold mt-2 tooltip_underline cursor-cell w-fit">Comments +</dt>
                                    <dd>
                                        {claim.comments && claim.comments.length > 0 ? (
                                            claim.comments.map((comment, index) => (
                                                <div key={index}>
                                                    <p>{comment.message}</p>
                                                </div>
                                            ))
                                        ) : (
                                            <span>-</span>
                                        )}
                                    </dd>
                                </Tooltip>
                            </dt>


                        </div>

                        <div>
                            <dt className="font-bold">Policy number</dt>
                            <dd>{props.claim.insurer_policy_number !== null ? props.claim.insurer_policy_number : '-'}</dd>

                            <dt className="font-bold mt-2">User</dt>
                            <dd>{props.claim.owned_by?.info?.first_name || '-'} {props.claim.owned_by?.info?.last_name || ''}</dd>

                            <dt className="font-bold mt-2">Requested by</dt>
                            <dd>{requestedBy !== null ? requestedBy : '-'}</dd>

                            <dt
                                onClick={showAddCCEmailModal}
                            >
                                <Tooltip
                                    content={'Click to add additional CC email'}
                                >
                                    <dd className="font-bold mt-2 mb-1 tooltip_underline cursor-cell">
                                        CC emails +
                                    </dd>
                                    <dd>
                                        {ccEmails()}
                                    </dd>
                                </Tooltip>
                            </dt>

                            <dt className="font-bold mt-2">Turnaround time (TAT)</dt>
                            <dd>{tatDisplay}</dd>

                            {
                                (props.claim?.attachments && props.claim?.attachments?.length <= 0) ?
                                    <Tooltip content='There are no attachments in this claim'>
                                        <dt className="font-bold mt-2">Attachments</dt>
                                        <dd>-</dd>
                                    </Tooltip>
                                    :
                                    <Tooltip content='Selecting an attachment will automatically download it'>
                                        <dd>
                                            <SimpleSelect
                                                width='w-[10rem] mt-4'
                                                selectOptionClassName='!max-h-[inherit] w-[20rem]'
                                                selectedState={null}
                                                selectableOptions={props.claim?.attachments || []}

                                                // DOWNLOAD ATTACHMENT
                                                onChange={(attachment) => {
                                                    if (!attachment || !attachment.id || !attachment.object_id || !attachment.object_type) {
                                                        props.showAlertModal('error', 'Invalid attachment', 'Attachment is missing required fields');
                                                        return;
                                                    }

                                                    // Create download url
                                                    let mutation = `
                                                    query GenerateFileDownloadURL{
                                                       generate_file_download_url(
                                                        file_id: "${attachment.id}"
                                                        object_id: "${attachment.object_id}"
                                                        object_type: ${attachment.object_type}
                                                      ){
                                                        error{type, message}
                                                        url
                                                      }
                                                    }
                                                `

                                                    customGraphRequest(
                                                        mutation,
                                                        (data) => {
                                                            props.showToastNotificationModal('success', 'URL Generated', 'The download will start shortly');

                                                            // Create a new anchor element
                                                            const anchor = document.createElement('a');

                                                            // Set the href attribute to the download URL
                                                            anchor.href = data.url;

                                                            // Trigger a click event on the anchor element
                                                            anchor.click();

                                                            // Cleanup: remove the anchor element from the document
                                                            anchor.remove();
                                                        },
                                                        (error) => props.onError(error)
                                                    )

                                                }}

                                                renderOption={(attachment) =>
                                                    <div className='flex gap-2'>
                                                        <p>{attachment.file_name}</p>
                                                    </div>
                                                }

                                                renderSelected={(attachment) =>
                                                    attachment ?
                                                        <div className='flex gap-2'>
                                                            <p>{attachment.file_name}</p>
                                                        </div> : 'Attachments'
                                                }
                                            />
                                        </dd>
                                    </Tooltip>
                            }

                        </div>

                    </dl>


                    <dl className="mt-8 divide-y divide-gray-200 text-sm lg:mt-0 lg:col-span-5">
                        <div className="pb-4 flex items-center justify-between">
                            <dt className="font-bold">Subtotal</dt>
                            <dd className="font-medium text-gray-900">
                                {props.claim !== null && props.claim.cost_subtotal !== 0 ? (props.claim.organisation.info.currency_symbol || ' ') + ' ' + props.currencyChange(props.claim.cost_subtotal) : '-'}
                            </dd>
                        </div>
                        <div className="py-4 flex justify-between">
                            <dt className="font-bold">Shipping</dt>
                            <dd className="font-medium text-gray-900 mt-6">
                                <ul className="mr-32 text-right">
                                    {supplierCosts.map((supplierCost, index) => (
                                            <li key={supplierCost.name}>
                                                <span className="mr-40">{supplierCost.name}</span>
                                                <span>{props.claim && props.claim.organisation ? props.claim.organisation.info.currency_symbol : ' '} {props.currencyChange(supplierCost.cost)}</span>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </dd>
                        </div>
                        <div className="py-4 flex items-center justify-between">
                            <dt className="font-bold">Tax</dt>
                            <dd className="font-medium text-gray-900">
                                {props.claim !== null && props.claim.cost_vat !== 0 ? (props.claim.organisation.info.currency_symbol || ' ') + ' ' + props.currencyChange(props.claim.cost_vat) : '-'}
                            </dd>
                        </div>
                        <div className="pt-4 pb-4 flex items-center justify-between">
                            <dt className="font-bold">Total</dt>
                            <dd className="font-medium text-purple-600">{props.claim !== null && props.claim.total_cost !== 0 ?
                                (props.claim.organisation.info.currency_symbol || ' ') + ' ' + props.currencyChange(props.claim.total_cost)
                                :
                                '-'}</dd>
                        </div>
                        <div className="pt-4 flex items-center justify-between">
                            <dt className="text-yellow-600">
                                {props.claim !== null && !allItemsQuantified() && (<>
                                    <span className="font-medium text-yellow-700">&#x2A; Please note: </span>
                                    <span>Not all replacements have been calculated yet.</span>
                                    <br/>
                                </>)}
                                <span className="text-yellow-700">&#x2A;</span>
                                <span> Does not include excess.</span>
                            </dt>
                            <dd></dd>
                        </div>
                    </dl>
                </div>


                {/* SLA EXPIRY */}
                <div className="w-full text-sm text-gray-900 text-center">

                    {claim.status !== 'QUANTIFIED' && renderUnquantifiedSLA()}

                    {claim.status === 'QUANTIFIED' && renderQuantifiedSLA()}

                </div>


            </section>
        );
    };


    return claimInfo();
}

function ClaimNote(props) {
    const renderClaimNote = () => {
        if (!props.claim || !props.claim.id || !props.claimNote || !props.claimNote.comments) {
            return;
        }

        if (props.claimNote.comments[0]) {
            let _cNote = props.claimNote.comments[0].message;
            // let cNote = _cNote.replace("</br>", "\n" );
            props.setClaimNote(_cNote);
        } else {
            props.setClaimNote('No notes found');
        }

        return (<section aria-labelledby="summary-heading" className="mt-10">
            <h2 id="summary-heading" className="font-medium ml-3 mb-3 text-2xl">Comments</h2>

            <div className="bg-gray-100 py-6 px-4 sm:px-6 sm:rounded-lg ">
                {(props.claimNote || ' ')}
            </div>
        </section>);

    };

    return renderClaimNote();
}

function Settlements(props) {

    /** DEVELOPMENT PLACEHOLDER DATA */
    // useEffect(() => {if(props.claim) props.setSettlements(placeholderSettlementData['data']['settlements']['settlements']);}, [props.claim]);

    const onVerifySettlement = (settlementId, method) => {
            props.showConfirmModal('success', 'Verify settlement', 'Are you sure you want to verify this settlement?', 'Verify', () => {
                verifySettlement(settlementId, method);
            });
        }

    const verifySettlement = (settlementId, method) => {

        coreApi.verifySettlementVoucher(
            {
                'settlement': settlementId,
                'claim_number': props.claim.insurer_claim_number,
                'method': method,
                'beneficiary_id': settlementId,
            },
            (data) => {
                props.showToastNotificationModal('success', 'Settlement verified', 'The settlement has been verified')
                querySettlements(
                    props.claim.id,
                    (data) => {
                        props.setSettlements(data.settlements);
                    },
                    props.onError
                );
            },
            props.onError
        );
    }

    const onDeleteSettlement = (settlementId) => {
        props.showConfirmModal(
            'warning',
            'Delete settlement',
            'Are you sure you want to delete this settlement?',
            'Delete',
            (options) => {
                cancelSettlementVoucher(settlementId, options.inputValue);
            },
            () => {
            },
            {
                'showInput': true,
                'inputPlaceholder': 'Reason for canceling settlement',
            }
        );
    }

    const cancelSettlementVoucher = (settlementId, reason) => {

        coreApi.cancelSettlementVoucher(
            {
                'settlement': settlementId,
                'claim_number': props.claim.insurer_claim_number,
                'reason': reason,
            },
            (data) => {
                props.showToastNotificationModal('success', 'Settlement canceled', 'The settlement has been canceled')
                querySettlements(
                    props.claim.id,
                    (data) => {
                        props.setSettlements(data.settlements);
                    },
                    props.onError
                );
            },
            props.onError
        );
    }

    const onViewBeneficiaryDetails = (settlementId) => {

        /** DEVELOPMENT PLACEHOLDER DATA */
        // showBeneficiaryDetails(placeholderSettlementBeneficiary['data']['settlement_beneficiary']); return;

        let query = `
            query {
                settlement_beneficiary(
                    settlement: "${settlementId}"
                    beneficiary: "${settlementId}"
                ){
                    error {type, message}
                    voucher{
                        status
                    }
                    beneficiary{
                        title
                        first_name
                        last_name
                        msisdn
                        email
                        verification_method
                    }
                }
            }
            `

        customGraphRequest(
            query,
            (data) => {
                showBeneficiaryDetails(data);
            },
            props.onError
        );
    }

    const showBeneficiaryDetails = (settlementBeneficiary) => {

        let content = (
            <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                <h1 className='p-4 mb-4 font-light text-3xl'>Settlement Beneficiary Details</h1>

                <div className='w-full border-b-2 border-gray-200 my-4'></div>

                <table>

                    <tbody>

                    <tr>
                        <td className='font-bold'>Email</td>
                        <td>{settlementBeneficiary.beneficiary.email}</td>
                    </tr>

                    <tr>
                        <td className='font-bold'>Title</td>
                        <td>{settlementBeneficiary.beneficiary.title}</td>
                    </tr>

                    <tr>
                        <td className='font-bold'>Last name</td>
                        <td>{settlementBeneficiary.beneficiary.last_name}</td>
                    </tr>

                    <tr>
                        <td className='font-bold'>MSISDN</td>
                        <td>{settlementBeneficiary.beneficiary.msisdn}</td>
                    </tr>

                    <tr>
                        <td className='font-bold'>Verification method</td>
                        <td>{settlementBeneficiary.beneficiary.verification_method}</td>
                    </tr>

                    <tr>
                        <td className='font-bold'>Voucher status</td>
                        <td>{settlementBeneficiary.voucher.status}</td>
                    </tr>

                    </tbody>


                </table>

                <div className="mt-5 mt-4 flex w-full justify-end">

                    <button
                        type="button"
                        className="btn-outline"
                        onClick={() => props.setCustomModalOpen(false)}
                    >
                        Close
                    </button>

                </div>

            </div>
        );

        props.showCustomModal(content);
    }

    const renderAwards = () => {

        if (props.settlements.filter(settlement => settlement.method === 'AWARD').length <= 0) return <></>;

        const currencySymbol = props.claim && props.claim.organisation ? props.claim.organisation.info.currency_symbol : ' ';

        function awardedValue(settlement) {

            let excess = settlement.excess || 0;
            let value = settlement.value || 0;

            try {
                let val = value - excess;
                return props.currencyChange(val);
            } catch (e) {
                return '-'
            }
        }

        function settlementDatetimeHumanReadable(settlement) {

            const localDateCreatedMoment = localMomentInstanceFromUTC(settlement.date_created);

            return (
                <div className='pl-4'>
                    <p>{moment(localDateCreatedMoment).format('YYYY-MM-DD')}</p>
                    <p>{moment(localDateCreatedMoment).format('HH:mm:00 z')}</p>
                </div>
            );
        }

        return (
            <div className='px-8'>

                <h1 className="ml-5 font-light text-gray-500 mt-10">
                    Awards
                </h1>

                <table className="p-8">

                    <thead>
                    <tr>
                        <th>Date and Time</th>
                        <th>Supplier</th>
                        <th>Items</th>
                        <th>Value</th>
                        <th className='w-[20rem]'>Status</th>
                        <th className='w-[30rem]'>Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    {props.settlements.filter(i => i.method === 'AWARD')
                        .map((settlement) => {

                            if (settlement.items) {
                                settlement.items.forEach(_itemID => {
                                    _itemID.details = props.claim.items.find(item => item.id === _itemID.id);
                                });
                            }

                            return (
                                <tr key={settlement.id}>

                                    {/* DATETIME */}
                                    <td>
                                        {settlementDatetimeHumanReadable(settlement)}
                                    </td>

                                    {/* SUPPLIER */}
                                    <td>
                                        {settlement.organisation_supplier.info.name}
                                    </td>

                                    {/* ITEMS */}
                                    <td>
                                        {settlement.items?.map((item, index) => (
                                                item && item.details ?
                                                    <p key={settlement.id + item.id}>
                                                        {index + 1}. {item.details.category.display_name} - {item.details.product.common_name}
                                                    </p>
                                                    :
                                                    ' '
                                            )
                                        )}
                                    </td>

                                    {/* VALUE */}
                                    <td>
                                        <p>
                                            <span>Total value: </span>{currencySymbol} {props.currencyChange(settlement.value)}
                                        </p>
                                        <p>
                                            <span>Excess: </span>{currencySymbol} {props.currencyChange(settlement.excess)}
                                        </p>
                                        <p>
                                            <b>Awarded value: </b>{currencySymbol} {awardedValue(settlement)}
                                        </p>

                                    </td>

                                    {/* STATUS */}
                                    <td>
                                            <span
                                                className="bg-blue-800 text-white inline-flex justify-center items-center px-2.5 py-0.5 rounded-full font-medium capitalize">
                                                {settlement.status.replaceAll('_', ' ').toLowerCase()}
                                            </span>
                                    </td>

                                    {/* ACTIONS */}
                                    <td>

                                        {'ROOT ADMINISTRATOR'.includes(props.mainOrganisation.type) &&
                                            <>
                                                <button className='btn-outline-danger px-3'
                                                        onClick={() => onDeleteSettlement(settlement.id)}>
                                                    <TrashIcon className="h-5 w-5 mr-2"/>
                                                    Delete
                                                </button>
                                                <button className='btn-outline px-3'
                                                        onClick={() => onVerifySettlement(settlement.id, 'AWARD')}>
                                                    <CheckIcon className="h-5 w-5 mr-2"/>
                                                    Verify
                                                </button>
                                                <button className='btn-outline px-3'
                                                        onClick={() => onViewBeneficiaryDetails(settlement.id)}>
                                                    <SearchIcon className="h-5 w-5 mr-2"/>
                                                    View
                                                </button>
                                            </>
                                        }

                                        {/* Suppliers can view settlements that were awarded to them */}
                                        {'SUPPLIER'.includes(props.mainOrganisation.type) && settlement.organisation_supplier.id === props.mainOrganisation.id &&
                                            <button className='btn-outline px-3'
                                                    onClick={() => onViewBeneficiaryDetails(settlement.id)}>
                                                <SearchIcon className="h-5 w-5 mr-2"/>
                                                View
                                            </button>
                                        }

                                    </td>

                                </tr>
                            );
                        })}
                    </tbody>

                </table>

            </div>
        );
    }

    const renderVouchers = () => {

        if (props.settlements.filter(settlement => settlement.method === 'VOUCHER').length <= 0) return <></>;


        const renderSettlementTable = (settlement) => {
            return (
                <tr key={settlement.id}>

                    {/* SUPPLIER */}
                    <td>
                        {settlement.organisation_supplier.info.name}
                    </td>

                    {/* VALUE */}
                    <td>
                        {props.claim && props.claim.organisation ? props.claim.organisation.info.currency_symbol : ' '} {props.currencyChange(settlement.value)}
                    </td>

                    {/* EXCESS */}
                    <td>
                        {props.claim && props.claim.organisation ? props.claim.organisation.info.currency_symbol : ' '} {props.currencyChange(settlement.excess)}
                    </td>

                    {/* TOTAL VALUE */}
                    <td>
                        {props.claim && props.claim.organisation ? props.claim.organisation.info.currency_symbol : ' '} {(parseFloat(settlement.value) - parseFloat(settlement.excess)).toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </td>

                    {/* STATUS */}
                    <td>
                        <span
                            className="bg-blue-800 text-white inline-flex justify-center items-center px-2.5 py-0.5 rounded-full font-medium capitalize"
                        >
                          {settlement.status.replaceAll('_', ' ').toLowerCase()}
                        </span>
                    </td>

                    {/* ACTIONS */}
                    <td>
                        {'ROOT ADMINISTRATOR'.includes(props.mainOrganisation.type) &&
                            <>
                                <button className='btn-outline-danger px-3'
                                        onClick={() => onDeleteSettlement(settlement.id)}>
                                    <TrashIcon className="h-5 w-5 mr-2"/>
                                    Delete
                                </button>
                                <button className='btn-outline px-3'
                                        onClick={() => onVerifySettlement(settlement.id, 'VOUCHER')}>
                                    <CheckIcon className="h-5 w-5 mr-2"/>
                                    Verify
                                </button>
                                <button className='btn-outline px-3'
                                        onClick={() => onViewBeneficiaryDetails(settlement.id)}>
                                    <SearchIcon className="h-5 w-5 mr-2"/>
                                    View
                                </button>
                            </>
                        }

                        {/* Suppliers can view settlements that were awarded to them */}
                        {'SUPPLIER'.includes(props.mainOrganisation.type) && settlement.organisation_supplier.id === props.mainOrganisation.id &&
                            <button className='btn-outline px-3'
                                    onClick={() => onViewBeneficiaryDetails(settlement.id)}>
                                <SearchIcon className="h-5 w-5 mr-2"/>
                                View
                            </button>
                        }

                    </td>
                </tr>
            );
        }

        return (
            <div className='px-8'>

                <h1 className="ml-5 mt-10 font-light text-gray-500">
                    Vouchers
                </h1>

                <table>

                    <thead>
                    <tr>
                        <th>Supplier</th>
                        <th>Value</th>
                        <th>Excess</th>
                        <th>Total Value</th>
                        <th className='w-[20rem]'>Status</th>
                        <th className='w-[30rem]'>Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    {props.settlements.filter(i => i.method === 'VOUCHER')
                        .map((settlement) => renderSettlementTable(settlement))
                    }
                    </tbody>

                </table>

            </div>
        );
    }

    const renderSettlements = () => {

        if (!props.claim || !props.settlements || !props.mainOrganisation) return <></>;

        if (isEmpty(props.settlements)) {
            return (
                <section aria-labelledby="summary-heading" className="mt-14 widget">

                    <h2 id="summary-heading" className="font-medium ml-3 mb-3 text-2xl">
                        Settlements
                    </h2>

                    <div className="p-6 italic text-gray-400">
                        No settlements found
                    </div>

                </section>
            );
        }


        return (
            <section className="mt-10 widget">
                <h2 className="ml-3 mb-3 text-2xl">Settlements</h2>

                {renderAwards()}

                {renderVouchers()}

            </section>
        );
    };

    return renderSettlements();

}

function ButtonSection(props) {

    if (!props.mainOrganisation) return <></>;
    if (props.mainOrganisation.type === 'SUPPLIER') return <></>;

    return (
        <section className="mt-14 flex justify-end gap-6">

            {/* VOUCHER */}
            <button
                onClick={() => props.navigate(`/claim/issue-voucher/${props.claimId}`)}
                className={classNames(
                    "btn w-1/4 py-5 justify-center font-medium",
                    'ROOT ADMINISTRATOR'.includes(props.mainOrganisation.type) ? '' : 'btn-disabled border-0 !w-full' // Only expose voucher button to root and admins
                )}

                disabled={'ROOT ADMINISTRATOR'.includes(props.mainOrganisation.type) ? false : true}
            >
                Voucher
            </button>

            {/* AWARD */}
            <button
                onClick={() => props.navigate(`/claim/issue-award/${props.claimId}`)}
                className="btn w-1/4 py-5 justify-center font-medium"
            >
                Award
            </button>

        </section>
    );
}

function Items(props) {

    const productsAndReplacements = () => {

        if (!props.claim) {
            return <LoadingSpinner/>;
        }

        // TODO what is this for? perhaps it is for the dev placeholder item status below
        // for (let item of props.claim.items) {
        //     if (item['status'] in displayStatuses) {
        //         item['display_status'] = displayStatuses[item['status']];
        //         item['status_step'] = statusSteps[item['display_status']];
        //     } else {
        //         item['display_status'] = '';
        //         item['status_step'] = 1;
        //     }
        // }

        // let item = props.claim.items[0];

        return (
            <div className="space-y-8">

                {props.claim.items.map(renderProduct)}


                {/* TEST CODE TO FORCE DIFFERENT ITEM STATES */}
                {/*{(() => {*/}
                {/*    let clone = structuredClone(item);*/}
                {/*    clone.status = 'CALCULATION_IN_PROGRESS';*/}
                {/*    return renderProduct(clone);*/}
                {/*})()}*/}


                {/*{(() => {*/}
                {/*    let clone = structuredClone(item);*/}
                {/*    clone.status = 'VERIFICATION';*/}
                {/*    return renderProduct(clone);*/}
                {/*})()}*/}


                {/*{(() => {*/}
                {/*    let clone = structuredClone(item);*/}
                {/*    clone.status = 'CANNOT_BE_VERIFIED';*/}
                {/*    return renderProduct(clone);*/}
                {/*})()}*/}


                {/*{(() => {*/}
                {/*    let clone = structuredClone(item);*/}
                {/*    clone.status = 'NEW';*/}
                {/*    return renderProduct(clone);*/}
                {/*})()}*/}


                {/*{(() => {*/}
                {/*    let clone = structuredClone(item);*/}
                {/*    clone.status = 'TENDERING';*/}
                {/*    return renderProduct(clone);*/}
                {/*})()}*/}


                {/*{(() => {*/}
                {/*    let clone = structuredClone(item);*/}
                {/*    clone.status = 'DELETED';*/}
                {/*    clone.display_status = 'Deleted';*/}
                {/*    return renderProduct(clone);*/}
                {/*})()}*/}


                {/*{(() => {*/}
                {/*    let clone = structuredClone(item);*/}
                {/*    clone.status = 'EXPIRED';*/}
                {/*    clone.display_status = 'Expired';*/}
                {/*    return renderProduct(clone);*/}
                {/*})()}*/}


                {/*{(() => {*/}
                {/*    let clone = structuredClone(item);*/}
                {/*    clone.status = 'UNABLE_TO_TENDER';*/}
                {/*    clone.display_status = 'Unable to tender';*/}
                {/*    return renderProduct(clone);*/}
                {/*})()}*/}

                {/*{(() => {*/}
                {/*    let clone = structuredClone(item);*/}
                {/*    clone.status = 'POST_TENDER_INSPECTION';*/}
                {/*    return renderProduct(clone);*/}
                {/*})()}*/}


                {/*{(() => {*/}
                {/*    let clone = structuredClone(item);*/}
                {/*    clone.status = 'QUANTIFIED';*/}
                {/*    return renderProduct(clone);*/}
                {/*})()}*/}


            </div>
        );
    };

    const renderProduct = (item) => {

        let imageUrl = 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png';
        let imageClassNames = 'object-contain';

        if (item.product.images !== null && item.product.images.thumbnail_web !== null) {
            imageUrl = item.product.images.thumbnail_web;
            imageClassNames = 'object-cover';
        }

        return (
            <div
                key={item.id}
                className="bg-white border-b-4 border-gray-200 rounded-lg py-6 px-4 "
            >
                <div className="flex pb-4">

                    {/* REQUESTED PRODUCT */}
                    <div className="flex justify-center items-center w-full">
                        <Safe>
                            <Item_RequestedProduct item={item}
                                                   imageUrl={imageUrl}
                                                   imageClassNames={imageClassNames}
                                                   verifiedByUsers={props.verifiedByUsers}
                            />
                        </Safe>
                    </div>

                    {/* > */}
                    <div className="flex justify-center items-center w-1/4 lg:w-fit">

                        <button className="btn-light p-1 tailwind-tooltip-container flex items-center justify-center"
                                onClick={() => {
                                    props.showProductInfoModal(item);
                                }}
                        >
                            {/* If replacement is the same as product, show equals icon */}
                            {item.replacement && item.replacement.product.id === item.product.id ? (
                                /* Makeshift = equals icon */
                                <div className='h-14 w-14 py-3'>
                                    <p className='text-[5rem] text-gray-300 text-center'>=</p>
                                </div>
                            ) : (
                                <ChevronRightIcon
                                    className="h-14 w-14 text-gray-300 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                            )}
                            <span className="tailwind-tooltip -top-[5rem]">
                                Click to view the <b>requested</b> product, or compare to the <b>replacement</b> product
                            </span>
                        </button>

                    </div>

                    {/* REPLACEMENT PRODUCT/QUOTE*/}
                    <div className="flex justify-center items-center w-full">
                        <Safe> <Item_ReplacementProductAndQuote item={item} {...props} /> </Safe>
                    </div>

                </div>

                {/* PROGRES BAR */}
                <div className="border-t border-gray-200 px-4">
                    <Safe> <ItemStatusFlow item={item}/> </Safe>
                </div>

            </div>);
    };

    function Item_RequestedProduct(props) {


        function getVerifiedBy() {

            const userId = props.item.product?.metadata?.verified_by;

            if (!userId) return <p></p>;

            let userData = props.verifiedByUsers.find(user => user.id === userId);

            if (userData) {
                return <p className="text-xs text-gray-500 mt-2">Verified by: {userData.info.full_name}</p>;
            } else {
                return <p className="text-xs text-gray-500 mt-2">Verified by: {userId}</p>;
            }

        }

        let item = props.item;
        let imageUrl = props.imageUrl;
        let imageClassNames = props.imageClassNames;
        return (
            <div className="sm:flex lg:col-span-5">
                <div className="flex-shrink-0 w-60 h-45 rounded-lg overflow-hidden">
                    <BasicImage
                        src={imageUrl}
                        fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                        alt="Product Image"
                        sizeWidthRem="16"
                        sizeHeightRem={16 * 0.75} // 4:3 aspect ratio
                        className="rounded-md"
                    />
                </div>

                <div className="mt-6 sm:mt-0 sm:ml-6">

                    <div className="flex justify-between">
                        <h3 className="font-medium text-lg hover:text-gray-700">
                            {item.is_manual ? item.product.brand || item.product.model_numbers[0] : item.product.common_name}
                        </h3>
                    </div>

                    <div className="mt-1 flex text-sm">
                        <p className="text-md text-gray-800">
                            {item.is_manual ? `${item.product.brand} | ${item.product.description}` : calculateProperties(item.product)}
                        </p>
                    </div>
                    <div className="mt-1 text-sm font-medium text-gray-900">
                        {item.product.model_number !== null ? (
                            <p className="text-sm text-gray-500">{item.product.model_number}</p>
                        ) : (
                            <p className="text-sm text-gray-500">
                                {item.product.model_numbers?.slice(0, 3).join(' | ') || ''}
                            </p>
                        )}
                        {item.is_manual ? `${formatAsTitle(item.category.category_a)} >  ${formatAsTitle(item.product.category)}` : ''}

                        {getVerifiedBy()}
                    </div>

                    <p className="mt-2 text-sm font-medium text-gray-900">{item.product.type === 'MANUAL' ? item.product.description : calculateProperties(item.product)}</p>

                    {/* MANUAL TAG */}
                    {item.is_manual &&
                        <div className="mt-2 flex text-sm">
                            <span
                                className="manual-item-tag">Manual item</span>
                        </div>
                    }
                </div>
            </div>
        );
    }


    return (
        <section aria-labelledby="products-heading" className="mt-10 widget">
            <div className="flex items-baseline justify-between">
                <h2 id="summary-heading" className="font-medium ml-3 mb-3 text-2xl">Products</h2>
            </div>
            {productsAndReplacements()}
        </section>
    );
}

function ItemStatusFlow({item}) {

    let display_status = displayStatuses[item['status']]
    let status_step = statusSteps[display_status]

    let secondStatus = '...';
    let progress = '20%';
    let gridCols = 'grid-cols-3';

    if (status_step > 0) {

        if (item.is_tender_item) {
            secondStatus = 'Tendering';
            gridCols = 'grid-cols-4';

            if (item.status === 'TENDERING') {
                progress = '38%';
            } else if (item.status === 'POST_TENDER_INSPECTION') {
                progress = '63%';
            }

        } else if (item.is_manual) {
            secondStatus = 'Verification';
            progress = '50%';
        } else {
            secondStatus = '';
        }
    }

    if (status_step === 3) {
        progress = '100%';
    }

    let statusTextColor = 'text-gray-800';
    let statusBarColor = 'bg-sky-600';

    if (item.status === 'CANNOT_BE_VERIFIED' || item.status === 'EXPIRED' || item.status === 'DELETED' || item.status === 'UNABLE_TO_TENDER') {
        statusTextColor = 'text-rose-500';
        statusBarColor = 'bg-rose-400';
    }

    return (<div className="mt-6" aria-hidden="true">
        <div className="bg-gray-200 rounded-full overflow-hidden">
            <div
                className={classNames(statusBarColor, 'h-2 rounded-full')}
                style={{width: progress}}
            />
        </div>
        <div className={classNames(gridCols, 'font-light hidden sm:grid text-sm font-medium text-gray-600 mt-6')}>
            <div className={statusTextColor}>
                Calculating
            </div>

            <div className={classNames(status_step > 0 ? statusTextColor : '', 'text-center')}>
                {secondStatus}
            </div>

            {status_step > 0 && item.is_tender_item && (
                <div className={classNames(status_step > 1 ? statusTextColor : '', 'text-center')}>
                    Post tender inspection
                </div>)}

            <div className={classNames(status_step > 2 ? statusTextColor : '', 'text-right')}>
                {status_step > 2 ? display_status : 'Quantified'}
            </div>
        </div>
    </div>);
}

function Item_ReplacementProductAndQuote(props) {

    if (!props.claim) return <></>;

    const hasItemsInVerification = () => {

        if (!props.claim) {
            return false;
        }

        for (let item of props.claim.items) {
            if (item.status === 'VERIFICATION') {
                return true;
            }
        }

        return false;
    };

    let item = props.item;

    // Use a separate variable so that it can be modified
    let itemStatus = item.status;

    let replacement = item.replacement;
    if (props.mainOrganisation?.type === 'SUPPLIER') {
        // the replacement is only shown if the item is quantified/post tender
        // if the claim is quantified, the replacement is shown
        // The supplier does not see the item.replacement
        // instead the supplier sees their own quoted replacement in item.supplier_panel[at the supplier].recommended_replacement
        // IF THEY HAVE - SHOW THAT QUOTE AS THE REPLACEMENT
        // IF THEY HAVE NOT - SHOW "declined to tender"
        let supplier = item.supplier_panel?.find(supplier => supplier.id === props.mainOrganisation.id) || null;
        replacement = supplier?.recommended_replacement || null;
        if (supplier?.status === 'DECLINED_TENDER') {
            itemStatus = 'UNABLE_TO_TENDER';
        }
        if (supplier?.status === 'UNABLE_TO_TENDER') {
            itemStatus = 'UNABLE_TO_TENDER';
        }
        if (supplier?.status === 'TENDER_DELETED') {
            itemStatus = 'DELETED';
        }


    }

    let replacementProduct = replacement?.product || null;
    let replacementQuote = replacement?.quote || null;


    let claimHasItemsInVerification = hasItemsInVerification();

    const replacementProductCard_calculationInProgress = () => {
        // calculation in progress
        // - CALCULATION_IN_PROGRESS
        return (
            <div className="flex justify-center items-center w-full">
                <LoadingSpinner size="14" text={'ml-20 text-2xl '} color={'gray'} body={'Calculating'}/>
            </div>
        );
    };

    const replacementProductCard_verification = () => {
        // - VERIFICATION
        return (
            <div className="w-full flex justify-center items-center gap-16 mx-8">

                {/* IMAGE */}
                {/*<img src='' />*/}
                <SearchIcon className="h-24 w-24 text-gray-400"/>

                {/* TEXT */}
                <div className="space-y-2 w-1/2">
                    <h1 className="text-lg font-bold">Verification</h1>
                    <p>This item is undergoing verification</p>

                    <Link className={classNames('btn mx-0 px-6 py-2',
                        'ROOT ADMINISTRATOR'.includes(props.mainOrganisation?.type) ? '' : 'hidden' // Only show this button if the user/org is an admin or supplier
                    )}
                          to={`/claim/verification/${props.claim.id}`}
                    >
                        <PencilAltIcon className="h-5 w-5 mr-2"/>
                        Verify
                    </Link>


                </div>

            </div>
        );
    };

    const replacementProductCard_CannotBeVerified = () => {
        return (
            <div className="w-full flex justify-center items-center gap-16 mx-8">

                {/* IMAGE */}
                {/*<img src='' />*/}
                <BanIcon className="h-24 w-24 text-red-400 "/>
                {/* TEXT */}
                <div className="space-y-2 w-1/2">
                    <h1 className="text-lg font-bold">Verification</h1>
                    <p>This item could not be verified</p>

                </div>

            </div>
        );
    };

    const replacementProductCard_tender = () => {

        // 1. Ready to tender (other items in verification)
        // 2. Tender (no other items in verification)
        // - NEW
        // - TENDERING

        // READY TO TENDER - AWAITING VERIFICATION
        // if(claimHasItemsInVerification) {
        if (claimHasItemsInVerification || itemStatus === 'NEW') { // TOOD remove this
            return (
                <div className="w-full flex justify-center items-center gap-16 mx-8">

                    {/* IMAGE */}
                    {/*<img src='' />*/}
                    <SearchIcon className="h-24 w-24 text-gray-200"/>

                    {/* TEXT */}
                    <div className="space-y-2 w-1/2">
                        <h1 className="text-lg font-bold">Ready to tender</h1>
                        <p>Awaiting the verification of all items</p>

                    </div>

                </div>
            );
        }


        // TENDER
        return (
            <div className="flex flex-col p-4 gap-10">
                <div className="w-full flex justify-center items-center gap-16 mx-8">

                    {/* IMAGE */}
                    {/*<img src='' />*/}
                    <MenuAlt2Icon className="h-24 w-24 text-gray-400"/>

                    {/* TEXT */}
                    <div className="space-y-2 w-1/2">
                        <h1 className="text-lg font-bold">Tendering</h1>
                        <p>This item is in tender</p>
                        <p>Potential replacements: {item.possible_replacements?.length || '-'}</p>

                        <Link className={classNames('btn mx-0 px-6 py-2',
                            'ROOT ADMINISTRATOR SUPPLIER'.includes(props.mainOrganisation?.type) ? '' : 'hidden' // Only show this button if the user/org is an admin or supplier
                        )}
                              to={`/claim/tender/${props.claim.id}`}
                        >
                            <PencilAltIcon className="h-5 w-5 mr-2"/>
                            Tender
                        </Link>

                    </div>


                </div>

                {/* If this supplier/organisation user has quoted on this item, show their quote/product */}
                {replacementProduct && replacementProductCard_recommendedReplacement()}

            </div>

        );
    };

    const replacementProductCard_unableToTender = () => {
        // unable to tender
        // declined to tender
        // - UNABLE_TO_TENDER
        // - DELETED
        // - EXPIRED

        if (itemStatus === 'UNABLE_TO_TENDER') {
            return (
                <div className="w-full flex justify-center items-center gap-16 mx-8">

                    {/* IMAGE */}
                    <BanIcon className="h-24 w-24 text-rose-400"/>

                    {/* TEXT */}
                    <div className="space-y-2 w-1/2">
                        <h1 className="text-xl mb-3 font-bold">{item.display_status}</h1>
                        <p>This item was marked as <i>"Unable to tender"</i></p>
                        <p>No further action will be taken for this item</p>
                        <p><b>Reason: </b>{item.status_reason}</p>
                    </div>

                </div>
            );
        }


        if (itemStatus === 'EXPIRED') {
            return (
                <div className="w-full flex justify-center items-center gap-16 mx-8">

                    {/* IMAGE */}
                    <BanIcon className="h-24 w-24 text-rose-400"/>

                    {/* TEXT */}
                    <div className="space-y-2 w-1/2">
                        <h1 className="text-xl mb-3 font-bold">{item.display_status}</h1>
                        <p>This item has expired</p>
                        <p>No further action will be taken for this item</p>
                    </div>

                </div>
            );
        }

        if (itemStatus === 'DELETED') {
            return (
                <div className="w-full flex justify-center items-center gap-16 mx-8">

                    {/* IMAGE */}
                    <BanIcon className="h-24 w-24 text-rose-400"/>

                    {/* TEXT */}
                    <div className="space-y-2 w-1/2">
                        <h1 className="text-xl mb-3 font-bold">{item.display_status}</h1>
                        <p>This item has been deleted</p>
                        <p>No further action will be taken for this item</p>
                    </div>

                </div>
            );
        }

    };

    const replacementProductCard_postTender = () => {
        // - POST_TENDER_INSPECTION

        return (
            <div className="overflow-scroll scrollbar-hide">
                {replacementProductCard_recommendedReplacement()}

                {/* POST TENDER BUTTON */}
                <div className={classNames('flex justify-end',
                    'ROOT ADMINISTRATOR'.includes(props.mainOrganisation?.type) ? '' : 'hidden' // Only show this button if the user/org is an admin
                )}
                >
                    <div className="w-[55%]">
                        <Link className="btn ml-4 py-2"
                              to={`/claim/post-tender/${props.claim.id}`}
                        >
                            <SearchIcon className="h-5 w-5 mr-2"/>
                            Post tender inspection
                        </Link>
                    </div>

                </div>


            </div>

        );
    };

    const replacementProductCard_recommendedReplacement = () => {

        if (!replacementProduct) return <i>No catalog pricing available</i>;

        let supplierImageUrl = null;
        if (replacementQuote !== null) {
            let name = replacementQuote.supplier.unique_reference_name.replaceAll('.', '-')
                .replaceAll('_', '-');
            supplierImageUrl = `https://slvrcld-images.s3.eu-west-1.amazonaws.com/organisations/${name}.png`;
        }

        return (
            <div className="w-full flex justify-center items-center gap-16 overflow-scroll scrollbar-hide">

                {/* IMAGE */}
                <BasicImage
                    src={replacement?.product?.images?.thumbnail_web}
                    fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                    alt="Product Image"
                    sizeWidthRem="16"
                    sizeHeightRem={16 * 0.75} // 4:3 aspect ratio
                    className="rounded-md"
                />

                {/* TEXT */}
                <div className="space-y-2">

                    {/* RECOMMENDED REPLACEMENT */}
                    <h1 className="text-lg font-bold">{replacementProduct.common_name}</h1>

                    {/* MANUAL TAG */}
                    {replacementProduct.type === 'MANUAL' &&
                        (<span
                            className="inline-flex items-center ml-2 px-2 py-0.5 rounded-full text-sm font-medium bg-blue-600 text-white">M</span>)}

                    {/* CATEGORY */}
                    <p className="text-sm text-gray-600">{item.category.display_name}</p>

                    {/* MODAL NUMBER */}
                    {replacementProduct.type === 'CATALOGUE' && replacementProduct.model_number !== null && (
                        <p className="text-sm text-gray-500">{replacementProduct.model_number}</p>)}
                    {replacementProduct.type === 'CATALOGUE' && replacementProduct.model_number === null && (
                        <p className="text-sm text-gray-500">{replacementProduct.model_numbers.slice(0, 3).join(' | ')}</p>)}


                    {/* DESCRIPTION */}
                    <p className="text-sm font-medium text-gray-900">
                        {replacementProduct.type === 'MANUAL' ? replacementProduct.description : calculateProperties(replacementProduct)}
                    </p>

                    {/* QUOTE PRICE */}
                    {replacement.quote !== null &&

                        <div className="flex items-center pt-2 gap-6">

                            {/* PRICE */}
                            <p className="text-lg font-bold text-gray-900">
                                {(props.claim?.organisation?.info ? props.claim.organisation.info.currency_symbol : ' ') + ' ' + parseFloat(replacement.quote.price)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </p>

                            <div className="flex items-center">

                                {/* SUPPLIER IMAGE */}
                                <BasicImage
                                    src={supplierImageUrl}
                                    fallbackSrc={'/org-logo-placeholder.jpg'}
                                    alt="Supplier logo"
                                    sizeWidthRem="2"
                                    sizeHeightRem="2"
                                    className="flex-shrink-0 rounded-full"
                                />

                                {/* SUPPLIER NAME*/}
                                <span className="ml-2">{replacementQuote.supplier.display_name}</span>
                            </div>

                        </div>

                    }

                </div>

            </div>
        );

    };


    // Return a different card depending on the status of the item
    if (itemStatus === 'CALCULATION_IN_PROGRESS') return replacementProductCard_calculationInProgress();
    if (itemStatus === 'VERIFICATION') return replacementProductCard_verification();
    if (itemStatus === 'CANNOT_BE_VERIFIED') return replacementProductCard_CannotBeVerified();
    if (itemStatus === 'NEW') return replacementProductCard_tender();
    if (itemStatus === 'TENDERING') return replacementProductCard_tender();
    if (itemStatus === 'UNABLE_TO_TENDER' || itemStatus === 'DELETED' || itemStatus === 'EXPIRED') return replacementProductCard_unableToTender();
    if (itemStatus === 'POST_TENDER_INSPECTION') return replacementProductCard_postTender();
    if (itemStatus === 'QUANTIFIED') return replacementProductCard_recommendedReplacement();

}

import React, {useRef} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {queryCurrentUser} from "./utils/coreApi";


/** This component enforces organisation type and user role access to different pages **/

export default function AccessControl (props) {

    const navigate = useNavigate();

    function checkPermissions(){

        // The user's organisation must be loaded before we can check permissions
        if(!props.mainOrganisation) return true;

        if(props.organisationTypes === 'ADMIN_UP'){
            if('ROOT ADMINISTRATOR'.includes(props.mainOrganisation.type)) return true;
            else return false;
        }
        if(props.organisationTypes === 'INSURER_UP'){
            if('ROOT ADMINISTRATOR INSURER'.includes(props.mainOrganisation.type)) return true;
            else return false;
        }
        if(props.organisationTypes === 'SUPPLIER_UP'){
            if('ROOT ADMINISTRATOR INSURER SUPPLIER'.includes(props.mainOrganisation.type)) return true;
            else return false;
        }

        if(props.organisationTypes === '') return true

        // if no conditions are met, redirect to home page
        console.log('AccessControl: Failed to check, redirecting to home page');
        navigate('/');

    }

    if(checkPermissions()){
        return props.children
    }
    else{
        console.error('AccessControl: User does not have permission to view this page');
        return <></>;
    }

}


// Library imports
import React, {Fragment, useEffect, useRef, useState} from 'react'
import {useNavigate, useSearchParams, useParams, Link, createSearchParams, useLocation} from 'react-router-dom';
import {Dialog, Disclosure, Transition} from '@headlessui/react'

import {
    RefreshIcon,
    SearchIcon,
    XIcon,
    ClipboardListIcon,
    DuplicateIcon,
    ExternalLinkIcon,
    TrashIcon,
    DocumentSearchIcon,
    PaperClipIcon,
    CloudUploadIcon, BanIcon, InformationCircleIcon, UploadIcon, ArrowNarrowRightIcon, ArrowRightIcon
} from '@heroicons/react/outline';

import {
    ArrowCircleLeftIcon, ChevronDownIcon, ChevronRightIcon,
    MinusSmIcon,
    PlusCircleIcon,
    PlusSmIcon,
    SparklesIcon
} from '@heroicons/react/solid';

// Local imports
import {
    addCatalogueProductToClaim,
    addManualProductToClaim,
    addReplacement,
    convertManualItemToCatalogueItem,
    createManualReplacement,
    fetchClaim,
    filterSearch,
    addFilterBasedItem,
    addAiBasedItem,
    generateAiProto,
    uploadFile,
    customGraphRequest
} from '../../utils/coreApi.js';

import {
    calculateProperties,
    classNames,
    compareDicts,
    currencySymbol,
    escapeDoubleQuotes,
    formatAsTitle,
    isEmpty, subArray
} from '../../utils/helpers.js';

import ConfirmModal from '../modals/ConfirmModal.jsx';
import NotificationCenter from '../../components/NotificationCenter.jsx';
import NotificationTopRight from '../../components/NotificationTopRight.jsx';
import QRCode from "react-qr-code";
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import Safe, {safe} from "../../components/Safe";
import MultiProductModal from "../modals/MultiProductModal";
import {useCookies} from "react-cookie";
import {useDocumentTitle} from "../../components/PageTitle";
import {getCache, hasCache, setCache, useQuery} from "../../components/useQuery";
import {BasicImage} from "../../components/base/BasicImage";
import ApiButton from "../../components/base/ApiButton";
import Tooltip from "../../components/Tooltip";
import {FILTER_SEARCH} from "../../utils/graphqlTemplates";
import {ImageZoom} from "../../components/base/ImageZoom";
import {placeholderParseAttachmentResponse} from "../../utils/developmentData";

export default function ClaimItemSearch(props) {

    const setTitle = useDocumentTitle("Item Search");

    // React router
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookie] = useCookies(['unsubmittedClaimHasBeenWarned']);

    // Parameters from the URL
    const [searchParams] = useSearchParams();

    // Parameters from the ReactRouter path. itemId is only used for convert and replacement modes
    const urlParams = useParams();
    const [claimId, setClaimId] = useState();
    const [itemId, setItemId] = useState();

    // Modal states
    const [productInfoModalOpen, setProductInfoModalOpen] = useState(false);
    const [productInfoModalOptions, setProductInfoModalOptions] = useState({
        "product": null,
        "onProductAdded": null,
    });

    // TODO - refactor this to use the base implementation
    const [itemNotificationOpen, setItemNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState({
        "heading": "Item added!",
        "message": "Successfully added item to claim."
    });

    // TODO - refactor this to use the base implementation
    const [notificationCenterOpen, setNotificationCenterOpen] = useState(false);
    const [notificationCenterOptions, setNotificationCenterOptions] = useState({
        "heading": "Item converted!",
        "message": "Successfully converted item. Redirecting to next page..."
    });

    const [claimNotSubmittedWarningOpen, setClaimNotSubmittedWarningOpen] = useState(false);

    const [showSnappyClaimsModal, setShowSnappyClaimsModal] = useState(false);

    const [showProductsFromAttachmentModal, setShowProductsFromAttachmentModal] = useState(false);
    const [parsedAttachmentProducts, setParsedAttachmentProducts] = useState(false);


    // UI States
    const [filterQueryInProgress, setFilterQueryInProgress] = useState(false);
    const [filterItemSearchQueryRequired, setFilterItemSearchQueryRequired] = useState(false);
    const [showExactMatchButton, setShowExactMatchButton] = useState(false);

    // Data states
    // const [selectableCategories, setSelectableCategories] = useState(null);
    const categoriesHook = useQuery({
        queryStringFunction: () => {
            return `
                query itemSearch_categories{
                  categories(sort_by:[{ field:"display_name" order:ASCENDING }]) {
                    error {
                      type
                      message
                    }
                    categories {
                      id
                      category_a
                      category_b
                      category_c
                      category_d
                      display_name
                    }
                  }
                }
            `
        },
        onSuccess: (data) => {
            categoriesHook.setState(data["categories"]);
        },
        cacheExpirationMin: 60 * 4, // 4 hours
        onError: props.onError,
        cacheResponse: true, useExistingCache: true, skipQueryIfCache: true,
    });
    const {state: selectableCategories, setState: setSelectableCategories} = categoriesHook;

    const [selectableFilters, setSelectableFilters] = useState([]); // list of objects


    const [claim, setClaim] = useState(null);
    const [products, setProducts] = useState(null); // the returned proto(including AI) products from graphql

    const [aiProduct, setAiProduct] = useState(null);
    const [aiDataLacking, setAiDataLacking] = useState(true);

    const [brands, setBrands] = useState([]); // The list of brands(categories and families) returned in the filter search
    const [autoSuggest, setAutoSuggest] = useState([]); // The list of auto-suggested strings


    const [queriedFeatures, setQueriedFeatures] = useState([{"category": []}, {"brand": []}, {"spec_item": []}]);

    // User input
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState({
        // 'display': '6.5"' <-- example of a selected filter.
    });
    const [inputSearchText, setInputSearchText] = useState('');


    useEffect(()=> {
        /** This useEffect is only loaded once, when the page has loaded*/

    }, [])

    useEffect(() => {
        /** When the params are modified, update the state */
        if(urlParams.claimId) setClaimId(urlParams.claimId) // is a claim ID in the URL?
        if(urlParams.itemId) setItemId(urlParams.itemId) // is an item ID in the URL?
        if(props.claimId) setClaimId(props.claimId) // was a claim ID passed down via props?
    }, [urlParams, props.claimId]);

    useEffect(() => {

        if (!props.user) {
            // wait for the base states to be loaded
        } else if (!claim) {
            // When all the required states have been received their data, make a filter search query
            if(claimId)
                fetchClaim(claimId, setClaim, null);
        } else if(isEmpty(products)) {
            initializeURLParams();
            // _search()
        }

        if(claim && !props.navbarTopPageContent.claim) props.setNavbarTopPageContent({'claim': claim, 'content': <Safe><SnappyClaimsButton setShowSnappyClaimsModal={setShowSnappyClaimsModal}/></Safe>})

        if(claim) setTitle(`Item Search : ${claim.insurer_claim_number}`)

    }, [props.user, selectableCategories, claim, claimId]);

    useEffect(()=> {
        /** when any of the dependencies/states change, re-query products */

        if(!props.user || !props.mainOrganisation || !claim || !selectableCategories){
            // do not make the initial query unless the required state data has been set
            return;
        }

        setURLParams();
        setFilterItemSearchQueryRequired(true);
    }, [props.user, props.mainOrganisation, claim, selectedCategoryId, selectedFilters])

    useEffect(() => {
        /** If the search text is modified, trigger a filter search */

        if(!inputSearchText) return;

        // Do not trigger on a short search query
        //TODO  if(inputSearchText.length < 3) return;

        // Do not trigger on a space press
        if(inputSearchText[inputSearchText.length - 1] === ' ') return;

        setFilterItemSearchQueryRequired(true);
    }, [inputSearchText]);

    useEffect(() => {

        // If a re-query is not required, then do not search
        if(!filterItemSearchQueryRequired) return;

        // If a filter search is in progress, do not send another query
        // When the current query returns, this useEffect will be re-triggered via the filterQueryInProgress
        if(!filterQueryInProgress){
            console.log('searching')
            setFilterItemSearchQueryRequired(false)
            _search();
        }

    }, [filterQueryInProgress, filterItemSearchQueryRequired])

    function initializeURLParams(){
        // https://ultimatecourses.com/blog/query-strings-search-params-react-router
        let searchParamsObject = Object.fromEntries([...searchParams])

        /** Set the parameters in the URL to the state objects */
        if('query' in searchParamsObject) setInputSearchText(searchParamsObject.query) // The input text search query
        if('category' in searchParamsObject) {
            /** The category URL param is a name e.g "smartphones" */
            let cat = selectableCategories.find(cat => cat.category_d.toUpperCase() === searchParamsObject.category.toUpperCase())
            setSelectedCategoryId(cat.id)
        }

        let filters = {}
        for(const [key, value] of Object.entries(searchParamsObject)){
            if(key === 'category' || key === 'query'){
                // do not add the category or query into the list of selected filters
            } else{
                filters[key] = value
            }
        }
        setSelectedFilters(filters)
    }
    function setURLParams(){
        /** Set the parameters in the URL based on the state objects */
        let newParams = {}

        if(selectedCategoryId) newParams['category'] = selectedCategory().category_d.toLowerCase()
        if(inputSearchText) newParams['query'] = inputSearchText
        for(const [key, value] of Object.entries(selectedFilters)){
            newParams[key] = value
        }

        /** IMPORTANT: Any modification to the path/URL will lose any props passed via useNavigate and accessed via useLocation.state
         * I could not find a way to modify the URL params without causing the useLocation.state to be preserved/not lost
         * So to work around this, we 'navigate' to the same url, with the modified params and simply include the previous state
         * Additionally we use the replace option to prevent multiple URL histories being made
         * The setSearchParams and navigate causes a new URL to be made, and therefor any props passed to this page via navigate(which are tied to a specific URL) will be lost */
        let params = createSearchParams(newParams)
        navigate(`${location.pathname}?${params.toString()}`, {replace:true, state: location.state})
    }

    const updateClaim = () => {
        fetchClaim(claimId, setClaim, navigate);
    };

    const clearFilters = () => {

        setSelectedCategoryId(null)
        setFilterItemSearchQueryRequired(false)
        setFilterQueryInProgress(false)
        setInputSearchText('')
        setSelectableFilters([])
        setSelectedFilters([])
        setProducts(null)
        setURLParams() // clear URL params

    }

    const _search = () => {

        /** Filter Search Query requirements
         * - either the text input query (aka query), or a category(field and filter) must be provided
         * - to search all products, do not supply a category, only text input
         * - to get a specific product, supply only the product id in the text input
         * */


        // Create an object with all the necessary filter search parameters
        /** searchParameters = {
            inputSearchText: '',
            selectedCategory: SMARTPHONES
            selectedCategoryId: '',
            categoryFilters_NameValue: [
                {'name': 'brand', 'value': 'samsung'}
            ]
        } */

        if(isEmpty(selectableCategories)) return;

        // Currently only the default/empty filter search is cached
        let useCache = true;


        // ===== BUILD SEARCH PARAMETERS =====

        let searchParameters = {
            'categoryFilters_NameValue': []
        }

        // text input
        if(inputSearchText) {
            searchParameters['inputSearchText'] = inputSearchText
            useCache = false; // if there is a text input, do not use the cache
        }

        // category
        if(selectedCategoryId) {
            // user has selected a category
            let selectedCategory = selectableCategories.find( (cat) => cat.id === selectedCategoryId);
            searchParameters['selectedCategory'] = selectedCategory.category_d
            searchParameters['selectedCategoryId'] = selectedCategory.id
            searchParameters.categoryFilters_NameValue.push({
                'name': 'category', 'value': selectedCategory.category_d.toLowerCase()
            })
        }
        else {
            // if the user has not selected a category, set smartphones by default
            let smartphones = selectableCategories.find( (cat) => cat.category_d === "SMARTPHONES");

            searchParameters['selectedCategory'] = smartphones.category_d // SMARTPHONES
            searchParameters['selectedCategoryId'] = smartphones.id

            searchParameters.categoryFilters_NameValue.push({
                'name': 'category', 'value': smartphones.category_d.toLowerCase()
            })

            if(inputSearchText){
                // the behavior of "search all categories if no category is selected" requires that smartphones is removed
                // if the user has typed in an input text query
                delete searchParameters['selectedCategory']
                delete searchParameters['selectedCategoryId']

                // remove the filter "category
                searchParameters.categoryFilters_NameValue = searchParameters.categoryFilters_NameValue.filter((obj) => obj.name !== 'category')
            }

        }

        // filters
        for (const [key, value] of Object.entries(selectedFilters)) {
            let filter = {
                'name': key, 'value': value
            }
            searchParameters.categoryFilters_NameValue.push(filter)
            useCache = false; // if there are filters, do not use the cache
        }


        // ===== BUILD QUERY  =====

        let args = ``;

        if (searchParameters.inputSearchText) {
            /** args += ` query:"${escapeDoubleQuotes(searchParameters.inputSearchText.trim())}"`;  */
            args += ` query:"${escapeDoubleQuotes(searchParameters.inputSearchText.trim().replaceAll('"', "''"))}"`; // text input currently doesn't support " double quotes
        }

        if (searchParameters.selectedCategory) args += ` category: ${searchParameters.selectedCategory}`;

        let filters = [];
        if(!searchParameters.categoryFilters_NameValue) searchParameters.categoryFilters_NameValue = [] // replace null with empty array
        for (const filter of searchParameters.categoryFilters_NameValue) {
            filters.push({
                "field": filter.name, "value": filter.value
            })
        }

        // If there are no category filters (brand, display, etc), don't add the filter_query argument
        if(filters){
            let filterStr = JSON.stringify(filters);
            filterStr = filterStr.replaceAll('"field"', "field")
            filterStr = filterStr.replaceAll('"value"', "value")
            args += ` filter_query: ${filterStr}`;
        }

        // the cache key is the query string, without the claim ID
        let keyQuery = FILTER_SEARCH.replace('|placeholder|', args);

        args += ` claim:"${claimId}" `;
        let searchQuery = FILTER_SEARCH.replace('|placeholder|', args);

        let queryIsCached = hasCache(keyQuery);
        if (useCache && queryIsCached) {
            /** This query is cached */
            let data = getCache(keyQuery);
            onFilterQuerySuccess(data.data);
        }

        // Query is not cached, make the query
        else {
            setFilterQueryInProgress(true)

            customGraphRequest(
                searchQuery,
                (data) => {
                    onFilterQuerySuccess(data);
                    cacheFilterSearch(keyQuery, data)
                },
                (error) => props.onError(error)
            );
        }

    };

    const cacheFilterSearch = (keyQuery, data) => {
        // Cache the filter search data
        try {
            setCache(keyQuery, data, 60 * 8);
        } catch (error) {
            // If there is a cache error, clear all caches of filter search
            console.error('Error setting cache of filter search data \n ERR: ', error.name);
            console.log('Clearing all filter search caches')
            Object.keys(localStorage).forEach(key => {
                if (key.includes("filter_search")) {
                    localStorage.removeItem(key);
                    console.count('Filter_search cache cleared')
                }
            });
        }
    }

    const onFilterQuerySuccess = (data) => {

        console.log('Filter search data:', data)

        setBrands(data["brands"])
        setAutoSuggest(data["auto_suggest"])

        setFilterQueryInProgress(false)

        setProducts(data["products"]);

        let newFilters = data["filters"]
        if(!isEmpty(newFilters)) setSelectableFilters(newFilters)

        // We need to synchronize the selected filters to the new list of available filters
        // If the user has selected a filter, that is no longer available, then un-select that filter
        let copy = {...selectedFilters}
        for (const [selectedFilter, selectedValue] of Object.entries(selectedFilters)) {
            /** data["filters"] = [
                 {
                   display_name: 'Brand',
                   name: 'brand',
                   values [{..}, {..}, {count: 20, value: 'samsung'},]
                 },
                 {...}
             ] */

            /** We need to synchronise the selected filters to the newly returned available filters
             * e.g: if a filter has been selected, but is not available in the new list of filters, we must un-select that filter. e.g hd_type: qhd
             * e.g: if a filter value has been selected but that value is not available in the new list of values for that filter, we must un-select that filter. e.g display: 6.5*/

            let filterStillExists = newFilters.find(filter => filter.name === selectedFilter)
            if(!filterStillExists){
                // this filter, e.g "form_factor", is selected, however "form_factor" is not longer a option in the new list of filters
                delete copy[selectedFilters]
            }
            else {
                // check if the selected value, e.g "fold", is still available
                let selectedValueStillExists = filterStillExists.values.find(value => value.value === selectedValue)
                if(!selectedValueStillExists){
                    // this value, e.g "fold" of the filter "form_factor", was selected, however is no longer a selectable filter
                    delete copy[selectedFilter]
                }
            }
        }
        // Make sure the filters are different before setting state, otherwise a state loop will occur
        if(!compareDicts(selectedFilters, copy)) setSelectedFilters(copy)


        /** Behavior - when no category is selected, then the filter search is done on all categories
         * therefor, on these category-less filter searches, the returned available "filters" are main categories
         * in this case, to prevent 2 "Categories" (1 is all, 1 is available to filter by),
         * rename the returned filter "Category" to "Available Categories" */
        let category = data['filters'].find(cat => cat.display_name === 'Category')
        if(category) {
            category.display_name = 'Available Categories'
            setSelectableFilters(data['filters']);
        }

        setAiDataLacking(data['ai_data_lacking'])

        // set ai item data
        if(data['ai_generated']){
            // there should only be 1 product in the returned filter search
            let singleProduct = null
            if(!isEmpty(data['products'])) singleProduct = data['products'][0]

            // there is an issue of an empty product being returned, this is due to the ai generation system
            // validate the product returned
            if(singleProduct && singleProduct.brand && singleProduct.common_name && singleProduct.model_number && singleProduct.category){
                setAiProduct(singleProduct)
            }

            setProducts([]);

        } else {
            setAiProduct(null)
            setAiDataLacking(null)
        }


        // If exact match is false, prompt the "exact match" button
        if(!data['found_exact_matches']) setShowExactMatchButton(true)

    }

    let selectedCategory = () => selectableCategories.find( (cat) => cat.id === selectedCategoryId);

    const allProps = {
        ...props,
        productInfoModalOpen, setProductInfoModalOpen,
        productInfoModalOptions, setProductInfoModalOptions,
        itemNotificationOpen, setItemNotificationOpen,
        notificationMessage, setNotificationMessage,
        notificationCenterOpen, setNotificationCenterOpen,
        notificationCenterOptions, setNotificationCenterOptions,
        claimNotSubmittedWarningOpen, setClaimNotSubmittedWarningOpen,
        showSnappyClaimsModal, setShowSnappyClaimsModal,
        showProductsFromAttachmentModal, setShowProductsFromAttachmentModal,
        parsedAttachmentProducts, setParsedAttachmentProducts,
        filterQueryInProgress, setFilterQueryInProgress,
        filterItemSearchQueryRequired, setFilterItemSearchQueryRequired,
        showExactMatchButton, setShowExactMatchButton,
        selectableCategories, setSelectableCategories,
        selectableFilters, setSelectableFilters,
        claim, setClaim,
        products, setProducts,
        aiProduct, setAiProduct,
        aiDataLacking, setAiDataLacking,
        brands, setBrands,
        autoSuggest, setAutoSuggest,
        queriedFeatures, setQueriedFeatures,
        selectedCategoryId, setSelectedCategoryId,
        selectedFilters, setSelectedFilters,
        inputSearchText, setInputSearchText,
        _search,
        claimId, itemId,
        updateClaim,
        clearFilters,
        selectedCategory,
        navigate,
        location,
        ...location.state,
        ...props,
    };

    return (
        <>
            <NotificationTopRight
                open={itemNotificationOpen}
                setOpen={setItemNotificationOpen}
                message={notificationMessage}
            />

            <NotificationCenter
                open={notificationCenterOpen}
                message={notificationCenterOptions}
            />

            <Safe>
                <SnappyClaimsModal
                    showModal={showSnappyClaimsModal}
                    setShowModal={setShowSnappyClaimsModal}

                    claim={claim}
                />
            </Safe>

            <Safe>
                <ProductsFromAttachmentModal
                    showModal={showProductsFromAttachmentModal}
                    setShowModal={setShowProductsFromAttachmentModal}
                    {...allProps}
                />
            </Safe>

            <Safe>
                <UnsubmittedClaimWarningModal
                    open={claimNotSubmittedWarningOpen}
                    setOpen={setClaimNotSubmittedWarningOpen}
                    claim={claim}
                />
            </Safe>

            <Safe>
                <MultiProductModal
                    {...props}
                    open={productInfoModalOpen}
                    setOpen={setProductInfoModalOpen}
                    options={productInfoModalOptions}
                />
            </Safe>


            {/* HEADER */}
            <div className="">

                {/* SUBMITTED CLAIM TRIGGER STRIP */}
                <div className="absolute z-20 left-0 right-0 top-0 h-[1rem]"
                     onMouseLeave={(e) => {
                         if (claim && e.pageY < 15) {

                             // If there is no 'unsubmittedClaimHasBeenWarned' cookie, then show the warning modal
                             if (!cookies.unsubmittedClaimHasBeenWarned) {

                                 // Set a new cookie to not show the warning modal again, and expire in 30 days
                                 setCookie('unsubmittedClaimHasBeenWarned', true,
                                     {
                                         path: '/', // available to all routes (there are multiple routes for the item search page)
                                         maxAge: 60 * 60 * 24 * 30 // expire in 30 days
                                     }
                                 )

                                 // show the unsubmitted claim warning modal
                                 setClaimNotSubmittedWarningOpen(true)
                             }
                         }
                     }}
                ></div>

                <div className="flex flex-col flex-1">

                    <main id="main" className="px-4">

                        <Safe>
                            <SearchBar
                                mode={props.mode}
                                {...allProps}
                            />
                        </Safe>

                        <div className="pt-4 pb-24 lg:grid lg:grid-cols-3 xl:grid-cols-5">

                            <Safe><Categories {...allProps} /></Safe>

                            <Safe><Products mode={props.mode} {...allProps} /></Safe>

                        </div>
                    </main>

                </div>


            </div>
        </>
    )
}

function SearchBar(props) {

    const [animateCheckoutButton, setAnimateCheckoutButton] = useState(false);
    const [checkoutPreviousItemCount, setCheckoutPreviousItemCount] = useState(0);


    useEffect(() => {

        // if the item count has increased, animate the checkout button
        if (props.claim && props.claim.item_count > 0 && props.claim.item_count > checkoutPreviousItemCount) {
            setCheckoutPreviousItemCount(props.claim.item_count);
            setAnimateCheckoutButton(true);
            setTimeout(() => setAnimateCheckoutButton(false), 500); // Duration of the animation
        }

        // if the item count has decreased, reset the item count
        if(props.claim && props.claim.item_count < checkoutPreviousItemCount) setCheckoutPreviousItemCount(0)

    }, [props.claim]);


    function onAddAttachment(){
        /* Upload the given attachment
        * Call the parsing/ingestion mutation, with the returned file ID */

        /** PLACEHOLDER DEVELOPMENT DATA */
        // props.setParsedAttachmentProducts(placeholderParseAttachmentResponse)
        // props.setShowProductsFromAttachmentModal(true)
        // return

        props.setParsedAttachmentProducts(null)

        function onUploadCatalogSheet (inputElement) {


            let file = inputElement.target.files[0];

            let sizeInMB = file?.size ? file?.size / 1024 / 1024 : 0;
            // fix to 2 decimal places
            sizeInMB = sizeInMB.toFixed(2);

            // Check for file size limit
            if (file.size > 7 * 1024 * 1024) { // 7 MB in bytes
                props.showAlertModal(
                    'info',
                    'File Too Large',
                    <>
                        <p>The chosen file is {sizeInMB}MB, which exceeds the 7MB limit</p>
                        <p>Please compress or upload another smaller file</p>
                    </>
                )

                // clear the input
                inputElement.target.value = '';

                return; // Stop the execution of the function if the file is too big
            }

            // Show spinner
            const fileSelector = document.getElementById('file_selector');
            const loadingSpinner = document.getElementById('file_upload_loading_spinner');
            // hide the filter selector
            fileSelector.style.display = 'none';
            // show the loading spinner
            loadingSpinner.style.display = 'block';

            uploadFile(
                {
                    objectId: props.claim.id,
                    objectType: 'claims',
                    // contentType: 'DATA',
                    fileName: file.name,
                    file: file,
                },
                (data) => {

                    // Hide the "Uploading" spinner
                    const loadingSpinner = document.getElementById('file_upload_loading_spinner');
                    loadingSpinner.style.display = 'none';

                    props.showToastNotificationModal('success', 'File Uploaded', 'The file has been uploaded successfully.');

                    // Parse the attachment
                    onParseAttachment(data);
                }, (e) => {
                    props.onError(e)

                    // clear the input
                    inputElement.target.value = '';
                }

            );

        };

        function onParseAttachment(fileUploadResponse){

            // show the loading spinner
            const loadingSpinner = document.getElementById('parsing_loading_spinner');
            loadingSpinner.style.display = 'block';

            const mutation = `
                mutation parse_claim_attachment{
                  parse_claim_attachment(
                    claim_id:"${props.claimId}"
                    file_id:"${fileUploadResponse.file.id}"
                  ){
                    error{
                      message
                      type
                    }
                    claim{
                      id
                      insurer_claim_number
                      possible_items{
                        claimant_quote
                        quantity
                        brand
                        description
                        model_number
                        proto_products{
                          id
                          common_name
                          ai_generated
                          brand
                          category
                          common_name
                          date_released
                          date_updated
                          dates_released
                          description
                          ean_codes
                          images{
                            main
                            thumbnail_print
                            thumbnail_web
                          }
                          model_number
                          model_numbers
                          properties{
                            name
                            display_name
                            value
                          }
                          type
                          
                        }
                        category{
                          category_d
                          category_a
                        }
                      }
                    }
                  }
                }
            `

            customGraphRequest(
                mutation,
                (data) => {

                    const fileSelector = document.getElementById('file_selector');
                    fileSelector.style.display = 'block';
                    const loadingSpinner = document.getElementById('parsing_loading_spinner');
                    loadingSpinner.style.display = 'none';

                    props.showToastNotificationModal('success', 'Attachment Parsed', 'The attachment has been parsed successfully.', 5000);

                    if(!data.claim.possible_items){
                        props.showAlertModal(
                            'info',
                            'No Items',
                            'The items were returned for the attachment.');
                        return;
                    }

                    // Show the products from the attachment
                    props.setShowProductsFromAttachmentModal(true)
                    props.setParsedAttachmentProducts(data)
                    props.setCustomModalOpen(false)
                },
                (error) => props.onError(error)
            )

        }

        let content = (

            <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                <h1 className='p-4 mb-4 font-light text-3xl '>Attachment</h1>

                <div className="divider_CRUD"/>

                <h4 className='p-4 mb-4 font-light text-lg text-center'>
                    Upload a document or image to attach to this claim
                </h4>

                <div className='flex flex-col items-center justify-center'>

                    <div id='file_selector'>
                        <input className="hidden" type="file" id="catalog_sheet_file_selection"
                               onChange={onUploadCatalogSheet}
                        />
                        <label className="btn-outline gap-3" htmlFor="catalog_sheet_file_selection">
                            <CloudUploadIcon className="w-5 h-5"/>
                            Upload file
                        </label>
                    </div>

                    <div id='file_upload_loading_spinner' className="mb-5 hidden">
                        <LoadingSpinner body="Uploading" color="rgb(8 145 178)"/>
                    </div>

                    <div id='parsing_loading_spinner' className="mb-5 hidden">
                        <LoadingSpinner body="Parsing" color="rgb(8 145 178)"/>
                    </div>


                </div>


                <div className="divider_CRUD"/>

                <div className='flex gap-4 mt-4 w-full justify-center tailwind-tooltip-container'>

                    <button
                        className="btn-outline"
                        onClick={() => props.setCustomModalOpen(false)}
                    >
                        Cancel
                    </button>
                </div>

            </div>
        );

        props.showCustomModal(content);

    }

    return (
        <div className="pt-1 w-full">
            <div className="mt-1 flex rounded-md pt-1 h-15 relative">

                {/*<FilterSearchAutoSuggestions {...props} />*/}


                {/* EXACT MATCH */}
                <div className={classNames(
                    'tailwind-tooltip-container',
                    'transition-all duration-300',
                    props.showExactMatchButton ? 'w-[4rem]' : 'w-[1rem]',
                    'hover:w-[4rem]'
                )}>
                    <button className='btn-light m-0 px-3 py-2 h-full'
                            onClick={() => {
                                // check if the leading and trailing quotes are present
                                if (props.inputSearchText.startsWith('"') && props.inputSearchText.endsWith('"')) return;

                                // clear the button show state
                                props.setShowExactMatchButton(false)

                                // add the 'exact match' quotes to the input search text
                                props.setInputSearchText(`"${props.inputSearchText}"`)
                            }}
                    >
                        <DocumentSearchIcon className='h-7 w-7 text-sky-500'/>
                    </button>
                    <span className="tailwind-tooltip">Exact match query</span>
                </div>


                {/* SEARCH INPUT */}
                <div className="relative flex items-stretch flex-grow focus-within:z-2 min-h-50px">
                    <input

                        /* Workaround to prevent Chrome from autofilling the search bar with a username */
                        readOnly
                        onFocus={(e) => e.target.removeAttribute('readonly')}
                        autoComplete="none"

                        name="search"
                        className="input border-r-0 rounded-r-none placeholder:capitalize"
                        placeholder={props.selectedCategoryId && props.selectableCategories ?
                            `Search for ${props.selectableCategories && formatAsTitle(props.selectedCategory().category_d)}`
                            :
                            "Search for products"
                        }
                        onChange={e => props.setInputSearchText(e.target.value)}
                        value={props.inputSearchText}
                    />


                    {/* AI ITEM */}
                    <div
                        className={`absolute top-0 bottom-0 right-[130px] h-full px-2 transition-all duration-300 delay-[3000ms] cursor-wait tooltip-container opacity-0 ${props.filterQueryInProgress ? ' opacity-100 scale-100' : ' delay-[100ms] opacity-0 pointer-events-none scale-50'}`}
                        onClick={props.clearFilters}>
                        <div className="flex h-full items-center justify-center opacity-1 text-purple-600">
                            AI
                        </div>
                        <span className="tooltip">
                          An AI product is being generated
                        </span>
                    </div>


                    {/* FILTER RE-QUERY REQUIRED */}
                    <div
                        className={`absolute top-0 bottom-0 right-[85px] h-full px-2 transition-all duration-100 cursor-wait tooltip-container opacity-0 ${props.filterItemSearchQueryRequired ? 'opacity-100 scale-100' : 'opacity-0 pointer-events-none scale-50'}`}
                        onClick={props.clearFilters}>
                        <div className="flex h-full items-center justify-center opacity-1 text-cyan-600">
                            !<RefreshIcon className="h-6 w-6"/>
                        </div>
                        <span className="tooltip">
                          Immediately re-query
                        </span>
                    </div>


                    {/* FILTER QUERY IN PROGRESS */}
                    <div
                        className={`absolute top-0 bottom-0 right-[40px] h-full px-2 transition-all cursor-wait tooltip-container opacity-0 ${props.filterQueryInProgress ? 'opacity-100 scale-100' : 'opacity-0 pointer-events-none scale-50'}`}
                        onClick={props.clearFilters}>
                        <div className="flex h-full items-center justify-center opacity-1 ">
                            <svg role="status"
                                 className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="white"></path>
                                <path className="fill-cyan-600"
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="white"></path>
                            </svg>
                        </div>
                        <span className="tooltip">
                          A product query is currently in progress
                        </span>
                    </div>


                    {/* CLEAR FILTERS X */}
                    <div
                        className="absolute top-0 bottom-0 right-0 h-full px-2 transition hover:bg-red-400 cursor-pointer tooltip-container"
                        onClick={props.clearFilters}>
                        <div className="flex h-full items-center justify-center opacity-1 ">
                            <svg xmlns="http://www.w3.org/2000/svg" stroke="gray"
                                 className="h-full w-8 px-1 stroke-gray-400 hover:stroke-white" fill="none"
                                 viewBox="0 0 24 24" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </div>
                        <span className="tooltip">
                          Clear all search filters
                        </span>
                    </div>

                </div>

                {/* ATTACHMENT */}
                <button
                    type="button"
                    className="btn-light rounded-none m-0"
                    onClick={onAddAttachment}
                >
                    <PaperClipIcon className="h-5 w-5text-gray-400" aria-hidden="true"/>
                    <span className="pl-3">Attachment</span>
                </button>


                {/* SEARCH BUTTON */}
                <button
                    type="button"
                    className="btn-light rounded-l-none border-l-0 m-0 mr-2 "
                    onClick={() => props._search()}
                >
                    <SearchIcon className="h-5 w-5text-gray-400" aria-hidden="true"/>
                    <span className="pl-3">Search</span>
                </button>


                {/* BACK TO VERIFICATION */}
                {props.mode === "convert" && (
                    <a
                        type="button"
                        href={`/claim/verification/${props.claimId}`}
                        className="tooltip-container ml-3 mt-1 h-10 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 hover:cursor-pointer"
                    >
                  <span className="ml-auto mr-auto">
                    <ArrowCircleLeftIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                    Verification
                  </span>
                        <span style={{marginTop: "95px", marginLeft: "0px"}}
                              className="tooltip">Back to verification</span>
                    </a>
                )}

                {/* RETURN TO TENDER/AWARD */}
                {(() => {
                    if (props.mode !== "addReplacement") return <></>;

                    const originPage = props.location.state?.originPage || 'tender'
                    let returnRoute = ''
                    if (originPage === 'tender') returnRoute = `/claim/tender/${props.claimId}`
                    else if (originPage === 'award') returnRoute = `/claim/issue-award/${props.claimId}`


                    return (
                        <button
                            onClick={() => props.navigate(returnRoute)}
                            className="tooltip-container btn m-0 ml-3"
                        >
                            <span className="ml-auto mr-auto">
                                <ArrowCircleLeftIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                                {originPage === 'tender' ? 'Tender' : 'Award'}
                            </span>
                            <span style={{marginTop: "75px", marginLeft: "-17px"}}
                                  className="tooltip">Back to tender</span>
                        </button>
                    );
                })()}

                {/* NEXT CART REVIEW */}
                {props.mode === "search" && (
                    <Link
                        to={props.claim ? "/claim/cart-review/" + props.claim?.id : "#"}
                        className={classNames(
                            `btn-light flex items-center m-0 py-1 px-3 h-auto`,
                            'transition-all duration-500 outline outline-0 outline-offset-1',
                            'shadow-sm',
                            animateCheckoutButton ? ' scale-[1.1] !outline-2 !outline-offset-[5px] shadow-xl outline-green-400' : 'outline-0 outline-transparent'
                        )}
                    >
                        <span className="sr-only">View cart</span>
                        <span className="text-gray-600">Next</span>
                        <span className="relative inline-block">
                        <div>
                          <svg className="w-10 h-10 text-gray-700 hover:text-cyan-700 fill-current" viewBox="0 0 22 22">
                            <path
                                d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z"/>
                          </svg>
                            {props.claim && props.claim.item_count > 0 ? (
                                <span
                                    className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1  leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-green-700 rounded-full">
                                {props.claim.item_count}
                              </span>
                            ) : (
                                <span></span>
                            )
                            }
                        </div>

                        </span>
                    </Link>
                )}
            </div>
        </div>
    );

}

function FilterSearchAutoSuggestions(props) {

    const [showSuggestions, setShowSuggestions] = useState(false);

    // Show [BRAND, FAMILY&CATEGORY, AUTOSUGGEST] suggestions
    const mode = useRef('BRANDS');

    const [disableSuggestions, setDisableSuggestions] = useState(false);

    useEffect(() => {

        if(checkAutoSuggestIsDisabled() || disableSuggestions) {
            setShowSuggestions(false)
            return
        }

        let showSuggestions = false
        if(props.inputSearchText && props.inputSearchText.length > 0){
            showSuggestions = true
        }

        setShowSuggestions(showSuggestions)

    }, [disableSuggestions, props.inputSearchText])

    useEffect(() => {
        // If the user has typed a brand (1 term followed by a space), then set that as the selected brand
        // if(props.inputSearchText && props.inputSearchText.length > 2 && props.inputSearchText.includes(' ')){
        //     // If there is already a selected brand, then don't set the brand again
        //     if(props.selectedFilters['brand']) return;
        //     let words = props.inputSearchText.split(' ')
        //     onBrandSuggestionClick({brand: words[0]})
        // }

        // If the input search text is empty, then clear the selected filters
        if(!props.inputSearchText){
            mode.current = 'BRANDS'
            props.setSelectedFilters({})
        }

    }, [props.inputSearchText]);

    // [0] is Y (up and down), [1] is X (left and right or categories / families)
    const [arrowNavigationIndex, setArrowNavigationIndex] = useState([0, 0]);
    const keyDownHandler = useRef(null);
    useEffect(() => {
        registerKeyBinds();
        // Rebind the keys, when these states have changed, so to update the closures (state data in the event listeners)
    }, [props.brands, props.inputSearchText, arrowNavigationIndex]);
    function registerKeyBinds() {

        // Arrow key navigation
        const handleKeyDown = (e) => {

            // was the arrow keys pressed
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                e.preventDefault();
                const upOrDown = e.key === 'ArrowDown' ? 1 : -1;
                // Limit to 0 or higher
                setArrowNavigationIndex(prev => {
                    const newY = prev[0] + upOrDown;
                    if (newY < 0) return prev; // Prevent going below 0
                    return [prev[0] + upOrDown, prev[1]]
                });
            }

            // left and right arrow keys
            else if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                e.preventDefault();
                const leftOrRight = e.key === 'ArrowRight' ? 1 : -1;
                setArrowNavigationIndex(prev => {
                    const newX = prev[1] + leftOrRight;
                    if (newX < 0 || newX > 1) return prev; // Prevent going below 0 or above 1
                    // Reset the Y (go back to the top of the list)
                    return [0, prev[1] + leftOrRight]
                });
            }

            else if (e.key === 'Enter' || e.key === 'Tab') {
                if(mode.current === 'BRANDS'){
                    let brand = props.brands[arrowNavigationIndex[0]]
                    onBrandSuggestionClick({brand: brand.brand})
                }
                if(mode.current === 'FAMILY&CATEGORY'){
                    const selectedBrand = getSelectedBrand()
                    if(arrowNavigationIndex[1] === 0){
                        let brand = props.brands.find(b => b.brand === selectedBrand)
                        let categoryObject = brand.categories[arrowNavigationIndex[0]]
                        onCategorySuggestionClick({category: categoryObject.category})
                    }
                    if(arrowNavigationIndex[1] === 1){
                        let familyObject = props.brands.find(b => b.brand === selectedBrand).families[arrowNavigationIndex[0]]
                        onFamilySuggestionClick({family: familyObject.family})
                    }
                }
                if(mode.current === 'AUTOSUGGEST'){
                    onAutoSuggestClick(props.autoSuggest[arrowNavigationIndex?.[0] || 'ERR'])
                }
            }

            else if (e.key === 'Escape') {
                setShowSuggestions(false)
            }
        };

        document.removeEventListener('keydown', keyDownHandler.current);
        keyDownHandler.current = handleKeyDown;
        document.addEventListener('keydown', keyDownHandler.current);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', keyDownHandler.current);
        };
    }

    function checkAutoSuggestIsDisabled() {
        const disableSuggestionsTimestamp = localStorage.getItem('disableSuggestionsTimestamp');
        if (disableSuggestionsTimestamp) {
            const elapsedTime = Date.now() - parseInt(disableSuggestionsTimestamp, 10);
            if (elapsedTime < 10 * 60 * 1000) {
                disableAutoSuggestions();
                return true;
            } else {
                localStorage.removeItem('disableSuggestionsTimestamp');
            }
        }
        return false;
    }

    function disableAutoSuggestions(){
        // Disable the suggestions for 10 minutes
        const timestamp = Date.now();
        localStorage.setItem('disableSuggestionsTimestamp', timestamp.toString());
        setDisableSuggestions(true);
    }

    function enableAutoSuggestions(){
        localStorage.removeItem('disableSuggestionsTimestamp');
        setDisableSuggestions(false);
    }

    function onBrandSuggestionClick(brand){

        let copy = {...props.selectedFilters}
        copy['brand'] = brand.brand
        props.setSelectedFilters(copy)

        // Set the input search text to the selected brand
        console.log('set brand in input search text', brand.brand)
        props.setInputSearchText(brand.brand + ' ')

        mode.current = 'FAMILY&CATEGORY'
    }

    function onCategorySuggestionClick(category){
        // Set the selected filter category

        const cat = props.selectableCategories.find( (cat) => cat.category_d.toUpperCase() === category.category.toUpperCase());
        if(!cat) return;

        let copy = {...props.selectedFilters}

        // If this category is already selected, then remove it
        if('category' in props.selectedFilters && props.selectedFilters['category'] === category.category){
            delete copy['category']
        }
        else {
            copy['category'] = category.category
            props.setSelectedFilters(copy)
            props.setSelectedCategoryId(cat.id)
        }

    }

    function onAutoSuggestClick(suggestions){
        // Set the text input to the suggestion
        props.setInputSearchText(suggestions)
    }

    function onFamilySuggestionClick(family){
        // Set the family in the selected filters
        let copy = {...props.selectedFilters}

        // If this family is already selected, then remove it
        if('family' in props.selectedFilters && props.selectedFilters['family'] === family.family){
            delete copy['category']
        }
        else {
            copy['family'] = family.family
            props.setSelectedFilters(copy)

            // Add this family to the input search text
            props.setInputSearchText(searchText => searchText + ' ' + family.family)
        }

    }

    function getSelectedBrand(){

        // Is there a brand in the selected filters?
        if('brand' in props.selectedFilters) return props.selectedFilters['brand']

        // Is there a brand in the input search text?
        // The first term/word is the brand
        let inputSearchText = props.inputSearchText
        let words = inputSearchText.split(' ')
        if(words.length > 0) return words[0]

        return ''
    }

    if (disableSuggestions) {
        return (
            <div
                className="absolute z-[10] top-[3.5rem] left-[1rem]"
            >
                <Tooltip content='Show auto suggestions' className='max-w-[7rem]'>
                    <ChevronDownIcon
                        className='h-4 w-4 text-gray-400 cursor-pointer'
                        onClick={enableAutoSuggestions}
                    />
                </Tooltip>
            </div>
        );
    }

    function brandsList() {
        if (mode.current !== 'BRANDS' || !props.brands)
            return <></>

        return (
            <>
                <li className='text-[10px] text-gray-400 text-center'>Brands</li>

                {props.brands?.map((brand, i) =>
                    <li className={classNames(
                        'relative pl-7 pr-4 py-2 border-b-[1px] border-gray-100 hover:bg-sky-100 flex items-center gap-4',
                        arrowNavigationIndex[0] === i ? 'bg-sky-100' : '',
                        // If this value is selected, then highlight it
                        props.selectedFilters['brand'] === brand.brand ? 'bg-sky-200 hover:bg-sky-200' : ''
                    )}
                        onClick={() => {
                            onBrandSuggestionClick(brand)
                            setArrowNavigationIndex([0, 0])
                        }}
                    >
                        <SuggestionArrow index={i} column={0}/>
                        <SuggestionIndex index={i}/>
                        <span className={brand.is_supported ? '' : ' text-gray-700'}>
                            {brand.brand}
                        </span>
                        <span className='text-xs text-gray-400'>
                            c: {brand.categories.length} &nbsp; f: {brand.families.length}
                        </span>
                    </li>
                )}

            </>
        );
    }

    function categoryFamilyList() {
        if (mode.current !== 'FAMILY&CATEGORY' || !props.brands)
            return <></>

        const brand = props.brands?.find(b => b.brand = getSelectedBrand())
        if(!brand) return <></>

        const categories = brand.categories
        const families = brand.families

        return (

            <li className='flex justify-between'>

                <div className='w-1/2'>
                    <li className='text-[10px] text-gray-400 text-center'>Categories</li>

                    {categories.map((cat, i) =>
                        <li className={classNames(
                            'relative pl-7 pr-4 py-2 border-b-[1px] border-gray-100 hover:bg-sky-100 flex items-center gap-4',
                            arrowNavigationIndex[1] === 0 && arrowNavigationIndex[0] === i ? 'bg-sky-100' : '',
                            // If this value is selected, then highlight it
                            props.selectedFilters['category'] === cat.category ? 'bg-sky-200 hover:bg-sky-200' : ''
                        )}
                            onClick={() => {
                                onCategorySuggestionClick(cat)
                                setArrowNavigationIndex([i, 0])
                            }}
                        >
                            <SuggestionArrow index={i} column={0}/>
                            <SuggestionIndex index={i}/>
                            <span>{formatAsTitle(cat.category)}</span>
                            <span
                                className='text-xs text-gray-200'>f: {cat.families.length}
                            </span>
                        </li>
                    )}

                </div>

                <div className='w-1/2'>
                    <li className='text-[10px] text-gray-400 text-center'>Families</li>

                    {families.map((fam, i) =>
                        <li className={classNames(
                            'relative pl-7 pr-4 py-2 border-b-[1px] border-gray-100 hover:bg-sky-100 flex items-center gap-4',
                            arrowNavigationIndex[1] === 1 && arrowNavigationIndex[0] === i ? 'bg-sky-100' : '',
                            // If this value is selected, then highlight it
                            props.selectedFilters['family'] === fam.family ? 'bg-sky-200 hover:bg-sky-200' : ''
                            )}
                            onClick={() => {
                                onFamilySuggestionClick(fam)
                                setArrowNavigationIndex([i, 1])
                            }}
                        >
                            <SuggestionArrow index={i} column={1}/>
                            <SuggestionIndex index={i}/>
                            <span>{fam.family}</span>
                            <span
                                className='text-xs text-gray-200'>c: {fam.categories.length}</span>
                        </li>
                    )}
                </div>

            </li>

        )
    }

    function autoSuggestList(){

        //  TODO dont show auto suggest text
        return (
            <>

                <li className='text-[10px] text-gray-400 text-center'>Auto suggest</li>
                    {/*{props.products?.map((product, i) =>*/}
                    {/*    <li className={classNames(*/}
                    {/*        'relative pl-7 pr-4 py-2 border-b-[1px] border-gray-100 hover:bg-sky-100 flex items-center gap-4',*/}
                    {/*        arrowNavigationIndex[1] === 0 && arrowNavigationIndex[0] === i ? 'bg-sky-100' : '',*/}
                    {/*        // If this value is selected, then highlight it*/}
                    {/*        // props.selectedFilters['category'] === cat.category ? 'bg-sky-200 hover:bg-sky-200' : ''*/}
                    {/*    )}*/}
                    {/*        // onClick={() => {*/}
                    {/*        //     onCategorySuggestionClick(cat)*/}
                    {/*        //     setArrowNavigationIndex([i, 0])*/}
                    {/*        // }}*/}
                    {/*    >*/}
                    {/*        /!* show the product image *!/*/}
                    {/*       <ImageZoom*/}
                    {/*            imageSrc={product.images.thumbnail_web || 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}*/}
                    {/*            altText="Product Preview"*/}
                    {/*            className="w-full max-w-[4rem] max-h-[2rem] object-contain px-2 !z-[100]"*/}
                    {/*            zoomClassName={classNames(*/}
                    {/*                'border-[1px] border-stone-400 rounded-md',*/}
                    {/*                // Don't show the preview for the placeholder product image*/}
                    {/*                product.images.thumbnail_web ? 'scale-[400%]' : 'scale-[100%]'*/}
                    {/*            )}*/}
                    {/*        />*/}
                    {/*        <SuggestionArrow index={i} column={0}/>*/}
                    {/*        /!*<SuggestionIndex index={i}/>*!/*/}
                    {/*        <span>{product.common_name} </span>*/}
                    {/*        /!*<span*!/*/}
                    {/*        /!*    className='text-xs text-gray-200'>f: {cat.families.length}*!/*/}
                    {/*        /!*</span>*!/*/}
                    {/*    </li>*/}
                    {/*)}*/}
                {props.autoSuggest?.map((autoSuggestString, i) =>
                    <li className={classNames(
                        'relative pl-7 pr-4 py-2 border-b-[1px] border-gray-100 hover:bg-sky-100 flex items-center gap-4',
                        arrowNavigationIndex[0] === i ? 'bg-sky-100' : '',
                        props.inputSearchText === autoSuggestString ? 'bg-sky-200' : ''
                    )}
                        onClick={() => {
                            onAutoSuggestClick(autoSuggestString)
                            setArrowNavigationIndex([0, 0])
                        }}
                    >
                        <SuggestionArrow index={i}/>
                        <SuggestionIndex index={i}/>
                        <i className=''>
                            {autoSuggestString}
                        </i>
                    </li>
                )}

            </>
        );
    }

    function SuggestionArrow({index, column = 0}) {
        return (
            <ChevronRightIcon className={classNames(
                'absolute left-1 h-5 w-5 text-gray-400 ',
                // If the navigation index is on this item, then show the arrow
                arrowNavigationIndex[0] === index && arrowNavigationIndex[1] === column ? 'visible' : 'invisible'
            )}/>
        );
    }
    function SuggestionIndex({index}) {
        return <></> // TODO
        return (
            <span className='text-xs text-gray-400'>{index + 1}</span>
        );
    }

    if (!showSuggestions) return <></>

    return (
        <div
            className="absolute z-[10] top-[4rem] left-[1rem] min-w-[20rem] w-[20rem] max-h-[30rem] scrollbar-hide overflow-y-scroll transition hover:shadow-xl border-4 border-gray-200 rounded-lg bg-white">
            <ul>
                <li className='text-xs text-gray-400 pl-4 pr-2 py-1 border-b-[1px] bg-gray-100 border-gray-100 hover:bg-sky-100 flex justify-between gap-4'>
                    <p>Auto Suggestions</p>

                    <div className='flex gap-5'>
                        <Tooltip content='Press the corresponding number on the keyboard to quickly select the desired suggestion'
                                 className='max-w-[10rem]'
                        >
                            <i className='tooltip_underline'><InformationCircleIcon className='h-4 w-4 text-gray-400 cursor-pointer'/></i>
                        </Tooltip>
                        <Tooltip content='Disable for 10 minutes' className='max-w-[8rem]'>
                            <BanIcon
                                className='h-4 w-4 text-gray-400 cursor-pointer'
                                onClick={disableAutoSuggestions}
                            />
                        </Tooltip>

                        <Tooltip content='Close'>
                            <XIcon
                                className='h-4 w-4 text-gray-400 cursor-pointer'
                                onClick={() => setShowSuggestions(false)}
                            />
                        </Tooltip>

                    </div>
                </li>

                <li><i className='text-gray-300'>Arrow nav: {arrowNavigationIndex[0]} {arrowNavigationIndex[1]} | Mode: {mode.current} | Brand: {getSelectedBrand()}</i></li>

                {brandsList()}

                {categoryFamilyList()}

                {autoSuggestList()}

            </ul>
        </div>
    );
}

function Categories(props) {

    const selectOption = (filterName, filterValue) => {

        let copy = {...props.selectedFilters}

        if (filterName in props.selectedFilters && filterValue === props.selectedFilters[filterName]) {
            // Remove if the same value was selected.
            delete copy[filterName]
            props.setSelectedFilters(copy)
        } else {
            // This filter was freshly selected, or a new value was selected (e.g motorola -> samsung)
            copy[filterName] = filterValue
            props.setSelectedFilters(copy)

        }

    }

    const clearOption = (filterName) => {
        let copy = {...props.selectedFilters}
        delete copy[filterName] // remove the filter
        props.setSelectedFilters(copy)
    }

    const renderCategories = (disclosureProps) => {

        if (props.selectableCategories === null) {
            // return <LoadingSpinner/>
            return <></>
        }

        let mainCategories = [];

        for (let category of props.selectableCategories) {
            if (category.category_d !== "UNKNOWN" && category.category_d !== "") {
                mainCategories.push(category);
            }
        }

        return (
            <fieldset>
                <div className="space-y-3">
                    {mainCategories.map((category) => (
                        <div key={category.category_d} className="flex items-center">
                            <button
                                onClick={()=>{
                                        // select category
                                        props.setSelectedFilters([])
                                        props.setSelectableFilters([])
                                        props.setSelectedCategoryId(category.id);
                                        disclosureProps.close();
                                }}
                                name={category.category_d}
                                className={
                                    `text-start w-full px-3 rounded-md text-gray-800 capitalize transition
                                     ring-2 ring-offset-2 ring-transparent hover:ring-cyan-500 hover:ring-cyan-500
                                     ${props.selectedCategoryId === category.id ? 'bg-cyan-500 text-gray-100 shadow-inner py-2' : 'py-1'}
                                     `
                                }
                            >
                                {category.display_name}
                            </button>

                        </div>
                    ))}
                </div>
            </fieldset>
        );
    }

    const renderMainCategories = () => {


        return (
            <div key="Categories">
                <Disclosure as="div" key="Categories"
                            className="bg-white border-t border-gray-200 px-4 py-6 transition duration-200 hover:shadow-lg rounded-md"
                >
                    {disclosureProps => (
                        <>
                            <h3 className="-mx-2 -my-3 flow-root">
                                <Disclosure.Button
                                    className="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500">
                                    <div
                                        className="flex flex-col items-start justify-between">

                                        <span className="font-medium text-gray-900">Category</span>
                                        <span
                                            className={`
                                                flex justify-start pt-2 font-small text-sm capitalize text-gray-500 hover:text-rose-700 transition 
                                                ${props.selectedCategoryId ? '' : 'hidden'}
                                            `}
                                            onClick={(e) => {
                                                props.setSelectedCategoryId(null);
                                                e.preventDefault(); // do not open the disclosure after removing category
                                                e.stopPropagation();
                                            }}
                                            // filter={filter.name}
                                        >
                                            <span className="text-xs outline-2 outline-cyan-800 hover:bg-rose-300 m-0 py-1 px-3 ">
                                            {
                                                props.selectableCategories &&
                                                props.selectedCategoryId &&
                                                formatAsTitle(props.selectedCategory().category_d)
                                            }
                                            </span>
                                        </span>
                                    </div>
                                    <span className="ml-6 flex items-center">
                                      {disclosureProps.open ? (
                                          <MinusSmIcon className="h-5 w-5" aria-hidden="true"/>
                                      ) : (
                                          <PlusSmIcon className="h-5 w-5" aria-hidden="true"/>
                                      )}
                                    </span>
                                </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                                {renderCategories(disclosureProps)}

                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        );
    }

    const renderFilterCategories = () => {
        return (
            props.selectableFilters.map((filter) => (
                <Disclosure as="div" key={filter.name}
                            className="bg-white border-t border-gray-200 px-4 py-5 transition duration-200 hover:shadow-lg rounded-md"
                >
                    {(disclosureProps) => (
                        <>

                            <h3 className="-mx-2 -my-3 flow-root">
                                <Disclosure.Button
                                    className="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500">

                                    <div
                                        className="flex flex-col items-start justify-between">
                                        <span className="font-medium text-left text-gray-900">{filter.display_name}</span>
                                        <span className={` flex justify-start pt-2 font-small text-sm capitalize text-gray-500 hover:text-rose-700 transition 
                                                            ${filter.name in props.selectedFilters ? '' : 'hidden'}
                                                        `}
                                            onClick={(e) => {
                                                clearOption(filter.name)
                                                e.preventDefault(); // do not open the disclosure after removing the filter
                                                e.stopPropagation();
                                            }}
                                        >
                                            <span className="text-xs outline-2 outline-cyan-800 hover:bg-rose-300 m-0 py-1 px-3 ">
                                                {formatAsTitle(props.selectedFilters[filter.name])}
                                            </span>
                                        </span>
                                    </div>

                                    <span className="ml-6 flex items-center">
                                        {disclosureProps.open ? (
                                          <MinusSmIcon className="h-5 w-5" aria-hidden="true"/>
                                        ) : (
                                          <PlusSmIcon className="h-5 w-5" aria-hidden="true"/>
                                        )}
                                    </span>
                                </Disclosure.Button>
                            </h3>

                            <Disclosure.Panel className="pt-6">
                                <fieldset>
                                    <div className="space-y-3 ">
                                        {filter.values.map((value, valueIdx) => (
                                            <div key={value.value}
                                                 className={`
                                                            flex justify-start gap-4 w-full py-2 px-3 rounded-md text-gray-700 transition 
                                                            ring-2 ring-offset-2 ring-transparent ring-b-gray-100 hover:ring-2 hover:ring-cyan-500
                                                            ${value.value === props.selectedFilters[filter.name] ? 'bg-cyan-500 text-gray-100 shadow-inner' : ''}
                                                 `}
                                                 onClick={() => {
                                                     // select filter
                                                     selectOption(filter.name, value.value)
                                                     disclosureProps.close();
                                                 }}
                                            >

                                                {/* NAME */}
                                                <p
                                                    htmlFor={`${filter.name}-${valueIdx}`}
                                                    className="text-sm grow-0 capitalize">
                                                    {value.value}
                                                </p>

                                                {/* COUNT */}
                                                <p
                                                    htmlFor={`${filter.name}-${valueIdx}`}
                                                    className="text-right grow capitalize">
                                                    ({value.count})
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </fieldset>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            ))
        );
    }

    return (
        <div className="divide-y divide-gray-200 space-y-2 mr-4 widget p-0 ml-0 h-fit">

            {/* Categories */}
            {safe(renderMainCategories)}

            {/* Filters */}
            {safe(renderFilterCategories)}

        </div>
    );
}

function Products(props){

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalOptions, setConfirmModalOptions] = useState({
        "heading": "",
        "message": "",
        "type": "",
        "buttonText": "",
    });

    const [ignoreNextGenericAccessoriesPrompt, setIgnoreNextGenericAccessoriesPrompt] = useState(false);
    useEffect(() => {
        let shiftPressed = false; // Tracks the state of the Shift key

        const handleKeyDown = (event) => {
            if (event.key === 'Shift' && !shiftPressed) {
                // Toggle the state only if Shift was not already pressed
                setIgnoreNextGenericAccessoriesPrompt(prevState => !prevState);
                shiftPressed = true; // Mark Shift as pressed
            }
        };

        const handleKeyUp = (event) => {
            if (event.key === 'Shift') {
                shiftPressed = false; // Reset on key up
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        // Cleanup function to remove event listeners
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);


    const showProductInfoModal = (product) => {

        props.setProductInfoModalOptions({
            'product': product,
            // 'productId': product.id,
            'onProductAdded': null,
            'mode': 'view',
            'claimActionButton': (
                <ProductCardActionButton {...props}
                                         addProtoToClaim={addProtoToClaim}
                                         confirmConvertItem={confirmConvertItem}
                                         confirmAddReplacement={confirmAddReplacement}
                                         product={product}
                                         ignoreNextGenericAccessoriesPrompt={ignoreNextGenericAccessoriesPrompt}
                />
            ),

            // If the HAI page is open (with the itemsearch embedded) and a new product is generated via update_proto_product, then the HAI page needs to be updated product
            'onProductSaved': (newProduct) => {
                if(props.onProtoSelectedForHaiTask)
                    props.onProtoSelectedForHaiTask(newProduct, props.haiTask);
            }
        });

        props.setProductInfoModalOpen(true);
    }

    // Add item replacement
    const confirmAddReplacement = (productId) => {

        setConfirmModalOptions({
            "heading": "Add replacement",
            "message": "Please confirm adding this replacement to the possible replacements for this item. This action cannot be undone.",
            "buttonText": "Add replacement",
            "productId": productId,
        });

        setConfirmModalOpen(true);
    }
    const callAddReplacement = (productId) => {

        addReplacement(
            props.itemId, productId, "",
            (data) => {

                props.setNotificationCenterOptions({
                    "heading": "Replacement added!",
                    "message": "Successfully added replacement. Redirecting back to tender page...",
                });

                props.setNotificationCenterOpen(true);

                setTimeout(() => {
                    /** Navigate back to the original page that called this "add replacement" */

                    props.setNotificationCenterOpen(false);

                    const originPage = props.location.state?.originPage || 'tender'
                    let returnRoute = ''
                    if(originPage === 'tender') returnRoute = `/claim/tender/${props.claimId}`
                    else if(originPage === 'award') returnRoute = `/claim/issue-award/${props.claimId}`

                    props.navigate(returnRoute,
                            {
                                state: {
                                    supplierId: props.location.state?.supplierId || null,
                                    quoteModal: props.location.state?.quoteModal || null,
                                    itemId: data.item.id,
                                    newlyAddedReplacement_productId: productId
                                }
                            }
                    );


                }, 2000);
            },
            (error) => {
                props.onError(error);
            }
        );
    }

    // Convert item
    const onConfirmClicked = (options) => {
        setConfirmModalOpen(false);
        if (props.mode === "convert") {
            convertItem(options["productId"]);
        } else if (props.mode === "addReplacement") {
            callAddReplacement(options["productId"])
        }
    }
    const confirmConvertItem = (productId) => {

        setConfirmModalOptions({
            "heading": "Convert manual item",
            "message": "Please confirm converting manual item. This action cannot be undone.",
            "type": "convert",
            "buttonText": "Convert",
            "productId": productId,
        });

        setConfirmModalOpen(true);
    }
    const convertItem = (productId) => {
        convertManualItemToCatalogueItem(
            props.itemId, productId,
            () => {

                props.setNotificationCenterOptions({
                    "heading": "Item converted!",
                    "message": "Successfully converted item. Redirecting to next page...",
                });

                props.setNotificationCenterOpen(true);
                setTimeout(() => {
                    props.navigate(`/claim/verification/${props.claimId}`);
                }, 1000);
            },
            (error) => {
                props.onError(error);
            }
        );
    }

    const addProtoToClaim = (productId, product) => {

        props.clearFilters();
        promptForGenericAccessories(props.selectableCategories.find(cat => cat.category_d === product.category));

        addCatalogueProductToClaim(
            props.claim.id, productId,
            () => {

                // Close the edit/view product modal
                props.setProductInfoModalOpen(false);

                props.updateClaim();
            },
            (error) => props.onError(error)
        );
    }

    // Create item
    const createManualItem = (manualItemData) => {

        // Create the manual item from the UI item card, or from data passed in the arguments
        let _manualItem_category = manualItemData ? manualItemData.category : manualItem_category
        let _manualItem_brand = manualItemData ? manualItemData.brand : manualItem_brand
        let _manualItem_modelNumber = manualItemData ? manualItemData.modelNumber : manualItem_modelNumber
        let _manualItem_description = manualItemData ? manualItemData.description : manualItem_description

        // Category
        if (!_manualItem_category) return props.showAlertModal('info', "No category selected", "Please a category from the dropdown");

        // Selected Brand
        if (!_manualItem_brand) return props.showAlertModal('info', "No brand specified", "Please enter a brand");

        // Model number or EAN code
        if (!_manualItem_modelNumber) return props.showAlertModal('info', "Model number required", "Please specify a model number");

        if (!_manualItem_description || _manualItem_description.length <= 0) return props.showAlertModal('info', "Description required", "Please enter a description");

        // Description max length
        if (!_manualItem_description.length > 100) return props.showAlertModal('info', "Description too long", "Please reduce the length of the description (max 100 characters)");

        // Add a manual product to an item as a replacement option for an existing proto
        if(props.mode === "addReplacement"){
            createManualReplacement({
                    itemId: props.itemId, // the item object passed via location.state from the tender/award page
                    reason: 'Add replacement',
                    brand: _manualItem_brand,
                    description: _manualItem_description,
                    modelNumber: _manualItem_modelNumber,
                    eanCode: '',
                },
                () => {
                    props.setNotificationCenterOptions({
                        'heading': 'Replacement added!',
                        'message': 'Successfully added manual replacement. Redirecting to tender page...'
                    });
                    props.setItemNotificationOpen(true);
                    setTimeout(() => {
                        props.setItemNotificationOpen(false);
                    }, 1000);

                    // Refresh claim
                    props.navigate(`/claim/tender/${props.claim.id}`)
                },
                (error) => {
                    props.onError(error);
                }
            );
        }

        // Add manual item to claim
        else {
            addManualProductToClaim(
                {
                    claimId: props.claim.id,
                    quantity: 1,
                    categoryId: _manualItem_category.id,
                    brand: _manualItem_brand,
                    description: _manualItem_description,
                    modelNumber: _manualItem_modelNumber,
                    eanCode: ''
                },
                () => {

                    // We only want to prompt for generic accessories the manual item UI card was used.
                    // If this createManualItem function was called from the generic accessories modal, then we don't want to prompt for generic accessories again
                    // if(!manualItemData)
                    //     promptForGenericAccessories(_manualItem_category);

                    // Refresh claim
                    props.clearFilters();
                    props.updateClaim();
                },
                (error) => {
                    props.onError(error);
                }
            );
        }




    }

    const createManualItem_FilterBasedItem = () => {

        // Add manual item as a replacement option for an existing proto
        if(props.mode === "addReplacement"){
            createManualReplacement({
                    itemId: props.itemId, // the item object passed via location.state from the tender/award page
                    reason: 'Add replacement',
                    brand: props.selectedFilters.brand,
                    description: getFilterBasedItemDescription(),
                    modelNumber: '',
                    eanCode: '',
                },
                () => {
                    props.setNotificationCenterOptions({
                        'heading': 'Replacement added!',
                        'message': 'Successfully added manual replacement. Redirecting to tender page...'
                    });
                    props.setItemNotificationOpen(true);
                    setTimeout(() => {
                        props.setItemNotificationOpen(false);
                    }, 1000);

                    // Refresh claim
                    props.navigate(`/claim/tender/${props.claim.id}`)
                },
                (error) => {
                    props.onError(error);
                }
            );
        }

        else {
            addFilterBasedItem({
                    claimId:props.claim.id,
                    categoryId:props.selectedCategoryId,
                    brand:props.selectedFilters.brand,
                    description:getFilterBasedItemDescription(),
                    categoryFilters:props.selectedFilters,
                },
                () => {

                    promptForGenericAccessories(props.selectedCategory());

                    // Refresh claim
                    props.clearFilters();
                    props.updateClaim();

                },
                (error) => {
                    props.onError(error);
                }
            );
        }

    }

    const createManualItem_AiBasedItem = () => {
        /** Process-a manual AI item **/

        // create an object with all selected filters
        let description = props.aiProduct.description
        if(!description){
            // create a description from the common name. e.g "LG 26WQ500-B 26" HD"  ->  "LG | 26WQ500-B | 26" | HD"
            description = props.aiProduct.common_name.trim().replaceAll(' ', ' | ')

            // add any selected filters to the description
            description += getFilterBasedItemDescription()
        }

        let category = props.selectedCategoryId ? props.selectedCategoryId : props.selectableCategories.find(cat => cat.category_d.toUpperCase() === props.aiProduct.category.toUpperCase())
        if(!category) {
            alert("Failed to locate the category data for the AI item");
            return
        }

        // Add manual item as a replacement option for an existing proto
        if(props.mode === "addReplacement"){
            createManualReplacement({
                    itemId: props.itemId, // the item object passed via location.state from the tender/award page
                    reason: 'Add replacement',
                    brand: props.aiProduct.brand,
                    description: props.aiProduct.description,
                    modelNumber: props.aiProduct.model_number,
                    eanCode: props.aiProduct.ean_codes ? props.aiProduct.ean_codes[0] : '',
                },
                () => {
                    props.setNotificationCenterOptions({
                        'heading': 'Replacement added!',
                        'message': 'Successfully added manual replacement. Redirecting to tender page...'
                    });
                    props.setItemNotificationOpen(true);
                    setTimeout(() => {
                        props.setItemNotificationOpen(false);
                    }, 1000);

                    // Refresh claim
                    props.navigate(`/claim/tender/${props.claim.id}`)
                },
                (error) => {
                    props.onError(error);
                }
            );
        }

        else {
            addAiBasedItem({
                claimId:props.claimId,
                categoryId:category.id,
                brand:props.aiProduct.brand,
                modelNumber:props.aiProduct.model_number,
                description:description,
                categoryFilters:props.selectedFilters,
                propertySpecs:props.aiProduct.properties,
            },
            () => {

                promptForGenericAccessories(category);

                // Refresh claim
                props.clearFilters();
                props.updateClaim();

            },
            (error) => {
                props.onError(error);
            }
        );
        }

    }

    const promptForGenericAccessories = (category) => {

        if(ignoreNextGenericAccessoriesPrompt) {
            setIgnoreNextGenericAccessoriesPrompt(false);
            return;
        }

        if(!category) return;

        const productCategory = category.category_d

        const supportedCategories = {
            'NOTEBOOKS': ['Carry bag', 'Backpack', 'Mouse', 'Charger'],
            'TABLETS': ['Case', 'Keyboard / Case', 'Charger'],
            'SMARTPHONES': ['Protective case', 'SIM swop', 'Charger'],
            'TVS': ['Wall bracket'],
        }

        if (!(productCategory in supportedCategories)) return;

        const accessoriesToShow = supportedCategories[productCategory] || null

        let content = (

            <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                <h1 className='p-4 mb-4 font-light text-3xl '>Generic Accessory</h1>

                <h4 className='p-4 mb-4 font-light text-lg text-center'>
                    Would you like to add one of the below
                    <br/>
                    generic accessories to this claim?
                </h4>

                <div className='w-full border-b-2 border-gray-200 my-4'></div>

                <table>
                    <tbody>


                    {accessoriesToShow.map((accessory, idx) => {

                        return <tr
                            key={idx}
                            className=''
                        >

                            {/* ACCESSORY NAME */}
                            <td className="">

                                <div className='px-12 h-full flex justify-between items-center'>

                                    <div className='flex justify-center gap-6 items-center'>
                                        <BasicImage
                                            src={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                            fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                            alt="Product Image"
                                            sizeWidthRem="6"
                                            sizeHeightRem={6 * 0.75} // 4:3 aspect ratio
                                        />

                                        <p className='text-lg'>{accessory}</p>
                                    </div>


                                    <button className='btn'
                                        onClick={(e) => {
                                            e.currentTarget.classList.add('btn-disabled');
                                            e.currentTarget.setAttribute('disabled', true);

                                            let manualProductData = {
                                                category: category,
                                                brand: 'GENERIC',
                                                modelNumber: `${productCategory}_${accessory}`,
                                                description: `${productCategory}_${accessory}`,
                                            }
                                            createManualItem(manualProductData)

                                        }}
                                    >
                                        <PlusCircleIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                                        {ignoreNextGenericAccessoriesPrompt ? 'Add to claim (skip accessories)' : 'Add to claim'}
                                    </button>

                                </div>

                            </td>

                        </tr>

                    })}

                    </tbody>
                </table>

                <div className="divider_CRUD"/>

                <div className='flex gap-4 mt-4 w-full justify-center tailwind-tooltip-container'>

                    <button
                        className="btn-outline"
                        onClick={() => props.setCustomModalOpen(false)}
                    >
                        Cancel
                    </button>

                    <span className="tailwind-tooltip left-[20%] -top-[150%] ">Press the <i>shift</i> key once before adding an item to avoid this modal</span>
                </div>

            </div>
        );

        props.showCustomModal(content);

    }

    const [aiGenerationInProgress, setAiGenerationInProgress] = useState(false);
    const generateProto_AiItem = () => {
        /** Process-b generate catalogue AI item **/
        if(aiGenerationInProgress) {
            props.showAlertModal(
                'info',
                'AI Product Generation in progress',
                'Please wait for the AI Product to be generated'
            )
            return;
        } else {
            setAiGenerationInProgress(true);
        }

        generateAiProto(
            props.aiProduct.category,
            props.aiProduct.brand,
            props.aiProduct.model_number,
            null,
            props.haiTaskId || null,
            (data) => {

                console.log('ai data', data);

                props.showNotificationModal('success', 'AI Producted Generated', 'An AI Product has successfully been generated and added to this claim');

                setAiGenerationInProgress(false);

                if(data.response_success === false) {

                    props.showAlertModal(
                        'error',
                        'Generation Failed',
                        'The AI Product failed to be generated.'
                    )

                } else {
                    setTimeout(() => {
                        let product = data.proto_product;
                        console.log('ai product', product);
                        /** Product to "link" to a HAI task. Send the AI product back to the HAI page (presuming this item search component has been "embedded" in the HAI page) */
                        props.onProtoSelectedForHaiTask(product, props.haiTask);

                        // TODO pass the generate_ai_proto product directly, when the generate_ai_proto mutation has been fixed
                        openProductModal_AfterProcessBSuccess(product);

                    }, 3000);
                }

            },
            (error) =>{
                console.log('ai error', error);
                setAiGenerationInProgress(false);
                props.showAlertModal(
                    'error',
                    'AI Generation Failed',
                    JSON.stringify(error)
                )
            }

        )
    }

    function openProductModal_AfterProcessBSuccess(aiProduct){
        /** When the process-b generate_ai_proto mutation is finished,
         * the CompareAndEditProtoModal must open to edit the newly generated product*/

        if(!aiProduct?.id) return props.showAlertModal(
            'error',
            'No product ID',
            'A new product ID was not returned from the server.'
        )

        // TODO - this is a workaround method to retrieve the process-b product data
        // When the generate_ai_proto has been fixed so that the full product is returned (currently just nulls),
        // Then this filter_search must be removed, and the product must from used directly from the generate_ai_proto mutation
        filterSearch(
            props.claimId,
            {
                inputSearchText: aiProduct.id,
            },
            (data) => {
                let processBProduct = data.products[0]
                showProductInfoModal(processBProduct);

                // re-update the proto in HAI
                if(props.onProtoSelectedForHaiTask)
                    props.onProtoSelectedForHaiTask(processBProduct, props.haiTask);

            },
            (error) => {
                console.log('error', error);
            }
        )

    }

    const getFilterBasedItemDescription = () => {
        /** Return a string that contains the selected category filters:
         * E.G: CHDHS-502 | Yes | 4K Ultra HD | 3840 x 2160 pixels | 10.0MP */

        let filters = {}
        for (const [key, value] of Object.entries(props.selectedFilters)) {
            if(['category', 'brand'].includes(key)) continue;
            filters[key] = value
        }

        /** create a description based off the selected filters */
        let description = ''
        for (const [key, value] of Object.entries(filters)) {
            if(key === 'category' || key === 'brand'){
                // do not add Category or Brand fields to the description
            } else {
                description += `${value} | `
            }
        }
        description = description.substring(0, description.length - 2) // remove the trailing pipe "| "
        description = description.trim() // remove white spaces

        return description
    }

    const [manualItem_category, setManualItem_category] = useState(null); // category Object. e.g: {id: '...', category_a:'...', category_b:'...', ...}
    const [manualItem_brand, setManualItem_brand] = useState(null);
    const [manualItem_modelNumber, setManualItem_modelNumber] = useState(null);
    const [manualItem_description, setManualItem_description] = useState(null);

    useEffect(() => {
        /** Clear the "Manual Item" card when:
         * A new main category is selected on the left
         * The list of selectable categories is updated */

        setManualItem_modelNumber('');
        setManualItem_brand('');
        setManualItem_description('');

        // Set the selected category for the add manual item card
        if (props.selectableCategories && props.selectedCategoryId) {
            // Set the manual item card's category to be the same
            setManualItem_category(props.selectedCategory())
        } else if (props.selectableCategories) {
            // If no main category has been selected on the left, then try set to the "other" category as the default category
            let default_category = props.selectableCategories.find(cat => cat.category_a == 'Other');
            setManualItem_category(default_category);
        } else {
            setManualItem_category(null);
        }


    }, [props.selectableCategories, props.selectedCategoryId]);

    useEffect(()=>{
        /** When AI data has been returned, populate the manual item card with that data */

        if(!props.aiProduct) return; //

        if(props.aiProduct.brand) setManualItem_brand(props.aiProduct.brand);
        if(props.aiProduct.model_number) setManualItem_modelNumber(props.aiProduct.model_number);
        if(props.aiProduct.common_name) setManualItem_description(props.aiProduct.common_name);

        if(props.aiProduct.category){
            let aiCategory = props.selectableCategories.find( (cat) => cat.category_d === props.aiProduct.category);
            setManualItem_category(aiCategory)
        }

    }, [props.aiProduct]);

    useEffect(()=>{
        /** When the input search text has been modified, set the first word as the 'brand' and the second word as the 'model number'
         * in the manual item card. e.g "samsung UN85TU7000FXZC mobile phone" -> brand = samsung, modelNumber = UN85TU7000FXZC */

        // convert any “” (italic style double quotes) to ""
        if(props.inputSearchText.includes('“') || props.inputSearchText.includes('”'))
            props.setInputSearchText(props.inputSearchText.replaceAll('“', '"').replace('”', '"'))

        function trimAndRemoveQuotes(str) {
            // Trim the string to remove leading and trailing whitespace
            let trimmedStr = str.trim();

            // Check if the string starts and ends with double quotes "
            if (trimmedStr.startsWith('"') || trimmedStr.endsWith('"')) {
                // Remove the double quotes
                trimmedStr = trimmedStr.slice(1, -1);
            }

            return trimmedStr;
        }

        // Remove leading and trailing double quotes from "exact match" quotations
        const searchText = trimAndRemoveQuotes(props.inputSearchText);

        let words = searchText.split(' ');
        if(!words) return;

        if(words[0]) setManualItem_brand(words[0])
        if(words[1]) {
            // Set the second word and all subsequent words as the model number
            setManualItem_modelNumber(subArray(words, 1, -1).join(' '))
        }

    }, [props.inputSearchText])

    useEffect(()=>{
        /** When the clearFilters() function has been executed, the manual item card should also be cleared
         * Because there is no reliable way to know when clearFilters has been executed in the parent component, we achieve
         * this by reacting to a change in the inputSearchText, which has proven to be sufficient for normal use cases
         *
         * Additionally, if products are ever returned then clear the manual item */

        let clearManualItems = false;
        if(!props.inputSearchText) clearManualItems = true; // inputSearchText has been updated, and is an empty string "", clear manual item.
        if(!isEmpty(props.products) && !props.inputSearchText){
            // Products have been returned, so the manual item should be cleared.
            // but only if the text input is empty - because of the "first word is brand and rest is modal number" feature
            clearManualItems = true;
        }

        if(clearManualItems){
            // Only clear text fields, not the category selection
            setManualItem_brand(null)
            setManualItem_modelNumber(null)
            setManualItem_description(null)
        }

    }, [props.inputSearchText, props.products])

    const manualBasedItem = () => {

        function getSelectableMainCategories() {
            /** The main categories are found in the ProductCategory enum type in GraphQL
             enum ProductCategory {
                ACTION_SPORTS_CAMERAS
                AUDIO_AMPLIFIERS
                BLENDERS
                CAMCORDERS
                CAMERA_DRONES
                COFFEE_MAKERS
                COMPUTER_MONITORS
                DECODERS
                DIGITAL_CAMERAS
                DISHWASHERS
                ELECTRIC_KETTLES
                FREEZERS
                FRIDGES
                FRIDGE_FREEZERS
                GAME_CONSOLES
                HANDHELD_VACUUMS
                HEADPHONES
                HOBS
                INKJET_PRINTERS
                IRONS
                LASER_PRINTERS
                MICROWAVES
                NOTEBOOKS
                OVENS
                ROBOT_VACUUMS
                SIDE_BY_SIDE_FRIDGE_FREEZERS
                SMARTPHONES
                SMARTWATCHES
                SOUNDBARS
                TABLETS
                TOASTERS
                TUMBLE_DRYERS
                TVS
                UNKNOWN
                VACUUMS
                WASHER_DRYERS
                WASHING_MACHINES
                }*/

            // create a list of each category_a
            let catA = props.selectableCategories.map(category => category.category_a);

            // because "category_a" is repeated in many of the categories, but we only want 1 choice to appear in the dropdown
            // remove all the repeated category_a. only keep 1 instance of each category_a
            let uniqueCategoryA = [...new Set(catA)]; // list of strings, each element is a category_a. unique due to being a Set datatype
            // sort list of category_a by alphabetical order
            uniqueCategoryA.sort((a, b) => a.localeCompare(b));
            let uniqueCategories = [];
            for (const category_a of uniqueCategoryA) {
                let fullCategoryData = props.selectableCategories.find(cat => cat.category_a === category_a);
                if (fullCategoryData)
                    uniqueCategories.push(fullCategoryData);
            }
            return uniqueCategories;
        }

        function getSelectableSubCategories() {
            if (!props.selectableCategories) return [];
            if (isEmpty(manualItem_category)) return [];

            let selectable = props.selectableCategories.filter(cat => cat.category_a === manualItem_category.category_a);

            return selectable;
        }


        return (
            <div className="group relative bg-white border border-gray-200 rounded-lg flex flex-col w-1/3 overflow-hidden ">

                <h1 className="text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600 text-md font-medium flex justify-center pt-3 py-2">
                    Manual Item
                </h1>

                {/* IMAGE */}
                <div className="flex justify-center">
                    <BasicImage
                        src={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable_spec.jpg'}
                        fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                        alt="Product Image"
                        sizeHeightRem={15}
                    />
                </div>

                {/* CATEGORY, BRAND, MODEL NUMBER, DESCRIPTION */}
                <div className="flex-1 p-4 space-y-2 flex flex-col justify-start">

                    <label className="text-md font-medium mr-2">Category: </label>

                    <div className="flex flex-col gap-2">
                        <select className="p-2 input"
                                value={manualItem_category ? manualItem_category.category_a : ''}
                                onChange={(e) => {
                                    let mainCat = props.selectableCategories.find(cat => cat.category_a === e.target.value);
                                    setManualItem_category(mainCat)
                                }}>

                            {getSelectableMainCategories().map(cat =>
                                <option
                                    value={cat.category_a}>{formatAsTitle(cat.category_a)}
                                </option>)
                            }
                        </select>

                        <select className="p-2 input"
                                value={manualItem_category ? manualItem_category.id : ''}
                                onChange={(e) => setManualItem_category(props.selectableCategories.find(cat => cat.id === e.target.value))}>

                            {/* if category data has been fetched, and a main category has been selected, then filter all the categories to get the categories where "category_a" equals the main selected categories "category_a" */}
                            {getSelectableSubCategories().map(category => {
                                if (category.category_c) // to prevent "duplicate" options try C first, then D, then B
                                    return <option value={category.id}>{category.category_c}</option>;
                                if (category.category_d && category.category_d != 'UNKNOWN')
                                    return <option value={category.id}>{formatAsTitle(category.category_d)}</option>;
                                if (category.category_b)
                                    return <option value={category.id}>{category.category_b}</option>;
                                if (category.category_a)
                                    return <option value={category.id}>{category.category_a}</option>;
                            })}

                        </select>
                    </div>

                    <input className='input px-2 py-2' placeholder="Brand"
                           value={manualItem_brand} onChange={(e) => setManualItem_brand(e.target.value)}/>

                    <input className='input px-2 py-2' placeholder="Model number"
                           value={manualItem_modelNumber} onChange={(e) => setManualItem_modelNumber(e.target.value)}/>

                    <input className='input px-2 py-4' placeholder="Brief Description " maxLength={100}
                           value={manualItem_description} onChange={(e) => setManualItem_description(e.target.value)}/>

                </div>

                <button
                    type="button"
                    onClick={()=> createManualItem() }
                    className="inline-flex items-center ml-4 mr-4 mb-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                >
                    {props.mode === "search" && (
                        <span className="ml-auto mr-auto py-1">
                            <PlusCircleIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                            Add to claim
                        </span>
                    )}
                    {props.mode === "addReplacement" && (
                        <span className="ml-auto mr-auto py-1">
                          <DuplicateIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                          Add as replacement
                        </span>
                    )}
                </button>
            </div>
        );
    }

    // UI render methods
    const filterBasedItem = () => {

        let shouldRenderFilterItem = Object.keys(props.selectedFilters).length >= 2
            && 'brand' in props.selectedFilters
            && props.selectedCategoryId

        if(!shouldRenderFilterItem)
            return <></>

        if(props.mode === "linkToHaiTask" || props.mode === "convert")
            return <></>

        return (
            <div
            className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden "
        >
            <span
                className="text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"><h1
                className="text-md font-medium flex justify-center mt-3 mb-2 ">Filter Item</h1> </span>

            <div className="flex justify-center bg-white group-hover:opacity-75 sm:aspect-none scale-75">
                <BasicImage
                    src={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable_spec.jpg'}
                    fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                    alt="Product Image"
                    sizeHeightRem={15}
                />
            </div>

            {/* CATEGORY BRAND DESCRIPTION*/}
            <div className="flex-1 p-4 space-y-2 flex flex-col ">
                <h3 className="mb-3">
                    <span className="flex justify-center capitalize">
                        <span className="text-md font-medium pr-3">Category: </span>{formatAsTitle(props.selectedCategory().category_d)}
                    </span>
                    <span className="flex justify-center capitalize">
                        <span className="text-md font-medium pr-3">Brand: </span>{'brand' in props.selectedFilters ? props.selectedFilters.brand : 'please select a brand'}
                    </span>
                </h3>

                <div className="mb-6 flex justify-center text-align: justify;r">
                  <span className=" text-sm text-center " >
                      {getFilterBasedItemDescription()}
                  </span>
                </div>


            </div>
            <button
                type="button"
                onClick={createManualItem_FilterBasedItem}
                className="inline-flex items-center ml-4 mr-4 mb-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
            >
                {props.mode === "search" && (
                    <span className="ml-auto mr-auto">
                      <PlusCircleIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                      {ignoreNextGenericAccessoriesPrompt ? 'Add to claim (skip accessories)' : 'Add to claim'}
                    </span>
                )}
                {props.mode === "addReplacement" && (
                    <span className="ml-auto mr-auto py-1">
                      <DuplicateIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                      Add as replacement
                    </span>
                )}
            </button>
        </div>
        );
    }

    const aiItemCard = () => {

        if(props.aiDataLacking || !props.aiProduct)
            return <></>

        return (
            <div
                // key={product.id} bg-blue-50
                className="group relative bg-white border border-gray-200 rounded-lg flex flex-col w-1/3 overflow-hidden "
            >
                <span
                    className="text-white bg-fuchsia-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600"><h1
                    className="text-md font-medium flex justify-center mt-3 mb-2 ">AI Item</h1> </span>
                <div className="flex justify-center bg-white group-hover:opacity-75 sm:aspect-none scale-75">
                    <BasicImage
                        src={'/ai_generated_card.png'}
                        fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                        alt="Product Image"
                    />
                </div>

                <div className="flex-1 p-4 space-y-2 flex flex-col align-center items-center ">
                    <h3 className="mb-3">
                        <table className="w-full">

                            <tbody>
                            <tr className="border-b-2 border-gray-200/75">
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && <span className="flex justify-start text-md font-medium pr-3">Category </span> } </td>
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && props.aiProduct.category } </td>
                            </tr>
                            <tr className="border-b-2 border-gray-200/75">
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && <span className="flex justify-start text-md font-medium pr-3">Brand </span> } </td>
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && props.aiProduct.brand } </td>
                            </tr>
                            <tr className="border-b-2 border-gray-200/75">
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && <span className="flex justify-start text-md font-medium pr-3">Released </span> } </td>
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && props.aiProduct.date_released } </td>
                            </tr>
                            <tr className="border-b-2 border-gray-200/75">
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && <span className="flex justify-start text-md font-medium pr-3">Common name </span> } </td>
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && props.aiProduct.common_name } </td>
                            </tr>
                            <tr className="border-b-2 border-gray-200/75">
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && <span className="flex justify-start text-md font-medium pr-3">Model number </span> } </td>
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && (props.aiProduct.model_number || props.aiProduct.model_numbers[0]) || '-' } </td>
                            </tr>
                            <tr className="border-b-2 border-gray-200/75">
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && <span className="flex justify-start text-md font-medium pr-3">Category </span> } </td>
                                <td className="py-2"> {props.aiProduct && props.aiProduct.category && props.aiProduct.category } </td>
                            </tr>
                            {props.aiProduct
                                && props.aiProduct.model_number
                                && props.aiProduct.properties.map(property =>
                                    <tr className="border-b-2 border-gray-200/75">
                                        <td className="py-2"> <span className="flex justify-start text-md font-medium pr-3">{formatAsTitle(property.display_name)} </span> </td>
                                        <td className="py-2"> {property.value} </td>
                                    </tr>
                                    )
                            }
                            </tbody>

                        </table>

                        <div className="divide-y w-full h-2"></div>

                        <div className="divide-y w-full h-2"></div>

                    </h3>

                    <div className="mb-6 flex justify-center text-align: justify;r">
                      <span className=" text-sm text-center " >
                            {props.aiProduct && props.aiProduct.description}
                      </span>
                    </div>
                </div>

                {/* SEARCH */}
                { props.mode === "search" &&
                    <button type="button"
                        onClick={createManualItem_AiBasedItem}
                        className="btn w-[94%] m-4 flex justify-center gap-4"
                    >
                        <PlusCircleIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                        {ignoreNextGenericAccessoriesPrompt ? 'Add to claim (skip accessories)' : 'Add to claim'}
                    </button>
                }

                {/* HAI & CONVERT */}
                { (props.mode === "linkToHaiTask" || props.mode === "convert") &&
                    <button type="button"
                        onClick={generateProto_AiItem}
                        className="btn w-[94%] m-4 flex justify-center gap-4"
                    >
                        { aiGenerationInProgress ?
                            <>
                                <LoadingSpinner size="6" text="hidden" color="rgb(8 145 178)"/>
                                <span className="tooltip">AI product generation in progress</span>
                            </>
                            :
                            <>
                                <SparklesIcon className="h-7 w-7" />
                                Generate Proto Item
                            </>

                        }

                    </button>
                }

                {/* ADD REPLACEMENT */}
                { props.mode === "convert" &&
                    <button type="button"
                        onClick={createManualItem_AiBasedItem}
                        className="btn w-[94%] m-4 flex justify-center gap-4"
                    >
                        <DuplicateIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                        Add as replacement
                    </button>
                }

                {props.mode === "addReplacement" && (
                    <button type="button"
                            onClick={createManualItem_AiBasedItem}
                            className="btn w-[94%] m-4 flex justify-center gap-4"
                    >
                        <DuplicateIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                        Add as replacement
                    </button>
                )}


            </div>
        );
    }

    const haiCard = () => {
        /** This card is displayed when in HAI mode, and there are no products returned from the filter search */

        return (
            <div className="p-5 rounded-lg">

                {/* DELETE HAI TASK */}
                <button className="btn-danger text gap-2"
                        onClick={props.onDeleteHAITask}>
                    <TrashIcon className="h-5 w-5"/>
                    Delete HAI Task
                </button>

                {/* IGNORE HAI TASK */}
                <button className="btn-outline text gap-2"
                        onClick={props.onIgnoreHAITask}>
                    <XIcon className="h-5 w-5"/>
                    Ignore HAI Task
                </button>

            </div>
        );
    };

    const protoProductCard = (product) => {
        return (
            <div
                key={product.id}
                className="w-full bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden transition hover:shadow-xl"
            >
                <div className="cursor-zoom-in aspect-w-4 aspect-h-3 bg-white sm:aspect-none !relative">
                    <div className="">
                        <div
                            className="pointer-events-none w-1/1 h-1/1 group-hover:bg-[#8088962e] absolute invisible group-hover:visible z-10 inset-0 flex flex-col justify-center align-center items-center transition-all hover:drop-shadow-xl ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffffe0"
                                 strokeWidth={2}
                                 className="w-14 h-14">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                            </svg>
                            <p className="text-white mt-4 bg-slate-800 rounded-md py-1 px-2 opacity-75">Specifications</p>
                        </div>
                    </div>

                    <div className='flex justify-center items-center h-full'>

                        {/* PRODUCT IMAGE */}
                        <BasicImage
                            src={product.images && product.images.thumbnail_web}
                            fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                            alt="Product Image"
                            sizeWidthRem="20"
                            sizeHeightRem={20 * 0.75} // 4:3 aspect ratio
                            onClick={() => showProductInfoModal(product)}
                        />

                        {/* AI tag */}
                        {product.ai_generated &&
                            <p className='absolute tailwind-tooltip-container left-3 bottom-3 bg-blue-600/25 hover:bg-blue-600 transition-all w-fit py-0 px-2 text-sm rounded-md text-white'>
                                AI
                                <span className='tailwind-tooltip -top-10 left-0'>This product was AI generated</span>
                            </p>
                        }
                    </div>

                </div>
                <div className="flex-1 p-4 space-y-2 flex flex-col">
                    <h3 className="text-md font-medium">
                        <a href={product.href}> {/* TODO what is this Href? */}
                            <span aria-hidden="true" className="absolute "/>
                            {product.common_name}
                        </a>
                    </h3>
                    <p className="text-sm text-gray-800">{calculateProperties(product)}</p>
                    {!isEmpty(product.model_numbers) ?
                        // List of model numbers
                        <p className="text-sm text-gray-500 truncate">{product.model_numbers.slice(0, 3).join(' | ')}</p>
                        :
                        // Single model number
                        <p className="text-sm text-gray-500">{product.model_number}</p>
                    }
                </div>

                <ProductCardActionButton {...props}
                                         addProtoToClaim={addProtoToClaim}
                                         confirmConvertItem={confirmConvertItem}
                                         confirmAddReplacement={confirmAddReplacement}
                                         product={product}
                                         ignoreNextGenericAccessoriesPrompt={ignoreNextGenericAccessoriesPrompt}
                />

            </div>
        );
    }

    // HTML render functions
    const renderProductCards = () => {

        /** Logic for rendering different cards
            Proto
                # show normal proto cards
                ai_generated - false
                ai_data_lacking - false # always false
                products > 0

            AI item
                # show ai item card
                # show manual item card
                ai_generated - true
                ai_data_lacking - false
                products > 0

            Manual item - 1
                # hide ai item card
                # pre-fill manual item card
                ai_generated - true
                ai_data_lacking - true
                product (if present, prefill)

            Manual item - 2
                # hide ai item card
                # pre-fill manual item card
                ai_generated - false
                ai_data_lacking - false
                products 0 (should be 0, but if data, prefill)

            mode = linkToHaiTask
                hide ai card
                hide manual card
                show generate item button
        */

        // Loading spinner - only if there are no products, and a query is in progress
        if(props.filterQueryInProgress && !props.products) {
            return (
                <div className="h-[20rem] w-full flex justify-center align-center items-center">

                    {/* Spinning Icon */}
                    <svg role="status"
                         className="mr-6 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-sky-600"
                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="white"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="fill-sky-600"></path>
                    </svg>
                    <p className="text-gray-400 text-xl">Searching</p>
                </div>
            );
        }

        // No products - link to HAI task mode or Convert mode
        if (isEmpty(props.products) && (props.mode === 'linkToHaiTask' || props.mode === "convert") ) {
            return (
                <div className="flex flex-wrap justify-around gap-4 w-full ">

                    {/* GENERATE ITEM CARD */}
                    {aiItemCard()}

                    {/* HAI CARD */}
                    {props.mode === 'linkToHaiTask' && haiCard()}

                </div>
            );
        }

        // No products
        if (props.products === null) {
            return (
                <div className="w-full ml-5 mt-5">
                    <svg role="status"
                         className="align-middle inline-block mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"></path>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"></path>
                    </svg>
                    <span>Loading products...</span>
                </div>
            )
        }


        // No products returned
        if (isEmpty(props.products)) {
            return (
                <div className="flex flex-wrap justify-around gap-4 w-full ">

                    {/* MANUAL ITEM CARD */}
                    {manualBasedItem()}

                    {/* AI ITEM CARD */}
                    {aiItemCard()}

                </div>
            );
        }


        return (
            <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 xl:grid-cols-4">

                {/* FILTER BASED ITEM */}
                {filterBasedItem()}

                {props.products.map((product) => protoProductCard(product)) }
            </div>
        );
    }


    return (
        <section aria-labelledby="product-heading"
                 className="col-span-2 col-span-4 widget mr-0">

            {/* TODO : change usage to base modal */}
            <ConfirmModal
                open={confirmModalOpen}
                setOpen={setConfirmModalOpen}
                options={confirmModalOptions}
                onConfirmClicked={onConfirmClicked}
            />

            <h2 id="product-heading" className="sr-only">
                Products
            </h2>

            {safe(renderProductCards)}
        </section>
    );
}

function ProductCardActionButton(props){
    /** There are multiple actions that can be taken on a Product card.
     * - Add to claim
     * - Link to HAI
     * - Add replacement
     * */

    function onClick (){
        if (props.mode === "search") {
            props.addProtoToClaim(props.product.id, props.product);
        } else if (props.mode === "convert") {
            props.confirmConvertItem(props.product.id);
        } else if (props.mode === "addReplacement") {
            props.confirmAddReplacement(props.product.id);
        } else if (props.mode === "linkToHaiTask") {
            /** A product has been selected to "link" to a HAI task. Send the product back to the HAI page (presuming this item search component has been "embedded" in the HAI page) */
            props.onProtoSelectedForHaiTask(props.product, props.haiTask);
        } else if (props.mode === "editProductModal") {
            /** This is called from the EditAndCompareProtoModal */
            props.editModal_setProduct(props.product);
        }
    }


    return (

        <button
            type="button"
            onClick={onClick}
            name={props.product.id}
            className="btn justify-center w-auto m-2"
        >
            {props.mode === "search" && (
              <>
                  <PlusCircleIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                  {props.ignoreNextGenericAccessoriesPrompt ? 'Add to claim (skip accessories)' : 'Add to claim'}
              </>
            )}

            {props.mode === "convert" && (
              <>
                  <RefreshIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                  Convert
              </>
            )}

            {props.mode === "addReplacement" && (
              <>
                  <PlusCircleIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                  Add replacement
              </>
            )}

            {props.mode === "linkToHaiTask" && (
              <>
                  <PlusCircleIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                  Link to HAI task
              </>
            )}

            {props.mode === "editProductModal" && (
              <>
                  <DuplicateIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                  Compare product
              </>
            )}

        </button>

    );

}

function SnappyClaimsButton(props){
    return (
        <div className="flex grow items-center justify-end">
            <button className="btn-light shadow-none m-0 h-14" onClick={() => props.setShowSnappyClaimsModal(true)}>Snappy.Claims self-help</button>
        </div>
    );
}

function SnappyClaimsModal(props) {


    const [qrSize, setQrSize] = useState("25");

    if(!props.claim)
        return <></>


    return (

        // == MODAL ==
        <div
            className={("transition-all fixed top-0 left-0 z-50 h-modal w-full h-full backdrop-brightness-50 ") + (props.showModal ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none")}
            onClick={() => props.setShowModal(false)}
        >
            <div className="flex justify-center align-center w-full h-full ">
                {/* WHITE BOX */}
                <div
                    className="bg-white self-center rounded-lg shadow-2xl  overflow-y-scroll w-7/12 h-10/12"
                    onClick={(e) => {
                        /**
                         this is to prevent the parent grabbing the click event, when the user clicks the white box
                         every click event (except buttons and anchors) 'bubble up' the dom tree to the highest parent with a click handler.
                         the stopProgagation stops the bubbling up the dom tree, preventing the parent gray background receiving the onclick and hiding the modal
                         **/
                        e.stopPropagation();
                    }}
                >

                    {/* HEADER */}
                    <div
                        className="flex justify-between p-4 border-b">
                        <h3 className="text-xl font-semibold text-gray-600">
                            Snappy Claims self-help
                        </h3>
                        <button type="button" onClick={() => props.setShowModal(false)}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                data-modal-hide="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor"
                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                    </div>

                    {/* BODY */}
                    <div className="flex flex-col justify-around space-y-6 px-24 py-12 text-gray-700">

                        {/* EXPLANATION */}
                        <p className="text-center text-lg">Please send the below link to your client to add items to this claim</p>

                        {/* LINK */}
                        <div className="flex justify-center gap-4">
                            <div className="input w-fit px-20 flex justify-center items-center">
                                <ExternalLinkIcon className="w-5 h-5 ml-1 mr-2 text-blue-600" />
                                <a className="text-blue-600 hover:text-blue-800 text-lg" target="_blank" href={`https://www.snappy.claims/claim/${props.claim.id}`} >
                                    https://www.snappy.claims/claim/{props.claim.id}
                                </a>
                            </div>
                        </div>

                        <input type="range" min="10" max="45" value={qrSize} onChange={e=>setQrSize(e.target.value)}
                            className="slider"
                        />

                        {/* QR CODE */}
                        <div className="flex justify-center"
                        >
                            <div
                                style={{width: qrSize + 'rem'}}
                            >
                                <QRCode
                                    value={`https://www.snappy.claims/claim/${props.claim.id}`}
                                    size={128}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    viewBox={`0 0 128 128`}
                                />
                            </div>

                        </div>


                    </div>

                    {/* FOOTER */}
                    <div className="flex justify-between p-4">

                        <button className="btn btn-light mr-0"
                                onClick={() => props.setShowModal(false)}>Close
                        </button>

                        <button className="btn p-3 m-0 w-24 border border-gray-200 shadow rounded-md"
                                onClick={()=>{
                                    navigator.clipboard.writeText(`https://www.snappy.claims/claim/${props.claim.id}`).then(() => {
                                        alert('The link has been copied to your clipboard')
                                    }).catch(error => {
                                        alert(`Failed to copy link - ${error}`)
                                    })
                                }
                                }
                            >
                                <ClipboardListIcon className="h-6 w-6 mr-2" aria-hidden="true" /> Copy
                            </button>
                    </div>
                </div>

            </div>
        </div>

    );

}

function UnsubmittedClaimWarningModal(props) {

  return (
      <div>
      <Transition.Root show={props.open} as={Fragment} >
      <Dialog as="div" className="relative z-10" onClose={props.setOpen} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto" onClick={() => props.setOpen(false)} >
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"  >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >

              {/* MODAL */}
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-1/3 h-1/2 p-6"
                   onClick={(e) => {
                        /**
                         this is to prevent the parent grabbing the click events inside the modal box
                         every click event (except buttons and anchors) 'bubble up' the dom tree to the highest parent with a click handler.
                         the stopProgagation stops the bubbling up the dom tree, preventing the parent gray background receiving the onclick and hiding the modal
                         **/
                        e.stopPropagation();
                    }}>
                <div>

                  {/* HEADING AND BODY */}
                  <div>
                    <div className="flex gap-4 p-2 pb-6 justify-center items-center border-b border-gray-200">
                      <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900 text-center">
                        Please note this claim still needs to be submitted
                      </Dialog.Title>
                    </div>


                      <div className="text-lg text-gray-800 px-6 py-12 space-y-2 text-center">
                        <p>If a claim is ready, it must be submitted.</p>
                        <p>The checkout page allows you to review all items added</p>
                        <p>change item quantities or remove items</p>
                        <p>and submit the claim for quantification.</p>
                      </div>

                  </div>

                  {/* X CLOSE */}
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={()=>props.setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>


                </div>

                {/* FOOTER */}
                <div className="mt-2 mx-20 flex justify-between">
                  <button
                    type="button"
                    className="btn-light shadow-none"
                    onClick={()=>props.setOpen(false)}
                  >
                    Close
                  </button>
                    <button
                    type="button"
                    className="btn-light shadow-none"
                    onClick={()=>props.setOpen(false)}
                  >
                    Add more items
                  </button>
                  <Link
                    to={props.claim !== null ? "/claim/cart-review/" + props.claim?.id : "#"}
                    className="btn-light flex items-center m-0 py-1 px-3 h-auto rounded-md  border-2 border-sky-400"
                    >
                    <span className="text-gray-600">Next</span>
                    <span className="relative inline-block">
                    <div>
                      <svg className="w-10 h-10 text-gray-700 hover:text-sky-700 fill-current" viewBox="0 0 22 22">
                        <path d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z"/>
                      </svg>
                        {props.claim !== null && props.claim.item_count > 0 ? (
                            <span
                                className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1  leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-green-700 rounded-full">
                            {props.claim.item_count}
                          </span>
                        ) : (
                            <span></span>
                        )
                        }
                    </div>

                    </span>
                    </Link>
                </div>

              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

      </div>
  )
}

function ProductsFromAttachmentModal(props) {

    // The individual items detected in the attachment, e.g a Apple iPhone
    const [detectedItems, setDetectedItems] = useState([
        // {
        //     "brand": "Apple",
        //     "category": {
        //         "category_a": "Smartphones, Tablets & Wearables",
        //         "category_d": "SMARTPHONES"
        //     },
        //     "description": "iPhone 14 Pro",
        //     "model_number": "MQ1W3FS/A",
        //     "proto_products": [
        //         {
        //             "common_name": "Apple iPhone 14 Pro 128GB",
        //             "id": "65022581db345be002143cea"
        //         },
        //         {
        //             "common_name": "Apple iPhone 14 Pro 512GB",
        //             "id": "65022587db345be002143cf0"
        //         },
        //     ],
        //     "quantity": 1
        // }
    ]);

    const [showPossibleProducts, setShowPossibleProducts] = useState(false);
    const [activeItem_possibleProducts, setActiveItem_possibleProducts] = useState(null);


    useEffect(() => {
        if (props.showModal)
            calculateItems();

        if(!props.showModal){
            if(!isEmpty(detectedItems)) setDetectedItems([]);
            if(!isEmpty(showPossibleProducts)) setShowPossibleProducts(false);
            if(!isEmpty(activeItem_possibleProducts)) setActiveItem_possibleProducts(null);
        }

    }, [props.showModal, detectedItems]);

    function calculateItems() {
        /* There are 3 types of items to display
        *   1 to 1
        *   Possible options
        *   Manual item
        * Determine which type each item is */

        // Is there parsed attachment data?
        if(isEmpty(props.parsedAttachmentProducts)) return;

        // Have the items already been calculated?
        if(!isEmpty(detectedItems)) return;

        let items = [];
        for (let item of props.parsedAttachmentProducts.claim.possible_items) {

            if (item.proto_products.length === 1) {
                // 1 to 1
                item.type = "1to1";
                item.selected_product = item.proto_products[0];
            } else if (item.proto_products.length > 1) {
                // Multiple options
                item.type = "multiple";
            } else if (item.proto_products.length === 0) {
                // Manual item
                item.type = "manual";
            }

            // Do not include multiple type items by default
            if(item.type === "multiple")
                item.include_item = false;
            else item.include_item = true;

            // Set the item's category
            let cat = props.selectableCategories.find(cat => cat.category_d === item.category.category_d && cat.category_a === item.category.category_a);
            console.log('cat', cat, 'item.category', item.category)
            if(cat)
                item.category = cat;

            items.push(item);

        }

        setDetectedItems(items);

    }

    function addAllProductsToClaim(buttonHandler) {
        // for each selected product, add it to the claim

        let allProducts = []

        // Filter out items that are not to be included
        // If include_item is undefined or null, it is included
        let selectedItems = detectedItems.filter(item => item.include_item !== false);

        for (let item of selectedItems) {

            // PROTOS
            if(!isEmpty(item.selected_product)){
                allProducts.push({
                    quantity: parseInt(item.quantity),
                    proto_id: item.selected_product.id,
                    claimant_quote: parseInt(item.claimant_quote) || null
                })
            }

            // MANUAL ITEMS
            if(item.type === "manual"){
                allProducts.push({
                    quantity: parseInt(item.quantity),
                    category: item.category.id,
                    brand: item.brand,
                    description: item.description,
                    model_number: item.model_number,
                    claimant_quote: parseInt(item.claimant_quote) || null
                })
            }

        }

        function formatProductForMutation(product) {
            return `{${Object.entries(product).map(([key, value]) => {
                return `\n ${key}: ${typeof value === 'string' ? `"${value}"` : value}`;
            }).join(',')} \n}`;
        }

        let mutation = `
            mutation AddAllProductsToClaim{
              add_multiple_products_to_claim(
                claim_id: "${props.claimId}"
                products:[\n${allProducts.map(product => formatProductForMutation(product)).join(',  \n')}\n
                ]
              ){
                error{type, message}
                items{
                  id
                }
              }
            }
        `

        console.log(mutation)

        customGraphRequest(
            mutation,
            (data) => {

                props.showNotificationModal(
                    'Products added',
                    'The products have been added to the claim',
                    ''
                )
                props.setShowModal(false);

                props.updateClaim();

                buttonHandler.onSuccess()
            },
            (error) => {
                buttonHandler.onError()
                props.onError(error)
            }
        )

    }

    function renderPossibleProducts() {

        if (!showPossibleProducts) return <></>;

        function renderProduct(product) {

            return (
                <tr key={product.id} className="hover:bg-white">
                    <td className="p-2 border border-0 border-l-[0.5rem] border-transparent hover:border-sky-200 transition">

                        <div className='px-12 h-full flex justify-between items-center'
                             onClick={() => {
                                 if (!activeItem_possibleProducts.selected_product || activeItem_possibleProducts.selected_product.id !== product.id) {
                                     activeItem_possibleProducts.selected_product = product;
                                     activeItem_possibleProducts.include_item = true;
                                 } else {
                                     activeItem_possibleProducts.selected_product = null;
                                     activeItem_possibleProducts.include_item = false;
                                 }
                                 setDetectedItems([...detectedItems])
                             }}
                        >

                            <div className='w-full flex justify-between gap-6 items-center'>

                                <div className='flex w-fit'>
                                    <BasicImage
                                        src={product.images && product.images.thumbnail_web}
                                        fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                        alt="Product Image"
                                        sizeWidthRem="6"
                                        sizeHeightRem={6 * 0.75} // 4:3 aspect ratio
                                    />

                                    <div className="flex-1 p-4 space-y-2 flex flex-col">
                                        <h3 className="text-md font-medium">
                                            {product.common_name}
                                        </h3>
                                        <p className="text-sm text-gray-800">{calculateProperties(product)}</p>
                                        {!isEmpty(product.model_numbers) ?
                                            // List of model numbers
                                            <p className="text-sm text-gray-500 truncate">{product.model_numbers.slice(0, 3).join(' | ')}</p>
                                            :
                                            // Single model number
                                            <p className="text-sm text-gray-500">{product.model_number}</p>
                                        }
                                    </div>
                                </div>

                                <input
                                    type='checkbox'
                                    className='checkbox p-4'
                                    checked={activeItem_possibleProducts?.selected_product?.id === product?.id}
                                    onChange={(e) => {
                                        if(e.target.checked) {
                                            activeItem_possibleProducts.selected_product = product;
                                        }
                                        else {
                                            activeItem_possibleProducts.selected_product = null;
                                        }
                                        setDetectedItems([...detectedItems])

                                    }}
                                />

                            </div>


                        </div>

                    </td>
                </tr>
            );
        }

        return (
            <div className='ml-[6rem]'>
                <table>
                    <tbody>
                        {activeItem_possibleProducts?.proto_products.map((product) => renderProduct(product))}
                    </tbody>
                </table>
            </div>
        );


    }

    const allProps = {
        ...props,
        detectedItems, setDetectedItems,
        showPossibleProducts, setShowPossibleProducts,
        activeItem_possibleProducts, setActiveItem_possibleProducts,
    }
    return (
        <div>
            <Transition.Root show={props.showModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={props.setShowModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto" onClick={() => props.setShowModal(false)}>
                        <div
                            className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >

                                {/* MODAL */}
                                <div
                                    className="relative w-fit max-h-[70rem] overflow-y-scroll bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all h-1/2 p-6"
                                    onClick={(e) => {
                                        /**
                                         this is to prevent the parent grabbing the click events inside the modal box
                                         every click event (except buttons and anchors) 'bubble up' the dom tree to the highest parent with a click handler.
                                         the stopProgagation stops the bubbling up the dom tree, preventing the parent gray background receiving the onclick and hiding the modal
                                         **/
                                        e.stopPropagation();
                                    }}>
                                    <div>

                                        <div>

                                            {/* HEADING */}
                                            {/* TODO make heading design match upload modal */}
                                            <div
                                                className="flex gap-4 p-2 pb-6 justify-center items-center border-b border-gray-200">
                                                <Dialog.Title as="h3"
                                                              className="text-2xl leading-6 font-medium text-gray-900 text-center">
                                                    <h1 className='p-4 mb-4 font-light text-3xl '>Add items to claim</h1>
                                                </Dialog.Title>
                                            </div>


                                            {/* BODY */}
                                            <div className="flex justify-between">

                                                {/* ITEMS */}
                                                <div className='w-[80] h-[50rem] overflow-y-scroll scrollbar-hide-bottom'>
                                                    {detectedItems.map((item) => <ParsedProduct
                                                        item={item} {...allProps} />)}
                                                </div>

                                                {/* POSSIBLE REPLACEMENTS */}
                                                <div className='w-[40] h-[50rem] overflow-scroll scrollbar-hide-bottom'>
                                                    {renderPossibleProducts()}
                                                </div>

                                            </div>

                                        </div>

                                        {/* X CLOSE */}
                                        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                            <button
                                                type="button"
                                                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => props.setShowModal(false)}
                                            >
                                                <span className="sr-only">Close</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true"/>
                                            </button>
                                        </div>


                                    </div>

                                    {/* FOOTER */}
                                    <div className="mt-2 mx-20 flex justify-between">
                                        <button
                                            type="button"
                                            className="btn-light shadow-none"
                                            onClick={() => props.setShowModal(false)}
                                        >
                                            Close
                                        </button>

                                        <ApiButton
                                            onClick={(buttonHandler) => props.showConfirmModal(
                                                'success',
                                                'Add Selected Items',
                                                'Are you sure you want to add all items to the claim?',
                                                'Add items',
                                                () => addAllProductsToClaim(buttonHandler),
                                                ()=>buttonHandler.onError('Canceled')
                                            )}
                                            className={'btn m-0 w-fit flex justify-center'}
                                            content={(<>
                                                <PlusCircleIcon className="mr-1 inline h-5 w-5 align-top"
                                                                aria-hidden="true"/>
                                                <p>Add selected to claim</p>
                                            </>)}
                                            loadingContent={<LoadingSpinner color='darkcyan' size='6'
                                                                            body=' '/>}
                                            successContent='All items added'
                                            errorContent='Failed to add items'
                                        />

                                    </div>

                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

        </div>
    )
}

function ParsedProduct(props){

    const item = props.item;

    function getSelectableMainCategories() {
        /** The main categories are found in the ProductCategory enum type in GraphQL
         enum ProductCategory {
         ACTION_SPORTS_CAMERAS
         AUDIO_AMPLIFIERS
         BLENDERS
         CAMCORDERS
         CAMERA_DRONES
         COFFEE_MAKERS
         COMPUTER_MONITORS
         DECODERS
         DIGITAL_CAMERAS
         DISHWASHERS
         ELECTRIC_KETTLES
         FREEZERS
         FRIDGES
         FRIDGE_FREEZERS
         GAME_CONSOLES
         HANDHELD_VACUUMS
         HEADPHONES
         HOBS
         INKJET_PRINTERS
         IRONS
         LASER_PRINTERS
         MICROWAVES
         NOTEBOOKS
         OVENS
         ROBOT_VACUUMS
         SIDE_BY_SIDE_FRIDGE_FREEZERS
         SMARTPHONES
         SMARTWATCHES
         SOUNDBARS
         TABLETS
         TOASTERS
         TUMBLE_DRYERS
         TVS
         UNKNOWN
         VACUUMS
         WASHER_DRYERS
         WASHING_MACHINES
         }*/

        // create a list of each category_a
        let catA = props.selectableCategories.map(category => category.category_a);

        // because "category_a" is repeated in many of the categories, but we only want 1 choice to appear in the dropdown
        // remove all the repeated category_a. only keep 1 instance of each category_a
        let uniqueCategoryA = [...new Set(catA)]; // list of strings, each element is a category_a. unique due to being a Set datatype
        // sort list of category_a by alphabetical order
        uniqueCategoryA.sort((a, b) => a.localeCompare(b));
        let uniqueCategories = [];
        for (const category_a of uniqueCategoryA) {
            let fullCategoryData = props.selectableCategories.find(cat => cat.category_a === category_a);
            if (fullCategoryData)
                uniqueCategories.push(fullCategoryData);
        }
        return uniqueCategories;
    }

    function getSelectableSubCategories() {
        if (!props.selectableCategories) return [];
        if (isEmpty(item.category)) return [];

        let selectable = props.selectableCategories.filter(cat => cat.category_a === item.category.category_a);

        return selectable;
    }


    function renderItem() {

        function protoCard(item) {

            let product = item;
            if(item.selected_product) product = item.selected_product;
            const image = product.images?.thumbnail_web || 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png';

            return (
                <div className='flex items-center'>
                    {/* PRODUCT IMAGE */}
                    <img src={image} alt="Product Image" className="w-24 h-24 object-contain rounded-md"/>

                    {/* INFO */}
                    <div className="flex-1 p-4 space-y-2 flex flex-col">
                        <h3 className="text-md font-medium">
                             {product.common_name || product.description}
                        </h3>
                        <h3 className="text-sm text-gray-400">
                            {product.brand} | {product.model_number} | {formatAsTitle(product.category)}
                        </h3>
                        {/*<p className="text-sm text-gray-800">{calculateProperties(product)}</p>*/}
                        {!isEmpty(product.model_numbers) ?
                            // List of model numbers
                            <p className="text-sm text-gray-500 truncate">{product.model_numbers.slice(0, 3).join(' | ')}</p>
                            :
                            // Single model number
                            <p className="text-sm text-gray-500">{product.model_number}</p>
                        }
                    </div>

                </div>
            );

        }

        function manualCard(item) {

            return (
                <div className='flex items-center'>

                    {/* PRODUCT IMAGE */}
                    <BasicImage
                        src={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                        fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                        alt="Product Image"
                        sizeWidthRem="6"
                        sizeHeightRem={6 * 0.75} // 4:3 aspect ratio
                    />

                    {/* INFO */}
                    <div className="flex-1 p-4 space-y-2 flex flex-col">

                        <div className='flex gap-4'>

                            {/* BRAND */}
                            <Tooltip content="Brand">
                                <input
                                    value={item.brand}
                                    placeholder='brand'
                                    onChange={(e) => {
                                        item.brand = e.target.value;
                                        props.setParsedAttachmentProducts({...props.parsedAttachmentProducts})
                                    }}
                                />
                            </Tooltip>

                            {/* MODEL NUMBER */}
                            <Tooltip content="Model number">
                                <input
                                    value={item.model_number}
                                    placeholder='model'
                                    onChange={(e) => {
                                        item.model_number = e.target.value;
                                        props.setParsedAttachmentProducts({...props.parsedAttachmentProducts})
                                    }}
                                />
                            </Tooltip>

                        </div>

                        {/* DESCRIPTION */}
                        <Tooltip content="Description">
                            <input
                                value={item.description}
                                placeholder='description'
                                onChange={(e) => {
                                    item.description = e.target.value;
                                    props.setParsedAttachmentProducts({...props.parsedAttachmentProducts})
                                }}
                            />
                        </Tooltip>


                        {/* CATEGORY */}
                        <div className='flex gap-2 min-w-[30rem]'>
                            <select className="p-2 input text-xs"
                                    value={item.category ? item.category.category_a : ''}
                                    onChange={(e) => {
                                        let mainCat = props.selectableCategories.find(cat => cat.category_a === e.target.value);
                                        if (!mainCat) return;
                                        item.category = mainCat;
                                        props.setDetectedItems([...props.detectedItems])
                                    }}>

                                {getSelectableMainCategories().map(cat =>
                                    <option
                                        value={cat.category_a}>{formatAsTitle(cat.category_a)}
                                    </option>)
                                }
                            </select>

                            <select className="p-2 input text-xs"
                                    value={item.category ? item.category.id : ''}
                                    onChange={(e) => {
                                        let newCat = props.selectableCategories.find(cat => cat.id === e.target.value)
                                        if (!newCat) return;
                                        item.category = newCat;
                                        props.setDetectedItems([...props.detectedItems])
                                    }}>

                                {/* if category data has been fetched, and a main category has been selected, then filter all the categories to get the categories where "category_a" equals the main selected categories "category_a" */}
                                {getSelectableSubCategories().map(category => {
                                    if (category.category_c) // to prevent "duplicate" options try C first, then D, then B
                                        return <option value={category.id}>{category.category_c}</option>;
                                    if (category.category_d && category.category_d != 'UNKNOWN')
                                        return <option
                                            value={category.id}>{formatAsTitle(category.category_d)}</option>;
                                    if (category.category_b)
                                        return <option value={category.id}>{category.category_b}</option>;
                                    if (category.category_a)
                                        return <option value={category.id}>{category.category_a}</option>;
                                })}

                            </select>
                        </div>


                    </div>

                </div>
            );
        }

        return (
            <>
                <div className={classNames(
                    'flex justify-between gap-6 items-center p-2',
                    props.activeItem_possibleProducts === item ? 'outline outline-2 outline-sky-200 rounded-md' : ''
                )}>

                    <div>
                        {(item.type === "1to1" || item.type === "multiple") && protoCard(item)}
                        {item.type === "manual" && manualCard(item)}
                    </div>

                    {/* ACTIONS */}
                    <div className='w-1/3'>
                        <div className='flex gap-4 items-center'>

                            <Tooltip content="Custom quote">
                                <input
                                    className='input'
                                    placeholder={currencySymbol(props.claim)}
                                    value={item.claimant_quote}
                                    onChange={(e) => {
                                      item.claimant_quote = e.target.value;
                                      props.setParsedAttachmentProducts({...props.parsedAttachmentProducts})
                                    }}
                                />
                            </Tooltip>

                            <Tooltip content="Quantity">
                                <select
                                    className='select'
                                    value={item.quantity}
                                    onChange={(e) => {
                                      item.quantity = parseInt(e.target.value);
                                      props.setParsedAttachmentProducts({...props.parsedAttachmentProducts})
                                    }}
                                >
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                    <option value='5'>5</option>
                                    <option value='6'>6</option>
                                    <option value='7'>7</option>
                                    <option value='8'>8</option>
                                    <option value='9'>9</option>
                                    <option value='10'>10</option>
                                </select>
                            </Tooltip>

                            <Tooltip content="Include this item">
                                <input
                                    className='checkbox p-4'
                                    type='checkbox'
                                    checked={item.include_item}
                                    onChange={(e) => {
                                        // If the user wants to include this item, confirm that a product has been selected for this item
                                        if(e.target.checked && item.type !== "manual" && !item.selected_product && !isEmpty(item.proto_products)){
                                            props.showAlertModal(
                                                'info',
                                                'Select a product',
                                                'Please select a product for this item so it may be included',
                                            )
                                        } else {
                                            item.include_item = e.target.checked;
                                            props.setDetectedItems([...props.detectedItems])
                                        }
                                    }}
                                />
                            </Tooltip>
                        </div>

                        <button
                            className='btn-outline mx-0 w-full justify-center '
                            onClick={() => {
                                /** Toggle the possible products panel */

                                // CLOSE (if re-clicking the "possible replacements" button on the same item)
                                // This compares via the objects memory address
                                if (props.activeItem_possibleProducts === item) {
                                    // Close the possible products panel
                                    props.setActiveItem_possibleProducts(null);
                                    props.setShowPossibleProducts(false);
                                }

                                // OPEN (if clicking the "possible replacements" button on a different item)
                                else {
                                    // Open the possible products panel
                                    props.setActiveItem_possibleProducts(null);
                                    setTimeout(() => props.setActiveItem_possibleProducts(item), 100);
                                    props.setShowPossibleProducts(true);
                                }
                            }}
                        >
                            {item.proto_products.length} possible products
                        </button>

                    </div>

                </div>

                <div className='divider_CRUD'></div>
            </>

        );

    }

    return renderItem();

}

import {Menu, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import {isEmpty} from "../../utils/helpers";
import {ChevronDownIcon} from "@heroicons/react/solid";

export function DropdownMenu(props) {

    const optionGroups = isEmpty(props.optionGroups) ? {} : props.optionGroups;

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                {props.mainButton}
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute duration-500 right-0 mt-2 w-56 origin-top-right divide-y-4 divide-gray-100 bg-white shadow-lg rounded-md focus:outline-none">


                    {optionGroups.map(options => (
                        <div className="px-2 py-2 space-y-2">
                            {options.map(option =>
                                <Menu.Item>
                                    {props.renderOption(option)}
                                </Menu.Item>
                            )}
                        </div>
                        ))}

                </Menu.Items>
            </Transition>
        </Menu>
    );

}

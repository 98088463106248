// Library imports

import {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';

import {PencilAltIcon, QuestionMarkCircleIcon, XIcon} from '@heroicons/react/solid';

// Local imports
import CategoryBox from '../../components/CategoryBox.jsx';

import {updateManualItem} from '../../utils/coreApi.js';
import {isEmpty} from '../../utils/helpers.js';


// Constants

export default function UpdateManualItemModal(props){

    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {

        let item = props.item;

        if (!props.open || item === null || selectedCategory !== null) {
            return;
        }

        if (item.category === null) {

            setSelectedCategory({
                'id': '',
                'category_d': '',
                'display_name': ''
            });
        } else if (selectedCategory === null || item.category.id !== selectedCategory['id']) {

            setSelectedCategory({
                'id': item.category.id,
                'category_d': item.category.category_d,
                'display_name': item.category.display_name
            });
        }

    });

    const setOpen = (open) => {

        props.setOpen(open);

        if (!open) {
            setSelectedCategory(null);
        }
    };

    // onClick functions

    const onUpdateManualItemClicked = (event) => {

        let item = props.item;

        let brand = document.getElementById('brand').value;
        let description = document.getElementById('description').value;
        let modelNumber = document.getElementById('modelNumber').value;
        let eanCode = document.getElementById('eanCode').value;
        let quantity = document.getElementById('quantity').value;

        updateManualItem({
                itemId:item.id,
                categoryId:selectedCategory['id'],
                brand:brand,
                description:description,
                modelNumber:modelNumber,
                eanCode:eanCode,
                quantity:quantity
            },
            props.onSuccess,
            props.onError
        );

        event.stopPropagation();
        event.preventDefault();
        return false;
    };


    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {
            }}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                      &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle pr-6 pl-6 pt-3">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center mt-1 h-10 w-10 rounded-full bg-cyan-600 sm:mx-0 sm:h-10 sm:w-10">
                                    <PencilAltIcon className="text-white h-6 w-6" aria-hidden="true"/>
                                </div>
                                <div className="font-bold text-xl text-left ml-4">
                                    Update Manual Item
                                    <p className="text-sm font-normal text-gray-600">
                                        Update item information below.
                                    </p>
                                </div>
                            </div>

                            <div className="w-full mt-3 border-t border-gray-300">
                            </div>

                            <div className="mt-5">
                                <div className="flow-root">
                                    <div className="flex-1 flex flex-col justify-between">
                                        <div className="divide-y divide-gray-200">
                                            <div className="space-y-6 pb-5">
                                                {/*<div className="text-sm text-gray-800">At least two fields are required.</div>*/}
                                                <div>
                                                    <label htmlFor="category" className="block text-sm font-medium text-gray-900">
                                                        Category
                                                    </label>
                                                    <div className="mt-1">
                                                        {/*{renderCategoryBox()}*/}
                                                        <CategoryBox
                                                            categories={props.categories}
                                                            selectedCategory={selectedCategory}
                                                            setSelectedCategory={setSelectedCategory}
                                                            allowEmptySelection={false}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="brand" className="block text-sm font-medium text-gray-900">
                                                        Brand
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            defaultValue={props.item !== null ? props.item.product.brand : ''}
                                                            name="brand"
                                                            id="brand"
                                                            className="input"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="modelNumber" className="block text-sm font-medium text-gray-900">
                                                        Model number
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            defaultValue={props.item !== null && !isEmpty(props.item.product.model_numbers) ? props.item.product.model_numbers[0] : ''}
                                                            name="modelNumber"
                                                            id="modelNumber"
                                                            className="input"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="eanCode" className="block text-sm font-medium text-gray-900">
                                                        Bar code (EAN)
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            defaultValue={props.item !== null && !isEmpty(props.item.product.ean_codes) ? props.item.product.ean_codes[0] : ''}
                                                            name="eanCode"
                                                            id="eanCode"
                                                            className="input"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                                        Brief description<span className="font-normal"> (max 100 characters)</span>
                                                    </label>
                                                    <div className="mt-1">
                                                    <textarea
                                                        type="text"
                                                        cols="40"
                                                        rows="5"
                                                        defaultValue={props.item !== null ? props.item.product.description : ''}
                                                        name="description"
                                                        id="description"
                                                        className="input"
                                                    ></textarea>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="quantity" className="block text-sm font-medium text-gray-900">
                                                        Quantity
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="number"
                                                            defaultValue={props.item !== null ? props.item.quantity : '1'}
                                                            name="quantity"
                                                            id="quantity"
                                                            className="input"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-600 hover:bg-cyan-700 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={onUpdateManualItemClicked}
                                >
                                    Update
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={() => setOpen(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

// Library imports
import React, {useEffect} from "react";

// Local imports
import {logoutApi} from "../../utils/coreApi.js";


// Logout container
const Logout = (props) => {

    // On every page load
    useEffect(() => {

        clearUser();

        logoutApi(successCallback, errorCallback);
    }, []);

    const clearUser = () => {

        // clear all local storage
        localStorage.clear();

        // clear all props
        props.setAuthenticated?.(false)
        props.setUser?.(null)
        props.setOrganisations?.(null)
        props.setSelectedCountry?.({name: '', code: ''})

    }

    const successCallback = (data) => {
        /** We want to cause a page reload, rather than to navigate, as an easy method to clear all the state data */
        // navigate('/login');
        window.location.href = '/login'
    };

    const errorCallback = (error) => {
        window.location.href = '/login'
    };


    // Page content
    return (
        <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        </div>
    );
};

export default Logout;

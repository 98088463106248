// Library imports
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {PencilAltIcon, TrashIcon} from '@heroicons/react/outline';
import {ArrowCircleLeftIcon, CheckCircleIcon, ChevronRightIcon, InformationCircleIcon} from '@heroicons/react/solid';


// Local imports
import NotificationTopRight from '../../components/NotificationTopRight.jsx';

import AlertModal from '../modals/AlertModal.jsx';
import ConfirmModal from '../modals/ConfirmModal.jsx';
import UpdateQuotePriceModal from '../modals/quote_modals/UpdateQuotePriceModal.jsx';

import {
    approvePostTenderItem,
    deleteTender,
    fetchClaim,
    queryCategories,
    updateQuotePrice
} from '../../utils/coreApi.js';

import {alertError, calculateProperties, classNames} from '../../utils/helpers.js';
import Safe, {safe} from "../../components/Safe";
import {useDocumentTitle} from "../../components/PageTitle";
import {BasicImage} from "../../components/base/BasicImage";


export default function ClaimPostTenderInspection(props) {

    const setTitle = useDocumentTitle("Post Tender");

    const displayStatuses = {
        'DECLINED_TENDER': 'Declined tender',
        'NOT_INVITED_TO_TENDER': 'Not invited to tender',
        'TENDER_COMPLETED': 'Tender completed',
        'TENDER_DELETED': 'Tender deleted',
        'TENDER_PENDING': 'Tender pending',
        'UNABLE_TO_TENDER': 'Unable to tender'
    };

    const statusStyles = {
        'DECLINED_TENDER': 'bg-red-600 text-white',
        'NOT_INVITED_TO_TENDER': 'bg-yellow-600 text-white',
        'TENDER_COMPLETED': 'bg-green-600 text-white',
        'TENDER_DELETED': 'bg-red-600 text-white',
        'TENDER_PENDING': 'bg-blue-800 text-white',
        'UNABLE_TO_TENDER': 'bg-red-600 text-white'
    };

    // React router
    const navigate = useNavigate();
    const {claimId} = useParams();

    // Used for errors
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        'heading': '',
        'message': ''
    });

    // Used for success feedback
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState({
        'heading': '',
        'message': ''
    });

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalOptions, setConfirmModalOptions] = useState({
        'heading': '',
        'message': '',
        'type': '',
        'buttonText': ''
    });

    const [updateManualQuoteOpen, setUpdateManualQuoteOpen] = useState(false);
    const [updateManualQuoteOptions, setUpdateManualQuoteOptions] = useState(null);

    // Data states
    const [claim, setClaim] = useState(null);
    const [currencySymbol, setCurrencySymbol] = useState(null);

    const [categories, setCategories] = useState(null);

    // On every page load
    useEffect(() => {

        if (claim === null) {
            fetchClaim(claimId, setClaim, navigate);
        }
        if (categories === null) {
            queryCategories(categories, setCategories, (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            });
        }

        }, []
    );

    useEffect(() => {
        try {
            if (claim !== null) {
                setCurrencySymbol(claim.organisation?.info?.currency_symbol || ' ');
            }
        } catch (error) {
            if (!currencySymbol) {
                setCurrencySymbol(' ');
            }
        }

        if (claim && !props.navbarTopPageContent.claim) props.setNavbarTopPageContent({'claim': claim})

        if(claim) setTitle(`Post Tender : ${claim.insurer_claim_number}`)

    }, [claim, currencySymbol]);


    // onClick functions

    const onApproveAll = () => {
        props.showConfirmModal(
            'success',
            'Approve all items',
            'Are you sure you want to approve all items?',
            'Approve all',
            () => approveAllItems(),
        )
    }

    const approveAllItems = () => {

        // let items = claim.items.filter(item => item.is_tender_item && item.status !== 'UNABLE_TO_TENDER');
        let items = claim.items.filter(item => item.is_tender_item && item.status == 'POST_TENDER_INSPECTION');

        let itemIds = items.map(item => item.id);

        const delay = 500

        props.showToastNotificationModal(
            'success',
            'Submitting...',
            ''
        );

        itemIds.forEach( (id, i) => {

            setTimeout(() => {

                approvePostTenderItem(
                    id,
                (data) => {
                    props.showToastNotificationModal(
                        'success',
                        'Item approved',
                        ''
                    );

                    fetchClaim(claimId, setClaim, navigate);
                },
                (error) => props.onError(error)
            );

            }, i * delay)

        });

    }

    const onApproveItem = (itemId) => {

        approvePostTenderItem(
            itemId,
            (data) => {
                setNotificationMessage({
                    'heading': 'Item approved',
                    'message': 'Post tender inspection finished for item!'
                });
                setNotificationOpen(true);
                setTimeout(() => {
                    setNotificationOpen(false);
                }, 1000);

                // find this item in the claim state, and change its status to 'QUANTIFIED'
                try {
                    let claimCopy = {...claim};
                    claim.items.find(item => item.id === itemId).status = 'QUANTIFIED';
                    setClaim(claimCopy);
                } catch (e){
                    console.log('Error updating claim item status: ', e)
                }

                fetchClaim(claimId, setClaim, navigate);
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            }
        );
    };

    const onUpdateQuotePrice = (options, buttonHandler) => {

        let item = options.item;
        let quote = options.quote;
        let price = parseFloat(options.price);

        let errorMessage = null;

        if (isNaN(price)) {
            errorMessage = 'Please enter a valid number.';
        } else if (price === quote.price) {
            errorMessage = 'The price has not been changed.';
        }

        if (errorMessage !== null) {
            let error = {
                'type': 'Error: Invalid Input',
                'message': errorMessage
            };
            alertError(setAlertOpen, setAlertOptions, error);
            buttonHandler.onError('Invalid input');
        }

        updateQuotePrice(
            item.id,
            quote.id,
            price,
            options.update_catalog_price,
            false,
            (data) => {
                setUpdateManualQuoteOpen(false);
                fetchClaim(claimId, setClaim, navigate);
                buttonHandler.onSuccess('Quote price updated');
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
                buttonHandler.onError('Failed to update quote price');
            }
        );

    };

    const promptUpdateManualQuote = (item, quote) => {

        setUpdateManualQuoteOptions({
            'item': item,
            'quote': quote
        });

        setTimeout(() => {
            setUpdateManualQuoteOpen(true);
        }, 50);
    };

    const promptDeleteManualQuote = (item, quote) => {

        if (quote === null) {
            return;
        }

        let message = `Please confirm deletion of the ${quote.supplier.unique_reference_name} quote. This action cannot be undone.`;
        let type = 'warning';
        let buttonText = 'Delete';

        setConfirmModalOptions({
            'heading': 'Delete tender',
            'message': message,
            'type': type,
            'buttonText': buttonText,
            'itemId': item.id,
            'quoteId': quote.id,
            'onConfirmClicked': (options) => {
                onDeleteManualQuote(options);
            }
        });

        setConfirmModalOpen(true);
    };

    const onDeleteManualQuote = (options) => {

        deleteTender(
            options.itemId, options.quoteId,
            (data) => {
                setNotificationMessage({
                    'heading': 'Tender deleted',
                    'message': 'Deleted tender for supplier.'
                });
                setNotificationOpen(true);
                setTimeout(() => {
                    setNotificationOpen(false);
                }, 1000);

                fetchClaim(claimId, setClaim, navigate);
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            }
        );
    };

    // HTML render functions

    const renderReplacement = (item) => {

        if (item.replacement === null || item.replacement.product === null) {
            return <div></div>;
        }

        let displayPrice = currencySymbol + ` ${parseFloat(item.replacement.quote.price).toFixed(2)}`;

        return (
            <div className="sm:flex lg:col-span-5">
                <BasicImage
                    src={item.replacement?.product?.images?.thumbnail_web}
                    fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                    alt="Product Image"
                    sizeWidthRem="16"
                    sizeHeightRem={16 * 0.75} // 4:3 aspect ratio
                    className="rounded-md"
                />

                <div className="mt-6 sm:mt-0 sm:ml-6">
                    <h3 className="text-lg font-medium text-gray-900">
                        <a href="#">{item.replacement.product.common_name}</a>
                    </h3>
                    <p className="mt-2 text-sm font-medium text-gray-900">{item.replacement.product.type === 'MANUAL' ? item.replacement.product.common_name : calculateProperties(item.replacement.product)}</p>
                    <p className="mt-2 text-gray-900">{item.replacement.quote.supplier.unique_reference_name}</p>
                    <p className="mt-2 font-medium text-gray-900">{displayPrice}</p>
                    {/*TODO:Implement best quote*/}
                    <span
                        className="mt-2 text-white bg-green-600 inline-flex items-center px-2.5 py-0.5 rounded-full font-medium capitalize"
                    >
                        Lowest quote
                    </span>
                </div>
            </div>
        );
    };

    const renderItems = () => {

        if (claim === null) {
            return (
                <div className="px-4 sm:px-6 lg:px-8 mt-5">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <svg role="status"
                                 className="align-middle inline-block mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"></path>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"></path>
                            </svg>
                            <span>Loading...</span>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="px-8">
                {claim.items.map((item) => safe(()=>renderItem(item)))}
            </div>
        );
    };

    const renderItem = (item) => {

        if (!item.is_tender_item || item.status === 'UNABLE_TO_TENDER') {
            return (<div></div>);
        }

        return (
            <div className='widget'>
                <div className="flex items-center mb-3">
                    <div className="sm:flex-auto">
                        <h1 className="text-2xl font-semibold text-gray-900 ml-1">
                            {item['product']['common_name']}
                        </h1>
                    </div>
                    {item.status !== 'QUANTIFIED' &&
                        (
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                <button
                                    type="button"
                                    onClick={() => onApproveItem(item.id)}
                                    className="tooltip-container btn-success"
                                >
                                    <span className="ml-auto mr-auto">
                                        <CheckCircleIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                                        Approve item
                                    </span>
                                    <span
                                        style={{marginTop: '75px', marginLeft: '-2px', width: '120px'}}
                                        className="tooltip"
                                    >
                                      Approve item
                                    </span>
                                </button>
                            </div>
                        )
                    }
                </div>

                <div
                    key={item.id}
                    className=""
                >
                    <div className="py-6 px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">


                        {/* REQUESTED PRODUCT */}
                        <div className="sm:flex lg:col-span-5">
                            <div className="flex-shrink-0 w-60 h-45 rounded-lg overflow-hidden">
                                <BasicImage
                                    src={item.product.images?.thumbnail_web}
                                    fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                    alt="Product Image"
                                    sizeWidthRem="14"
                                    sizeHeightRem={14 * 0.75} // 4:3 aspect ratio
                                    className="rounded-md"
                                />
                            </div>

                            <div className="mt-6 sm:mt-0 sm:ml-6">
                                <h3 className="text-lg font-medium text-gray-900">
                                    <a href="#">{item.product.common_name}</a>
                                </h3>
                                <p className="mt-2 text-sm font-medium text-gray-900">{item.product.type === 'MANUAL' ? item.product.common_name : calculateProperties(item.product)}</p>
                                {item.product.model_number !== null ? (
                                    <p className="mt-3 text-sm text-gray-500">{item.product.model_number}</p>
                                ) : (
                                    <p className="mt-3 text-sm text-gray-500">{item.product.model_numbers.slice(0, 3).join(' | ')}</p>
                                )}
                            </div>
                        </div>

                        {/* > */}
                        <div className="sm:flex lg:col-span-1">
                            <ChevronRightIcon
                                className="h-15 w-15 text-gray-300 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                        </div>

                        {/* RECOMMENDED REPLACEMENT PRODUCT */}
                        {safe(()=>renderReplacement(item))}
                    </div>
                </div>

                {/* QUOTES */}
                {safe(()=>renderSuppliers(item))}
            </div>
        );
    };

    const renderSuppliers = (item) => {

        if (item.status === 'QUANTIFIED') {
            return (<div></div>);
        }

        return (
            <div
                // className="-mx-4 mt-3 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg"
            >
                <table className='m-0'>
                    <thead>
                        <tr>
                            <th>
                                Supplier
                            </th>
                            <th>
                                Recommended Replacement
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Unit Price
                            </th>
                            <th>
                                Quantity
                            </th>
                            <th>
                                Sub-Total
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 bg-white">
                        {item.supplier_panel.map((supplierData) => (
                            safe(()=>renderSupplierReplacement(supplierData, item))
                        ))}
                    </tbody>

                </table>
            </div>
        );
    };

    const renderSupplierReplacement = (supplierData, item) => {

        let supplier = supplierData['supplier'];

        let replacementData = supplierData['recommended_replacement'];
        let replacementProduct = null;
        let supplierStatus = supplierData['status'];

        if (replacementData !== null && replacementData['product'] !== null) {
            replacementProduct = replacementData['product'];
        }

        let hasPreferredReplacement = (replacementProduct !== null);

        let replacementCommonName = 'Not tendered';

        let displayPrice = '-';
        let displaySubTotal = '-';
        let quote = null;

        if (hasPreferredReplacement) {

            replacementCommonName = replacementProduct.common_name;

            quote = replacementData.quote;
            // displayPrice = `${quote.currency_symbol} ${quote.price}`;
            displayPrice = currencySymbol + ` ${parseFloat(quote.price).toFixed(2)}`;

            let subTotalPrice = quote.price * item.quantity;
            // displaySubTotal = `${quote.currency_symbol} ${subTotalPrice}`;
            displaySubTotal = currencySymbol + ` ${parseFloat(subTotalPrice).toFixed(2)}`;
        }

        let isCompleted = (supplierStatus === 'TENDER_COMPLETED');

        let rowBgColor = '';

        if (supplierStatus === 'TENDER_COMPLETED') {
            rowBgColor = 'bg-green-100';
        } else if (supplierStatus === 'NOT_INVITED_TO_TENDER') {
            rowBgColor = 'bg-yellow-100';
        } else if (supplierStatus !== 'TENDER_PENDING') {
            rowBgColor = 'bg-red-100';
        }

        return (
            <tr key={item.id} className={rowBgColor}>
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                            <BasicImage
                                src={supplier.info.logo_image_url}
                                fallbackSrc={'/org-logo-placeholder.jpg'}
                                alt="logo"
                                sizeWidthRem="2.6"
                                className="rounded-full"
                            />
                        </div>
                        <div className="ml-4">
                            <div
                                className={classNames('text-gray-900', 'font-medium')}>{supplier.info.name}</div>
                        </div>
                    </div>
                </td>
                <td className="">
                    <div className="flex items-center">
                        <BasicImage
                            src={replacementProduct?.images?.thumbnail_web}
                            fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                            alt="Product Image"
                            sizeWidthRem="2.5"
                            className="rounded-md"
                        />
                        <div className="ml-4">
                            <div className="text-gray-900 font-medium">{replacementCommonName}</div>
                        </div>
                    </div>
                </td>
                <td className="">
                    <div className='flex justify-start'>
                        <span
                            className={classNames(
                                statusStyles[supplierStatus],
                                'inline-flex items-center px-2.5 py-0.5 rounded-full font-medium capitalize'
                            )}
                        >
                                {displayStatuses[supplierStatus]}
                        </span>
                    </div>
                </td>
                <td className="">{displayPrice}</td>
                <td className="">{item.quantity}</td>
                <td className="">{displaySubTotal}</td>
                <td className="">
                    <button
                        disabled={!isCompleted ? 'disabled' : undefined}
                        onClick={() => promptUpdateManualQuote(item, quote)}
                        className={classNames(
                            !isCompleted ? 'text-gray-400' : 'text-cyan-600 hover:text-cyan-500 tooltip-container',
                            'mr-1 inline-flex items-center text-sm font-medium rounded-md'
                        )}
                    >
                        <PencilAltIcon className="h-7 w-7" aria-hidden="true"/>
                        <span className="tooltip">Edit quote</span>
                    </button>
                    <button
                        disabled={!isCompleted ? 'disabled' : undefined}
                        onClick={() => promptDeleteManualQuote(item, quote)}
                        className={classNames(
                            !isCompleted ? 'text-gray-400' : 'text-red-600 hover:text-red-400 tooltip-container',
                            'mr-1 inline-flex items-center text-sm font-medium rounded-md'
                        )}
                    >
                        <TrashIcon className="h-7 w-7" aria-hidden="true"/>
                        <span className="tooltip">Delete tender</span>
                    </button>
                </td>
            </tr>
        );
    };

    return (
        <>
            <div className="">

                {/* TODO : change usage to base modal */}
                <NotificationTopRight
                    open={notificationOpen}
                    setOpen={() => {
                    }}
                    message={notificationMessage}
                />

                {/* TODO : change usage to base modal */}
                <AlertModal
                    open={alertOpen}
                    setOpen={setAlertOpen}
                    options={alertOptions}
                />

                <Safe>
                    <UpdateQuotePriceModal
                        {...props}
                        open={updateManualQuoteOpen}
                        setOpen={setUpdateManualQuoteOpen}
                        options={updateManualQuoteOptions}
                        onConfirmClicked={onUpdateQuotePrice}
                        onError={(error) => {
                            alertError(setAlertOpen, setAlertOptions, error);
                        }}
                    />
                </Safe>

                <div className="flex flex-col flex-1">

                    {/* TODO : change usage to base modal */}
                    <ConfirmModal
                        open={confirmModalOpen}
                        setOpen={setConfirmModalOpen}
                        options={confirmModalOptions}
                        onConfirmClicked={() => {
                        }}
                    />

                    <main className="flex-1 pb-8">
                        {/* Page header */}
                        {Header(claim, navigate)}

                        {/* APPROVE ALL */}
                        {ApproveAll(claim, onApproveAll)}

                        <div>
                            {safe(renderItems)}
                        </div>

                    </main>
                </div>

            </div>
        </>
    );
}

function Header(claim, navigate){

    const renderInstructions = () => {

        if (claim !== null && claim.status_reason !== 'Post tender inspection') {
            return (<div></div>);
        }

        return (
            <div className="page-header-bar widget mt-0 mx-8 w-fit rounded-md bg-blue-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                        <div>
                            <p className="text-sm font-medium text-blue-700">
                                There are two actions that can be taken per supplier on items during post tender
                                inspection:
                            </p>
                            <ul role="list" className="list-disc text-sm mt-2 text-left text-blue-700">
                                <li>Edit the quote of a supplier</li>
                                <li>Delete tender for a supplier</li>
                            </ul>
                        </div>
                        <p className="mt-3 text-sm md:mt-0 md:ml-6">
                            <a href="#" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                                Learn more <span aria-hidden="true">&rarr;</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const renderFinishedInstructions = () => {

        if (claim === null || claim.status_reason === 'Post tender inspection') {
            return (<div></div>);
        }

        let message = 'Post tender inspection has been completed for this claim.';

        if (claim.status_reason === 'Post tender inspection') {
            message += ' Post tender inspection is in progress at the moment.';
        }

        return (
            <div className="page-header-bar widget mt-0 mx-8 w-fit rounded-md bg-green-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800">Post tender inspection completed</h3>
                        <div className="mt-2 text-sm text-green-700">
                            <p>{message}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="page-header-bar">
                <h3 className="page-header-title">Post Tender Inspection</h3>

                <div
                    onClick={() => navigate(`/claim/quantification-review/${claim?.id || ''}`)}
                    className="tooltip-container btn py-2"
                >
                                <span className="">
                                  <ArrowCircleLeftIcon className="mr-2 inline h-5 w-5 align-center"
                                                       aria-hidden="true"/>
                                  Claim Profile
                                </span>
                    <span style={{marginTop: '75px', marginLeft: '-15px', width: '200px'}}
                          className="tooltip">Return to the claim quantification review</span>
                </div>


            </div>

            <div className="pb-5">
                {safe(renderFinishedInstructions)}
                {safe(renderInstructions)}
            </div>
        </div>
    )
}

function ApproveAll(claim, onApproveAll) {

    if (!claim) return (<div></div>)
    if (claim.status_reason !== 'Post tender inspection') return (<div></div>)

    return (
        <div className='w-full flex justify-end px-10 pt-5'>
            <button
                type="button"
                onClick={onApproveAll}
                className="tooltip-container btn-outline-success"
            >
                <span className="ml-auto mr-auto">
                    <CheckCircleIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                    Approve All
                </span>
            </button>
        </div>
    )
}

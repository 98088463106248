import {useEffect, useState} from 'react'
import {Combobox} from '@headlessui/react'
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid'
import {classNames} from '../utils/helpers.js';


export default function UserBox(props) {

    const [query, setQuery] = useState('');

    const [selectableUsers, setSelectedUsers] = useState([]);

    useEffect(() => {

        updateSelectableUsers();

    }, [props.users, query])

    const updateSelectableUsers = () => {
        if (!props.users) {
            return;
        }

        let filteredUsers = [];

        if (props.users) {

            if (query === '') {
                filteredUsers = filteredUsers.concat(props.users);
            } else {
                filteredUsers = props.users.filter((user) => {
                    return user.info.full_name.toLowerCase().includes(query.toLowerCase());
                });

            }
        }

        setSelectedUsers(filteredUsers);

        // now that we have filtered the users that are selectable, we need to set the initial selection
        // due to many stateful updates, we add a small delay before updating this state
        setTimeout(() => props.setSelectedOption(filteredUsers[0]), 400)
    }


    return (
        <Combobox as="div" value={props.selectedOption !== null ? props.selectedOption : ""}
                  onChange={props.setSelectedOption}>
            <div className="relative mt-1">
                <Combobox.Input
                    className="input"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(user) => user !== null && user.info !== undefined ? user.info.full_name : ""}
                />
                <Combobox.Button
                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                </Combobox.Button>

                {selectableUsers.length > 0 && (
                    <Combobox.Options
                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {selectableUsers.map((user) => (
                            <Combobox.Option
                                key={user.id}
                                value={user}
                                className={({active}) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9 h-9',
                                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                    )
                                }
                            >
                                {({active, selected}) => (
                                    <>
                                        <div className="flex items-center">
                                            <img
                                                src={user.info.profile_image_url ? user.info.profile_image_url : "/profile-picture.jpg"}
                                                alt=""
                                                className="h-6 w-6 flex-shrink-0 rounded-full"
                                                onError={({currentTarget}) => {
                                                    currentTarget.onError = null;
                                                    currentTarget.src = "/profile-picture.jpg";
                                                }}
                                            />
                                            <span
                                                className={classNames('ml-3 truncate', selected && 'font-semibold')}>{user.info.full_name}</span>
                                        </div>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-indigo-600'
                                                )}
                                            >
                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                      </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );

}
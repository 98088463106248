// Library imports
import React, {Fragment, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {
    CloudUploadIcon,
    PencilAltIcon,
    PencilIcon,
    PlusCircleIcon,
    PlusIcon,
    SearchCircleIcon, TruckIcon
} from '@heroicons/react/outline';
import {
    ArrowCircleLeftIcon, ArrowDownIcon,
    BanIcon,
    CheckCircleIcon, CheckIcon,
    DocumentAddIcon,
    InformationCircleIcon,
    SearchIcon
} from '@heroicons/react/solid';


// Local imports
import NotificationTopRight from '../../components/NotificationTopRight.jsx';

import AlertModal from '../modals/AlertModal.jsx';
import CreateManualQuoteModal from '../modals/quote_modals/CreateManualQuoteModal.jsx';
import DismissTenderModal from '../modals/DismissTenderModal.jsx';

import {
    customGraphRequest,
    dismissAllPendingTender,
    dismissTender, queryAccounts,
    updateQuotePrice
} from '../../utils/coreApi.js';

import {alertError, allReplacementDataForItem, classNames, isEmpty} from '../../utils/helpers.js';
import UpdateQuotePriceModal from '../modals/quote_modals/UpdateQuotePriceModal';
import UpdateQuoteModal from '../modals/quote_modals/UpdateQuoteModal';
import Safe, {safe} from "../../components/Safe";
import {useDocumentTitle} from "../../components/PageTitle";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import {Combobox, Dialog, Transition} from "@headlessui/react";
import {CountryTypes, OrganisationTypes} from "../../utils/constants";
import Tooltip from "../../components/Tooltip";
import ApiButton from "../../components/base/ApiButton";
import FilterSelect from "../../components/base/FilterSelect";
import {BasicImage} from "../../components/base/BasicImage";
import {CreateRandomQuotesForSupplier} from "../../components/development_components/DevelopmentComponents";
import { currencySymbol as _currencySymbol } from "../../utils/helpers.js";

export default function ClaimTender(props) {

    const setTitle = useDocumentTitle("Tender");

    const location = useLocation();

    const displayStatuses = {
        'DECLINED_TENDER': 'Declined tender',
        'NOT_INVITED_TO_TENDER': 'Not invited to tender',
        'TENDER_COMPLETED': 'Tender completed',
        'TENDER_DELETED': 'Tender deleted',
        'TENDER_PENDING': 'Tender pending',
        'UNABLE_TO_TENDER': 'Unable to tender'
    };

    const statusStyles = {
        'DECLINED_TENDER': 'bg-red-600 text-white',
        'NOT_INVITED_TO_TENDER': 'bg-yellow-600 text-white',
        'TENDER_COMPLETED': 'bg-green-600 text-white',
        'TENDER_DELETED': 'bg-red-600 text-white',
        'TENDER_PENDING': 'bg-blue-800 text-white',
        'UNABLE_TO_TENDER': 'bg-red-600 text-white'
    };

    // React router
    const navigate = useNavigate();
    const {claimId} = useParams();

    // UI states
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        'heading': '',
        'message': ''
    });

    // Used for success feedback
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState({
        'heading': '',
        'message': ''
    });

    const [dismissTenderOpen, setDismissTenderOpen] = useState(false);
    const [dismissTenderOptions, setDismissTenderOptions] = useState(null);

    const [createManualQuoteOpen, setCreateManualQuoteOpen] = useState(false);
    const [createManualQuoteOptions, setCreateManualQuoteOptions] = useState(null);

    const [editReplacementModalOpen, setEditReplacementModalOpen] = useState(false);
    const [editReplacementModalOptions, setEditReplacementModalOptions] = useState(null);

    // Data states
    const [claim, setClaim] = useState(null);
    const [currencySymbol, setCurrencySymbol] = useState(null);

    const [suppliersAndItems, setSuppliersAndItems] = useState(null);

    const [updateManualQuoteOpen, setUpdateManualQuoteOpen] = useState(false);
    const [updateManualQuoteOptions, setUpdateManualQuoteOptions] = useState(null);

    const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);
    const [showAddOnceOffSupplierModal, setShowAddOnceOffSupplierModal] = useState(false);
    const [newlyCreatedAdhocOrganisation, setNewlyCreatedAdhocOrganisation] = useState(null);


    // On every page load
    useEffect(() => {

        if (claim === null || suppliersAndItems === null) {
            fetchItemsTendering();
        }

    }, []);

    useEffect(() => {
        try {
            if (claim !== null) {
                setCurrencySymbol(claim.organisation?.info?.currency_symbol || ' ');
            }
        } catch (error) {
            if (!currencySymbol) {
                setCurrencySymbol(' ');
            }
        }

        if (claim && !props.navbarTopPageContent.claim) props.setNavbarTopPageContent({'claim': claim})

        if(claim) setTitle(`Tender : ${claim.insurer_claim_number}`)


    }, [claim, currencySymbol]);


    useEffect(() => {
        /** Open the quote modal if a replacement was added */
        checkAutoReopenQuoteModal();
    }, [claim, suppliersAndItems])

    const checkAutoReopenQuoteModal = () => {
        /** After adding a replacement item (modal -> add replacement -> select item -> return to Tender page), Check what modal was previously open, and reopen */

        if (!location.state || !location.state.quoteModal) return
        if (!claim) return
        if (!suppliersAndItems) return

        const fromItemSearch = location.state;

        /** Since the claim query has been removed from this page, we need to find the item and supplier data
         * (previously from the claim.items object) from the items_tendering.supplier_and_items state object */
        let item = null
        let supplier = null
        suppliersAndItems.forEach(supplier_items_object => {
            if(item) return
            let _item = supplier_items_object.items.find( item => item.id === fromItemSearch.itemId)
            if(_item) {
                item = _item
                supplier = supplier_items_object.supplier
            }
        })

        let product_replacementData = item.possible_replacements.find(replacementData => replacementData.product.id === fromItemSearch.newlyAddedReplacement_productId)
        let supplier_items = suppliersAndItems.find(supplier_items => supplier_items.supplier.id === fromItemSearch.supplierId)
        let supplierDataInTheSupplierPanel = supplier

        let recommendedReplacement = supplierDataInTheSupplierPanel?.recommended_replacement || null

        if (!supplier_items || !item) return console.error("Failed to re-open quote modal")

        let previouslyOpenQuoteModal = fromItemSearch.quoteModal

        if (previouslyOpenQuoteModal === 'createQuote') {
            setCreateManualQuoteOptions({
                'claim': claim,
                'supplier': supplier_items.supplier,
                'item': item,
                'defaultSelectedReplacementData': product_replacementData
            });
            setCreateManualQuoteOpen(true);
        }
        if (previouslyOpenQuoteModal === 'updateQuote') {
            setEditReplacementModalOptions({
                'claim': claim,
                'supplier': supplier_items.supplier,
                'item': item,
                'supplierRecommendedReplacement': recommendedReplacement,
                'defaultSelectedReplacementData': product_replacementData
            });
            setEditReplacementModalOpen(true);
        }

        // clear the state after navigating back, to prevent unintentionally re-opening the modals
        navigate(`${location.pathname}`, {replace: true, state: null})

    }

    const fetchItemsTendering = () => {

        let queryArgs = `claim:"${claimId}"`;
        let query = ITEMS_TENDERING.replace('|placeholder|', queryArgs);

        customGraphRequest(
            query,
            (data) => {

                setClaim(data['claim']);
                setSuppliersAndItems(data['suppliers_and_items']);

            },
            (error) => {
                props.onError(error,
                    () => setTimeout(() => navigate('/'), 1000)
                );
            }
        );

    };

    const promptCreateManualQuote = (supplier, item) => {

        setCreateManualQuoteOptions({
            'claim': claim,
            'supplier': supplier,
            'item': item
        });

        setTimeout(() => {
            setCreateManualQuoteOpen(true);
        }, 50);
    };

    const promptDismissTender = (supplier, item) => {

        setDismissTenderOptions({
            'supplier': supplier.id,
            'item': item.id
        });
        setDismissTenderOpen(true);
    };
    const onDismissTender = (options, reason) => {

        dismissTender(
            options['item'], reason, options['supplier'],
            (data) => {

                setNotificationMessage({
                    'heading': 'Tender updated',
                    'message': 'Completed tender for item.'
                });
                setNotificationOpen(true);

                setTimeout(() => {
                    setNotificationOpen(false);
                }, 1000);
                fetchItemsTendering();
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            }
        );
    };


    const promptUpdateQuote = (item, supplier, supplierRecommendedReplacement) => {

        setEditReplacementModalOptions({
            'claim': claim,
            'supplier': supplier,
            'item': item,
            'supplierRecommendedReplacement': supplierRecommendedReplacement
        });

        setEditReplacementModalOpen(true);

    };


    const onCreateManualQuote = (data) => {

        setNotificationMessage({
            'heading': 'Tender updated',
            'message': 'Completed tender for item.'
        });
        setNotificationOpen(true);

        setTimeout(() => {
            setNotificationOpen(false);
        }, 1000);
        fetchItemsTendering();
    };
    const onSuccessfulEditReplacement = (data) => {
        fetchItemsTendering();
    };


    const promptCompleteTender = () => {

        props.showConfirmModal(
            'success',
            'Complete tender',
            'Please confirm dismissing tender for all pending suppliers in this claim. This action cannot be undone.',
            'Complete tender',
            onCompleteTender
        );

    };
    const onCompleteTender = (event) => {

        dismissAllPendingTender(
            claimId,
            (data) => {

                setNotificationMessage({
                    'heading': 'Tender updated',
                    'message': 'Completed tender for claim.'
                });
                setNotificationOpen(true);

                setTimeout(() => {
                    setNotificationOpen(false);
                }, 1000);
                fetchItemsTendering();
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            }
        );
    };


    const promptUpdateQuotePrice = (item, supplier, supplierRecommendedReplacement) => {
        /** supplierRecommendedReplacement is from the supplier_panel - it is the "recommended replacement(quote product)" from the recommendation engine */
        setUpdateManualQuoteOptions({
            'item': item,
            'quote': supplierRecommendedReplacement.quote
        });
        setUpdateManualQuoteOpen(true);
    };
    const onUpdateQuotePrice = (options, buttonHandler) => {

        let item = options.item;
        let quote = options.quote;
        let price = parseFloat(options.price);

        let errorMessage = null;

        if (isNaN(price)) {
            errorMessage = 'Please enter a valid number.';
        } else if (price === quote.price) {
            errorMessage = 'The price has not been changed.';
        }

        if (errorMessage !== null) {
            let error = {
                'type': 'Error: Invalid Input',
                'message': errorMessage
            };
            alertError(setAlertOpen, setAlertOptions, error);
            // buttonHandler.onError('Invalid input');
        }

        updateQuotePrice(
            item.id,
            quote.id,
            price,
            options.update_catalog_price,
            (data) => {
                setUpdateManualQuoteOpen(false);
                fetchItemsTendering();
                // fetchClaim(claimId, setClaim, navigate);
                // buttonHandler.onSuccess('Quote price updated');
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
                // buttonHandler.onError('Failed to update quote price');
            }
        );
    };

    // HTML render functions
    const renderInstructions = () => {

        if (claim !== null && claim.status_reason !== 'Tendering') {
            return (<div></div>);
        }

        return (
            <div className="page-header-bar widget mt-0 mx-8 w-fit rounded-md bg-blue-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                        <div>
                            <p className="text-sm font-medium text-blue-700">
                                There are three actions that can be taken per supplier on items during tender:
                            </p>
                            <ul role="list" className="list-disc text-sm mt-2 text-left text-blue-700">
                                <li>Add a quote for one of the possible replacements for an item</li>
                                <li>Add a replacement to the list of possible replacements for an item</li>
                                <li>Decline tender or mark as unable to tender for an item</li>
                            </ul>
                        </div>
                        <p className="mt-3 text-sm md:mt-0 md:ml-6">
                            <a href="#" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                                Learn more <span aria-hidden="true">&rarr;</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const renderPostTenderInstructions = () => {

        if (claim === null || (claim.status_reason !== 'Post tender inspection' && claim.status !== 'QUANTIFIED')) {
            return (<div></div>);
        }

        let message = 'Tender has been completed for this claim.';

        if (claim.status_reason === 'Post tender inspection') {
            message += ' Post tender inspection is in progress at the moment.';
        }

        return (
            <div className="page-header-bar widget mt-0 mx-8 w-fit rounded-md bg-green-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800">Tender completed</h3>
                        <div className="mt-2 text-sm text-green-700">
                            <p>{message}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const showAdhocDeliveryFeeModal = (supplier) => {

        function onSetAdhocDeliveryFee(){

            // Get the input via ID
            let deliveryFeeInput = document.getElementById('deliveryFee');
            deliveryFeeInput = deliveryFeeInput.value;

            const mutation = `
                mutation UpsertAdhocDeliveryFee {
                  supplier_upsert_delivery_cost_adhoc(
                    claim_id: "${claim.id}"
                    supplier_id: "${supplier.id}"
                    delivery_cost: ${deliveryFeeInput}
                  ){
                    error {type, message}
                    claim{
                      id
                      delivery_costs{
                        supplier_id
                        is_adhoc
                        delivery_cost
                      }
                    }
                  }
                }
            `
            customGraphRequest(
                mutation,
                (data) => {
                    props.showNotificationModal(
                        'success',
                        'Adhoc Delivery Fee Updated',
                        'The adhoc delivery fee has successfully been updated.'
                    );

                    // Update the claim with the new adhoc delivery fee
                    setClaim({...claim, delivery_costs: data.claim.delivery_costs})

                    props.setCustomModalOpen(false)
                },
                (error) => props.onError(error)
            )

        }

        let content = (

            <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                <h1 className='p-4 mb-4 font-light text-3xl '>Adhoc Delivery Fee</h1>

                <div className="divider_CRUD"/>

                <h4 className='p-4 mb-4 font-light text-lg text-center'>
                    Set the adhoc delivery fee for this supplier for this claim
                </h4>

                <div className='flex justify-center mb-4'>

                    <input
                        className="input w-[8rem]"
                        id="deliveryFee"
                        placeholder={_currencySymbol(claim)}
                    />

                </div>


                <div className="divider_CRUD"/>

                <div className='flex gap-4 mt-4 w-full justify-center tailwind-tooltip-container'>

                    <button
                        className="btn-outline"
                        onClick={() => props.setCustomModalOpen(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn"
                        onClick={onSetAdhocDeliveryFee}
                    >
                        Submit
                    </button>
                </div>

            </div>
        );

        props.showCustomModal(content);


    }

    const renderAdhocDeliveryFee = (supplier) => {

        // Get the adhoc delivery fee for this supplier
        const deliveryObject = claim.delivery_costs?.find(deliveryCost => deliveryCost.supplier_id === supplier.id) || null

        return (
            <div className='w-full flex justify-end pt-4 pr-2'>

                { deliveryObject &&
                    <Tooltip position='left' message='The ad-hoc delivery fee set for this supplier for this claim' style='max-w-max w-[8rem]'>
                        <p className='px-2 text-gray-600'>
                            {_currencySymbol(claim)} {deliveryObject.delivery_cost}
                        </p>
                    </Tooltip>
                }

                <Tooltip position='top' message='Set a ad-hoc delivery fee for this supplier'
                         classNames='max-w-[8rem] w-full'>
                    <button onClick={()=> showAdhocDeliveryFeeModal(supplier)}>
                        <TruckIcon className="h-5 w-5 text-sky-600"/>
                    </button>
                </Tooltip>
            </div>
        );

    }

    const renderSupplier = () => {

        if (suppliersAndItems === null) {
            return (
                <div className="px-4 sm:px-6 lg:px-8 mt-5">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <svg role="status"
                                 className="align-middle inline-block mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"></path>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"></path>
                            </svg>
                            <span>Loading...</span>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="mx-8 flex flex-col gap-8">

                {suppliersAndItems.map((supplierItems) => (

                    <div
                        key={supplierItems.supplier.id}
                        className='widget shadow-sm pt-6 pb-4'
                    >

                        {/* SUPPLIER NAME*/}
                        <div className="flex items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-2xl font-semibold text-gray-900 ml-1">{supplierItems['supplier']['info']['name']}</h1>
                            </div>

                            <CreateRandomQuotesForSupplier supplier_and_items={supplierItems} {...props} />

                        </div>

                        {/* HEADER */}
                        <div
                            className="-mx-4 mt-3 overflow-hidden sm:-mx-6 md:mx-0 md:rounded-lg"
                        >
                            <table className="min-w-full divide-y divide-gray-300 table-fixed">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th>
                                        Original
                                    </th>
                                    <th>
                                        Replacement
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Unit Price
                                    </th>
                                    <th>
                                        Quantity
                                    </th>
                                    <th>
                                        Sub-Total
                                    </th>
                                    <th className='text-right'>
                                        Actions
                                    </th>
                                </tr>
                                </thead>

                                {/* BODY */}
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {supplierItems.items.map((item) => (
                                    renderItem(supplierItems['supplier'], item)
                                ))}
                                </tbody>
                            </table>
                        </div>

                        {renderAdhocDeliveryFee(supplierItems['supplier'])}

                    </div>
                ))}
            </div>
        );
    };

    const renderItem = (supplier, item) => {
        // return a single table row

        let replacementData = null; // the recommended replacement for this supplier for this item (of all possible replacements, the lowest quote from this supplier), found in the supplier_panel
        let replacementProduct = null;
        let supplierStatus = '';

        let _supplierData;

        for (let supplierData of item.supplier_panel) {
            if (supplierData['id'] == supplier['id']) {
                _supplierData = supplierData;
                replacementData = supplierData['recommended_replacement'];
                supplierStatus = supplierData['status'];
                break;
            }
        }

        if (replacementData !== null && replacementData['product'] !== null) {
            replacementProduct = replacementData['product'];
        }

        let hasPreferredReplacement = (replacementProduct !== null);

        let replacementCommonName = 'Not tendered';
        let possibleReplacements = `(${item.possible_replacements.length} possible replacement`;

        if (item.possible_replacements.length > 1) {
            possibleReplacements += 's)';
        } else {
            possibleReplacements += ')';
        }

        let displayPrice = '-';
        let displaySubTotal = '-';

        if (hasPreferredReplacement) {

            replacementCommonName = replacementProduct.common_name;
            possibleReplacements = '';

            let quote = replacementData.quote;
            displayPrice = currencySymbol + ` ${parseFloat(quote.price).toFixed(2)}`;

            let subTotalPrice = quote.price * item.quantity;
            // displaySubTotal = `${quote.currency_symbol} ${subTotalPrice}`;
            displaySubTotal = currencySymbol + ` ${parseFloat(subTotalPrice).toFixed(2)}`;
        }

        let isPending = (supplierStatus === 'TENDER_PENDING');

        let rowBgColor = '';

        if (supplierStatus === 'TENDER_COMPLETED') {
            rowBgColor = 'bg-green-100';
        } else if (supplierStatus === 'NOT_INVITED_TO_TENDER') {
            rowBgColor = 'bg-yellow-100';
        } else if (supplierStatus !== 'TENDER_PENDING') {
            rowBgColor = 'bg-red-100';
        }


        let showUpdateQuoteModals = false;

        // If this supplier, has atleast 1 quote, for any of the "possible replacement products", in this item - then show the update quote modal
        let allReplacements_QuoteProduct = allReplacementDataForItem(item);
        let supplierHasQuoteInThisItem = allReplacements_QuoteProduct.find(replacement => replacement.supplier.id === supplier.id);
        if (!isEmpty(supplierHasQuoteInThisItem)) {
            showUpdateQuoteModals = true;
        }

        let noRecommendedReplacementData = isEmpty(replacementData?.product) && isEmpty(replacementData?.quote);

        return (
            <tr key={item.id} className={rowBgColor}>

                {/* ORIGINAL PRODUCT */}
                <td className="">
                    <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                            <img
                                src={item.product.images !== null && item.product.images.thumbnail_web ? item.product.images.thumbnail_web : 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                className="h-10 w-10 rounded-full"
                                alt=""
                            />
                        </div>
                        <div className="ml-4">
                            <div className="font-medium">
                                {item.product.common_name}
                            </div>
                            <div className="text-gray-600 font-normal mt-1">
                                {item.category.display_name}
                            </div>
                            <dl className="font-normal">
                                <dd className="mt-1 max-w-md text-gray-900">
                                    {/*{item.product.type === 'MANUAL' ? item.product.description : calculateProperties(item.product)}*/}
                                    {item.product.description}
                                </dd>
                            </dl>
                        </div>
                    </div>
                </td>

                {/* REPLACEMENT */}
                <td className="">
                    <div className="flex items-center">
                        <div className="h-12 w-12 flex-shrink-0">
                            <img
                                src={replacementProduct !== null && replacementProduct.images !== null && replacementProduct.images.thumbnail_web ? replacementProduct.images.thumbnail_web : 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                className="h-12 w-12 rounded-full"
                                alt=""
                            />
                        </div>
                        <div className="ml-4">

                            <div className="font-medium flex justify-between items-center">
                                <p>{replacementCommonName}</p>

                                {/* only display edit button if this supplier has a "recommended replacement(quote, product)" for this item(of all possible replacements) */}
                                {showUpdateQuoteModals &&
                                    <button className="btn-outline py-0 px-2 border-none m-0 ml-2 shadow-none"
                                            onClick={() => promptUpdateQuote(item, supplier, replacementData)}>
                                        <PencilAltIcon className="h-5 w-5" aria-hidden="true"/></button>
                                }

                            </div>

                            {replacementCommonName !== 'Not tendered' && (
                                <div className="text-gray-600 font-normal mt-1">
                                    {item.category.display_name}
                                </div>)}
                            {possibleReplacements !== '' && (
                                <div className="text-gray-600 font-normal">
                                    {possibleReplacements}
                                </div>
                            )}
                            {replacementProduct !== null && (
                                <div className="text-gray-900 font-normal max-w-md mt-1">
                                    {/*{replacementProduct.type === 'MANUAL' ? replacementProduct.description : calculateProperties(replacementProduct)}*/}
                                    {replacementProduct.description}
                                </div>
                            )}
                        </div>
                    </div>
                </td>

                {/* STATUS */}
                <td className="">
                    <div
                        className={classNames(
                            'flex justify-center w-full '
                        )}
                    >
                        <p className={classNames(
                            statusStyles[supplierStatus],
                            'px-2.5 py-0.5 w-fit font-medium rounded-full')}>
                            {displayStatuses[supplierStatus]}
                        </p>
                    </div>

                </td>

                {/* UNIT PRICE */}
                <td className="">
                    <div className="flex justify-center items-center gap-1">
                        <p className="w-fit">{displayPrice}</p>

                        {/* only display edit button if this supplier has a "recommended replacement(quote, product)" for this item */}
                        {!noRecommendedReplacementData &&
                            <button className="btn-outline p-1 border-none m-0 mb-1 shadow-none"
                                    onClick={() => promptUpdateQuotePrice(item, supplier, replacementData)}>
                                <PencilAltIcon className="h-5 w-5" aria-hidden="true"/></button>}
                    </div>
                </td>

                {/* QUANTITY */}
                <td className="">
                    {item.quantity}</td>

                {/* SUB TOTAL */}
                <td className="">
                    {displaySubTotal}</td>

                {/* ACTIONS */}
                <td className="">
                    <div className='flex justify-end'>
                        <button
                            disabled={!isPending ? 'disabled' : undefined}
                            onClick={() => {
                                promptCreateManualQuote(supplier, item);
                            }}
                            className={classNames(
                                !isPending ? 'text-gray-400' : 'text-cyan-600 hover:text-cyan-500 tooltip-container',
                                'mr-1 inline-flex items-center text-sm font-medium rounded-md'
                            )}
                        >
                            <PlusCircleIcon className="h-7 w-7" aria-hidden="true"/>
                            <span className="tooltip">Add quote</span>
                        </button>
                        <a
                            disabled={!isPending ? 'disabled' : undefined}
                            href={`/claim/item-add-replacement/${claimId}/${item.id}?category=${item.product.category.toLowerCase()}`}
                            className={classNames(
                                !isPending ? 'text-gray-400 pointer-events-none' : 'text-cyan-600 hover:text-cyan-500 tooltip-container',
                                'mr-1 inline-flex items-center text-sm font-medium rounded-md'
                            )}
                        >
                            <SearchCircleIcon className="h-7 w-7" aria-hidden="true"/>
                            <span className="tooltip">Add replacement</span>
                        </a>
                        <button
                            disabled={!isPending ? 'disabled' : undefined}
                            onClick={() => {
                                promptDismissTender(supplier, item);
                            }}
                            className={classNames(
                                !isPending ? 'text-gray-400' : 'text-red-500 hover:text-red-400 tooltip-container',
                                'mr-1 inline-flex items-center text-sm font-medium rounded-md'
                            )}
                        >
                            <BanIcon className="h-7 w-7" aria-hidden="true"/>
                            <span className="tooltip">Cannot tender</span>
                        </button>
                    </div>

                </td>
            </tr>
        );
    };

    if (!claim) return <div></div>

    return (
        <>
            <div className="">

                {/* TODO : change usage to base modal */}
                <NotificationTopRight
                    open={notificationOpen}
                    setOpen={() => {
                    }}
                    message={notificationMessage}
                />

                {/* TODO : change usage to base modal */}
                <AlertModal
                    open={alertOpen}
                    setOpen={setAlertOpen}
                    options={alertOptions}
                />

                <Safe>
                    <AddSupplierToClaimModal
                            open={showAddSupplierModal}
                            setOpen={setShowAddSupplierModal}
                            setShowAddOnceOffSupplierModal={setShowAddOnceOffSupplierModal}
                            newlyCreatedAdhocOrganisation={newlyCreatedAdhocOrganisation}
                            setNewlyCreatedAdhocOrganisation={setNewlyCreatedAdhocOrganisation}
                            claim={claim}
                            suppliersAndItems={suppliersAndItems}
                            fetchItemsTendering={fetchItemsTendering}
                            {...props}
                        />

                </Safe>


                <Safe>
                    <AddOnceOffSupplierToClaimModal
                            open={showAddOnceOffSupplierModal}
                            setOpen={setShowAddOnceOffSupplierModal}
                            setShowAddSupplierModal={setShowAddSupplierModal}
                            setNewlyCreatedAdhocOrganisation={setNewlyCreatedAdhocOrganisation}
                            claim={claim}
                            suppliersAndItems={suppliersAndItems}
                            {...props}
                        />

                </Safe>

                <Safe>
                    <CreateManualQuoteModal
                        open={createManualQuoteOpen}
                        setOpen={setCreateManualQuoteOpen}
                        options={createManualQuoteOptions}
                        onConfirmClicked={onCreateManualQuote}
                        onError={(error) => {
                            alertError(setAlertOpen, setAlertOptions, error);
                        }}
                    />
                </Safe>

                <Safe>
                    <UpdateQuoteModal
                        {...props}
                        open={editReplacementModalOpen}
                        setOpen={setEditReplacementModalOpen}
                        options={editReplacementModalOptions}
                        onConfirmClicked={onSuccessfulEditReplacement}
                        onError={(e) => props.onError(e)}
                    />
                </Safe>

                <Safe>
                    <UpdateQuotePriceModal
                        {...props}
                        open={updateManualQuoteOpen}
                        setOpen={setUpdateManualQuoteOpen}
                        options={updateManualQuoteOptions}
                        onConfirmClicked={onUpdateQuotePrice}
                        onError={(e) => props.onError(e)}
                    />
                </Safe>

                <Safe>
                    <DismissTenderModal
                        open={dismissTenderOpen}
                        setOpen={setDismissTenderOpen}
                        options={dismissTenderOptions}
                        onDismissTender={onDismissTender}
                    />
                </Safe>


                <div className="flex flex-col flex-1">

                    <main className="pb-8">

                        {/* HEADER */}
                        <div className="page-header-bar">

                            <h3 className="page-header-title">Tender</h3>
                            <div>

                                <div
                                    onClick={() => navigate(`/claim/quantification-review/${claimId}`)}
                                    className="tooltip-container btn py-2"
                                >
                                            <span className="">
                                              <ArrowCircleLeftIcon className="mr-2 inline h-5 w-5 align-center"
                                                                   aria-hidden="true"/>
                                              Claim Profile
                                            </span>
                                    <span style={{marginTop: '75px', marginLeft: '-15px', width: '200px'}}
                                          className="tooltip">Return to the claim quantification review</span>
                                </div>

                                {claim !== null && props.mainOrganisation?.type !== 'SUPPLIER' && claim.status_reason === 'Post tender inspection' && (
                                    <div
                                        onClick={() => navigate(`/claim/post-tender/${claimId}`)}
                                        className="tooltip-container btn py-2"
                                    >
                                            <span className="ml-auto mr-auto">
                                              <SearchIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                                              Post Tender Inspection
                                            </span>
                                        <span style={{marginTop: '75px', marginLeft: '-15px', width: '210px'}}
                                              className="tooltip">Go to post tender inspection</span>
                                    </div>
                                )}
                            </div>

                        </div>

                        {safe(renderPostTenderInstructions)}
                        {safe(renderInstructions)}

                        <div className='flex justify-end'>

                            {/* ADD SUPPLIER */}
                            <div className="text-right mr-8 my-4">

                                {/* Only show button if claim is in tender */}
                                {/* Only show button to ADMIN and INSURER */}
                                {claim !== null && claim.status_reason === 'Tendering' &&
                                    props.mainOrganisation?.type !== 'SUPPLIER' &&
                                    (
                                        <>

                                            <Tooltip position='top'
                                                     message='Invite a supplier to tender on selected items'>
                                                <button
                                                    type="button"
                                                    onClick={() => setShowAddSupplierModal(true)}
                                                    className="btn"
                                                >
                                                    <PlusIcon className="mr-1 inline h-5 w-5 align-top"
                                                              aria-hidden="true"/>
                                                    Supplier
                                                </button>

                                            </Tooltip>

                                        </>
                                    )}
                            </div>


                            {/* COMPLETE TENDER */}
                            <div className="text-right mr-8 my-4">

                                {/* Only show button if claim is in tender */}
                                {/* Only show button to ADMIN and INSURER */}
                                {claim !== null && claim.status_reason === 'Tendering' &&
                                    props.mainOrganisation?.type !== 'SUPPLIER' &&
                                    (
                                        <button
                                            type="button"
                                            onClick={promptCompleteTender}
                                            className="tooltip-container btn-success"
                                        >
                                            <CheckCircleIcon className="mr-1 inline h-5 w-5 align-top" aria-hidden="true"/>
                                            Complete Tender
                                            <span className='tailwind-tooltip'>
                                                Complete the tender for the claim
                                            </span>
                                        </button>
                                    )}
                            </div>




                        </div>

                        {/* ITEMS BY SUPPLIER */}
                        {safe(renderSupplier)}


                    </main>
                </div>

            </div>
        </>
    );
}


function AddSupplierToClaimModal (props) {

    const [organisations_supplierPanel, setOrganisations_supplierPanel] = useState(null);
    const [organisations_adhoc, setOrganisations_adhoc] = useState(null);

    // AdditionalSupplier components - suppliers to add to the claim
    const [additionalSuppliers, setAdditionalSuppliers] = useState([]);

    function addSupplierToClaim(selectedOrg) {
        if(selectedOrg.id === 'create') {
            props.setOpen(false);
            props.setShowAddOnceOffSupplierModal(true);
            return;
        }
        setAdditionalSuppliers([...additionalSuppliers, selectedOrg]);
    }

    useEffect(() => {

        // check if the props.open type is an object
        if(props.newlyCreatedAdhocOrganisation){
            // If a supplier was created, it is passed in via the props.open state
            // Pre-select the adhoc supplier that was created
            addSupplierToClaim(props.newlyCreatedAdhocOrganisation);
            props.setNewlyCreatedAdhocOrganisation(null);
        }

    }, [organisations_adhoc]);

    useEffect(() => {
        if(props.open)
            fetchAccounts();
    }, [props.open]);

    function fetchAccounts() {
        /* All supplier pannel organisations are retrieved via the Accounts query
        * This is due to the requirement of an account between the insurer and the supplier in order to invite a supplier
        * to tender on a item */

        queryAccounts(
            null,
            'ADMINISTRATOR_SUPPLIER, INSURER_SUPPLIER',
            (data) => {

                // Filter out by the claim's country
                let _accounts = data.accounts.filter(account => account.organisation_2.info.country === props.claim.country);

                let organisations = _accounts.map(account => account.organisation_2);

                setOrganisations_supplierPanel(organisations);
            },
            (error) => props.onError(error),
        )
    }


    useEffect(() => {

        if(props.open){
            fetchAdhocOrganisations();
        }
    }, [props.open]);
    function fetchAdhocOrganisations() {
        /* All Adhoc supplier organisations have to be retrieve via the Organisations query
        * This is due to an account not existing between a adhoc supplier org and the claim insurer until the adhoc supplier
        * has been invited to tender on an item atleast 1 time */

        let queryArgs = "";

        let orgTypes = ['SUPPLIER'] // Supplier type must be specified if is_adhoc is true
        queryArgs += ` types:[${orgTypes.join(", ")}]`;

        queryArgs += ` is_adhoc: true`;
        queryArgs += ` country: ${props.mainOrganisation.info.country}`;

        let minimalOrganisationsQuery = `
            query InviteSupplier_SupplierPanelOrganisations{
              organisations(|placeholder|) {
                error {
                  type
                  message
                }
                organisations {
                  id
                  date_created
                  date_updated
                  status
                  unique_reference_name
                  type
                  account_type
                  info {
                    name
                    country
                    country_text
                    website
                    currency
                    logo_image_url
                    snappy_info{
                      status_text
                      thank_you_text
                      landing_text
                      snappy_logo_url
                    }
                  }
                }
              }
            }
        `
        minimalOrganisationsQuery = minimalOrganisationsQuery.replace('|placeholder|', queryArgs);

        customGraphRequest(
            minimalOrganisationsQuery,
            (data) => {
                console.log('fetchAdhocOrganisations', data)

                const allOrgs = data['organisations'];
                const alphabeticallyOrderedOrganisations = allOrgs.sort((a, b) => {
                    let nameA = a.unique_reference_name.toUpperCase(); // to ensure case-insensitive comparison
                    let nameB = b.unique_reference_name.toUpperCase(); // to ensure case-insensitive comparison

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
                setOrganisations_adhoc(alphabeticallyOrderedOrganisations)
            },
            (error) => {
                props.onError(error)
            }
        );

    }

    function onRemoveSupplier(supplierOrg) {
        setAdditionalSuppliers(additionalSuppliers.filter(supplier => supplier.id !== supplierOrg.id));
    }

    // == RENDER FUNCTIONS ==

    function renderAddSupplier() {

        function selectableSupplierAccounts() {
            // If the supplier organisation is in the item's supplier_panel, then it is already in the claim
            // Return all suppliers that are not in the claim

            if(!organisations_supplierPanel) return [];

            let organisationsNotInClaim = organisations_supplierPanel.filter(org => {

                const orgInClaim = props.suppliersAndItems.find(replacementObject => {
                    return replacementObject.supplier.id === org.id
                })

                return !orgInClaim;

            });

            // Filter out duplicates
            organisationsNotInClaim = organisationsNotInClaim.filter((org, index, self) => {
                return index === self.findIndex((t) => (
                    t.id === org.id
                ))
            });

            return organisationsNotInClaim || [];
        }

        // Include the option to create a new supplier as a option in the Adhoc suppliers
        let adhocOrgs_AndCreateOption = organisations_adhoc ? organisations_adhoc.concat({id: 'create', info: {name: 'Create new supplier'}}) : [];

        let supplierPanelOrganisationsNotInThisClaim = selectableSupplierAccounts();

        // Loading spinner while fetching accounts
        if(isEmpty(supplierPanelOrganisationsNotInThisClaim)) return <LoadingSpinner  size="8" text='text-lg' body="Loading accounts..." color="rgb(8 145 178)" />

        return (
            <div className='flex gap-4'>

                <Tooltip position='top' message='All supplier accounts'>

                    <FilterSelect
                        selectableOptions={supplierPanelOrganisationsNotInThisClaim}
                        onChange={addSupplierToClaim}
                        filterOptions={(selectableOptions, query) =>
                            query === '' ?
                                selectableOptions
                            :
                                selectableOptions.filter((option) =>
                                option.info.name
                                .toLowerCase()
                                .replace(/\s+/g, '') // remove all whitespace
                                .includes(query.toLowerCase().replace(/\s+/g, ''))
                        )}
                        renderMainInput={(selected, query, setQuery)=> (
                            <div className="w-full relative">
                                <Combobox.Button as="div" className="flex w-full items-center">
                                    <div className="absolute left-2 flex items-center pr-2">
                                        {selected?.info?.logo_image_url &&
                                            <img
                                                src={selected?.info?.logo_image_url}
                                                className="h-8 w-8 rounded-full"
                                                alt="logo"
                                                onError={(e) => {
                                                    if (!e.target.dataset.retry) {
                                                        e.target.src = "/org-logo-placeholder.jpg";
                                                        e.target.dataset.retry = "true";
                                                    }
                                                }}
                                            />
                                        }
                                    </div>

                                    <Combobox.Input
                                        className="input pl-12 pr-10 w-full"
                                        displayValue={(selected) => selected ? selected.info.name : '-'}
                                        onChange={(event) => setQuery(event.target.value)}
                                    />

                                    <div className="absolute right-0 flex items-center pr-2">
                                        <ArrowDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </Combobox.Button>
                            </div>

                        )}
                        renderOption={(option) =>
                            <Combobox.Option
                                key={option.id}
                                className='relative'
                                value={option}
                            >
                                {({selected, active}) => (
                                    <div className={classNames(
                                        'flex transition-all gap-2 py-3 pl-8 items-center group !h-[3.5rem]',
                                        selected ? 'font-bold' : 'font-normal', // Is the option selected
                                        active ? 'bg-sky-600 text-white' : 'text-gray-900' // Is the option being hovered over
                                    )}>

                                        {/* SELECTED CHECK */}
                                        {selected &&
                                            <div className={classNames(
                                                'absolute inset-y-0 left-0 flex items-center pl-1',
                                                active ? 'text-white' : 'text-sky-600'
                                            )}>
                                                <CheckIcon className={`h-6 w-6`} />
                                            </div>
                                        }

                                        {/* ORG LOGO */}
                                        <BasicImage
                                            src={option?.info.logo_image_url}
                                            fallbackSrc={'/org-logo-placeholder.jpg'}
                                            alt="logo"
                                            sizeWidthRem="1.8"
                                            className="rounded-full"
                                        />

                                        {/* ORG NAME */}
                                        {option.info.name}

                                    </div>
                                )}
                            </Combobox.Option>
                            }
                    />

                </Tooltip>

                <Tooltip position='top' message='All ad-hoc organisations'>

                    <FilterSelect
                        selectableOptions={adhocOrgs_AndCreateOption}
                        onChange={addSupplierToClaim}
                        filterOptions={(selectableOptions, query) =>
                            query === '' ?
                                selectableOptions
                            :
                                selectableOptions.filter((option) =>
                                option.info.name
                                .toLowerCase()
                                .replace(/\s+/g, '') // remove all whitespace
                                .includes(query.toLowerCase().replace(/\s+/g, ''))
                        )}
                        renderMainInput={(selected, query, setQuery)=> (
                            <div className="w-full relative">
                                <Combobox.Button as="div" className="flex w-full items-center">
                                    <div className="absolute left-2 flex items-center pr-2">
                                        {selected?.info?.logo_image_url &&
                                            <img
                                                src={selected?.info?.logo_image_url}
                                                className="h-8 w-8 rounded-full"
                                                alt="logo"
                                                onError={(e) => {
                                                    if (!e.target.dataset.retry) {
                                                        e.target.src = "/org-logo-placeholder.jpg";
                                                        e.target.dataset.retry = "true";
                                                    }
                                                }}
                                            />
                                        }
                                    </div>

                                    <Combobox.Input
                                        className="input pl-12 pr-10 w-full"
                                        displayValue={(selected) => selected ? selected.info.name : '-'}
                                        onChange={(event) => setQuery(event.target.value)}
                                    />

                                    <div className="absolute right-0 flex items-center pr-2">
                                        <ArrowDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </Combobox.Button>
                            </div>

                        )}
                        renderOption={(option) =>
                            <Combobox.Option
                                key={option.id}
                                className='relative'
                                value={option}
                            >
                                {({selected, active}) => (
                                    <div className={classNames(
                                        'flex transition-all gap-2 py-3 pl-8 items-center group !h-[3.5rem]',
                                        selected ? 'font-bold' : 'font-normal', // Is the option selected
                                        active ? 'bg-sky-600 text-white' : 'text-gray-900' // Is the option being hovered over
                                    )}>

                                        {/* SELECTED CHECK */}
                                        {selected &&
                                            <div className={classNames(
                                                'absolute inset-y-0 left-0 flex items-center pl-1',
                                                active ? 'text-white' : 'text-sky-600'
                                            )}>
                                                <CheckIcon className={`h-6 w-6`} />
                                            </div>
                                        }

                                        {/* ORG LOGO */}
                                        <BasicImage
                                            src={option?.info.logo_image_url}
                                            fallbackSrc={'/org-logo-placeholder.jpg'}
                                            alt="logo"
                                            sizeWidthRem="1.8"
                                            className="rounded-full"
                                        />

                                        {/* ORG NAME */}
                                        {option.info.name}

                                    </div>
                                )}
                            </Combobox.Option>
                            }
                    />

                </Tooltip>

            </div>
    );
    }

    function renderSuppliers() {
        return (
            <>
                {additionalSuppliers.map((supplierOrg, index) => (
                    <AdditionalSupplier
                        {...props}
                        key={supplierOrg.id}
                        supplierOrganisation={supplierOrg}
                        claim={props.claim}
                        supplierAndItems={props.suppliersAndItems}
                        onRemoveSupplier={onRemoveSupplier}
                    />
                ))}
            </>
        );
    }

    return (
        <Transition.Root show={props.open} as={Fragment} onClick={() => props.setOpen(false)}>
            <Dialog as="div" className="fixed z-[10] inset-0 overflow-y-auto" onClose={() => {
                props.setOpen(false)
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-50"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-50"
                    leaveTo="opacity-0"
                >
                    {/*<Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-40 transition-opacity" onClick={()=>props.setOpen(false)} />*/}
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="flex items-center justify-center min-h-screen">

                    {/* To prevent Headless UI focusing on the first button (focusable-object) */}
                    <button className='opacity-0 pointer-events-none w-[0px] h-[0px] absolute'></button>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="flex justify-center items-center w-full h-full">

                            <div className='bg-white rounded-xl'
                                 onClick={(e) => {
                                     e.preventDefault(); // prevent closing the modal from clicking inside of the main content
                                 }}
                            >

                                <div className="w-[50rem] flex flex-col justify-center items-center p-8">

                                    <h1 className='p-4 mb-4 font-light text-3xl'>
                                        Invite a supplier to selected items
                                    </h1>

                                    {renderAddSupplier()}

                                    <div className='divider'></div>

                                    {renderSuppliers()}


                                    <div className='flex gap-4 mt-4 w-full justify-center'>
                                        <button
                                            className="btn-outline"
                                            onClick={() => props.setOpen(false)}
                                        >
                                            Close
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}


function AdditionalSupplier (props) {

    // The supplier selected to be added to the claim
    const [supplier, setSupplier] = useState(props.supplierOrganisation);
    useEffect(() => {
        setSupplier(props.supplierOrganisation)

        // loadItems()
    }, [props.supplierOrganisation]);

    // The items that the supplier has been added to
    const [itemsSupplierIsAddedTo, setItemsSupplierIsAddedTo] = useState([]);

    function addSupplierToItem(item, buttonHandler) {
        /** The mutation works on a per-item basis */

        let mutation = `
            mutation addSuppliersToTender {
              add_supplier_to_claim_item(
                item_id: "${item.id}"
                supplier_id: "${props.supplierOrganisation.id}"
              ){
                error{type, message}
                item{
                  id
                }
              }
            }
        `

        customGraphRequest(
            mutation,
            (data) => {
                console.log('Added suppliers to claim', data)
                props.showAlertModal('success', 'Supplier invited', <p>The supplier <b>{props.supplierOrganisation.unique_reference_name}</b> has been invited to the selected item <b>{item.product.common_name}</b></p>);

                setItemsSupplierIsAddedTo([...itemsSupplierIsAddedTo, item])
                props.fetchItemsTendering();
                buttonHandler.onSuccess('Supplier invited');
            },
            (error) => {
                props.onError(error);
                buttonHandler.onError('Failed to invite supplier');
            }
        );

    }

    if(!props.claim.items) return <></>

    return (
        <div className='w-full py-4' key={supplier.id}>

            <div className='w-full flex gap-4 justify-between'>
                <p className='text-2xl font-bold my-4'>{supplier.unique_reference_name}</p>

                <Tooltip position='top' message='Finish selecting items'>
                    <button className='btn-outline text-xs py-2 px-4 h-min'
                            onClick={() => props.onRemoveSupplier(supplier)}
                    >
                        X
                    </button>
                </Tooltip>
            </div>

            <div className='flex flex-col gap-4'>

                {props.claim.items.map((item) => (
                    <>

                        <div key={item.id} className='flex justify-between pl-12'>

                            <div className="flex items-center">
                                <div className="h-10 w-10 flex-shrink-0">
                                    <img
                                        src={item.product.images !== null && item.product.images.thumbnail_web ? item.product.images.thumbnail_web : 'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                                        className="h-10 w-10 rounded-full"
                                        alt=""
                                    />
                                </div>
                                <div className="ml-4">
                                    <div className="font-medium">
                                        {item.product.common_name}
                                    </div>
                                    <div className="text-gray-600 font-normal mt-1">
                                        {item.category.display_name}
                                    </div>
                                    <dl className="font-normal">
                                        <dd className="mt-1 max-w-md text-gray-900">
                                            {/*{item.product.type === 'MANUAL' ? item.product.description : calculateProperties(item.product)}*/}
                                            {item.product.description}
                                        </dd>
                                    </dl>
                                </div>
                            </div>

                            <Tooltip position='top' message={itemsSupplierIsAddedTo.find(i => i.id === item.id) ? 'Invited' : 'Invite'}>
                                <ApiButton
                                    onClick={(buttonHandler) => {
                                        addSupplierToItem(item, buttonHandler);
                                    }}
                                    className={classNames(
                                            'btn h-min',
                                            itemsSupplierIsAddedTo.find(i => i.id === item.id) && 'btn-disabled'
                                        )}
                                    content='Add supplier to item'
                                    loadingContent={<LoadingSpinner color='darkcyan' size='6' body='' />}
                                    successContent='Supplier invited'
                                    errorContent='Failed to invite supplier'
                                    defaultStatus={itemsSupplierIsAddedTo.find(i => i.id === item.id) ? 'success' : 'idle'}
                                />
                            </Tooltip>


                        </div>

                        <div className='divider'></div>

                    </>


                ))}
            </div>

        </div>
    );

}


function AddOnceOffSupplierToClaimModal(props) {


    const [name, setName] = useState(null);
    const [website, setWebsite] = useState(null);
    const [websiteTLD, setWebsiteTLD] = useState(null);
    const [orgType, setOrgType] = useState('SUPPLIER');
    const [country, setCountry] = useState('NL');
    const [currencyCode, setCurrencyCode] = useState('EUR');

    useEffect(() => {
        setFieldsFromClaim();
    }, [props.open]);

    function setFieldsFromClaim() {

        setOrgType('SUPPLIER');

        setCountry(props.claim.country);

        setCurrencyCode(props.claim.organisation.info.currency);

    }

    function fullWebsite () {
        if(!website || !websiteTLD) return 'Invalid website';
        return website + websiteTLD;
    }

    function onCreateSupplier() {

        if(!name) return props.showAlertModal('error', 'Name required', 'Please enter a valid name for the supplier');
        if(!website) return props.showAlertModal('error', 'Website name', 'Please enter a valid website domain for the supplier');
        if(!websiteTLD) return props.showAlertModal('error', 'Website TLD required', 'Please enter a valid website TLD (E.G: .com) for the supplier');

        // For Adhoc suppliers, the unique reference name is the website domain
        const websiteDomain = fullWebsite();

        let mutation = `
            mutation ClaimTender_CreateAdhocSupplierOrganisation {
              create_organisation(
                name: "${name}"
                website: "${websiteDomain}"
                unique_reference_name: "${websiteDomain}"
                type: ${orgType}
                country: ${country}
                currency: ${currencyCode}
                is_adhoc: true
              ){
                error{type, message}
                organisation {
                  id
                  unique_reference_name
                  info{
                    name
                  }
                }
              }
            }
        `

        customGraphRequest(
            mutation,
            (data) => {
                console.log('Created ad-hoc supplier', data)
                props.showAlertModal('success', 'Supplier created', <div>
                    <p><b>The supplier has been created:</b></p>
                    <p><b>Name:</b> {name}</p>
                    <p><b>Website (SLD + TLD):</b> {websiteDomain}</p>
                    <p><b>Reference name:</b> {websiteDomain}</p>
                    <p><b>Type:</b> {orgType}</p>
                    <p><b>Country:</b> {country}</p>
                    <p><b>Currency:</b> {currencyCode}</p>
                </div>);

                // Close the create modal, and open the invite supplier modal
                props.setOpen(false);
                props.setNewlyCreatedAdhocOrganisation(data.organisation);
                props.setShowAddSupplierModal(true);
            },
            (error) => {
                props.onError(error);
            }
        );

    }


    return (
        <Transition.Root show={props.open} as={Fragment} onClick={() => props.setOpen(false)}>
            <Dialog as="div" className="fixed z-[50] inset-0 overflow-y-auto" onClose={() => {
                props.setOpen(false)
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-50"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-50"
                    leaveTo="opacity-0"
                >
                    {/*<Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-40 transition-opacity" onClick={()=>props.setOpen(false)} />*/}
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="flex items-center justify-center min-h-screen">

                    {/* To prevent Headless UI focusing on the first button (focusable-object) */}
                    <button className='opacity-0 pointer-events-none w-[0px] h-[0px] absolute'></button>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="flex justify-center items-center w-full h-full">

                            <div className='bg-white rounded-xl'
                                 onClick={(e) => {
                                     e.preventDefault(); // prevent closing the modal from clicking inside of the main content
                                 }}
                            >

                                <div className="w-[40rem] flex flex-col justify-center items-center p-8">

                                    <h1 className='p-4 mb-4 font-light text-3xl'>
                                        Create an ad-hoc supplier
                                    </h1>

                                    <div className='divider'></div>


                                    <div className='flex flex-col gap-8 w-full '>

                                        {/* NAME */}
                                        <Tooltip position='top' content='The name of this supplier organisation'>
                                            <label>Name</label>
                                            <input className="input" type="text" value={name} placeholder={'Joes Pianos'}
                                                   onChange={e => setName(e.target.value)}/>
                                        </Tooltip>

                                        {/* WEBSITE */}
                                        <Tooltip position='top' content={<>
                                            <p>The website address of this supplier</p>
                                            <p>Only the domain name(joespiano) and the top level domain(.co.za) are required.
                                                E.g:</p>
                                            <p className='tracking-wider p-1 text-lg'>
                                                <span className="line-through tracking-widest italic">https://www.instruments.</span>
                                                <b>&nbsp;joespianos.co.za&nbsp;</b>
                                                <span className="line-through tracking-widest italic">/index.html</span>
                                            </p>
                                        </>}>

                                            <label>Website</label>

                                            <div className="flex justify-around gap-14">

                                                <Tooltip position='bottom'
                                                         message={<div>
                                                             <p>The name of the website (SLD). E.G:</p>
                                                             <p>
                                                                 <span className='line-through'>www.</span>
                                                                 <span> takealot </span>
                                                                 <span className='line-through'>.co.za</span>
                                                             </p>
                                                             <p>
                                                                 <span className='line-through'>www.</span>
                                                                 <span> mediamarkt </span>
                                                                 <span className='line-through'>.nl</span>
                                                             </p>
                                                         </div>}
                                                >
                                                    <label className='text-sm'>Name</label>
                                                    <input className='input'
                                                           type="text" value={website}
                                                           placeholder='joespianos'
                                                           onChange={(e) => {
                                                               // Get the value from the event target
                                                               let inputValue = e.target.value;

                                                               // Change to lower case, remove any whitespace and full stops
                                                               let processedValue = inputValue.toLowerCase().replace(/\s+/g, '').replace(/\./g, '');

                                                               // Set the website state with the cleaned and processed value
                                                               setWebsite(processedValue);
                                                           }}/>
                                                </Tooltip>
                                                <Tooltip position='bottom'
                                                         message={<div>
                                                             <p>The ending of the website (TLD). E.G:</p>
                                                             <p>.com</p>
                                                             <p>.co.za</p>
                                                             <p>.nl</p>
                                                         </div>}>
                                                    <label className='text-sm'>Top level domain</label>
                                                    <input className='input'
                                                           type="text" value={websiteTLD}
                                                           placeholder='.co.za'
                                                           onChange={(e) => {
                                                               // Get the value from the event target
                                                               let inputValue = e.target.value;

                                                               // Change to lower case, remove any whitespace
                                                               let processedValue = inputValue.toLowerCase().replace(/\s+/g, '');

                                                               // Set the website state with the cleaned and processed value
                                                               setWebsiteTLD(processedValue);
                                                           }}/>
                                                </Tooltip>

                                            </div>

                                        </Tooltip>

                                        {/* TYPE + COUNTRY + CURRENCY */}
                                        <div className="flex justify-around gap-14">

                                            <div className="w-full">
                                                <label>Organisation type</label>
                                                <br/>
                                                <input
                                                    className='input disabled' disabled
                                                    onChange={(e) => setOrgType(e.target.value)}
                                                    value={orgType}
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label>Country</label>
                                                <br/>
                                                <input
                                                    className='input disabled' disabled
                                                    onChange={(e) => setCountry(e.target.value)}
                                                    value={country}
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label>Currency</label>
                                                <br/>
                                                <input
                                                    className='input disabled' disabled
                                                    onChange={(e) => setCurrencyCode(e.target.value)}
                                                    value={currencyCode}
                                                />
                                            </div>

                                        </div>
                                    </div>



                                    <div className='divider'></div>

                                    <div className='flex gap-4 mt-4 w-full justify-center'>

                                        <Tooltip position='top' message='Create this supplier organisation. You will still need to invite this supplier to tender on selected items'>
                                            <button
                                                className='btn'
                                                onClick={()=>{
                                                    props.showConfirmModal(
                                                        'success',
                                                        'Create supplier',
                                                        (<div>
                                                            <p>Are you sure you want to create this supplier?</p>
                                                            <p></p>
                                                            <p><b>Name:</b> {name}</p>
                                                            <p><b>Website (SLD + TLD):</b> {fullWebsite()}</p>
                                                            <p><b>Reference name:</b> {fullWebsite()}</p>
                                                            <p><b>Type:</b> {orgType}</p>
                                                            <p><b>Country:</b> {country}</p>
                                                            <p><b>Currency:</b> {currencyCode}</p>
                                                        </div>),
                                                        'Create supplier',
                                                        onCreateSupplier,
                                                )
                                                }}
                                            >
                                            Create supplier
                                            </button>
                                        </Tooltip>

                                        <button
                                            className="btn-outline"
                                            onClick={() => props.setOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}


const ITEMS_TENDERING = `
query ClaimTender{
  items_tendering(|placeholder|) {
    error {
      type
      message
    }
    
    claim{
      id
      insurer_claim_number
      location_text
      country
      organisation{
        info{
          name
          currency_symbol
          currency
          logo_image_url
        }
      }
      delivery_costs {
        supplier_id
        delivery_cost
        is_adhoc
      }
      status
      status_reason
      
      items {
        claimant_quote
        id
        date_created
        date_updated
        is_tender_item
        is_manual
        status
        status_reason
        type
        category {
          id
          category_a
          category_b
          category_c
          category_d
          display_name
        }
        product {
          id
          date_created
          date_updated

          date_verified
          date_released
          dates_released

          brand
          category
          common_name
          description

          status
          type

          ean_codes
          metadata
          model_number
          model_numbers
          
          ai_generated

          images {
            main
            thumbnail_web
            thumbnail_print
          }
          properties {
            name
            display_name
            value
          }
        }

      }
      
      
      
    }

    suppliers_and_items {
      
      supplier {
        id
        unique_reference_name
        info {
          name
        }
      }

      items {
        id
        is_tender_item
        category {
          display_name
        }
        quantity
        supplier_panel {
          id
          name
          unique_reference_name
          status
          recommended_replacement {
            product {
              id
              date_verified
              date_released
              dates_released

              brand
              category
              common_name
              description

              status
              type

              images {
                thumbnail_web
              }

            }
            quote {
              id
              status
              currency_symbol
              currency
              price
            }
          }
        }
        
        possible_replacements {
          product {
            id
            dates_released
            common_name
            
            brand
            
            images {
              thumbnail_web
            }

          }
          quotes {
            id
            price
            supplier {
              id
              unique_reference_name
            }
          }
        }
        product {
          id

          date_verified
          date_released
          dates_released
            
          brand
          category
          common_name
          description

          status
          type

          images {
            thumbnail_web
          }

        }
        replacement {
          product {
            id
            date_verified
            date_released
            dates_released
            
            brand
            category
            common_name
            description

            status
            type

            images {
              thumbnail_web
            }

          }
          quote {
            id
            status
            price
            supplier {
              id
            }
          }

        }
      }
      
    }
    
  }
}
`
















import Tooltip from "../../components/Tooltip";

export default function DevelopmentComponentDemo(props) {


    function modals() {
        return (
            <div className="flex flex-col justify-center items-center gap-5">


                <div className="flex justify-center gap-4">
                    <button className="btn-success"
                            onClick={() => {
                                props.showConfirmModal('success', 'type: success', 'test', 'test', (options) => alert('Confirmed clicked'), (options) => alert('Canceled clicked'));
                            }}
                    >
                        Show confirm modal
                    </button>
                    <button className="btn"
                            onClick={() => {
                                props.showConfirmModal('convert', 'type: convert', 'test', 'test', (options) => alert('Confirmed clicked'), (options) => alert('Canceled clicked'));
                            }}
                    >
                        Show confirm modal
                    </button>
                    <button className="btn-danger"
                            onClick={() => {
                                props.showConfirmModal('warning', 'type: warning', 'test', 'test', (options) => alert('Confirmed clicked'), (options) => alert('Canceled clicked'));
                            }}
                    >
                        Show confirm modal
                    </button>
                </div>


                <div className="flex justify-center gap-4">
                    <button className="btn-success"
                            onClick={() => {
                                props.showAlertModal('success', 'type: success', 'test', 'test', () => alert('Modal closed'));
                            }}
                    >
                        Show Alert modal
                    </button>
                    <button className="btn-light"
                            onClick={() => {
                                props.showAlertModal('info', 'type: info', 'test', 'test', () => alert('Modal closed'));
                            }}
                    >
                        Show Alert modal
                    </button>
                    <button className="btn-danger"
                            onClick={() => {
                                props.showAlertModal('error', 'type: error', 'test', 'test', () => alert('Modal closed'));
                            }}
                    >
                        Show Alert modal
                    </button>
                </div>


                <div className="flex justify-center gap-4">
                    <button className="btn-success"
                            onClick={() => {
                                props.showNotificationModal('success', 'type: success', 'test', 'test', (options) => alert('onClose()'));
                            }}
                    >
                        Show Notification modal
                    </button>
                </div>


                <div className="flex justify-center gap-4">
                    <button className="btn-success"
                            onClick={() => {
                                props.showToastNotificationModal('success', 'type: success', 'test', 2000, (options) => alert('onClose'));
                            }}
                    >
                        Show Toast notification
                    </button>
                </div>

                <div className="flex justify-center gap-4">
                    <button className="btn-success"
                            onClick={() => {

                                let content = (
                                    <div className="w-[40rem] h-[40rem] flex flex-col justify-center items-center">
                                        <p>Custom content modal body</p>
                                    </div>
                                );

                                props.showCustomModal(
                                    content,
                                    (options) => alert('onClose')
                                );
                            }}
                    >
                        Show Custom Modal
                    </button>
                </div>


            </div>
        );
    }

    function buttons() {
        return (
            <div className='flex flex-col h-full w-1/2 justify-center items-center'>
                <div className="flex flex-wrap justify-center items-center gap-5">

                    <button className="btn">btn</button>
                    <button className="btn-primary">btn-primary</button>
                    <button className="btn-light">btn-light</button>
                    <button className="btn-success">btn-success</button>
                    <button className="btn-danger">btn-danger</button>
                    <button className="btn-disabled">btn-disabled</button>
                    <button className="btn-error">btn-error</button>
                    <div className='w-full'></div>
                    <button className="btn-outline">btn-outline</button>
                    <button className="btn-outline-primary">btn-outline-primary</button>
                    <button className="btn-outline-light">btn-outline-light</button>
                    <button className="btn-outline-success">btn-outline-success</button>
                    <button className="btn-outline-danger">btn-outline-danger</button>
                    <button className="btn-outline-error">btn-outline-error</button>
                    <button className="btn-outline" disabled>btn-outline-disabled</button>

                </div>
            </div>
        );
    }

    function tooltips(){
        return (
            <div>
                <div>

                    <Tooltip position="left" message="Tooltip message">
                        <button className="btn">Tooltip left</button>
                    </Tooltip>

                    <Tooltip position="top" message="Tooltip message">
                        <button className="btn">Tooltip top</button>
                    </Tooltip>

                    <Tooltip position="bottom" message="Tooltip message">
                        <button className="btn">Tooltip bottom</button>
                    </Tooltip>

                    <Tooltip position="right" message="Tooltip message">
                        <button className="btn">Tooltip right</button>
                    </Tooltip>

                </div>
                <div>

                    <Tooltip position="left"
                             message="Tooltip very long tooltip message to see what happens Tooltip very long tooltip message to see what happens">
                        <button className="btn">Tooltip left long</button>
                    </Tooltip>

                    <Tooltip position="top"
                             message="Tooltip very long tooltip message to see what happens Tooltip very long tooltip message to see what happens">
                        <button className="btn">Tooltip top (long) long</button>
                    </Tooltip>

                    <Tooltip position="bottom"
                             message="Tooltip very long tooltip message to see what happens Tooltip very long tooltip message to see what happens">
                        <button className="btn">Tooltip bottom long</button>
                    </Tooltip>

                    <Tooltip position="right"
                             message="Tooltip very long tooltip message to see what happens Tooltip very long tooltip message to see what happens">
                        <button className="btn">Tooltip right long</button>
                    </Tooltip>

                </div>

                <div>

                    <Tooltip position="left"
                             message={
                        <div>
                            <p>Tooltip JSX</p>
                            <b>Tooltip JSX</b>
                            <i>Tooltip JSX</i>
                            <br/>
                            Tooltip JSX
                        </div>
                    }>
                        <button className="btn">Tooltip JSX</button>
                    </Tooltip>

                    <Tooltip position="top"
                             message={
                        <div>
                            <p>Tooltip JSX</p>
                            <b>Tooltip JSX</b>
                            <i>Tooltip JSX</i>
                            <br/>
                            Tooltip JSX
                        </div>
                    }>
                        <button className="btn">Tooltip JSX</button>
                    </Tooltip>

                </div>
            </div>
        );
    }


    return (<div className="w-full h-full flex flex-col align-center justify-center gap-[10rem]">

        <div className='flex justify-around gap-5 outline outline-2 outline-gray-200'>
            {modals()}

            {buttons()}
        </div>

        <div className='flex justify-around gap-5 outline outline-2 outline-gray-200'>

            {tooltips()}

        </div>


    </div>);

}
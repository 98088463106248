// Library imports
import React, {useState} from "react";
import {useParams} from "react-router-dom";

// Local imports
import AlertModal from '../modals/AlertModal.jsx';
import NotificationCenter from '../../components/NotificationCenter.jsx';

import {resetPassword} from "../../utils/coreApi.js";
import {alertError, classNames} from "../../utils/helpers.js";


const PasswordReset = () => {

    // UI states
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        "iconType": "success",
        "heading": "",
        "message": "",
    });

    const [notificationCenterOpen, setNotificationCenterOpen] = useState(false);
    const notificationCenterMessage = {
        "heading": "Password changed",
        "message": "Your password has successfully been changed. Redirecting to login page..."
    }

    // React router
    const {token} = useParams();

    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const upperAndLowerCaseMatch = /(?=.*[a-z])(?=.*[A-Z])/;
    const containsANumberMatch = /\d/;

    const onSetPasswordClicked = (event) => {

        if (password1 !== password2) {
            setAlertOptions({
                "iconType": "error",
                "heading": "Password mismatch",
                "message": "The two entered passwords do not match",
            });
            setAlertOpen(true);
            return;
        }

        // Consists of at least 12 characters.
        if (password1.length < 12) {
            setAlertOptions({
                "iconType": "error",
                "heading": "Password length",
                "message": "Atleast 12 characters are required",
            });

            setAlertOpen(true);
            return;
        }

        // Includes UPPER & lower case characters.
        if (!upperAndLowerCaseMatch.test(password1)) {
            setAlertOptions({
                "iconType": "error",
                "heading": "Case error",
                "message": "Atleast one upper and lower case character is required",
            });

            setAlertOpen(true);
            return;
        }

        // Includes one or more numbers.
        if (!containsANumberMatch.test(password1)) {
            setAlertOptions({
                "iconType": "error",
                "heading": "Number required",
                "message": "Atleast one number is required",
            });

            setAlertOpen(true);
            return;
        }

        // Do not include the example password
        if (password1 === "silverCloud2019") {
            setAlertOptions({
                "iconType": "error",
                "heading": "Unique password required",
                "message": "Please use a unique password",
            });

            setAlertOpen(true);
            return;
        }

        resetPassword(
            token,
            password1,
            password2,
            (data) => {

                setNotificationCenterOpen(true);
                setTimeout(() => {
                    window.location = "/login"
                }, 3000);
            },
            (error) => {
                alertError(setAlertOpen, setAlertOptions, error);
            }
        );

    };

    // Page content
    return (
        <>

            <AlertModal
                open={alertOpen}
                setOpen={setAlertOpen}
                options={alertOptions}
            />

            <NotificationCenter
                open={notificationCenterOpen}
                message={notificationCenterMessage}
            />

            <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">

                {/* HEADER */}
                <div class="sm:mx-auto sm:w-full sm:max-w-md">
                    <img class="mx-auto h-24 w-auto" src="/logo512.png" alt="SLVRCLD logo"/>
                    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Reset password
                    </h2>
                </div>

                {/* BODY */}
                <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

                        <p class="text-gray-900 mb-5">
                            Enter your new password.
                        </p>

                        <div class="space-y-6">

                            {/* PASSWORD 1 */}
                            <div>
                                <label for="password" class="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div class="mt-1">
                                    <input onChange={(e)=>setPassword1(e.target.value)}
                                           type="password"
                                           autoComplete=""
                                           required
                                           class="input"
                                    />
                                </div>
                            </div>

                            {/* PASSWORD 2 */}
                            <div>
                                <label class="block text-sm font-medium text-gray-700">
                                    Confirm password
                                </label>
                                <div class="mt-1">
                                    <input onChange={(e)=>setPassword2(e.target.value)}
                                           type="password"
                                           autoComplete=""
                                           required
                                           class="input"/>
                                </div>
                            </div>

                            {/* SUBMIT */}
                            <div>
                                <button class="btn w-full justify-center" onClick={onSetPasswordClicked}>
                                    Set password
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className="py-8 px-4 text-gray-500 shadow sm:rounded-lg sm:px-10 mt-5 italic">
                        <p className="text-gray-700 mb-1 not-italic underline" id="">Password requirements:</p>

                        <p className={password1.length < 12 ? '' : 'text-green-600'}>Consists of at least 12 characters</p>

                        <p className={upperAndLowerCaseMatch.test(password1) ? 'text-green-600' : ''}>Includes UPPER & lower case characters</p>

                        <p className={containsANumberMatch.test(password1) ? 'text-green-600' : ''}>Includes one or more numbers</p>

                        <p className={classNames(
                            password1.length < 11 ? 'hidden' : '', // only show if the password1 is 11 characters or more
                            password1 !== password2 ? 'text-red-700' : 'text-green-600',
                        )}>
                            Passwords match
                        </p>

                        <p>For example: <i>silverCloud2019</i></p>
                    </div>

                </div>

            </div>
        </>
    );
};

export default PasswordReset;
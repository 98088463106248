//NOTE: https://silvercloud.atlassian.net/wiki/spaces/~963011682/pages/1015349280/How+to+complete+HAI+tasks

// Library imports
import React, {useEffect, useMemo, useState} from 'react';
import {createSearchParams, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {
    CloudUploadIcon,
    ExternalLinkIcon, LogoutIcon,
    ReplyIcon
} from '@heroicons/react/outline';
import DataTable from '../../components/DataTable';
import {XIcon} from "@heroicons/react/solid";

// Local imports
import {
    debounceQuery,
    haiAssignTask,
    haiTaskDelete,
    haiTaskResolve,
    haiTaskSearch, queryAccounts,
} from '../../utils/coreApi.js';
import {classNames, formatAsTitle, isEmpty} from '../../utils/helpers.js';
import Safe from "../../components/Safe";
import ClaimItemSearch from "../claim/ClaimItemSearch";
import {useDocumentTitle} from "../../components/PageTitle";
import {BasicImage} from "../../components/base/BasicImage";

export default function AdminHAI(props) {

    useDocumentTitle("HAI");

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    // Data states
    const [tab, setTab] = useState('TASKS');
    const [suppliers, setSuppliers] = useState([]); // get organisation of type SUPPLIER

    // not refactored
    const [allHaiTasks, setAllHaiTasks] = useState([]);
    const [tasksTableData, setTasksTableData] = useState([]); // the row data for the HAI tasks table
    const [archivedTasksTableData, setArchivedTasksTableData] = useState([]); // the row data for the archived HAI tasks table

    const [showTaskModal, setShowTaskModal] = useState(false);

    const [taskQueryAmount, setTaskQueryAmount] = useState(10);
    const [datatablePaginationPage, setDatatablePaginationPage] = useState(1);

    const [taskQuery_Supplier, setTaskQuery_Supplier] = useState('');
    const [taskQuery_Category, setTaskQuery_Category] = useState('');
    const [taskQuery_Status, setTaskQuery_Status] = useState('NEW');
    const [taskQuery_Country, setTaskQuery_Country] = useState('');
    const [taskQuery_SourceURL, setTaskQuery_SourceURL] = useState('');
    const [taskQuery_DateCreated, setTaskQuery_DateCreated] = useState('');
    const [taskQuery_TaskType, setTaskQuery_TaskType] = useState('');



    const [taskInModal, setTaskInModal] = useState(null); // trigger change
    const [protoSelectedToLink, setProtoSelectedToLink] = useState(null); // trigger change

    const [taskModal_supplier, setTaskModal_supplier] = useState('');
    const [taskModal_sku, setTaskModal_sku] = useState('');
    const [taskModal_modelNumber, setTaskModal_modelNumber] = useState('');
    const [taskModal_ean, setTaskModal_ean] = useState('');
    const [taskModal_protoId, setTaskModal_protoId] = useState('');

    // All the columns for the HAI tasks DataTable. The row cells can also possibly be found here
    const tasksTableColumns = useMemo(() => [
        {
            Header: 'Task type',
            accessor: 'taskType',
            // Cell: (cell) =>
            //     <p className="text-sm">
            //         {formatAsTitle(cell.row.original.haiTask?.task_type || '-')}
            //     </p>,
            Filter: ({column}) => {
                return (
                    <div className="mt-1">
                        <select className="select table-filter-search"
                                value={column.filterValue || ''}
                                onChange={(e) => {
                                    column.setFilter(e.target.value || undefined);
                                }}
                        >
                            <option value="">{`Search all`}</option>
                            <option value="QUOTE_MATCHING">QUOTE_MATCHING</option>
                        </select>
                    </div>
                );
            }

        },
        {
            Header: 'Description',
            accessor: 'description',
            Filter: ({column}) => {
                return (
                    <div className="mt-1 tailwind-tooltip-container">
                        <input className="table-filter-search"
                               value={column.filterValue || ''}
                               onChange={(e) => {
                                   column.setFilter(e.target.value || undefined);
                               }}
                               placeholder={`Search all ${column.filteredRows.length}`}
                        />
                        <span className='tailwind-tooltip -top-[300%]'>Filter by the source URL</span>
                    </div>
                );
            },
            Cell: (cell) =>
                <a className="text-sm text-blue-800 flex max-w-[20rem] max-h-[8rem] overflow-scroll tailwind-tooltip-container scrollbar-hide py-4"
                   target="_blank"
                   href={cell.row.original.haiTask?.data?.source_url || '#'}>
                    <ExternalLinkIcon className="w-5 h-5 mr-2"/>
                    <p className='overflow-scroll scrollbar-hide text-overflow-ellipsis h-[4rem]'>
                        {cell.row.original.haiTask?.description || '-'}
                    </p>
                    <span
                        className='tailwind-tooltip '> <b><i>Click to view the product</i></b> {cell.row.original.haiTask?.data?.source_url || ''}</span>

                </a>
        },
        {
            Header: 'Country',
            accessor: 'country',
            Filter: ({column}) => {
                return (
                    <div className="mt-1">
                        <select className="select table-filter-search"
                                value={column.filterValue || ''}
                                onChange={(e) => {
                                    column.setFilter(e.target.value || undefined);
                                }}
                        >
                            <option value="">{`Search all`}</option>
                            <option value="NL">Netherlands</option>
                            <option value="ZA">South Africa</option>
                            <option value="GB">Great Britain</option>
                            <option value="BE">Belgium</option>
                            <option value="AU">Australia</option>
                        </select>
                    </div>
                );
            }
        },
        {
            Header: 'Supplier',
            accessor: 'supplier',
            Filter: ({column}) => {
                return (
                    <div className="mt-1 tailwind-tooltip-container">
                        <input className="table-filter-search"
                               value={column.filterValue || ''}
                               onChange={(e) => {
                                   column.setFilter(e.target.value || undefined);
                               }}
                               placeholder={`Search all`}
                        />
                        <span className='tailwind-tooltip -top-[300%]'>Filter by a supplier's full name as shown in this column</span>
                    </div>
                );
            }
        },
        {
            Header: 'Category',
            accessor: 'category',
            Filter: ({column}) => {
                return (
                    <div className="mt-1">
                        <select className="select table-filter-search"
                                value={column.filterValue || ''}
                                onChange={(e) => {
                                    column.setFilter(e.target.value || undefined);
                                }}
                        >
                            <option value="">{`Search all`}</option>
                            {/*{productCategories.map((category) => <option value={category}>{formatAsTitle(category)}</option> )}*/}
                            {productCategories.map((category) => {
                                return (<option value={category} key={category}>{category}</option>);
                            })}
                        </select>
                    </div>
                );
            }
        },
        {
            Header: 'Data',
            accessor: 'haiTaskData',
            Cell: (cell) =>
                <p className="text-sm overflow-scroll tailwind-tooltip-container scrollbar-hide pl-2"
                   href={cell.row.original.haiTask?.data?.source_url || '#'}>

                    <p className="text-sm">Brand: {cell.row.original.haiTask?.data?.brand ? cell.row.original.haiTask?.data?.brand : ''} </p>
                    <p className="text-sm">Model: {cell.row.original.haiTask?.data?.model_numbers ? cell.row.original.haiTask?.data?.model_numbers : ''} </p>
                    <p className="text-sm">EAN: {cell.row.original.haiTask?.data?.ean_code ? cell.row.original.haiTask?.data?.ean_code : ''} </p>

                    <span className='tailwind-tooltip '>
                        <i>Full data object:</i>
                        {cell.row.original.haiTask?.data ? JSON.stringify(cell.row.original.haiTask?.data) : ''}
                    </span>

                </p>
        },
        {
            Header: 'Date created',
            accessor: 'dateCreated',
            Filter: ({column}) => {
                return (
                    <div className="mt-1 tailwind-tooltip-container">
                        <input className="table-filter-search"
                               value={column.filterValue || ''}
                               onChange={(e) => {
                                   column.setFilter(e.target.value || '');
                               }}
                               placeholder={`Search all`}
                        />
                        <span className='tailwind-tooltip -top-[300%]'>Format must be: YYYY-MM-DD HH:MM:SS</span>
                    </div>
                );
            }
        },
        {
            Header: 'Status',
            accessor: 'status',
            Filter: ({column}) => {
                return (
                    <div className="mt-1">
                        <select className="select table-filter-search"
                                value={column.filterValue || ''}
                                onChange={(e) => {
                                    column.setFilter(e.target.value || undefined);
                                }}
                        >
                            {/*use ${column.filteredRows.length} to get the table's total rows after filters have been applied*/}
                            <option value="">{`Search all`}</option>
                            <option value="NEW">NEW</option>
                            <option value="IN_PROGRESS">IN_PROGRESS</option>
                            <option value="RESOLVED">RESOLVED</option>
                            <option value="DELETED">DELETED</option>
                            {/*<option value="DELETED">Deleted</option>*/}
                        </select>
                    </div>
                );
            }
        },
        {
            Header: 'Actions',
            searchable: false,
            accessor: 'identifir',
            headerStyle: 'text-right',
            id: 'edit',
            width: '150%',
            Cell: ({cell}) => (
                <div className="text-sm">

                    <div className="flex justify-between">

                        <button
                            className="btn-outline text-xs py-1.5 px-2 mx-1"
                            onClick={() => {
                                setTaskInModal(cell.row.original.haiTask);
                            }}>
                            Add Data
                        </button>

                        {/* Ignore */}
                        <button
                            className="btn-outline-light text-xs py-1.5 px-2 mx-1"
                            onClick={() => onMarkTaskAsIgnore(cell)}>
                            Ignore
                        </button>

                        {/* X delete */}
                        <button
                            className="btn-outline-danger text-xs py-1.5 px-2 mx-1"
                            onClick={() => onDeleteTask(cell)}>
                            X
                        </button>

                    </div>


                </div>
            )
        }
    ], [tasksTableData]);
    const archiveTableColumns = useMemo(() => [
        {Header: 'ID', accessor: 'taskId'},
        {Header: 'Created Date', accessor: 'dateCreated'},
        {Header: 'Updated Date', accessor: 'dateUpdated'},
        {Header: 'Resolved Date', accessor: 'dateResolved'},
        {Header: 'Category', accessor: 'category'},
        {
            Header: 'Status',
            accessor: 'status',
            Filter: ({column}) => {
                return (
                    <div className="mt-1">
                        <select className="select table-filter-search"
                                value={column.filterValue || ''}
                                onChange={(e) => {
                                    column.setFilter(e.target.value || undefined);
                                }}
                        >
                            {/*use ${column.filteredRows.length} to get the table's total rows after filters have been applied*/}
                            <option value="">{`Search all`}</option>
                            <option className='text-black' value="NEW">NEW</option>
                            <option className='text-black' value="IN_PROGRESS">IN_PROGRESS</option>
                            <option className='text-black' value="RESOLVED">RESOLVED</option>
                            <option className='text-black' value="DELETED">DELETED</option>
                            {/*<option value="DELETED">Deleted</option>*/}
                        </select>
                    </div>
                );
            }
        },
        {Header: 'URL', accessor: 'link'},
        {
            id: 'Delete', accessor: 'actions',
            searchable: false,
            Cell: ({cell}) => (
                <button
                    className="btn-outline-danger text-xs py-1.5 px-2 mx-1"
                    onClick={() => onDeleteTask(cell)}>
                    X
                </button>
            )
        }], [archivedTasksTableData]);

    // On every page load
    useEffect(() => {

        if (!props.user) {
            return;
        }

        initializeURLParams();

        updateHaiTasks();

        //  GET SUPPLIERS LIST
        queryAccounts(
            // props.mainOrganisation.id,
            null,
            null,
            (data) => {
                let allSupplierAccounts = [];

                allSupplierAccounts = data.accounts.filter(account => account.type === 'ADMINISTRATOR_SUPPLIER' || account.type === 'INSURER_SUPPLIER');

                // Suppliers should only be the organisation 2 in 'ADMINISTRATOR_SUPPLIER' and 'INSURER_SUPPLIER' account types
                let allSuppliers = allSupplierAccounts.map(account => account.organisation_2);

                // Remove duplicates based on an id key
                allSuppliers = allSuppliers.reduce((acc, current) => {
                  const isDuplicate = acc.some(item => item.id === current.id);
                  if (!isDuplicate) {
                    acc.push(current);
                  }
                  return acc;
                }, []);

                setSuppliers(allSuppliers);

            },
            (error) => props.onError(error)
        );

    }, [props.user]);

    useEffect(() => {
        /** on page load, check if a proto has been passed from the item search page */
        checkForLinkedProto();
    }, [tasksTableData]);

    useEffect(() => {
        /** When the "hai task currently in the modal" is updated, or the "selected proto to link to hai task" is updated, populate the HAI modal */
        onEditTaskData();
    }, [taskInModal, protoSelectedToLink]);

    useEffect(() => {

        // If the hai tasks haven't been fetched, don't update the table
        // allHaiTasks could be [] empty, because no results are returned from the query
        if (!allHaiTasks) return;

        updateTasksTable();
        updateArchivedTable();
    }, [allHaiTasks]);

    useEffect(() => {
        updateHaiTasks();
    }, [taskQueryAmount, datatablePaginationPage, taskQuery_Status, taskQuery_Category, taskQuery_Supplier, taskQuery_SourceURL, taskQuery_Country, taskQuery_TaskType, taskQuery_DateCreated]);


    const updateHaiTasks = () => {

        props.showToastNotificationModal('success', 'Updating...', '', 2000);

        debounceQuery(
            'haiTaskSearch',
            1000,
            () => {
                haiTaskSearch({
                        resultLimit:taskQueryAmount,
                        page:datatablePaginationPage,
                        supplier:taskQuery_Supplier,
                        status:taskQuery_Status,
                        productCategory:taskQuery_Category,
                        sourceURL:taskQuery_SourceURL,
                        country:taskQuery_Country,
                        dateCreated:taskQuery_DateCreated,
                        taskType:taskQuery_TaskType,
                    },
                    (data) => {
                        setAllHaiTasks(data.tasks);
                        props.showToastNotificationModal('success', 'HAI tasks updated', 'Successfully updated HAI tasks', 2500);
                    }, (error) => {
                        props.onError(error);
                    }
                );
            }
        );

    };

    const updateTasksTable = (supplier = null) => {
        /** Create the data for the HAI Tasks DataTable */

        let allTasks = [];
        allHaiTasks.forEach(task => {

            // Only new and in-progress tasks should appear in the HAI table
            // However - if a status filter has specifically been chosen, then ignore this check
            if(!taskQuery_Status){
                if (task.status !== 'NEW' && task.status !== 'IN_PROGRESS') return;
            }

            let haiTask = {
                // taskType: formatAsTitle(task.task_type),
                taskType: task.task_type,
                description: task.description,
                country: task.data.country || '-',
                supplier: task.data.supplier?.unique_reference_name || '-',
                category: task.data.category,
                dateCreated: task.date_created,
                status: task.status,
                id: task.id,
                haiTaskData: task.data,
                haiTask: task,
            };
            allTasks.push(haiTask);
        });

        setTasksTableData(allTasks);
    };

    const updateArchivedTable = () => {

        let allTasks = [];
        allHaiTasks.forEach(task => {

            // Only resolved and deleted tasks should appear in the HAI table
            if (task.status !== 'RESOLVED' && task.status !== 'DELETED') return;

            let archivedTask = {
                taskId: <p className="text-sm">{task.id}</p>,
                dateCreated: task.date_created.slice(0, 10), // status: i.status,
                dateUpdated: task.date_updated.slice(0, 10),
                dateResolved: task.date_resolved ? task.date_resolved.slice(0, 10) : '-',
                category: formatAsTitle(task.data.category),
                link: <a className="text-blue-900 flex" href={task.data.source_url} target="_blank">
                    <ExternalLinkIcon className="h-4 w-4 mr-2"/>
                    {task.data.source_url}
                </a>,
                status: formatAsTitle(task.status),
                taskType: formatAsTitle(task.task_type),
                description: <p className="text-sm max-w-[20rem]">{task.description}</p>,
                haiTask: task
            };
            allTasks.push(archivedTask);
        });
        setArchivedTasksTableData(allTasks);

        console.log('allTasks', allTasks)
    };

    const checkForLinkedProto = () => {
        /** When the HAI page has been loaded, check if a Proto item was passed to it from the ClaimItemSearch page */

        if (!location.state) return; // state passed between pages must be done via the location hook

        // Set the HAI modal proto id field to the proto id that was passed from the ClaimItemSearch page
        let linkedProtoId = location.state.protoItemId;
        setTaskModal_protoId(linkedProtoId);

        // Find the HAI task that was linked to the proto item, so that we can open the HAI modal with that task
        if (isEmpty(tasksTableData)) return; // if the tasks table data has not been loaded yet, do not continue
        // find the task that would have requested the ClaimItemSearch
        let haiTask = tasksTableData.find(task => task.haiTask.id === location.state.haiTaskId);
        if (!haiTask) {
            // props.showAlertModal('error', 'HAI Task not found', 'The HAI task that was linked to the proto item was not found');
            props.showToastNotificationModal('info', 'Task not available', 'The HAI task that requested this linked proto, is no longer available', 3000);
            console.error('The HAI task that requested this linked proto, is no longer available');
            console.error('location.state', location.state)
            return; // task was not found
        }
        setTaskInModal(haiTask.haiTask);

        if (!location.state.protoItem) return; // no proto item was passed
        if (protoSelectedToLink) return; // if a linked proto has already been set, do not re-set again
        setProtoSelectedToLink(location.state.protoItem);


    };

    function initializeURLParams(){
        /** On page load, check for query params in the route/url, and set the filters accordingly */

        // https://ultimatecourses.com/blog/query-strings-search-params-react-router
        let searchParamsObject = Object.fromEntries([...searchParams])

        /** http://portal.slvrcld.com/hai_admin?category=action_sports_cameras&supplier_name=test&status=new&country_code=za&description=http://www.takealot.com/productidhere */

        console.log('searchParamsObject', searchParamsObject)

        /** Set the parameters in the URL to the state objects */
        if('supplier_name' in searchParamsObject) setTaskQuery_Supplier(searchParamsObject.supplier_name)
        if('category' in searchParamsObject) setTaskQuery_Category(searchParamsObject.category.toUpperCase())
        if('status' in searchParamsObject) setTaskQuery_Status(searchParamsObject.status.toUpperCase())
        if('country_code' in searchParamsObject) setTaskQuery_Country(searchParamsObject.country_code.toUpperCase())
        if('description' in searchParamsObject) setTaskQuery_SourceURL(searchParamsObject.description)
        if('task_type' in searchParamsObject) setTaskQuery_TaskType(searchParamsObject.task_type.toUpperCase())
        if('date_created' in searchParamsObject) setTaskQuery_DateCreated(searchParamsObject.date_created)

    }

    const onEditTaskData = () => {

        if (!taskInModal) return;

        // Set the modal's SKU input to the HAI task's SKU
        setTaskModal_sku(taskInModal.data?.sku_code || '');

        autoSelectSupplier();

        // If a linked proto was found, the expected behavior is that a proto was selected from the item search page and redirected here, so open the modal for that task
        if (protoSelectedToLink) {
            setTaskModal_protoId(protoSelectedToLink.id);
        }

        // EAN
        setTaskModal_ean(taskInModal.data?.ean_code || '');

        // Model number
        setTaskModal_modelNumber(taskInModal.data?.model_numbers?.[0] || '');

        // Clear any hai task filter query params before opening the ClaimItemSearch (in the task modal)
        let params = createSearchParams([])
        navigate(`${location.pathname}?${params.toString()}`, {replace:true, state: location.state})

        // Show the modal
        setShowTaskModal(true);
    };

    useEffect(() => {
        /** Auto selecting the supplier in the HAI Modal may fail, because the list of suppliers has not been fetched yet.
         * When the list of supplier's has been updated, and there is "a task selected" to view in the modal, then try auto select the supplier */
        autoSelectSupplier()
    }, [suppliers]);
    const autoSelectSupplier = () => {

        if(!taskInModal) return;

        // Auto select this HAI task supplier in the supplier drop down, based on the taskInModal.data.supplier_sKU
        let supplierSKU = taskInModal.data?.supplier?.unique_reference_name || null;
        if (supplierSKU) {
            let supplier = suppliers.find(sup => sup.unique_reference_name === supplierSKU);
            if (supplier) setTaskModal_supplier(supplier.id);
        }
    }

    const onAssignSelf = (haiTaskId, successCallBack) => {
        haiAssignTask(props.user.id, haiTaskId, (data) => {
            props.showToastNotificationModal('success', 'Task assigned', 'Successfully self-assigned to HAI task');
            if (successCallBack) successCallBack();
        }, (error) => {
            props.onError(error);
        });
    };

    const onMarkTaskAsIgnore = (rowData) => {
        if (rowData.row.original.haiTask.status === 'IN_PROGRESS') {
            props.showAlertModal('info', 'Can not ignore', 'This task is in-progress, and therefor can not be ignored');
            return;
        }

        let taskId = rowData.row.original.haiTask.id;
        haiTaskResolve(props.user.id, taskId, `EXCLUDE_QUOTE`, null, null, (data) => {
            props.showAlertModal('success', 'HAI task ignored', 'The HAI task has been successfully ignored');
            updateHaiTasks();
        }, (error) => {
            props.onError(error);
        });
    };

    const onDeleteTask = (rowData) => {

        let taskId = rowData.row.original.haiTask.id;
        props.showConfirmModal('warning', 'Delete HAI Task', 'Are you sure you want to delete this HAI task?', 'Delete', () => {
            haiTaskDelete(taskId, (data) => {
                props.showAlertModal('success', 'HAI task deleted', 'The HAI task has been successfully deleted');
                updateHaiTasks();
            }, (error) => {
                props.onError(error);
            });
        }, null);

    };

    const onSaveTaskData = () => {

        if (!haiTaskHasAssignedUsers(taskInModal)) {
            // props.showAlertModal('info', 'HAI Task status is NEW', 'Please assign a user to the task to move it into IN_PROGRESS ');
            onAssignSelf(taskInModal.id, saveTask);
        } else {
            saveTask();
        }

    };

    const saveTask = () => {
        let query = [];
        query += `{field:"supplier", value:"${taskModal_supplier}"}`;
        query += `{field:"sku_code", value:"${taskModal_sku}"}`;
        query += `{field:"ean", value:"${taskModal_ean}"}`;
        query += `{field:"model_number", value:"${taskModal_modelNumber}"}`;
        query += `{field:"proto_id", value:"${taskModal_protoId}"}`;

        query += `{field:"link", value:"${taskInModal.data.source_url}"}`;

        haiTaskResolve(
            props.user.id,
            taskInModal.id,
            'LINK_DATA_TO_PROTO',
            query,
            null,
            (data) => {
                updateHaiTasks();
                props.showNotificationModal('success', 'Successfully resolved', 'The HAI task has successfully been resolved');
            },
            (error) => {
                props.onError(error);
            });
        setShowTaskModal(false);
    };

    const haiTaskHasAssignedUsers = (haiTask) => {
        if (isEmpty(haiTask.assignees))
            return false;

        let userIsAssigned = haiTask.assignees[0].user
        // if (!haiTask.assignees[0].user)
        //     return false;

        // Return true or false depending on if the user object is truthy or falsy
        return !!userIsAssigned;
    };


    const [taskFilters, setTaskFilters] = useState([]);
    const [tableInstance, setTableInstance] = useState(null);
    useEffect(() => {
        /** When the filters in the tasks DataTable header's filter inputs are modified, this useEffect is triggered
         * Update the local state copies of the filters accordingly */
        if (!taskFilters.filters) return;
        setTaskQuery_Status(taskFilters.filters.find(f => f.id === 'status')?.value || null);
        setTaskQuery_Category(taskFilters.filters.find(f => f.id === 'category')?.value || null);
        setTaskQuery_Country(taskFilters.filters.find(f => f.id === 'country')?.value || null);
        setTaskQuery_Supplier(taskFilters.filters.find(f => f.id === 'supplier')?.value || null);
        setTaskQuery_SourceURL(taskFilters.filters.find(f => f.id === 'description')?.value || null);
        setTaskQuery_DateCreated(taskFilters.filters.find(f => f.id === 'dateCreated')?.value || null);
        setTaskQuery_TaskType(taskFilters.filters.find(f => f.id === 'taskType')?.value || null);
    }, [taskFilters]);

    useEffect(() => {
        /** If the filters states are updated (via the URL params on page load), then set the table's filters accordingly */
        if(!tableInstance) return;

        let filter;

        filter = tableInstance.columns.find(c => c.id === 'category')
        if(filter) filter.setFilter(taskQuery_Category)

        filter = tableInstance.columns.find(c => c.id === 'supplier')
        if(filter) filter.setFilter(taskQuery_Supplier)

        filter = tableInstance.columns.find(c => c.id === 'status')
        if(filter) filter.setFilter(taskQuery_Status)

        filter = tableInstance.columns.find(c => c.id === 'country')
        if(filter) filter.setFilter(taskQuery_Country)

        filter = tableInstance.columns.find(c => c.id === 'description')
        if(filter) filter.setFilter(taskQuery_SourceURL)

        filter = tableInstance.columns.find(c => c.id === 'dateCreated')
        if(filter) filter.setFilter(taskQuery_DateCreated)

        filter = tableInstance.columns.find(c => c.id === 'taskType')
        if(filter) filter.setFilter(taskQuery_TaskType)

    }, [taskQuery_Category, taskQuery_Supplier, taskQuery_Country, taskQuery_Status, taskQuery_SourceURL, taskQuery_DateCreated, taskQuery_TaskType]);

    const allProps = {
        ...props,
        location,
        tab, setTab,
        suppliers, setSuppliers,
        allHaiTasks, setAllHaiTasks,
        tasksTableData, setTasksTableData,
        archivedTasksTableData, setArchivedTasksTableData,
        showTaskModal, setShowTaskModal,
        taskQueryAmount, setTaskQueryAmount,
        datatablePaginationPage, setDatatablePaginationPage,
        taskQuery_Supplier, setTaskQuery_Supplier,
        taskQuery_Category, setTaskQuery_Category,
        taskQuery_Status, setTaskQuery_Status,
        taskQuery_Country, setTaskQuery_Country,
        taskQuery_SourceURL, setTaskQuery_SourceURL,
        taskInModal, setTaskInModal,
        protoSelectedToLink, setProtoSelectedToLink,
        taskModal_supplier, setTaskModal_supplier,
        taskModal_sku, setTaskModal_sku,
        taskModal_modelNumber, setTaskModal_modelNumber,
        taskModal_ean, setTaskModal_ean,
        taskModal_protoId, setTaskModal_protoId,

        onSaveTaskData,

    }

    return (<>

        <div className="body">

            <div className="">

                {/* PAGE HEADER */}
                <div className="page-header-bar">

                    <h1 className="page-header-title">
                        HAI
                    </h1>

                    {/* DATE AND TIME */}
                    <p className='page-header-title ml-0 pr-8 text-sm'>
                        {new Date().toLocaleString('en-US', {day: '2-digit'})} {new Date().toLocaleString('en-US', {month: 'long'})} {new Date().getFullYear()}
                    </p>

                </div>


                <div className="widget">


                    <div className='flex items-center justify-around'>

                        {/* PAGE RESULTS AMOUNT */}
                        <div className="flex justify-center items-center gap-2">
                        <p className="">Results per page:</p>
                        <button className={`px-5 py-2 shadow-none text-lg ${taskQueryAmount === 10 ? 'btn-primary' : 'btn-light'} `}
                                onClick={() => {
                                    setTaskQueryAmount(10);
                                    setDatatablePaginationPage(1);
                                }}>10
                        </button>
                        <button className={`px-5 py-2 shadow-none text-lg ${taskQueryAmount === 25 ? 'btn-primary' : 'btn-light'} `}
                                onClick={() => {
                                    setTaskQueryAmount(25);
                                    setDatatablePaginationPage(1);
                                }}>25
                        </button>
                        <button className={`px-5 py-2 shadow-none text-lg ${taskQueryAmount === 50 ? 'btn-primary' : 'btn-light'} `}
                                onClick={() => {
                                    setTaskQueryAmount(50);
                                    setDatatablePaginationPage(1);
                                }}>50
                        </button>
                        <input className={ classNames(
                            'input w-24 outline outline-offset-4 outline-cyan-400 m-0 text-lg shadow-none',
                                ![10, 25, 50].includes(taskQueryAmount) ? 'outline-2' : 'outline-0'
                            )}
                               maxLength={5000}
                               minLength={1}
                               placeholder='#'
                               type='number'
                            onChange={(e) => {
                                setTaskQueryAmount(e.target.value);
                                setDatatablePaginationPage(1);
                            }}
                        />
                    </div>


                        {/* TASKS ARCHIVE */}
                        <div className="flex w-1/4 gap-2">
                            <button
                                type="button"
                                onClick={() => setTab('TASKS')}
                                className={classNames(
                                    'w-full justify-center',
                                    tab === 'TASKS' ? 'btn' : 'btn-light'
                                )}
                            >
                                Tasks
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setTab('ARCHIVED')

                                    /** When tabbed to the Archive table, set the task query status to RESOLVED, and fetch HAI tasks */
                                    setTaskQuery_Status('RESOLVED');
                                }}
                                className={classNames(
                                    'w-full justify-center',
                                    tab === 'ARCHIVED' ? 'btn' : 'btn-light'
                                )}
                            >
                                Archives
                            </button>
                        </div>

                    </div>


                    {/* TABLES | in progress task - archived tasks */}
                    {tab === 'TASKS' ?
                        <Safe>
                            <DataTable
                                data={tasksTableData}
                                columns={tasksTableColumns}
                                pageSize={taskQueryAmount}

                                parentPagination={true}
                                datatablePaginationPage={datatablePaginationPage}
                                setDatatablePaginationPage={setDatatablePaginationPage}

                                setFilters={setTaskFilters}
                                setTableInstance={setTableInstance}
                                stateFilters={[
                                    /** When the table columns or data state is modified, the table is reloaded and the filters are lost.
                                     * To keep the filters (which is needed if we wish to use the filters for graphql querying), we pass down the initial state of the filters
                                     * So when the table is reloaded, the filters will be reloaded to as they were, in their previous state */
                                    taskQuery_Status ?
                                        {id: 'status', value: taskQuery_Status} : {},
                                    taskQuery_SourceURL ?
                                        {id: 'description', value: taskQuery_SourceURL} : {},
                                    taskQuery_Country ?
                                        {id: 'country', value: taskQuery_Country} : {},
                                    taskQuery_Supplier ?
                                        {id: 'supplier', value: taskQuery_Supplier} : {},
                                    taskQuery_Category ?
                                        {id: 'category', value: taskQuery_Category} : {},
                                    taskQuery_TaskType ?
                                        {id: 'taskType', value: taskQuery_TaskType} : {},
                                    taskQuery_DateCreated ?
                                        {id: 'dateCreated', value: taskQuery_DateCreated} : {},

                                ]}
                            />
                        </Safe>
                        :
                        <Safe>
                            <DataTable
                                data={archivedTasksTableData}
                                columns={archiveTableColumns}
                                pageSize={taskQueryAmount}

                                parentPagination={true}
                                datatablePaginationPage={datatablePaginationPage}
                                setDatatablePaginationPage={setDatatablePaginationPage}

                                setFilters={setTaskFilters}
                                setTableInstance={setTableInstance}
                                stateFilters={[
                                    /** When the table columns or data state is modified, the table is reloaded and the filters are lost.
                                     * To keep the filters (which is needed if we wish to use the filters for graphql querying), we pass down the initial state of the filters
                                     * So when the table is reloaded, the filters will be reloaded to as they were, in their previous state */
                                    taskQuery_Status ?
                                        {id: 'status', value: taskQuery_Status} : {},
                                    taskQuery_SourceURL ?
                                        {id: 'description', value: taskQuery_SourceURL} : {},
                                    taskQuery_Country ?
                                        {id: 'country', value: taskQuery_Country} : {},
                                    taskQuery_Supplier ?
                                        {id: 'supplier', value: taskQuery_Supplier} : {},
                                    taskQuery_Category ?
                                        {id: 'category', value: taskQuery_Category} : {},
                                    taskQuery_TaskType ?
                                        {id: 'taskType', value: taskQuery_TaskType} : {},
                                    taskQuery_DateCreated ?
                                        {id: 'dateCreated', value: taskQuery_DateCreated} : {},

                                ]}
                            />
                        </Safe>
                    }

                </div>


            </div>

        </div>


        {/* ADD DATA MODAL */}
        <Safe>
            <AddDataModal
                {...allProps}
            />
        </Safe>
    </>);
}

function AddDataModal(props) {

    const navigate = useNavigate();

    const [showItemSearch, setShowItemSearch] = useState(true);

    useEffect(() => {
        /** When the modal gets closed, clear the taskInModal state */
        if (!props.showTaskModal) {
            props.setTaskInModal(null);
            props.setTaskModal_supplier(null);
            props.setTaskModal_sku('');
            props.setTaskModal_modelNumber('');
            props.setTaskModal_ean('');
            props.setTaskModal_protoId('');
            props.setProtoSelectedToLink(null);
            setShowItemSearch(true);
        }
    }, [props.showTaskModal]);
    function closeTaskModal(){

        // Clear the route query params from the ClaimItemSearch
        // This can not be placed in a useEffect otherwise it will clear the hai task filter query params
        let params = createSearchParams([])
        navigate(`${props.location.pathname}?${params.toString()}`, {replace:true, state: props.location.state})

        props.setShowTaskModal(false);
    }

    function onLinkToProto() {
        setShowItemSearch(!showItemSearch);
    }

    let haiPlaceholderClaimId = process.env.REACT_APP_HAI_CLAIM_ID;

    function onProtoSelectedToLinkToHAITask(product, haiTask){
        props.setProtoSelectedToLink(product);
    }

    function renderSelectedProto(){
        return (
            <div className='tailwind-tooltip-container'
                     onClick={() => {
                         // REMOVE SELECTED PROTO
                         props.setProtoSelectedToLink(null);
                         props.setTaskModal_protoId('');
                     }}
                >
                    <BasicImage
                        src={props.protoSelectedToLink.images?.thumbnail_web}
                        fallbackSrc={'https://product-image-assets.s3.eu-west-1.amazonaws.com/generic/photounavailable.png'}
                        alt="Product Image"
                        sizeWidthRem="6"
                        className="object-center bg-white"
                    />

                    <span className='tailwind-tooltip -top-10 left-0 flex gap-1'>
                        <XIcon className="w-5 h-5 text-red-500 cursor-pointer"/>
                        Click to remove
                    </span>

                    <div>
                        <p>Name: {props.protoSelectedToLink.common_name}</p>
                        <p>Brand: {props.protoSelectedToLink.brand}</p>
                        <p>Released: {props.protoSelectedToLink.date_released}</p>
                    </div>
                </div>
        );
    }

    function onDeleteHAITask(){
        // TODO the parent already has this code. This code is being duplicated here
        let taskId = props.taskInModal.id;
        if (!taskId) return props.showAlertModal('error', 'Can not delete HAI task', 'The HAI task ID is missing');
        props.showConfirmModal('warning', 'Delete HAI Task', 'Are you sure you want to delete this HAI task?', 'Delete', () => {
            haiTaskDelete(taskId, (data) => {
                props.showAlertModal('success', 'HAI task deleted', 'The HAI task has been successfully deleted', () => {
                    props.setShowTaskModal(false);
                });
            }, (error) => {
                props.onError(error);
            });
        }, null);
    }

    function onIgnoreHAITask(){
        // TODO the parent already has this code. This code is being duplicated here
        let taskId = props.taskInModal.id;

        if (props.taskInModal.status === 'IN_PROGRESS') {
            props.showAlertModal('info', 'Can not ignore', 'This task is in-progress, and therefor can not be ignored');
            return;
        }

        haiTaskResolve(props.user.id, taskId, `EXCLUDE_QUOTE`, null, null, (data) => {
            props.showAlertModal('success', 'HAI task ignored', 'The HAI task has been successfully ignored');
        }, (error) => {
            props.onError(error);
        });
    }



    if (!props.showTaskModal) return <></>;

    return (
        <>


            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={closeTaskModal}
                ></div>
                <div className="flex items-center min-h-screen px-4 py-8">

                    <div className={classNames(
                        'relative p-4 flex flex-col items-center mx-auto bg-white rounded-md shadow-lg',
                        showItemSearch ? 'w-[80%] h-full' : 'w-auto'
                    )}>

                        <h1 className="flex justify-center text-gray-500 mt-5">
                            Add Data To This Quote
                        </h1>


                        <h1 className="flex justify-center text-gray-500 ">
                            Please make sure you have assigned the quote first
                        </h1>

                        <div className="p-13">
                            <div className=" sm:flex m-5">

                                {/* SUPPLIER */}
                                <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                    <label htmlFor="project-name" className="text-gray-500">
                                        Supplier
                                    </label>
                                    <div className="mt-1">
                                        <div>
                                            <select type="text"
                                                    className="input w-[20rem]"
                                                    id="supplier_x"
                                                    value={props.taskModal_supplier || ''}
                                                    onChange={(e) => props.setTaskModal_supplier(props.suppliers.find(sup => sup.id === e.target.value).id)}
                                            >
                                                {props.suppliers.map((sup) => {
                                                    return (
                                                        <option value={sup.id} key={sup.id}>
                                                            {sup.info.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="text-gray-300 mt-1 pl-2 transition hover:text-gray-600 text-sm w-[20rem]">
                                        * The suppliers connected to
                                        <br/>
                                        {props.mainOrganisation.info.name}
                                    </div>
                                </div>

                                {/* SKU */}
                                <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                    <label htmlFor="project-name" className="text-gray-500">
                                        SKU
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="sku_x"
                                            type="text"
                                            className="input w-[20rem]"
                                            value={props.taskModal_sku || ''}
                                            onChange={(e) => props.setTaskModal_sku(e.target.value)}
                                        />
                                    </div>
                                    <div className="items-center gap-2 mt-3 sm:flex">
                                    </div>
                                </div>
                            </div>

                            <div className=" sm:flex m-5">

                                {/* MODEL NUMBER */}
                                <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                    <label htmlFor="project-name" className="text-gray-500">
                                        Model Number
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="model_number_x"
                                            type="text"
                                            value={props.taskModal_modelNumber || ''}
                                            onChange={e => props.setTaskModal_modelNumber(e.target.value)}
                                            className="input w-[20rem]"
                                        />
                                    </div>
                                    <div className="items-center gap-2 mt-3 sm:flex">
                                    </div>
                                </div>

                                {/* EAN */}
                                <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                    <label htmlFor="project-name" className="text-gray-500">
                                        Ean
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="ean_x"
                                            type="text"
                                            value={props.taskModal_ean || ''}
                                            onChange={e => props.setTaskModal_ean(e.target.value)}
                                            className="input w-[20rem]"
                                        />
                                    </div>
                                    <div className="items-center gap-2 mt-3 sm:flex">
                                    </div>
                                </div>
                            </div>

                            <div className=" sm:flex m-5">

                                {/* PROTO ID */}
                                <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                    <label htmlFor="project-name" className="text-gray-500">
                                        Proto ID
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="proto_x"
                                            type="text"
                                            value={props.taskModal_protoId || ''}
                                            onChange={e => props.setTaskModal_protoId(e.target.value)}
                                            className="input w-[20rem]"
                                        />
                                    </div>
                                    <div className="items-center gap-2 mt-3 sm:flex">
                                    </div>
                                </div>

                                {/* SELECTED PROTO */}
                                <div className="mt-1 flex flex-col items-center w-full text-sm text-gray-400">
                                    {!props.protoSelectedToLink ?
                                        <p>No product linked</p>
                                        :
                                        renderSelectedProto()
                                    }
                                </div>

                            </div>


                            <div className="flex justify-end">

                                {/* LINK TO PROTO */}
                                <button
                                    className="btn-outline text-xs text-center p-2 px-2 mx-1 gap-1"
                                    onClick={onLinkToProto}
                                >
                                    {showItemSearch ?
                                        <>
                                            <ReplyIcon className="w-5 h-5"/>
                                            Close item search
                                        </>
                                        :
                                        <>
                                            <LogoutIcon className="w-5 h-5"/>
                                            Link to proto
                                        </>
                                    }
                                </button>

                                {/* SAVE */}
                                <button className="btn text-center"
                                        onClick={props.onSaveTaskData}
                                >
                                    <CloudUploadIcon className="w-5 h-5"/>
                                    SAVE
                                </button>
                            </div>


                        </div>


                        {showItemSearch &&
                            <div className='w-full h-[50vw] mb-10 overflow-scroll'>
                                <ClaimItemSearch
                                    {...props}
                                    claimId={haiPlaceholderClaimId}
                                    mode="linkToHaiTask"
                                    onProtoSelectedForHaiTask={onProtoSelectedToLinkToHAITask}
                                    haiTask={props.taskInModal}
                                    onDeleteHAITask={onDeleteHAITask}
                                    onIgnoreHAITask={onIgnoreHAITask}
                                />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    );
}


const productCategories =
    [
        'ACTION_SPORTS_CAMERAS',
        'AUDIO_AMPLIFIERS',
        'BLENDERS',
        'CAMCORDERS',
        'CAMERA_DRONES',
        'COFFEE_MAKERS',
        'COMPUTER_MONITORS',
        'DECODERS',
        'DIGITAL_CAMERAS',
        'DISHWASHERS',
        'ELECTRIC_KETTLES',
        'FREEZERS',
        'FRIDGES',
        'FRIDGE_FREEZERS',
        'GAME_CONSOLES',
        'HANDHELD_VACUUMS',
        'HEADPHONES',
        'HOBS',
        'INKJET_PRINTERS',
        'IRONS',
        'LASER_PRINTERS',
        'MICROWAVES',
        'NOTEBOOKS',
        'OVENS',
        'ROBOT_VACUUMS',
        'SIDE_BY_SIDE_FRIDGE_FREEZERS',
        'SMARTPHONES',
        'SMARTWATCHES',
        'SOUNDBARS',
        'TABLETS',
        'TOASTERS',
        'TUMBLE_DRYERS',
        'TVS',
        'UNKNOWN',
        'VACUUMS',
        'WASHER_DRYERS',
        'WASHING_MACHINES',
    ]

import React from 'react';

export const OrganisationType = {
    ROOT: 'ROOT',
    ADMINISTRATOR: 'ADMINISTRATOR',
    INSURER: 'INSURER',
    SUPPLIER: 'SUPPLIER',
};

function LimitedOrganisationAccess(props) {
    /**
     *  Check whether the main organisation type is in the list of types
     *  Used for component access control
     *  mainOrganisation: the main organisation object
     *  types: an array of organisation types to check for
     *      ROOT, ADMINISTRATOR, INSURER, SUPPLIER
     * */
    const checkOrganisationPermission = () => {

        if (!props.mainOrganisation || !props.types) return false;

        const mainOrganisationType = props.mainOrganisation.type;

        // ROOT has access to all components
        if(mainOrganisationType === OrganisationType.ROOT) return true;

        return props.types.includes(mainOrganisationType);

    };

    return (
        checkOrganisationPermission() ?
            props.children
            :
            <></>
    );
}

export default LimitedOrganisationAccess;
